const marathi = {
    select_language: "भाषा निवडा",
    instruction: "आम्ही शिफारस करतो की तुम्ही खाली सूचीबद्ध केलेली एक निवडा ",
    language: "इंग्रजी ",
    continue: "तुमचा व्हिडिओ कॉल सुरू ठेवण्यासाठी",
    // instruction:"तुमचा व्हिडिओ कॉल सुरू ठेवण्यासाठी आम्ही तुम्हाला खाली सूचीबद्ध केलेली <b> भाषा</b> निवडा अशी शिफारस करतो",

    // =================================
    //        Commence your video KYC
    // =================================
    verify_your_identity: "तुमची ओळख सत्यापित करा",
    handy_documents: "तुम्ही तुमच्या पूर्ण केवायसी प्रक्रियेला पुढे जाण्यापूर्वी कृपया तुमचे खालील कागदपत्रे हातात ठेवा.",
    aadhaar_verify: "आधार पडताळणी",
    pan_capture: "पॅन कॅप्चर",
    video_call: "व्हिडिओ कॉल (प्रश्नोत्तरे)",
    // =================================
    //              Modal
    // =================================

    user_consent: "वापरकर्ता संमती",
    user_instruction: "वर क्लिक करून ",
    user_agree: "'सहमत', ",
    user_here: "आपण याद्वारे:",
    // user_instruction:"<b>'सहमत'</b> वर क्लिक करून, तुम्ही याद्वारे:",
    // user_instruction1: "वैयक्तिक तपशील प्रदान करण्यासाठी Syntizen technologies प्रायव्हेट लिमिटेडने केलेली विनंती मान्य करा.",
    // user_instruction2: "माहिती सामायिक करून सर्व माहिती प्रवेश, कॉपी आणि संग्रहित करण्यासाठी माझी बिनशर्त एकाग्रता प्रदान करा.",
    // user_instruction3: "तसेच मी/आम्ही विनंती करणार्‍या संस्थेच्या वतीने तसे करण्यास अधिकृत आहोत आणि tkae एकमात्र आणि त्यासाठी संपूर्ण जबाबदारी स्वीकारतो.",
    user_instruction1: "आपले बँकर्ससह व्हिडिओ संवाद सत्र रेकॉर्डिंग मोडमध्ये असेल.",
    user_instruction2: "बँकरबरोबर व्हिडिओ संवाद सत्रादरम्यान एक थेट छायाचित्र टिपला जाईल.",
    user_instruction3: "आपले आधार तपशील व्ही-सीआयपी प्रक्रियेतील आधार सत्यापनासाठी वापरले जातील.",
    user_instruction4: "व्ही-सीआयपी प्रक्रियेमध्ये पॅन सत्यापन करण्यासाठी आपल्या पॅन कार्डचा एक फोटो गोळा केला जाईल.",
    user_instruction5: "आपले थेट स्थान व्ही-सीआयपी प्रक्रियेमध्ये मिळविले जाईल.",
    user_instruction6: "आपण व्हिडिओ संवाद सत्रादरम्यान सर्व तपशील बरोबर असल्याचे सुनिश्चित केले पाहिजे.",
    user_instruction7: "आधार एक्सएमएल पॅकेट किंवा आधार सुरक्षित क्यूआर कोड 3 दिवसांपेक्षा मोठा नसावा.",
    user_instruciton8: "वर नमूद केलेल्या सर्व पायऱ्या आरबीआयच्या मार्गदर्शक तत्त्वांनुसार आहेत.",
    // content_cdtn1: "मी, व्ही-सीआयपी आयडी",
    // content_cdtn2: " धारक वर नमूद केलेल्या सर्व मुद्द्यांशी सहमत आहे आणि याद्वारे, माझ्या संमतीची पुष्टी करतो.",
    accept_cdtn1: "मी, ",
    accept_cdtn2: " VCIP ID सह - ",
    accept_cdtn3: " वर नमूद केलेल्या सर्व मुद्द्यांशी सहमत आहे आणि याद्वारे, माझ्या संमतीची पुष्टी करा.",
    // disagree: "असहमत",
    agree: "सहमत",

    // =================================
    //              Token Number
    // =================================



    token_number: "तुमचा टोकन क्रमांक शून्य होईपर्यंत प्रतीक्षा करावी ही विनंती.",
    wait_time: "तुमचा अंदाजे प्रतीक्षा वेळ",
    wait_patinently: "या क्षणी आमचे सर्व एजंट व्यस्त असल्याने, आम्ही तुम्हाला धीराने प्रतीक्षा करण्याची आणि काही वेळाने परत येण्याची शिफारस करतो",
    // =================================
    //              Headers
    // =================================


    initiating_call: "व्हिडिओ कॉल सुरू करत आहे",
    choose_language: "एक भाषा निवडा",
    commence_video: "तुमचा व्हिडिओ केवायसी सुरू करा",
    aadhaar_xml: "आधार XML",
    kyc_details: "केवायसी तपशील",
    aadhaar_offline: "आधार ऑफलाइन केवायसी",
    download_sucess: "यशस्वीरित्या डाउनलोड करा",
    pan_verification: "पॅन पडताळणी",
    Keep_things_handy: "तुम्ही आमच्या एजंटसोबत व्हिडिओ कॉलसाठी तयार आहात का?",
    title_token: "टोकन क्रमांक",
    reschedule: "पुन्हा वेळापत्रक",
    status: "स्थिती",


    // =================================
    //              PAN
    // =================================


    Pan_heading: "पॅन कार्ड फोटो काढा",
    capture_photo: "फोटो कॅप्चर करा",
    retake: "पुन्हा घ्या",
    submit: "प्रस्तुत करणे",

    // =================================
    //              PAN Details
    // =================================



    ocr_info: "OCR माहिती",
    name: "नाव",
    father_name: "वडिलांचे नाव",
    dob: "DOB",
    pan_number: "पॅन क्रमांक",
    please_wait: "Please wait...",
    fetching_aadhar: "Fetching Aadhaar Details...!",
    uid: "UID",
    gender: "लिंग",
    Address: "पत्ता",
    xml_file: "तुमच्याकडे आधार XML फाईल आहे का?",
    yes: "होय",
    no: "नाही",



    waiting_agent: "एजंटची वाट पाहत आहे",
    end_call: "कॉल समाप्त करा",
    book_slot: "एक स्लॉट बुक करा",
    id_number:"ID Number",


    // =================================
    //              Kyc completed 
    // =================================


    kyc_update: "केवायसी सादर केला.",
    verification: "तुमचे सत्यापन पूर्ण झाले आहे आणि तुमचा सर्व डेटा सुरक्षितपणे संग्रहित केला आहे.",

       // ===================================
    //          Employement Details
    // ===================================

    occupation_details:"व्यवसाय तपशील",
    occupation_details_subtext:"तुमच्या पर्यायांपैकी एक निवडा"







}

export default marathi;