import React from "react";

const Button = (props) => {
  return (
    <>
    {
    props?.isClientTideAccess ?(
      <button
      className={props?.className}
      type={props?.type}
      style={{
        background:props?.joinnowcolorchange ?"#C8C9D0": "#1929D6",
        borderRadius: "256px",
        height: "44px",
        width:"99%",
        border:"none",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "20px",
        color: "#FFFFFF",
        opacity: props?.opacity
      }}
      data-toggle={props.datatoggle}
      data-target={props.datatarget}
      onClick={props?.click}
      disabled={props?.disabled}
    >
      {props?.title}
    </button>
     
    ):(
      <button
      className={props?.className}
      type={props?.type}
      style={{
        background: props.backgroundColor,
        color: props?.color,
        fontSize: props?.fontSize,
        opacity: props?.opacity,
        border:`1.5px solid ${props?.border}`
        // marginTop:props?.aadharbutton &&"-10%",
        
      }}
      data-toggle={props.datatoggle}
      data-target={props.datatarget}
      onClick={props?.click}
      disabled={props?.disabled}
    >
      {props?.title}
    </button>
    )
  }
    </>
  );
};

export default Button;
