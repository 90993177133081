import { ACTION_GET_DIGILOCKER_LINK_REQ, ACTION_GET_DIGILOCKER_STATUS_REQ, ACTION_GET_OKYC_GENERATE_OTP, ACTION_GET_OKYC_GET_CATCHA, ACTION_GET_OKYC_GET_DATA, ACTION_GET_OKYC_REFRESH_CATCHA, ACTION_GET_KINA_ENTER_ID, ACTION_GET_CUSTOMER_KINA_DETAILS, ACTION_GET_CUSTOMER_OCCUPATION_DETAILS, ACTION_SUBMIT_CUSTOMER_OCCUPATION_DETAILS, ACTION_GET_CUSTOMER_OCCUPATION_DROP_DOWN_DETAILS } from "./SagaActionTypes";


export const actionAadharDigiLocker = (payload) => {
    return {
        type: ACTION_GET_DIGILOCKER_LINK_REQ,
        payload: payload
    }
}


export const actionAadharDigiLockerStatus = (payload) => {
    return {
        type: ACTION_GET_DIGILOCKER_STATUS_REQ,
        payload: payload
    }
}


export const actionGetCaptcha = (payload) => {
    return {
        type: ACTION_GET_OKYC_GET_CATCHA,
        payload: payload
    }
}

export const actionRefreshCaptchaAction = (payload) => {
    return {
        type: ACTION_GET_OKYC_REFRESH_CATCHA,
        payload: payload
    }
}

export const actionGenerateCaptchaAction = (payload) => {
    return {
        type: ACTION_GET_OKYC_GENERATE_OTP,
        payload: payload
    }
}

export const actionOKYCDetailsAction = (payload) => {
    return {
        type: ACTION_GET_OKYC_GET_DATA,
        payload: payload
    }
}

export const actionKINAEnterID = (payload) => {
    return {
        type: ACTION_GET_KINA_ENTER_ID,
        payload: payload
    }
}
export const actionKINACustomerDetails = (payload)=> {
    return {
        type:ACTION_GET_CUSTOMER_KINA_DETAILS,
        payload:payload
    }
}


export const actionCustomerOccupationDetails = (payload)=> {
    return {
        type:ACTION_GET_CUSTOMER_OCCUPATION_DETAILS,
        payload:payload
    }
}

export const actionCustomerCoocupationDropDownDetails = (payload)=>{
    return {
        type:ACTION_GET_CUSTOMER_OCCUPATION_DROP_DOWN_DETAILS,
        payload:payload
    }
}

export const actionSubmitCustomerOccupationDetails = (payload)=> {
    return {
        type:ACTION_SUBMIT_CUSTOMER_OCCUPATION_DETAILS,
        payload:payload
    }
}