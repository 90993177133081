import { combineReducers } from 'redux';
import HomeReducer from './HomeReducer';
import VcipReducer from './VcipReducer';


const rootReducer = combineReducers({
    HomeReducer: HomeReducer,
    VcipReducer: VcipReducer
})

export default rootReducer;