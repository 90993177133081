import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";

const ChatCmpChime = (props) => {
  const val = sessionStorage.getItem("connectionId");
  // const [showPicker, setShowPicker] = useState(false);

  // const onEmojiClick = (event, emojiObject) => {
  //   props.setMessage((prevInput) => prevInput + emojiObject.emoji);
  //   setShowPicker(false);
  // };

  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const color = cssProperties?.button?.text_color;

  const bottomRef = useRef(null);
  const onfocusInput = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    onfocusInput.current.focus();
  }, [props]);

  const time = (val) => {
    return new Date(val).toLocaleTimeString("en-IN", { timeStyle: "short" });
  };

  const sendmsg = (event) => {
    event.preventDefault();
    props?.sendChatMessage(event);
  };

  return (
    <div
      style={{
        position: "relative",
        boxShadow: "0px 0px 20px rgba(31, 31, 31, 0.3)",
      }}
    >
      <div className="info border-right-0">
        <h5 className="info-title">
          <img src="../images/chaticon.svg" style={{ marginRight: ".5rem" }} />
          VIDEO KYC CHAT
          <div className="infoicon" onClick={props.handelBotfunction}>
            {props.letbot ? null : (
              <div
                style={{
                  color: "white",
                }}
              >
                &#10006;
              </div>
            )}
          </div>
        </h5>
        <div className="chat">
          <div className="chat-box" id="chatbox">
            {props?.bulk?.map((res, i) =>
              res?.sender == "0" ? (
                <div key={i} className="message-box message-box1">
                  <div className="bank-msg">
                    <div className="media">
                      <div className="media-body chat-right">
                        <p className="chat-msg">{res?.message?.notifymsg}</p>
                        <p className="chat-time text-right">
                          {time(res?.message?.msgtime)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={i} className="message-box message-box2">
                  <div className="user-msg">
                    <div className="media">
                      <div className="media-body chat-left">
                        <p className="chat-msg">{res.message.notifymsg}</p>
                        <p className="chat-time text-left">
                          {time(res?.message?.msgtime)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
            <div ref={bottomRef}></div>
          </div>
          <div className="inpCmp">
            <form
              className="chat-form"
              autoComplete="off"
              onSubmit={sendmsg}
              id="chatform"
            >
              <div className="form-group position-relative">
                {/* <button
                type="button"
                className="chat-icon icon1"
                onClick={() => setShowPicker((val) => !val)}
              >
                <i className="fas fa-laugh"></i>
              </button> */}
                <input
                  type="text"
                  className="chat-inp"
                  name="message"
                  onChange={props?.changeMessage}
                  value={props?.message}
                  placeholder="Type a Message"
                  ref={onfocusInput}
                  required
                />
                <button type="submit" className="chat-icon icon2">
                  {/* <i className="fas fa-microphone" /> */}
                  <img src="../images/chatenter.svg" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <div className="emojeeBox">
        {showPicker && (
          <Picker
            pickerStyle={{ width: "100%" }}
            skinTone={SKIN_TONE_MEDIUM_DARK}
            onEmojiClick={onEmojiClick}
          />
        )}
      </div> */}
    </div>
  );
};

export default ChatCmpChime;
