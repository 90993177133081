import toast from "react-hot-toast";
import { put, takeLatest, call, delay } from "redux-saga/effects";
import RouteNames from "../../Constants/RouteNames";
import Axios from "../../Service/axios";
import { actionAadharDigiLockerStatus, actionKINACustomerDetails } from "../SagaActions/AadharSagaActions";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/CommonSagaActions";
import { setVcipStatusesAction } from "../SagaActions/GetVcipDetailsSagaActions";
import {
  ACTION_GET_DIGILOCKER_LINK_REQ,
  ACTION_GET_DIGILOCKER_STATUS_REQ,
  ACTION_GET_OKYC_GENERATE_OTP,
  ACTION_GET_OKYC_GET_CATCHA,
  ACTION_GET_OKYC_GET_DATA,
  ACTION_GET_OKYC_REFRESH_CATCHA,
  ACTION_GET_KINA_ENTER_ID,
  ACTION_GET_CUSTOMER_OCCUPATION_DETAILS,
  ACTION_SUBMIT_CUSTOMER_OCCUPATION_DETAILS,
  ACTION_GET_CUSTOMER_OCCUPATION_DROP_DOWN_DETAILS,
} from "../SagaActions/SagaActionTypes";

const postCall = (URL, body) => {
  return Axios.post(URL, body).then((res) => {
    return res?.data;
  });
};

// GET DIGILOCKER URL
const getDigiLocckerReq = (model) => {
  const URL = "DigiLockerRequest";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getDigiLockerReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(getDigiLocckerReq, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      // toast.success(resp?.respdesc);
      toast.success("Pending...");
      const data = { url: resp?.url, txnid: resp?.txnid };
      if (action?.payload?.callback) {
        action?.payload?.callback(data);
      }
    } else {
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

// GET DIGILOCKER STATUS
const getDigiLocckerStatusReq = (model) => {
  const URL = "FetchDigiLockerTxnStatus";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};
function* getDigiLockerStatusReqSaga(action) {
  // yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(getDigiLocckerStatusReq, action?.payload?.model);
    // if (resp && resp?.respcode === "200") {
    if (resp?.statuscode === "1" && action?.payload?.callback) {
      toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
    } else if (resp) {
      action?.payload?.callback(resp);
    }
    if (resp?.respcode === "200" && resp?.status === "pending" && resp?.statuscode === "3") {
      if (window?.location?.pathname === RouteNames.AADHAR_KYC_KYC_DIGILOCCKER) {
        yield delay(5000);
        yield put(actionAadharDigiLockerStatus(action?.payload));
      }
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
    if (window?.location?.pathname === RouteNames.AADHAR_KYC_KYC_DIGILOCCKER) {
      yield delay(5000);
      yield put(actionAadharDigiLockerStatus(action?.payload));
    }
  } finally {
    // yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* getOKYCCaptchaReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postCall,
      "OKYCv3_GetCaptcha",
      action?.payload?.model
    );
    if (resp && resp?.respcode === "200") {
      // if (resp?.statuscode === "1" && action?.payload?.callback) {
      //   toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
      // } else if (resp) {
      //   action?.payload?.callback(resp);
      // }
    } else {
      action?.payload?.callback(resp);
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* getOKYCRefreshCaptchaReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postCall,
      "OKYCv3_RefreshCaptcha",
      action?.payload?.model
    );
    if (resp && resp?.respcode === "200") {
      // if (resp?.statuscode === "1" && action?.payload?.callback) {
      //   toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
      // } else if (resp) {
      //   action?.payload?.callback(resp);
      // }
    } else {
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


function* getOKYCGenerateOTPReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postCall,
      "OKYCv3_GenerateOTP",
      action?.payload?.model
    );
    if (resp && resp?.respcode === "200") {
      // if (resp?.statuscode === "1" && action?.payload?.callback) {
      //   toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
      // } else if (resp) {
      //   action?.payload?.callback(resp);
      // }
    } else {
      action?.payload?.callback(resp);
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


function* getOKYCDetailsReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postCall,
      "OKYCv3_GetKYCData",
      action?.payload?.model
    );
    if (resp && resp?.respcode === "200") {
      // if (resp?.statuscode === "1" && action?.payload?.callback) {
      //   toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
      // } else if (resp) {
      //   action?.payload?.callback(resp);
      // }
    } else {
      action?.payload?.callback(resp);
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const getCustomerKINAValidInput = (model) => {
  const URL = "VerifyKycData";
  return Axios.post(URL, model).then(res => { return res?.data })
}

function* getCustomerKINAValidInputReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
      const resp = yield call(getCustomerKINAValidInput, action?.payload?.model);
      if (resp && resp?.respcode === "200") {
          toast.success(resp?.respdesc);
          if (action?.payload?.callback) {
              action?.payload?.callback(resp);
              yield put(actionKINACustomerDetails(resp));

          }else{
            action?.payload?.callback(resp);
            yield put(actionKINACustomerDetails(resp));

            toast(resp?.respdesc);
          }
      } else {
          toast(resp?.respdesc);
      }
  } catch (err) {
      if (err.response) {
          toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
      } else {
          toast(err.message);
      }
  } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
  }
}



const getCustomerOccupationDetailsInput = () => {
  const URL = "GetCustomerOccupation";
  return Axios.post(URL).then(res => { return res.data })
}

function* getCustomerOccupationDetailsReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
      const resp = yield call(getCustomerOccupationDetailsInput);

      // console.log(resp,action.payload)
      if (resp && resp?.respcode === "200") {
          // toast.success(resp?.respdesc);
          if (action?.payload?.callback) {
              action?.payload?.callback(resp);
              // yield put(actionKINACustomerDetails(resp));

          }else{
            action?.payload?.callback(resp);
            // yield put(actionKINACustomerDetails(resp));

            // toast(resp?.respdesc);
          }
      } else {
          // toast(resp?.respdesc);
      }
  } catch (err) {
      if (err.response) {
          toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
      } else {
          toast(err.message);
      }
  } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


const getCustomerOccupationDropDownDetailsInput = (model) => {
  const URL = "Getcustomeroccupationv2";
  return Axios.post(URL,model).then(res => { return res.data })
}

function* getCustomerOccupationDropDownDetailsReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(getCustomerOccupationDropDownDetailsInput, action?.payload?.model);
    // console.log(resp,action.payload)
    if (resp && resp?.respcode === "200") {
      // toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
    } else {
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


const submitCustomerOcupationDetailes = (model) => {
  const URL = "SubmitCustomerOccupation";
  return Axios.post(URL, model).then(res => { return res?.data })
}

function* submitCustomerOccupationDetailsReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
      const resp = yield call(submitCustomerOcupationDetailes, action?.payload?.model);
      if (resp && resp?.respcode === "200") {
          toast.success(resp?.respdesc);
          if (action?.payload?.callback) {
              action?.payload?.callback(resp);
              // yield put(actionKINACustomerDetails(resp));

          }else{
            action?.payload?.callback(resp);
            // yield put(actionKINACustomerDetails(resp));

            toast(resp?.respdesc);
          }
      } else {
          toast(resp?.respdesc);
      }
  } catch (err) {
      if (err.response) {
          toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
      } else {
          toast(err.message);
      }
  } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
  }
}






export default function* AdharWatcherSaga() {
  yield takeLatest(ACTION_GET_DIGILOCKER_LINK_REQ, getDigiLockerReqSaga);
  yield takeLatest(
    ACTION_GET_DIGILOCKER_STATUS_REQ,
    getDigiLockerStatusReqSaga
  );
  yield takeLatest(ACTION_GET_OKYC_GET_CATCHA, getOKYCCaptchaReqSaga);
  yield takeLatest(ACTION_GET_OKYC_REFRESH_CATCHA, getOKYCRefreshCaptchaReqSaga);
  yield takeLatest(ACTION_GET_OKYC_GENERATE_OTP, getOKYCGenerateOTPReqSaga);
  yield takeLatest(ACTION_GET_OKYC_GET_DATA, getOKYCDetailsReqSaga);
  yield takeLatest(ACTION_GET_KINA_ENTER_ID, getCustomerKINAValidInputReqSaga);
  yield takeLatest(ACTION_GET_CUSTOMER_OCCUPATION_DETAILS, getCustomerOccupationDetailsReqSaga);
  yield takeLatest(ACTION_GET_CUSTOMER_OCCUPATION_DROP_DOWN_DETAILS, getCustomerOccupationDropDownDetailsReqSaga);
  yield takeLatest(ACTION_SUBMIT_CUSTOMER_OCCUPATION_DETAILS, submitCustomerOccupationDetailsReqSaga);
}
