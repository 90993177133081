import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Layout/Header";
import { useNavigate } from "react-router-dom";
import Customeridform from "../../Components/kinabank/Customeridform";
import AppFooter from "../Common/AppFooter";
import Button from "../../Components/Elements/Button";
import RouteNames from "../../Constants/RouteNames";
import {
  actionKINAEnterID
} from "../../Store/SagaActions/AadharSagaActions";
import { Text } from "../Language/Language";


function Customerid() {
  const [payrollid, setPayrollid] = useState();
  const [memberid, setMemberid] = useState();
  const [CustomerKINAId, setCustomerKINAId] = useState("");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const disabledbutton = (payrollid && memberid) != undefined ?false: true;
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);


// console.log(disabledbutton,"11111")
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "payrollid":
        setPayrollid(value);
        break;
      case "memberid":
        setMemberid(value);
        break;
      default:
        break;
    }
  };

  const SubmitCustomerIdDetails = ()=>{ 
    const vcipkeyData = sessionStorage.getItem("vcipkey");
    const model = {
      vcipkey: vcipkeyData,
      payrollid: payrollid,
      memberid:memberid,
    };
  
    dispatch(actionKINAEnterID({ model: model, callback: getcustomerIDDetails }));
  }
  const getcustomerIDDetails = (data) => {
    if (data?.respcode === "200") {
      setCustomerKINAId(data);
      navigate(RouteNames.CUSTOMER_DETAILS, {
        state:{
          data:data
        }
      })

    // if (data.respcode === "406") restartProcess();
    // if (data.respcode === "200") {
    //   setCaptchaDetails(data);
    //   setIsCaptchaTrue(true);
    //   setAadharapi(false);
    // }
  };
}


  return (
    <div className="idpage">
      <Header
        title={"Enter ID’s"}
        navigate={() => navigate(null, { replace: true })}
        hideHeader={false}
      />
      <Customeridform 
      handleChange={handleChange}
      payrollid={payrollid}
      memberid={memberid}
      />
      <div className="customeridbtn">
        <Button
          className={"custidsubbtn"}
          type="button"
          title={<Text tid="submit"/>}
          color={cssProperties?.button?.text_color}
          fontSize={cssProperties?.button?.font_size}
          backgroundColor={cssProperties?.button?.color}
          disabled= {disabledbutton}
          opacity={disabledbutton?"0.5":null}
          click={SubmitCustomerIdDetails}
        />
      </div>
    </div>
  );
}

export default Customerid;
