import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "./streaming.css";
import base64 from "base-64";
// import Timer from "react-timer-wrapper";
// import Timecode from "react-timecode";

import { selectPeers, useHMSStore, selectLocalPeer, selectRemotePeers, useHMSActions, selectBroadcastMessages, selectHMSMessages, selectTrackByID, selectPeerAudioByID, selectSpeakers, useHMSNotifications, HMSNotificationTypes, useScreenShare, useDevices, DeviceType } from "@100mslive/react-sdk";

import ChatCmp from "../../../Components/ChatCmp/ChatCmp";
import InitiatingVideoCallCmp from "../../../Components/VideoCallCmp/InitiatingVideoCallCmp";
import ChatCmpChime from "./ChatComponent";
import {
    actionNoficationListSaga,
    endVideoCallByAgentSagaAction,
    pushNotificationSaga,
    streamingEventByCustomerAction,
} from "../../../Store/SagaActions/CommonSagaActions";
import CleintNames from "../../../Constants/ClientNames";
import RouteNames from "../../../Constants/RouteNames";
import { Text } from "../../Language/Language";
import customeCodes from "../../../Constants/customeCodes";
import BackCameraAccessModal from "../../../Components/BackCameraAccessModal/BackCameraAccessModal";
import CallTimer from "../../../Components/TimeCounter";
import { create100msLiveRoomCodeSagaAction, uploadPanCapturedImageSagaAction } from "../../../Store/SagaActions/VideoSagaActions";
import { actionGetSingleCustomer, deleteCustomerAction } from "../../../Store/SagaActions/InitiateVideoCallSagaActions";

var isCallEndedByAgent = false;
var countImageCapture = 0;  
var remoteelement = [];
var enabledEndCallBtnTimer;
var notifyEndCallMsg;
var getCustomerStatusInterval;
var isMinimizedScreen = false;
var autoDropCount = "0";
const Initiatecall100MsLive = (props) => {
    const [letbot, setletBot] = useState(false);
    const [notifymsgcount, setnotifymsgcount] = useState(0);
    const [message, setMessage] = useState("");
    const [bulk, setBulk] = useState([]);
    // const [notifymsgstate, setNotifymsgstate] = useState(false);
    const [remotevideoelement, setRemotevideoelement] = useState([]);
    const [notifymsgstate, setNotifymsgstate] = useState(false);
    const [notificationList, setNotificationList] = useState({});
    const [isEnbledEndCallBtn, setisEnbledEndCallBtn] = useState(false);
    const [agentRoomStatus, setAgentRoomStatus] = useState("");
    const [capturedPanImage, setCapturedPanImage] = useState("");
    const [iscapturedPanImageDone, setIsCapturedPanImageDone] = useState(0);
    const [showLiveImageCircleSvg, setShowLiveImageCircleSvg] = useState(false);
    const [remotesubscriber,setRemotesubscriber] = useState([])


    const [localVideoState, setLocalVideoState] = useState(true);
    const [allowcameraflip, setAllowcameraflip] = useState(false);
    const [canvasdisplay, setCanvasdisplay] = useState(false);
    const [recapturepan, setRecapturepan] = useState(false);
    const [cameramirror, setCameramirror] = useState(true);
    const [imageName, setImageName] = useState("")
    const [autoEndCallTimeForCustomerDrop, setAutoEndCallTime] = useState("00:00");
    const [agentJoinStatus,setAgentJoinStatus]=useState(false)

    const hmsActions = useHMSActions();
    const LocalPeer = useHMSStore(selectLocalPeer);
    const RemotePeer = useHMSStore(selectRemotePeers);
    const messagenotification = useHMSNotifications(HMSNotificationTypes.NEW_MESSAGE);
    const remotepeerleftnotification = useHMSNotifications(HMSNotificationTypes.PEER_LEFT)
    // const streamingType = process.env.REACT_APP_STREAMING_TYPE;

    const dispatch = useDispatch();
    // const params = useParams();
    const params = props?.params;
    var notificationIntervalId;
    const autoEndCallTime = 300;
    var apiCount = 0;
    const navigate = useNavigate();
    const isPageDataAvailable = useSelector(
        (state) => state.HomeReducer.isPageDataAvailable
    );
    const clientName = useSelector((state) => state.HomeReducer.clientName);
    const isusfb = (clientName === CleintNames?.USFB || clientName === CleintNames?.USFBSYNTIZEN) ? true : false;
    const streamingEventObj = useSelector(
        (store) => store.VcipReducer.streamingEventObj
    );

    const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
    const { streamingType, streamingURL, enableStreamLoadBalance,enableGetCutomerKey } = dynamicSettingObj;
    const isEnableGetCutomerKey = enableGetCutomerKey == "true"
    const isEnableStreamLoadBalalnce = enableStreamLoadBalance == "true"
    const showAllowPopupFromCustomer = dynamicSettingObj?.videoCallConfig?.flow?.panReCap?.showAllowPopupFromCustomer
    const signatureCaptureAllowPopup = dynamicSettingObj?.videoCallConfig?.flow?.signatureCapture?.showAllowPopupFromCustomer

    useEffect(() => {
        console.log("*** initiating video call ");
        if (isPageDataAvailable && isEnableGetCutomerKey) {
            document.addEventListener('visibilitychange', handleVisibilityChange);
            getCustomerStatus()
            notifyEndCallEvent();
        }else if(isPageDataAvailable && !isEnableGetCutomerKey){
            roomjoin()
            notifyEndCallEvent();
        }else {
            clearNotificationInterval();
            clearTimeout(enabledEndCallBtnTimer);
            clearTimeout(notifyEndCallMsg);
            // clearInterval(enabledEndCallBtnTimer);
            // clearInterval(notifyEndCallMsg);
            if (sessionStorage.getItem("videoconfsessionid") && !sessionStorage.getItem("AgentSubscribed")) {
                if (isEnableGetCutomerKey) {
                    deleteCustomerData()
                  }
                endCallByCustomer("Customer Reloaded");
            }
            navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
        }
        return async () => {
            deleteCustomerData()
            hmsActions.endRoom(true,"end room")
            clearInterval(getCustomerStatusInterval)
            clearNotificationInterval();
            clearTimeout(enabledEndCallBtnTimer);
            clearTimeout(notifyEndCallMsg);
            // clearInterval(enabledEndCallBtnTimer);
            // clearInterval(notifyEndCallMsg);
            setShowLiveImageCircleSvg(false);
        };
    }, []);

    const handleVisibilityChange = () => {
        if (document.hidden) {
          // console.log("hidden");
          isMinimizedScreen = true
          // clearInterval(getCustomerStatusInterval)
        } else {
          // console.log("alive");
          isMinimizedScreen = false
          // getCustomerStatus()
        }
      };

    const getCustomerStatus = () => {
        getCustomerStatusInterval = setInterval(() => {
          if(!isMinimizedScreen){
            const model = {
              streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
              body: {
                videoconfsessionid: sessionStorage.getItem("videoconfsessionid")
              }
            }
            dispatch(actionGetSingleCustomer({
              model,
              callback: getSingleCustomerRes
            }))
          }
        }, 2000)
    }

    const getSingleCustomerRes = async (data) => {
        let timeoutId;
        if (data?.data?.customerJoinStatus == "1") {
            createRooomCode(data?.data?.roomId)
          clearTimeout(timeoutId);
          clearInterval(getCustomerStatusInterval)
        }
    }

    //create roomcode
  const createRooomCode = (data) => {
    const model = {
      roomid: data,
      role: "customer"
    }
    dispatch(create100msLiveRoomCodeSagaAction({
      model,
      callback: (res) => get100msLiveRoomCodedata(res)
    }))
  }

  const get100msLiveRoomCodedata = async (data) => {
    if (data?.enabled == true) {
      roomjoin(data?.code)
    }
  }

    useEffect(()=>{
        if((showAllowPopupFromCustomer == false && notificationList?.notificationid == "4") || (signatureCaptureAllowPopup == false && notificationList?.notificationid == "4")){
          setAllowcameraflip(false)
          setAllowcameraflip(false)
          flipCameraMode(true)
          setCameramirror(false)
          setCanvasdisplay(true)
          setRecapturepan(true)
        }
      },[showAllowPopupFromCustomer,notificationList?.notificationid,signatureCaptureAllowPopup])


    
    const roomjoin = async (romcode) => {
        const roomCode =romcode || sessionStorage.getItem("roomcodefor100mslive")
        const vcipid = sessionStorage.getItem("videoconfsessionid");
        const authToken = await hmsActions.getAuthTokenByRoomCode({ roomCode: roomCode });
        const config = {
            userName: vcipid,
            authToken: authToken,
            // settings: {
            //     isAudioMuted: false,
            //     isVideoMuted: false
            // },
            // rememberDeviceSelection: true, // remember manual device change
            // captureNetworkQualityInPreview: true // whether to measure network score
        };
        await hmsActions.join(config)
    }

    useEffect(() => {
        if (LocalPeer != undefined && LocalPeer?.videoTrack != undefined) {
            const videoElement = document.getElementById("localpeer")
            hmsActions.attachVideo(LocalPeer?.videoTrack, videoElement);
        }
    }, [LocalPeer])

    useEffect(() => {
        const remotevideo = document.getElementById("remotepeer");
        if (RemotePeer && RemotePeer.length > 0 ) {
            setAgentJoinStatus(true)
            RemotePeer.forEach((remotePeer) => {
                if (remotePeer?.videoTrack) {
                    setRemotesubscriber([remotePeer])
                    hmsActions.attachVideo(remotePeer.videoTrack, remotevideo);
                }
            });
        }
    }, [RemotePeer])

    useEffect(()=>{
        const minutes = autoEndCallTimeForCustomerDrop.split(":")[0]
        const seconds = autoEndCallTimeForCustomerDrop.split(":")[1]
        if(minutes >= "05" && seconds > "00" && !agentJoinStatus&& autoDropCount < "1"){
          setisEnbledEndCallBtn(true)
          autoDropCount = "1"
          var audio = new Audio("/tone.mp3");
          audio.play();
          toast.success(
            <Text tid="As_an_agent_could_not_connect_the_call_will_be_ending_in_5_sec_Please_try_again" />,
            {
              position: "bottom-right",
              duration: 4000,
            }
          );
        }
      },[autoEndCallTimeForCustomerDrop])

    useEffect(() => {
        const lastElement = remotesubscriber[remotesubscriber.length - 1];
        if (remotepeerleftnotification?.type == "PEER_LEFT") {    
          const remotevideo = document.getElementById("remotepeer");
          hmsActions.detachVideo(lastElement?.videoTrack, remotevideo);
          hmsActions.leave()
          navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
        }   
      }, [remotepeerleftnotification])

    useEffect(() => {
        if (notificationList?.notificationid === "2") {
            endVideoCall();
        }
        if (notificationList?.notificationid === "6" || notificationList?.notificationid == "1" && notificationList?.streamingValue == "instructions") {
            if (notificationList?.streamingValue == "Image captured successfully" || notificationList?.streamingValue == "PAN Image captured successfully") {
                setShowLiveImageCircleSvg(false);
            }
            // setRecapturepan(false);
            // setCanvasdisplay(false);
            var audio = new Audio("/tone.mp3");
            audio.play();
            toast.success(notificationList.notifications, {
                position: "bottom-center",
                duration: 2000,
            });
        }
        if (notificationList?.notificationid == "7") {
            setCanvasdisplay(false);
            if (recapturepan == true) {
                flipCameraMode(false);
                setCameramirror(true)
            }
        }
        if (notificationList?.notificationid == "1" && notificationList?.streamingValue == "Fetching Customer Location") {
            customerlocationsend()
        }
    }, [notificationList]);

    window.onpopstate = () => {
        navigate(null);
    };

    useEffect(() => {
        if (isEnbledEndCallBtn && isEnableGetCutomerKey) {
            deleteCustomerData()
            endCallByCustomer("Customer Auto Drop");
            sessionStorage.setItem("isAutoCallEnded", true);
        }else if(isEnbledEndCallBtn){
            endCallByCustomer("Customer Auto Drop");
            sessionStorage.setItem("isAutoCallEnded", true);
        }
    }, [isEnbledEndCallBtn]);

    useEffect(() => {
        if (remotesubscriber?.length > 0) {
            console.log(
                "enabledEndCallBtnTimer---> joined",
                enabledEndCallBtnTimer,
                notifyEndCallMsg
            );
            clearTimeout(enabledEndCallBtnTimer);
            clearTimeout(notifyEndCallMsg);
            // clearInterval(enabledEndCallBtnTimer);
            // clearInterval(notifyEndCallMsg);
        }
    }, [remotesubscriber]);

    // STREAMING BASED QUESTION NOTIFICATION SEND
    // useEffect(() => {
    //     if (
    //         streamingEventObj?.streamingKey ===
    //         customeCodes.SEND_CUSTOMER_NET_SPEED_TO_AGENT
    //     ) {
    //         sendMessage(streamingEventObj);
    //     }
    // }, [streamingEventObj]);

    //panrecature and livephoto
    useEffect(() => {
        if (iscapturedPanImageDone > 0) {
            countImageCapture = iscapturedPanImageDone;
            const model = {
                streamingKey: customeCodes.CAPTURE_CUSTOMER_PAN_DONE,
                streamingValue: isEnableGetCutomerKey ? imageName : capturedPanImage,
                notificationid: "30",
                notifications: isEnableGetCutomerKey ? imageName : capturedPanImage
            };
            sendMessage(model);
        }
    }, [capturedPanImage, iscapturedPanImageDone,imageName]);

    const sendMessage = (notify) => {
        const data = JSON.stringify(notify)
        // console.log("=====>send", notify);
        const senddata = base64.encode(data)
        // console.log("=====>send", senddata);
        hmsActions.sendBroadcastMessage(senddata);
    };

    const notifyEndCallEvent = () => {
        enabledEndCallBtnTimer = setTimeout(() => {
            setisEnbledEndCallBtn(true);
        }, 1000 * autoEndCallTime);
        notifyEndCallMsg = setTimeout(() => {
            var audio = new Audio("/tone.mp3");
            audio.play();
            toast.success(
                <Text tid="As_an_agent_could_not_connect_the_call_will_be_ending_in_5_sec_Please_try_again" />,
                {
                    position: "bottom-right",
                    duration: 4000,
                }
            );
        }, 1000 * (autoEndCallTime - 5));
    };

    const clearNotificationInterval = () => {
        if (notificationIntervalId) {
            clearInterval(notificationIntervalId);
        }
    };

    // END VIDEO CALL
    const endVideoCall = async (data) => {
        if (notificationList?.notificationid !== "2" && apiCount === 0 && data) {
            // setIsPushNtfyDone(true);
            const model = {
                notificationid: "3",
                vcipkey: sessionStorage.getItem("vcipkey"),
                notifymsg: data,
            };
            dispatch(
                pushNotificationSaga({ model, callback: pushNotificationRespData })
            );
        } else if (notificationList?.streamingValue == "finalendcall") {
            sessionStorage.setItem("finalendcall", "finalendcall")
            hmsActions.leave()
            navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
        }
        else {
            hmsActions.leave()
            // await window.location.reload()
            navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
        }
    };

    const pushNotificationRespData = async (data) => {
        // localStorage.setItem("reloaderr","customer ended call")
        const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
        const model = {
            streamingURL: isEnableStreamLoadBalalnce   ? JSON.parse(videoconfsessionurl) : streamingURL,
            body: {
                roomName: params.id
            }
        }
        if(isEnableGetCutomerKey){
            deleteCustomerData()
        }
        sessionStorage.removeItem("videoconfsessionid")
        hmsActions.leave()
        // dispatch(livekitdeleteroom({ model: model, callback: deleteroomdata }))
        // apiCount += 1;
        // // setTimeout(() => {
        // // sessionStorage.setItem("agentendcall","agentendcall")
        // if (room) {
        //   await room?.disconnect();
        // }
        // if (globalRoomData) {
        //   await globalRoomData?.disconnect();
        // }
        // await window.location.reload()
        // }, 300);
    };




    const endCallByCustomer = (data) => {
        dispatch(endVideoCallByAgentSagaAction({ callback: (res) => getEndCallRespData(res, data) }));
    };

    const getEndCallRespData = (res, data) => {
        if (res?.respcode === "200") {
            endVideoCall(data);
        }
    };

    const customerlocationsend = () => {
        if (navigator.geolocation) {
            // navigator.geolocation.getCurrentPosition((this.showPosition));
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position.coords.latitude && position.coords.longitude) {
                        const LatandLong = {
                            streamingKey: "CUSTOMER_LATITUDE_AND_LONGITUDE",
                            streamingValue: `${position.coords.latitude},${position.coords.longitude}`,
                            notificationid: "CUSTOMER_LATITUDE_AND_LONGITUDE",
                            notifications: "Successfully fetched the customer Location",
                        }
                        // console.log("ssssssssssssssssssss",position.coords.latitude,position.coords.longitude),
                        sendMessage(LatandLong)
                        toast.success("Location shared successfully", {
                            position: "bottom-center",
                            duration: 2000,
                        });
                    } else {
                        const Latlongerror = {
                            streamingKey: "CUSTOMER_LOCATION_FAILED",
                            streamingValue: "",
                            notificationid: "CUSTOMER_LATITUDE_AND_LONGITUDE",
                            notifications: "Unable to fetch the customer Location",
                        }
                        sendMessage(Latlongerror)
                        toast.error("Unable to fetch location", {
                            position: "bottom-center",
                            duration: 2000,
                        });
                    }
                },
                (error) => {
                    const Latlongerror = {
                        streamingKey: "CUSTOMER_LOCATION_FAILED",
                        streamingValue: "",
                        notificationid: "CUSTOMER_LATITUDE_AND_LONGITUDE",
                        notifications: "Unable to fetch the customer Location",
                    }
                    sendMessage(Latlongerror)
                    toast.error("Unable to fetch location", {
                        position: "bottom-center",
                        duration: 2000,
                    });
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                }
            );
        } else {
            const Latlongerror = {
                streamingKey: "CUSTOMER_LOCATION_FAILED",
                streamingValue: "",
                notificationid: "CUSTOMER_LATITUDE_AND_LONGITUDE",
                notifications: "Geolocation is not supported by customer browser",
            }
            sendMessage(Latlongerror)
            toast.error("Geolocation is not supported by this browser", {
                position: "bottom-center",
                duration: 2000,
            });
        }
    }

    useEffect(()=>{
        if(messagenotification != null){
            // console.log("messagenotification",messagenotification)
            const receivedmsg = messagenotification?.data?.message
            const str = base64.decode(receivedmsg)
            // console.log("=====str", str);
            if (str) {
                let parseObj = JSON.parse(str);
                if (parseObj?.notificationid === "99") {
                    var audio = new Audio("/tone.mp3");
                    audio.play();
                    const temp = {
                        sender: 1,
                        message: parseObj,
                    };
                    setBulk((prev) => [...prev, temp]);
                    if (!letbot) {
                        setnotifymsgcount((prev) => prev + 1);
                        setNotifymsgstate(true);
                    }
                }
                if (
                    parseObj?.streamingKey !== customeCodes.SEND_CUSTOMER_NET_SPEED_TO_AGENT
                ) {
                    if (parseObj?.notificationid === "2") {
                        isCallEndedByAgent = true;
                    }
                    if (
                        parseObj?.notificationid == "4" &&
                        parseObj?.notifications == "Flip Camera for PanCapture"
                    ) {
                        setAllowcameraflip(true);
                        setRecapturepan(false);
                    }
                    if (parseObj?.notificationid === "6") {
                        setCanvasdisplay(false);
                        let video = "";
                        if (streamingType === customeCodes.STREAMING_TYPE_LIVEKIT_VALUE) { // LIVEKIT
                            video = document.getElementById("us");
                        } else if(streamingType === customeCodes.STREAMING_TYPE_100MS_LIVE_VALUE){
                            video = document.getElementById("localpeer")
                        }else{
                            video = document.querySelector(".streamcomponent .video-play");
                        }
                        captureCustomerImg(video);
                    }
                    setNotificationList(parseObj);
                }
                if (parseObj?.notificationid == "8") {
                    setLocalVideoState(!localVideoState);
                    setShowLiveImageCircleSvg(true);
                }
            }
        }   
       
    },[messagenotification])


    // Chatting Function
    const handelBotfunction = () => {
        setletBot(!letbot);

        if (letbot) {
            setNotifymsgstate(false);
            setnotifymsgcount(0);
        }
    };

    const changeMessage = (e) => {
        // console.log("chatttttttttt",e.target.value)
        setMessage(e.target.value);
    };

    const sendChatMessage = (event) => {
        const chatMessage = {
            notificationid: "99",
            notifymsg: message,
            msgtime: Date.now(),
        };
        const temp = {
            sender: 0,
            message: chatMessage,
        };
        // console.log("ccccccccc",temp)
        setBulk((prev) => [...prev, temp]);
        setMessage("");
        // console.log("=====>send",chatMessage);
        const chatdata = JSON.stringify(chatMessage)
        const senddata = base64.encode(chatdata)
        // console.log("senddata", senddata)
        hmsActions.sendBroadcastMessage(senddata);
        // const msg = state.encoder.encode(JSON.stringify(chatMessage));
    };

    // CHANGE CAMERA FACING MODE
    const flipCameraMode = async (isFacingModeChanged) => {
        if (isFacingModeChanged == true) {
            setCameramirror(false)
        }
        await hmsActions.switchCamera()
    };

    // CAPTURE PAN IMAGE AT CUSTOMER SIDE
    const captureCustomerImg = async (video) => {
        setImageName("")
        try {
            const canvas = document.getElementById("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
                .getContext("2d")
                .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            const data = canvas.toDataURL("image/png");
            // Function to compress live image
            const compressedImage = canvas.toDataURL('image/jpeg', 0.95);
            const compressedImageTo70 = canvas.toDataURL('image/jpeg', 0.7);
            // console.log("compressed img", compressedImage);
            const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
            const model = {
                streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
                body: {
                    name: params.id + "-" + countImageCapture + ".png",
                    base64Image: compressedImage,
                }
            };
            const redisUploadPan = {
                redisUploadPan: true
              }
              if (isEnableGetCutomerKey) {
                dispatch(
                  uploadPanCapturedImageSagaAction({
                    model: model,
                    redisUploadPan: redisUploadPan,
                    callback: getUploadedPanCapturedImage,
                  })
                );
              } else {
                dispatch(
                  uploadPanCapturedImageSagaAction({
                    model: model,
                    callback: getUploadedPanCapturedImage,
                  })
                );
              }
        } catch (e) {
            setCapturedPanImage("");
            setIsCapturedPanImageDone(prev => prev + 1);
            // console.log(e, 'try catch error')
        }
    };

    const getUploadedPanCapturedImage = (data) => {
        setCapturedPanImage(data);
        setIsCapturedPanImageDone(prev => prev + 1);
        if (isEnableGetCutomerKey) {
            const capturedImageName = params.id + "-" + countImageCapture + ".png"
            setImageName(capturedImageName)
        }
        // const model = {
        //   streamingKey: customeCodes.CAPTURE_CUSTOMER_PAN_DONE,
        //   streamingValue: data,
        //   notificationid: "30",
        //   notifications: data
        // };
        // sendMessage(model);
    }

    const deleteCustomerData = () => {
        const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
        const model = {
          streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
          body: {
            "videoconfsessionid": params?.id
          }
        }
        dispatch(deleteCustomerAction({
          model,
          callback: deleteCustomerRes
        }))
      }
    
      const deleteCustomerRes = () => {
        window.location.reload()
        navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
      }

    return (
        <>
            <article className="app-body" style={{ height: "100vh" }}>
                <canvas
                    id="canvas"
                    style={{
                        objectFit: "fill",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        display: "none",
                    }}
                />
                <div className="timer">
                    <CallTimer  setAutoEndCallTime={setAutoEndCallTime} />
                </div>
                <>
                    {remotesubscriber?.length == 0 
                        ? (
                            <div className="d-flex align-items-center justify-content-center h-100">
                                <div
                                    className={`badge position-relative d-flex align-items-center justify-content-center 
                               
                                `}
                                    style={{ zIndex: 9999 }}
                                >
                                    {/* {!room && <div className="clock" style={{ borderColor: "green" }}></div>} */}
                                    <span
                                        style={{
                                            wordWrap: "wrap",
                                            padding: "5px",
                                            fontSize: "14px",
                                            color:"black"
                                        }}
                                    >
                                        Waiting for the Agent
                                        <br />
                                        <br />
                                        Do not close/refresh the page or access any other app
                                    </span>
                                </div>
                            </div>
                        ) : null}
                </>

                <div id="agent">
                    <video id="localpeer" style={{ transform: cameramirror ? "rotateY(180deg)" : "" }} className="remote" autoPlay muted playsInline></video>
                </div>

                {(notificationList?.notificationid == "4" &&
                    canvasdisplay == true &&
                    notificationList?.notifications == "Flip Camera for PanCapture") ||
                    canvasdisplay == true ||
                    (notificationList?.notificationid == "4" &&
                        notificationList?.notifications ==
                        "Flip Camera for PanCapture retake") ? (
                    <>
                        <div className="ovel-canvas">
                            {" "}
                            <img
                                src="../../images/Pancanvas.svg"
                                className="ovel-canvas-img"
                            />
                        </div>
                    </>
                ) : null}

                {notificationList && Object.values(notificationList)?.length > 0 ? (
                    notificationList?.notificationid === "1" && notificationList?.streamingValue != "Fetching Customer Location" && notificationList?.streamingValue != "instructions" &&
                        notificationList?.notifications ? (
                        <div className="display-qtn">
                            <span>{notificationList?.notifications}</span>
                        </div>
                    ) : null
                ) : null}

                {(notificationList?.notificationid == "8" || showLiveImageCircleSvg) ? (
                    <>
                        <div className="ovel-canvas">
                            {" "}
                            <img
                                src="../../images/Ovel-canvas.svg"
                                className="ovel-canvas-img"
                            />
                        </div>
                    </>
                ) : null}

                <video id="remotepeer"  style={{transform:"rotateY(180deg)"}} className={remotesubscriber.length > 0 ? "local" : ""} autoPlay muted playsInline></video>
                
            </article>


            {allowcameraflip
                // && isusfb == false 
                ? (
                    <BackCameraAccessModal
                        flipCameraMode={flipCameraMode}
                        setCameramirror={setCameramirror}
                        setAllowcameraflip={setAllowcameraflip}
                        setCanvasdisplay={setCanvasdisplay}
                        setRecapturepan={setRecapturepan}
                        sendMessage={sendMessage}
                        Livekit={"Livekit"}
                    />
                ) : null}


            {remotesubscriber?.length > 0 ? (
                <div className={letbot ? "bot botactive" : "bot"}>
                    {letbot ? null : (
                        <button className="chat-bot" id="bot" onClick={handelBotfunction}>
                            {notifymsgcount !== 0 && (
                                <span
                                    className=""
                                    style={{
                                        width: "22px",
                                        height: "22px",
                                        background: "red",
                                        borderRadius: "60%",
                                        color: "white",
                                        textAlign: "center",
                                        zIndex: "2147483647",
                                        position: "absolute",
                                        fontWeight: "bold",
                                        fontSize: "11px",
                                        top: "-4px",
                                        display: "flex",
                                        alignItems: "center",
                                        right: "48px",
                                        justifyContent: "center",
                                    }}
                                >
                                    {notifymsgcount}
                                </span>
                            )}

                            <span className="close-chat">
                                <i
                                    className="far fa-comment-alt"
                                    style={{ fontSize: "4vh" }}
                                ></i>
                            </span>
                        </button>
                    )}
                    <div className={letbot ? "bot-box chatshow" : "bot-box"} id="chat-id">
                        <ChatCmpChime
                            sendChatMessage={sendChatMessage}
                            changeMessage={changeMessage}
                            message={message}
                            setMessage={setMessage}
                            bulk={bulk}
                            handelBotfunction={handelBotfunction}
                        />
                    </div>
                </div>
            ):null}
        </>
    );
};

export default Initiatecall100MsLive;
