import React from 'react'
import './Digilocker.css'
export default function DigilockerLoader() {
  return (
        <>
      <div className="ldsring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  )
}
