import React from 'react'
import { useSelector } from 'react-redux';
import OccupationDropDown from './EmploymentDropDown';
import SelectEmployementDetails from './EmployementDetail';

const EmploymentLayout = () => {
    const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
    const { streamingType, streamingURL,enableOccupationdropdown } = dynamicSettingObj;
    const ShowEmploymentDropdown = enableOccupationdropdown == "true" ? true : false

    const element = ShowEmploymentDropdown
        ? < OccupationDropDown/>
        :<SelectEmployementDetails />
  return (
    <>{element}</>
  )
}

export default EmploymentLayout
