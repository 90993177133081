// API STATUS
export const APISTATUS = "APISTATUS";

// LOCATION
export const ACTION_GET_LOCATION_DETAILS_REQ = '@ACTION/GET/LOCATION/DETAILS/REQUEST';

// HOME PAGE
export const ACTION_GET_CUSTOMER_VCIP_DETAILS_REQ = '@ACTION/GET/CUSTOMER/VICPDETAILS/REQUEST';
export const ACTION_GET_CUSTOMER_VCIP_DETAILS_RES = '@ACTION/GET/CUSTOMER/VICPDETAILS/RESPONSE';
export const ACTION_GET_CUSTOMER_CREATE_SLOT_REQ = '@ACTION/GET/CUSTOMER/CREATE/SLOT/REQUEST';
export const ACTION_GET_CUSTOMER_VCIP_STATUSES_REQ = '@ACTION/GET/CUSTOMER/CREATE/VCIP/STATUSES/REQUEST';
export const ACTION_GET_CUSTOMER_VCIP_STATUSES_RESP_STORE = '@ACTION/STORE/GET/CUSTOMER/CREATE/VCIP/STATUSES/RESPONSE';
export const ACTION_GET_CUSTOMER_KINA_DETAILS = '@ACTION/GET/CUSTOMER/KINADETAILS/RESPONSE';


// AADHAAR

export const ACTION_GET_DIGILOCKER_LINK_REQ = '@ACTION/GET/CUSTOMER/DIGILOCKER/REQUEST';
export const ACTION_GET_DIGILOCKER_LINK_REQ_EKYC = '@ACTION/GET/CUSTOMER/DIGILOCKER/REQUEST/EKYC';
export const ACTION_GET_DIGILOCKER_STATUS_REQ = '@ACTION/GET/CUSTOMER/DIGILOCKER/STATUS/REQUEST';
export const ACTION_GET_DIGILOCKER_STATUS_REQ_EKYC = '@ACTION/GET/CUSTOMER/DIGILOCKER/STATUS/REQUEST/EKYC';
export const ACTION_GET_OKYC_GET_CATCHA = '@ACTION/GET/CUSTOMER/OKYC/CAPTCHA/REQUEST';
export const ACTION_GET_OKYC_REFRESH_CATCHA = '@ACTION/GET/CUSTOMER/OKYC/REFRESH/CAPTCHA/REQUEST';
export const ACTION_GET_OKYC_GENERATE_OTP = '@ACTION/GET/CUSTOMER/OKYC/GENERATE/OTP/REQUEST';
export const ACTION_GET_OKYC_GET_DATA = '@ACTION/GET/CUSTOMER/OKYC/DATA/REQUEST';
export const ACTION_GET_KINA_ENTER_ID = '@ACTION/GET/CUSTOMER/KINA/DATA/REQUEST';


export const ACTION_GET_EKYC_GET_CATCHA = '@ACTION/GET/CUSTOMER/EKYC/CAPTCHA/REQUEST';
export const ACTION_GET_EKYC_REFRESH_CATCHA = '@ACTION/GET/CUSTOMER/EKYC/REFRESH/CAPTCHA/REQUEST';
export const ACTION_GET_EKYC_GENERATE_OTP = '@ACTION/GET/CUSTOMER/CKYC/GENERATE/OTP/REQUEST';
export const ACTION_GET_EKYC_GET_DATA = '@ACTION/GET/CUSTOMER/CKYC/DATA/REQUEST';
export const ACTION_POST_AADHAAR_EKYC_REQUEST = "@ACTION/POST/AADHAAR/EKYC/REQUEST";
export const ACTION_POST_FETCH_AADHAAR_EKYC_STATUS = "@ACTION/POST/FETCH/AADHAAR/EKYC/STATUS";


// PAN
export const ACTION_GET_PAN_CAPTURE_REQ = '@ACTION/GET/CUSTOMER/PAN/CAPTURE/REQUEST';
export const ACTION_GET_PAN_SAVEDETAILS_REQ = '@ACTION/GET/CUSTOMER/PAN/SAVEDETAILS/REQUEST';
export const ACTION_GET_PAN_VERIFY_REQ = '@ACTION/GET/CUSTOMER/PAN/VERIFY/REQUEST';
export const ACTION_GET_PAN_DECLARATION_REQ = '@ACTION/GET/CUSTOMER/PAN/DECLARATION/REQUEST';
export const ACTION_POST_PAN_DECLARATIONSUBMIT_REQ = '@ACTION/POST/CUSTOMER/PAN/DECLARATIONSUBMIT/REQUEST';

// INITIATE VIDEO CALL
export const ACTION_GET_VC_LANGUAGES_REQ = '@ACTION/GET/CUSTOMER/VC/LANGUAGES/REQUEST';
export const ACTION_GET_VC_INITIATE_CONFERENCE_QUEUE_REQ = '@ACTION/GET/CUSTOMER/VC/INITIATE/CONFERENCE/QUEUE/REQUEST';
export const ACTION_GET_VC_UPDATED_TOKEN_REQ = '@ACTION/GET/CUSTOMER/VC/UPDATED/TOKEN/REQUEST';
export const ACTION_GET_VC_RESCHEDULE_REQ = '@ACTION/GET/CUSTOMER/VC/RESCHEDULE/REQUEST';
export const ACTION_GET_VC_SCHEDULE_CALENDER_REQ = '@ACTION/GET/CUSTOMER/VC/SCHEDULE/CALENDER/REQUEST';
export const ACTION_GET_VC_SCHEDULE_DETAILS_REQ = '@ACTION/GET/CUSTOMER/VC/SCHEDULE/DETAILS/REQUEST';
export const ACTION_GET_VC_SCHEDULE_CANCEL_REQ = '@ACTION/GET/CUSTOMER/VC/SCHEDULE/CANCEL/REQUEST';
export const ACTION_GET_VC_JOIN_VIDEO_SESSION_REQ = '@ACTION/GET/CUSTOMER/VC/JOIN/VIDEO/SESSION/REQUEST';
export const ACTION_ADD_SINGLE_CUSTOMER_REQ = "ACTION/ADD/SINGLE/CUSTOMER/REQ";
export const ACTION_GET_SINGLE_CUSTOMER_REQ = "ACTION/GET/SINGLE/CUSTOMER/REQ";
export const ACTION_DELETE_CUSTOMER_REQ = "ACTION/DELETE/CUSTOMER/REQ"

// VIDEO CALL
export const ACTION_CREATE_VIDEO_SESSION_REQ = '@ACTION/GET/CUSTOMER/CREATE/VIDEO/SESSION/REQUEST';
export const ACTION_CREATE_VIDEO_TOKEN_REQ = '@ACTION/GET/CUSTOMER/CREATE/VIDEO/TOKEN/REQUEST';
export const ACTION_CREATE_VIDEO_TOKEN_RES = '@ACTION/GET/CUSTOMER/CREATE/VIDEO/TOKEN/RESPONSE';
export const ACTION_GET_SPEECH_PITCH = '@ACTION/GET/CUSTOMER/GET/SPEECH/PITCH';
export const ACTION_OPENVIDU_ISSUE = '@ACTION/GET/CUSTOMER/CREATE/OPENVIDU/ISSUES';

//LivekitVideocall
export const ACTION_CREATE_VIDEO_TOKEN_LIVEKIT_REQ = '@ACTION/CREATE/VIDEO/TOKEN/LIVEKIT/REQUEST';
export const ACTION_UPLOAD_PAN_CAPTURED_IMAGE_BY_AGENT_REQ = '@ACTION/UPLOAD/PAN/CAPTURED/IMAGE/BY/AGENT/REQUEST';
export const ACTION_LIVEKIT_DELETEROOM_REQ = "@ACTION/LIVEKIT/DELETEROOM/REQUEST"


// DURING VIDEO CALL
export const ACTION_GET_NOTIFICATIONS_LIST_REQ = '@ACTION/GET/NOTIFICATION/LIST/REQUEST';
export const ACTION_GET_NOTIFICATIONS_PUSH_REQ = "@ACTION/GET/NOTIFICATION/PUSH/REQUEST";

// END VIDEO CALL 
export const ACTION_END_VIDEO_CALL_BY_AGENT_REQ = '@ACTION/GET/END/VIDEO/CALL/BY/AGENT/REQUEST';
export const STREAMING_EVENT_BY_CUSTOMER = '@ACTION/STREAMING/EVENT/BY/CUSTOMER';



// DYNAMIC CSS PROPERTIES
export const DYNAMIC_CSS_PROPERTIES = '@ACTION/CSS/DYNAMIC/CSS/PROPERTIES';

//SET DYNAMIC CLIENT NAME
export const DYNAMIC_CLIENT_NAME = '@ACTION/CLIENT/DYNAMIC/CLIENT';

// STORE PAGE DATA
export const STORE_PAGE_DATA = '@ACTION/STORE/PAGE/DATA';

//EKYC SEND OTP
export const AADHAAR_EKYC_PROCESS_SEND_OTP = '@ACTION/GET/EKYC123'
export const AADHAAR_EKYC_PROCESS_AADHAARDETAILS = '@ACTION/GET/EKYC/DETAILS'

// oCCUPATION DETAILS
export const ACTION_GET_CUSTOMER_OCCUPATION_DETAILS = '@ACTION/GET/OCCUPATION/DETAILS'
export const ACTION_GET_CUSTOMER_OCCUPATION_DROP_DOWN_DETAILS = "@ACTION/GET/CUSTOMER/OCCUPATION/DROP/DOWN/DETAILS"
export const ACTION_SUBMIT_CUSTOMER_OCCUPATION_DETAILS = '@ACTION/SUBMIT/OCCUPATION/DETAILS'


export const ACTION_DYNAMIC_STREAMING_URL = '@ACTION/DYNAMIC/STREAMING/URL'

export const GET_CUSTOMER_ENV_DATA_SAGA_ACTION = "GET_CUSTOMER_ENV_DATA_SAGA_ACTION";

export const ACTION_GET_CUSTOMER_CONSENT = '@ACTION/GET/CUSTOMER/CONSENT'

export const ACTION_GET_CUSTOMER_DECLARATION = '@ACTION/GET/CUSTOMER/DECLARATION'

// 100ms live
export const ACTION_CREATE_100MSLIVE_ROOM = "@ACTION/CREATE/100MSLIVE/ROOM"

export const ACTION_CREATE_ROOM_CODE = "@ACTION/CREATE/ROOM/CODE"

export const ACTION_DISABLE_ROOM = "@ACTION/DISABLE/ROOM"

export const ACTION_CREATE_MANAGEMENT_TOKEN = "@ACTION/CREATE/MANAGEMENT/TOKEN"
export const ACTION_100MS_START_RECORDING = "@ACTION/100MS/START/RECORDING"

export const ACTION_100MS_STOP_RECORDING = "@ACTION/100MS/STOP/RECORDING"


// BANK DETAILS CONSTANTS

export const ACTION_VERIFY_BANK_ACCOUNT_SAGA_ACTION = "ACTION_VERIFY_BANK_ACCOUNT_SAGA_ACTION"