import React from 'react';
import { Text } from '../Language/Language';

const PageNotFound = () => {
  return (
    <div className="center center-absolute flex-column">
      <img src={"/images/404.png"} className="w-100" alt="" />
      <Text tid ="page_not_found"/>
    </div>

  )
}

export default PageNotFound