import React from "react";
import { useSelector } from "react-redux";
import { Text } from "../../Pages/Language/Language";
import Button from "../Elements/Button";
import H4 from "../Elements/H4";
import P from "../Elements/P";

const AadharDataOkyc = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  return (
    <>
      <section className="app-start">
        <div className="aadhar-details" style={{ marginBottom: "70px" }}>
          {/* <h4 className="title mb-2">Aadhar Details</h4> */}
          <div className="ocr-pht">
            <img
              src={"data:image/png;base64," + props?.aadharDetails?.pht}
              alt="photo"
            />
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              defaultValue={props?.aadharDetails?.name}
              id="floatingInput"
              readOnly
            />
            <label htmlFor="floatingInput">
              <Text tid="name" />
              {/* Name */}
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              defaultValue={props?.aadharDetails?.fname}
              id="floatingInput"
              readOnly
            />
            <label htmlFor="floatingInput">
              <Text tid="father_name" />

              {/* Father Name */}
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              defaultValue={props?.aadharDetails?.uid}
              id="floatingInput"
              readOnly
            />
            <label htmlFor="floatingInput">
              <Text tid="uid" />

              {/* UID */}
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              defaultValue={props?.aadharDetails?.dob}
              id="floatingInput"
              readOnly
            />
            <label htmlFor="floatingInput">
              <Text tid="dob" />

              {/* DOB */}
            </label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              defaultValue={props?.aadharDetails?.gender}
              id="floatingInput"
              readOnly
            />
            <label htmlFor="floatingInput">
              <Text tid="gender" />

              {/* Gender */}
            </label>
          </div>
          <div className="form-floating cus-textareaKYC mb-2">
            <textarea
              className="form-control"
              id="floatingTextarea"
              defaultValue={`${props?.aadharDetails?.address}`}
              readOnly
            />
            <label htmlFor="floatingTextarea">
              <Text tid="Address" />

              {/* Address */}
            </label>
          </div>
        </div>
      </section>
    </>
  );
};

export default AadharDataOkyc;
