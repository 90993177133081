import React, { useState } from "react";
import Calendar from "react-calendar";
import { useSelector } from "react-redux";
import Button from "../../Elements/Button";
import Micvideocheckmodel from "../../../Pages/Modals/Micvideocheckmodel";
import RouteNames from "../../../Constants/RouteNames";
import customeCodes from "../../../Constants/customeCodes";
import MicvideocheckmodelLivekit from "../../../Pages/Modals/MicvideocheckmodelLivekit";

import { Text } from "../../../Pages/Language/Language";



const RescheduleCmp = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { streamingType, streamingURL } = dynamicSettingObj;

  const [isopen, setIsopen] = useState(false);
  const movetovideocheckpage = () => {
    setIsopen(true);
  };

  const select_time = "Select Time"
  const Select_time_hindi = "समय चुनें"
  const lang = sessionStorage.getItem('lang');

  const InitiateVCFQStorage = sessionStorage.getItem("InitiateVCFQStorage");

  const InitiateVCFQStorageParse = JSON.parse(InitiateVCFQStorage);
  // const streamingType = process.env.REACT_APP_STREAMING_TYPE;

  return (
    <section className="app-start">
      {props?.scheduleDetails?.sdate && (
        <>
          <h4 className="title text-center"> <Text tid="schedule_details" /></h4>
          <h6 className="txt-color text-center f-bold">
            {props?.scheduleDetails?.sdate +
              " (" +
              props?.scheduleDetails?.ftime +
              " - " +
              props?.scheduleDetails?.ttime +
              ")"}
          </h6>
          <div className="text-center">
            {/* <button
              className="cancel-schedule-btn"
              onClick={props?.cancelSchedule}
            >
              Cancel Schedule
            </button> */}
            <Button
              className={props?.isCashbook ? "cancel-schedule-cashbook-btn" : "cancel-schedule-btn"}
              // style={{
              //   bo : !props?.isCashbook ? "20px":""
              // }}
              type="button"
              click={props?.cancelSchedule}
              title={<Text tid="cancel_schedule" />}
            />
          </div>

          {props?.scheduleDetails?.joinstatus === "1" ? (
            <>
              {/* <button className="btn mb-3 mt-5" type="button" data-toggle="modal" data-target="#verification" onClick={props.generateXMLModal}>Join Now</button> */}
              {/* <button className="btn mb-3 mt-5" type="button" onClick={props.joinCall}>Join Now</button> */}
              <Button
                className="btn mb-3 mt-5"
                type="button"
                click={movetovideocheckpage}
                isClientTideAccess={props?.isClientTideAccess}
                title={<Text tid="join_now" />}
                color={cssProperties?.button?.text_color}
                fontSize={cssProperties?.button?.font_size}
                backgroundColor={cssProperties?.button?.color}
              />
            </>
          ) : null}
        </>
      )}
      {isopen && streamingType === customeCodes.STREAMING_TYPE_OPENVIDU_VALUE && (
        <Micvideocheckmodel
          setIsopen={setIsopen}
          cssProperties={cssProperties}
          RouteNames={RouteNames}
          isClientTideAccess={props?.isClientTideAccess}
          joinVideoSessionreq={props?.joinCall}
          reschedule={true}
          id={props?.id}
        />
      )}
      {streamingType === customeCodes.STREAMING_TYPE_LIVEKIT_VALUE && isopen && (
        <MicvideocheckmodelLivekit
          setIsopen={setIsopen}
          cssProperties={cssProperties}
          RouteNames={RouteNames}
          isClientTideAccess={props?.isClientTideAccess}
          joinVideoSessionreq={props.createTokenLiveKit}
          id={InitiateVCFQStorageParse?.videoconfsessionid}
          reschedule={true}
        // handleTokenExpire={handleTokenExpire}
        // hasTokenExpired={hasTokenExpired}
        />
      )}
      {props?.isRescheduleFormEnabled ||
        (!props?.scheduleDetails?.sdate) ? (
        <>
          {props.isClientTideAccess ? (
            <p className="mt-1 reschedulecalendertitle">
              <Text tid="please_select_a" /> <b> <Text tid="date_time" /> </b> <Text tid="to_reschedule_a_video_call_session_with_our_agent" />
            </p>
          ) : (
            <p className="txt text-center mt-3">
              <Text tid="please_select_a" /> <b> <Text tid="date_time" /> </b> <Text tid="to_reschedule_a_video_call_session_with_our_agent" />
            </p>
          )}
          {!props?.isClientTideAccess && (
            <div className="my-3">
              <hr />
            </div>
          )}
          {props?.isClientTideAccess ? (
            <div className="tidecalender form-select">
              <Calendar
                onChange={props?.onChange}
                minDate={new Date()}
                value={props?.value}
              />
            </div>
          ) : (
            <div>
              <Calendar
                onChange={props?.onChange}
                minDate={new Date()}
                value={props?.value}
              />
            </div>
          )}

          {props?.isClientTideAccess ? (
            <div>
              <label className="selecttimelableforbookslot">
                <Text tid="select_an_available_time_slot" />
              </label>
              <div className="selecttimeforbookslotmain">
                <select
                  className="selecttimeforbookslot form-select"
                  name="stime"
                  onChange={props.handleChange}
                >
                  {lang == "hi" ? <option value={""}>{Select_time_hindi}</option> : <option value={""}>{select_time}</option>}
                  {props?.scheduleCalenderData?.stimes &&
                    props?.scheduleCalenderData?.stimes?.length
                    ? props?.scheduleCalenderData?.stimes?.map((item, i) => (
                      <option key={i}>{item}</option>
                    ))
                    : null}
                </select>
              </div>
            </div>
          ) : (
            <div className="my-4 d-flex">
              <div className="form-floating w-100 mb-3">
                <select
                  className="form-select"
                  name="stime"
                  onChange={props.handleChange}
                  id="floatingSelect"
                >
                  {lang == "hi" ? <option value={""}>{Select_time_hindi}</option> : <option value={""}>{select_time}</option>}
                  {props?.scheduleCalenderData?.stimes &&
                    props?.scheduleCalenderData?.stimes?.length
                    ? props?.scheduleCalenderData?.stimes?.map((item, i) => (
                      <option key={i}>{item}</option>
                    ))
                    : null}
                </select>
                <label htmlFor="floatingSelect">
                  <Text tid="select_available_time_slots" />
                </label>
              </div>
            </div>
          )}
        </>
      ) : null}
    </section>
  );
};

export default RescheduleCmp;
