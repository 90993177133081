import toast from 'react-hot-toast';
import { put, takeLatest, call } from 'redux-saga/effects';
import StatusCodes from '../../Constants/StatusCodes';
import Axios from '../../Service/axios';
import { actionReqResStatusLoaderSagaAction } from '../SagaActions/CommonSagaActions';
import { ACTION_VERIFY_BANK_ACCOUNT_SAGA_ACTION } from '../SagaActions/SagaActionTypes';

// PAN CAPTURE
const verifyBankAccountRequest = (model) => {
    const URL = "VerifyAccount";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* verifyBankDetailsSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    // console.log(action,"actiondata for bank details");
    try {
        const resp = yield call(verifyBankAccountRequest, action?.payload?.model);
        if (resp && resp?.respcode === "200") {
            if (action?.payload?.callback) {
                // toast.success(resp?.respdesc);
                action?.payload?.callback(resp);
            }
        } else {
            toast.error(resp?.respdesc);
            action?.payload?.callback(resp);
        }
    } catch (err) {
        if (err.response) {
            toast.error(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast.error(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

export default function* BankDetailsSaga() {
    yield takeLatest(ACTION_VERIFY_BANK_ACCOUNT_SAGA_ACTION, verifyBankDetailsSaga);
}




