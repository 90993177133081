import axios from "axios";

const origin = window?.location?.origin;
const hostname = window?.location?.hostname;
let baseURL =  process.env.REACT_APP_BASE_URL || origin + "/api/vkyc/";
if (hostname === "localhost") {
    baseURL = process.env.REACT_APP_BASE_URL
}

const instance = axios.create({
    baseURL: baseURL
});

instance.defaults.headers.common['Content-Type'] = "application/json";
instance.defaults.headers.common['apikey'] = "0";
// instance.defaults.headers.common["authkey"] = "";
// instance.defaults.headers.common["authkey"] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFnZW50QHN5bnRpemVuLmNvbXwzfDF8SlVQVUItTU5ZT0ktR1FFRVotTkdTWUR8QWdlbnQiLCJuYmYiOjE2NDk2NTI1NTQsImV4cCI6MTY0OTczODk1NCwiaWF0IjoxNjQ5NjUyNTU0fQ.QDCsbEhJlHDe7QYDJslSOnk6qKx-S0ivWFzfWLPWTaM";
// instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';

instance.interceptors.request.use(
    request => {
        const authkey = sessionStorage.getItem('authkey');
        request.headers.common["authkey"] = authkey;
        return request;
    },
    error => {
        return Promise.reject(error);
    }
);


instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);


export default instance;