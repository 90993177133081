import React from 'react';
import { Text } from '../../Pages/Language/Language';

const InitiatingVideoCallCmp = (isItReconnection) => {
  return (
    <section className="app-start">
      <div className="text-center mb-3">
        <img src="../images/waiting.svg" className='spin-loader' alt="waiting" />
      </div>
      <h4 className="title text-center"><Text tid="please_wait"/></h4>
      <p className="txt text-center">
      {isItReconnection? "Please wait while we connect you to the agent": <Text tid="while_we_are_searching_for_the_agent_to_initiate_the_video_call"/>}
      </p>
    </section>
  )
}

export default InitiatingVideoCallCmp