import React, { useState } from "react";
import { PulseLoader } from "react-spinners";
import { detect } from "detect-browser";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { copyTextToClipboard } from "../../utils/common";
import H1 from "../../Components/Elements/H1";
import P from "../../Components/Elements/P";
import { Text } from "../Language/Language";

export default function BrowserCheck() {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  const browser = detect();
  const Id = sessionStorage.getItem("link");
  const user = sessionStorage.getItem("user");
  const override = {
    display: "block",
    borderColor: "red",
  };
  const userDetails = JSON.parse(user);
  const navigate = useNavigate()
  window.onpopstate = () => {
    navigate(null);
  }

  return (
    <>
      {!browser ? (
        <span className="d-flex align-items-end w-100">
          {" "}
          <Text tid="checking_browser_details" />
          <PulseLoader
            color={"red"}
            loading={true}
            cssOverride={override}
            size={4}
          />
        </span>
      ) : (
        <div className="cstmr-brwsr-dtls-mn">
          <div className="cstmr-brwsr-dtls">
            <div className="text-center mb-3">
              <img src="../images/Robot.png" alt="" />
            </div>
            <H1
              title={`Hi ${userDetails?.cust_name}!`}
              color={cssProperties?.button?.color}
              textAlign={"center"}
              fontWeight={"bold"}
              // fontSize={cssProperties?.pagetitle_heading?.font_size}
            />
            <h2 className="cstmr-brwsr-dtls-ttl">
              {/* Hi {userDetails?.cust_name}, You are using {browser?.name}{" "}
              {browser?.version} on {browser?.os} */}
              <Text tid="you_are_using"/>{" "}
              <span className="strong fw-bold">
                {browser?.name} {browser?.version}
              </span>{" "}
              <Text tid="on"/> <span className="strong fw-bold">{browser?.os}</span>
            </h2>
            {browser ? (
              <>
                <P
                  className="txt text-center"
                  txt={
                    "This Browser and device combination, does not support video calling. Please copy the link and open it in any of the below browser combinations."
                  }
                  color={cssProperties?.body?.font_color}
                  fontSize={cssProperties?.body?.font_size}
                />

                <p className="cstmr-brwsr-dtls-sbttl my-4 center"                     
                  onClick={() => {
                      copyTextToClipboard(Id);
                      toast.success("Copied", { position: "bottom-center" });
                    }}
                >
                  <span className="cstmr-brwser-dtls-copytxt"><Text tid="copy_link_here"/></span>
                  <button
                    className="themeBtn"
                    style={{
                      // height: 32,
                      background: "transparent",
                      paddingLeft: 0,
                    }}
                    // onClick={() => {
                    //   copyTextToClipboard(Id);
                    //   toast.success("Copied", { position: "bottom-center" });
                    // }}
                  >
                    <img src="../images/Copylink.png" alt="" />
                  </button>
                </p>
                <div className="row m-0">
                  <div className="col">
                    <div className="cstmr-brwsr-dtls-bx">
                      <div className="cstmr-brwsr-dtls-bx-img">
                        <img src="../images/android-icon.png" alt="" />
                      </div>
                      <div className="cstmr-brwsr-dtls-bx-cntnt">
                        <h3 className="cstmr-brwsr-dtls-bx-ttl"><Text tid="android"/></h3>
                        <p className="cstmr-brwsr-dtls-bx-txt mt-3">
                          <span><Text tid="google_chrome_V70"/></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="cstmr-brwsr-dtls-bx">
                      <div className="cstmr-brwsr-dtls-bx-img">
                        <img src="../images/apple-icon.png" alt="" />
                      </div>
                      <div className="cstmr-brwsr-dtls-bx-cntnt">
                        <h3 className="cstmr-brwsr-dtls-bx-ttl"><Text tid="iPhone"/></h3>
                        <p className="cstmr-brwsr-dtls-bx-txt mt-3">
                          <span><Text tid="Safari_V14"/></span>
                        </p>
                        {/* <p className="cstmr-brwsr-dtls-bx-txt">
                          <span>Google Chrome V70+</span>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              "Valid Browser"
            )}
          </div>
        </div>
      )}
    </>
  );
}
