import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeclarationCmp from "../../../Components/PanCmp/DeclarationCmp";
import RouteNames from "../../../Constants/RouteNames";
import AppFooter from "../../Common/AppFooter";
import Header from "../../../Layout/Header";
import { Text } from "../../Language/Language";
import { useDispatch } from "react-redux";
import { actionPanDeclaration, actionPanDeclarationSubmit } from "../../../Store/SagaActions/PanSagaActions";
import CleintNames from "../../../Constants/ClientNames";

var parsedUser

const Declaration = () => {
  const [declationData, setDeclationData] = useState("");
  const [isChecked, SetisChecked] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const vcipkeyData = sessionStorage.getItem("vcipkey");
  const langidData = sessionStorage.getItem("langid");

  useEffect(() => {

    const user = sessionStorage.getItem("user");
    parsedUser = JSON.parse(user);

    dispatch(
      actionPanDeclaration({
        callback: getPanDeclarationData,
      })
    );
  }, []);

  const getPanDeclarationData = (data) => {
    setDeclationData(data);
  };

  const handleCheckbox = () => {
    SetisChecked(!isChecked);
  };

  const submitDeclaration = () => {
    const model = {
      vcipkey: vcipkeyData,
      declaration_consent: isChecked ? "1" : "0",
      declaration: declationData,
      declarationtype: "1"
    };
    dispatch(
      actionPanDeclarationSubmit({
        model: model,
        callback: submittedRes,
      })
    );
  }
  
  const submittedRes = (data) => {
    if(parsedUser?.custoccupname==""){
        navigate(RouteNames.EMPLOYEMENT_DETAILS);
      }else{
        navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
      }
  }
  
  return (
    <>
      <Header
        title={"Declaration"}
        navigate={() => navigate(-1)}
        hideHeader={false}
      />
      <article className="app-body">
        <DeclarationCmp
          declationData={declationData}
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
        />

        <AppFooter
          btnName="Submit"
          isDisabled={!isChecked}
          navigate={submitDeclaration}
        />
      </article>
      {/* </>
            } */}
    </>
  );
};

export default Declaration;
