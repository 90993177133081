import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../Components/Elements/Button";
import H5 from "../../Components/Elements/H5";
import { Text } from "../Language/Language";
import toast from "react-hot-toast";
import CleintNames from "../../Constants/ClientNames";


const trillionLoanData = [
  "You are voluntarily participating in the video KYC process.",
  "You will be required to show a valid government-issued identity document pan card during the process.",
  "The session will be recorded for compliance and security purposes.",
  "The information collected will only be used for verification and compliance with regulatory requirements."
]

const UserConsentModal = (props) => {
  const [isChecked, SetisChecked] = useState(false);
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { dynamicUserConsent } = dynamicSettingObj;
  const showDynamicUserConsent = dynamicUserConsent === "true" ? true : false
  const user = JSON.parse(sessionStorage.getItem("user"));
  // console.log(user)

  const handleCheckbox = () => {
    SetisChecked(!isChecked);
  };

  const handleSubmit = () => {
    if (isChecked) {
      props?.agree();
    } else {
      toast(<Text tid="please_select_check_box_and_proceed" />);
    }
  };

  function generateVideoGuideList() {
    return (
    <ul className="video-guide">
      <li style={{ color: cssProperties?.body?.font_color }}>
        <Text tid="user_instruction1" />
      </li>
      <li style={{ color: cssProperties?.body?.font_color }}>
        <Text tid="user_instruction2" />
      </li>
      {!props?.iskinabank && (
        <>
          <li style={{ color: cssProperties?.body?.font_color }}>
            <Text tid="user_instruction3" />
          </li>
          <li style={{ color: props?.isusfb ? "#FF0000" : cssProperties?.body?.font_color, fontWeight: props?.isusfb ? "bold" : "" }}>
            <Text tid="user_instruction4" />
          </li>
        </>
      )}
      <li style={{ color: cssProperties?.body?.font_color }}>
        <Text tid="user_instruction5" />
      </li>
      <li style={{ color: cssProperties?.body?.font_color }}>
        <Text tid="user_instruction6" />
      </li>
      {!props?.iskinabank && (
        <li style={{ color: cssProperties?.body?.font_color }}>
          <Text tid="user_instruction7" />
        </li>
      )}
      <li style={{ color: cssProperties?.body?.font_color, marginBottom: 180 }}>
        <Text tid="user_instruciton8" />
      </li>
    </ul>
    )
}


  return (
    <>
      <div className="modal-header align-items-center">
        {/* <h5 className="modal-title text-center w-100" id="exampleModalLabel">
          <Text tid="user_consent" />
        </h5> */}
        <H5
          className={"modal-title text-center w-100"}
          title={<Text tid="user_consent" />}
          // titleClr2={cssProperties?.titleClr2}
          color={cssProperties?.sub_heading?.font_color}
          fontSize={cssProperties?.pagetitle_heading?.font_size}
        />
      </div>
      <div className="modal-body" style={{ marginBottom: (showDynamicUserConsent && Object.keys(props?.userConsent).length > 0) ? "200px" : null }}>
        {(showDynamicUserConsent && Object.keys(props?.userConsent).length > 0) ?
          <>
            <p style={{ fontSize: "15px", fontWeight: 600 }}>Customer Due Diligence Procedure via V-CIP :</p>
            <p style={{ fontSize: "15px", fontWeight: 600 }}>User undertaking and consent:</p>
            <ul className="video-guide">
              {props?.userConsent?.userUndertakingAndConsent?.map((data, idx) => {
                return (
                  <li style={{ color: cssProperties?.body?.font_color }} key={idx}>
                    {data}
                  </li>
                )
              })
              }
            </ul>
            <ul className="video-guide">
              {props?.userConsent?.mainConsentPoints?.map((data, idx) => {
                return (
                  <li style={{ color: cssProperties?.body?.font_color }} key={idx}>
                    {data}
                  </li>
                )
              })
              }
            </ul>
          </>
          :
          CleintNames?.TRILLION == user?.acccode ?
            <>
              <ul className="video-guide">
                <li style={{ color: cssProperties?.body?.font_color }}>
                  We are contacting you to inform you that as part of our verification process, we require your consent to proceed with the video KYC (know your customer) process. This process allows us to remotely verify your identity in a secure and efficient manner.
                </li>

                <li style={{ color: cssProperties?.body?.font_color }}>
                  By consenting to this process, you acknowledge and agree to the following:
                </li>

                {trillionLoanData.map((item, idx) => {
                  return (
                    <li key={idx} style={{ color: cssProperties?.body?.font_color }}>
                      {idx + 1}. {item}
                    </li>
                  );
                })
                }
              </ul>
            </>
            :
            generateVideoGuideList()
        }
      </div>
      <div
        className="userconsentbutton"
        style={{
          position: "fixed",
          bottom: "0",
          left: "50%",
          transform: "translate(-50%, 2%)",
          width: "90%",
          backgroundColor: "rgba(255, 255, 255, 0.9490196078)",
          height: CleintNames?.TRILLION == user?.acccode ? "170px" : "240px",
          overflow: CleintNames?.TRILLION == user?.acccode ? "auto" : "hidden"
        }}
      >
        <div className="modal-footer" style={{ flexWrap: "initial" }}>
          <div className="form-check d-flex justify-content-center">
            <input
              className="form-check-input me-2"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckbox}
              id="adr"
              style={
                isChecked
                  ? {
                    backgroundColor: cssProperties?.button?.color,
                    borderColor: cssProperties?.button?.color,
                  }
                  : null
              }
            />
            <label
              className="form-check-label"
              style={{ flex: 1, color: cssProperties?.body?.font_color, overflowY: "auto", height: "150px" }}
              htmlFor="adr"
            >
              {(showDynamicUserConsent && Object.keys(props?.userConsent).length > 0) ?
                <>
                  <Text tid="accept_cdtn1" />
                  {props?.cust_name}
                  <Text tid="accept_cdtn2" />
                  {props?.vcipid + ","}
                  {props?.userConsent?.userAgreement?.map((data, idx) => {
                    return (
                      <p style={{ color: cssProperties?.body?.font_color, marginBottom: "0", fontSize: "15px" }} key={idx}>
                        {data}
                      </p>
                    )
                  })
                  }
                </>
                :
                <>
                  <Text tid="accept_cdtn1" />
                  {props?.cust_name}
                  <Text tid="accept_cdtn2" />
                  {props?.vcipid}
                  <Text tid="accept_cdtn3" />
                </>
              }
            </label>
          </div>
        </div>
      </div>
      <Button
        className="btn cus-consentBtn mb-3"
        type="submit"
        // disabled={isChecked === false ? true : false}
        click={handleSubmit}
        title={<Text tid="agree" />}
        color={cssProperties?.button?.text_color}
        fontSize={cssProperties?.button?.font_size}
        backgroundColor={cssProperties?.button?.color}
      />
    </>
  );
};

export default UserConsentModal;
