import {
  ACTION_GET_CUSTOMER_CONSENT,
  ACTION_GET_CUSTOMER_CREATE_SLOT_REQ,
  ACTION_GET_CUSTOMER_DECLARATION,
  ACTION_GET_CUSTOMER_VCIP_DETAILS_REQ,
  ACTION_GET_CUSTOMER_VCIP_DETAILS_RES,
  ACTION_GET_CUSTOMER_VCIP_STATUSES_REQ,
  ACTION_GET_CUSTOMER_VCIP_STATUSES_RESP_STORE,
  DYNAMIC_CLIENT_NAME,
  DYNAMIC_CSS_PROPERTIES,
} from "./SagaActionTypes";

export const actionGetVcipDetails = (payload) => {
  return {
    type: ACTION_GET_CUSTOMER_VCIP_DETAILS_REQ,
    payload: payload,
  };
};

export const setCSSColorCodes = (payload) => {
  return {
    type: DYNAMIC_CSS_PROPERTIES,
    payload: payload,
  };
};

export const actionGetVcipDetailsResponse = (payload) => {
  return {
    type: ACTION_GET_CUSTOMER_VCIP_DETAILS_RES,
    payload: payload,
  };
};

export const actionCreateSlot = (payload) => {
  return {
    type: ACTION_GET_CUSTOMER_CREATE_SLOT_REQ,
    payload: payload,
  };
};

export const setClientName = (payload) => {
  return {
    type: DYNAMIC_CLIENT_NAME,
    payload: payload,
  };
};

export const getVcipStatusesAction = (payload) => {
  return {
    type: ACTION_GET_CUSTOMER_VCIP_STATUSES_REQ,
    payload: payload,
  };
};

export const setVcipStatusesAction = (payload) => {
  return {
    type: ACTION_GET_CUSTOMER_VCIP_STATUSES_RESP_STORE,
    payload: payload,
  };
};

export const actionGetCustomerConsent = (payload)=>{
  return{
    type:ACTION_GET_CUSTOMER_CONSENT,
    payload
  }
}
export const actionGetCustomerDeclaration = (payload)=>{
  return{
    type:ACTION_GET_CUSTOMER_DECLARATION,
    payload
  }
}