import React, { useEffect, useState } from "react";
import RescheduleCmp from "../../../Components/VideoCallCmp/RescheduleCmp/RescheduleCmp";
import "react-calendar/dist/Calendar.css";
import AppFooter from "../../Common/AppFooter";
import { useDispatch, useSelector } from "react-redux";
import { detect } from "detect-browser";
import moment from "moment";
import {
  actionCancelScheduleAction,
  actionGetScheduleCalenderAction,
  actionGetScheduleDetailsAction,
  actionRescheduleSagaAction,
  joinVideoSessionAction,
} from "../../../Store/SagaActions/InitiateVideoCallSagaActions";
import { createLivekitTokenSagaAction } from "../../../Store/SagaActions/VideoSagaActions";
import { useLocation, useNavigate } from "react-router-dom";
import RouteNames from "../../../Constants/RouteNames";
import Header from "../../../Layout/Header";
import { Text } from "../../Language/Language";
import CleintNames from "../../../Constants/ClientNames";

const browser = detect();
const browserDetails =  `Name:${browser?.name},Version:${browser?.version},OS:${browser?.os}`


var intervalId;
var intervalId2;
var intervalId3;

const Reschedule = (props) => {
  const [value, onChange] = useState(new Date());
  const [sdate, setSdate] = useState("");
  const [sTime, setSTime] = useState("");
  const [scheduleCalenderData, setScheduleCalenderData] = useState({});
  const [scheduleDetails, setScheduleDetails] = useState({});
  const [isRescheduleFormEnabled, setIsRescheduleFormEnabled] = useState(false);
  // const [intervalId, setIntervalId] = useState(null);
  // const [intervalId2, setIntervalId2] = useState(null);
  // const [InitiateVCFQ, setInitiateVCFQ] = useState({});
  // let intervalId;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  window.onpopstate = () => {
    navigate(null);
  }
  const { state } = useLocation();

  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const isCashbook = clientName === CleintNames?.CASHBOOK ? true : false
  
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { streamingType, streamingURL,enableStreamLoadBalance } = dynamicSettingObj;
  const isEnableStreamLoadBalalnce = enableStreamLoadBalance == "true"

  const vcipkeyData = sessionStorage.getItem("vcipkey");
  const langidData = sessionStorage.getItem("langid");
  const scheduleDetailsStorage = sessionStorage.getItem("scheduleDetailsStorage");
  const InitiateVCFQStorage = sessionStorage.getItem("InitiateVCFQStorage");
  const InitiateVCFQ = state?.InitiateVCFQ ? state?.InitiateVCFQ : InitiateVCFQStorage;

  useEffect(() => {
    if (
      InitiateVCFQ?.tokennumber === "-1"
      || InitiateVCFQ?.isscheduled === "1"
      || scheduleDetailsStorage
    ) {
      getInitialScheduleDetails();
      // intervalId = setInterval(() => {
      //   // if (!intervalId || intervalId != intervalVal) {
      //   //   setIntervalId(intervalVal);
      //   // }
      //   getScheduleDetails();
      // }, 7000);
    } else {
      getScheduleCalender(new Date());
    }
    // setInitiateVCFQ(InitiateVCFQ);
    return () => {
      clearInterval(intervalId);
      clearInterval(intervalId2);
      if (intervalId3) {
        clearInterval(intervalId3);
      }
      sessionStorage.removeItem("scheduleDetailsStorage");
    };
  }, []);

  useEffect(() => {
    if (scheduleDetails?.joinstatus === "-1") {
      getCancelScheduleDetails();
    }
    if (scheduleDetails?.joinstatus === "1") {
      clearAPICall()
    }
    // console.log("asdasdas");
  }, [scheduleDetails]);

  const getInitialScheduleDetails = () => {
    const _model = {
      vcipkey: vcipkeyData,
    };
    dispatch(
      actionGetScheduleDetailsAction({
        model: _model,
        callback: getInitialScheduleDetailsData,
      })
    );
  }

  const getInitialScheduleDetailsData = (data) => {
    setScheduleDetails(data);
    sessionStorage.setItem("scheduleDetailsStorage", JSON.stringify(data));
    intervalId = setInterval(() => {
      getScheduleDetails();
    }, 7000);
  }

  const clearAPICall = () => {
    if (intervalId || intervalId2 || intervalId3) {
      clearInterval(intervalId);
      clearInterval(intervalId2);
      clearInterval(intervalId3);
      // setIntervalId(null);
    }
  };

  const getScheduleDetails = () => {
    const model = {
      vcipkey: vcipkeyData,
    };
    dispatch(
      actionGetScheduleDetailsAction({
        model: model,
        callback: getScheduleDetailsData,
      })
    );
  };

  const getScheduleDetailsData = (data) => {
    setScheduleDetails(data);
    sessionStorage.setItem("scheduleDetailsStorage", JSON.stringify(data));
  };

  const getScheduleCalender = (val) => {
    const model = {
      vcipkey: vcipkeyData,
      langid: langidData,
      sdate: moment(val).format("YYYY-MM-DD"),
    };
    dispatch(
      actionGetScheduleCalenderAction({
        model: model,
        callback: getScheduleCalenderData,
      })
    );
  };

  const getScheduleCalenderData = (data) => {
    setScheduleCalenderData(data);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSTime(value);
  };

  const rescheduleVC = () => {
    const model = {
      vcipkey: vcipkeyData,
      sdate: moment(value).format("YYYY-MM-DD"),
      stime: sTime,
      langid: langidData,
    };
    dispatch(
      actionRescheduleSagaAction({ model: model, callback: getRescheduleData })
    );
  };

  const getRescheduleData = (data) => {
    if (data.respcode === "200") {
      setSTime("");
      setIsRescheduleFormEnabled(false);
      // props?.setIsRescheduled(false);
      getScheduleDetails();
      intervalId2 = setInterval(() => {
        // if (!intervalId2 || intervalId2 != interval2) {
        //   setIntervalId2(interval2);
        // }
        getScheduleDetails();
      }, 7000);
      // navigate(RouteNames.INITIATE_VIDEO_CALL)
    }
  };

  const joinCall = () => {
    clearInterval(intervalId);
    clearInterval(intervalId2);
    if (intervalId3) {
      clearInterval(intervalId3)
    }
    // props?.joinVideoSession();
    const location = sessionStorage.getItem("location");
    const geolocation = sessionStorage.getItem("geolocation");
    const ip = sessionStorage.getItem("ip");
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    let outcoming = "";
    if (connection) {
      outcoming =
        connection.rtt +
        " " +
        (connection.effectiveType === "4g"
          ? connection.effectiveType
          : "Low Speed");
    }
    const model = {
      vcipkey: vcipkeyData,
      custdetails: {
        ip: ip,
        location: location,
        geolocation: geolocation,
        nw_incoming: connection ? `${connection.downlink}` : "",
        nw_outgoing: outcoming,
        videoresolution: `${window.screen.width} x ${window.screen.height}`,
        browserdetails: browserDetails,
        // os: browser?.os,
      },
      agentdetails: {
        ip: "10.10.10.10",
        location: "",
        geolocation: "",
        nw_incoming: "",
        nw_outgoing: "",
        videoresolution: "",
      },
    };
    dispatch(
      joinVideoSessionAction({ model: model, callback: joinVideoSessionData })
    );
  };

  const getLivekitToken = (data) => {
    if (data?.token && data?.wssURL) {
      sessionStorage.setItem('token', data?.token);
      sessionStorage.setItem('wssURL', data?.wssURL);
      joinCall();
    } else {
      toast.error("Video session response failed");
    }
  };

  const createTokenLiveKit = () => {
    if (InitiateVCFQ?.videoconfsessionid) {
      const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
      const model = {
        streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
        body: {
          room: InitiateVCFQ?.videoconfsessionid,
          participantName: `customer_${InitiateVCFQ?.videoconfsessionid}`,
          canSubscribe: true,
        }
      };
      dispatch(
        createLivekitTokenSagaAction({
          model: model,
          callback: getLivekitToken,
        })
      );
    } else {
      toast.error("Video session failed");
    }
  };

  const joinVideoSessionData = (data) => {
    if (data?.respcode === "200") {
      sessionStorage.removeItem("scheduleDetailsStorage");
      sessionStorage.setItem(
        "videoconfsessionid",
        InitiateVCFQ?.videoconfsessionid
      );
      sessionStorage.removeItem("InitiateVCFQStorage");
      setTimeout(() => {
        navigate("/session/" + InitiateVCFQ?.videoconfsessionid);
      }, 1000);
    } else {
      sessionStorage.removeItem("UserTokendata");
      sessionStorage.setItem("tokenSessionExpire", "SessionExpire");
      setTimeout(() => {
        navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
      }, 2000);
    }
  };

  const cancelSchedule = () => {
    const model = {
      vcipkey: vcipkeyData,
    };
    if (intervalId) {
      clearInterval(intervalId);
    }
    if (intervalId2) {
      clearInterval(intervalId2);
    }
    dispatch(
      actionCancelScheduleAction({
        model: model,
        callback: getCancelScheduleDetails,
      })
    );
  };

  const getCancelScheduleDetails = (data) => {
    // clearAPICall();
    // props?.cancelledSchedule(data);
    if (data?.respcode != "200") {
      intervalId3 = setInterval(() => {
        getScheduleDetails();
      }, 7000);
    }
    // console.log(data)
    sessionStorage.removeItem("UserTokendata");
    sessionStorage.removeItem("InitiateVCFQStorage");
    navigate(RouteNames.TOKEN_NUMBER, { replace: true });
  };

  const calenderDateSelected = (val) => {
    onChange(val);
    getScheduleCalender(val);
  };

  const backButton = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    if (intervalId2) {
      clearInterval(intervalId2);
    }
    if (intervalId3) {
      clearInterval(intervalId3);
    }
    navigate(RouteNames.TOKEN_NUMBER, { replace: true });
  }

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  return (
    <>
      {(isClientTideAccess && scheduleDetails?.sdate) ? <Header
        title={<Text tid="reschedule" />}
        navigate={""}
        hideHeader={false}
      /> : <Header
        title={<Text tid="reschedule" />}
        navigate={scheduleDetails?.sdate ? null : backButton}
        isClientTideAccess={isClientTideAccess}
        hideHeader={false}
      />}
      <article className="app-body">
        <RescheduleCmp
          InitiateVCFQ={InitiateVCFQ}
          isRescheduled={props?.isRescheduled}
          isClientTideAccess={isClientTideAccess}
          value={value}
          scheduleDetails={scheduleDetails}
          isRescheduleFormEnabled={isRescheduleFormEnabled}
          scheduleCalenderData={scheduleCalenderData}
          onChange={calenderDateSelected}
          handleChange={handleChange}
          joinCall={joinCall}
          createTokenLiveKit={createTokenLiveKit}
          cancelSchedule={cancelSchedule}
          id={InitiateVCFQ?.videoconfsessionid}
          isCashbook={isCashbook}
        />
        <div
          className={"bookslotbuttonposition"}
          style={{
            backgroundColor: isClientTideAccess ? "#F1F2F3" : "#FFFFFFF2",
            paddingBottom: isClientTideAccess ? "4%" : "4%"
          }}>
          {(value && sTime) ||
            (props?.isRescheduled && !scheduleDetails?.sdate) ? (
            <AppFooter
              btnName={<Text tid="confirm" />}
              isClientTideAccess={isClientTideAccess}
              isDisabled={value && sTime ? false : true}
              navigate={value && sTime ? rescheduleVC : null}
            />
          ) : null}
        </div>
      </article>
    </>
  );
};

export default Reschedule;
