import React, { useEffect, useState } from "react";
import Button from "../../Components/Elements/Button";
import { Text } from "../Language/Language";
// import { useNavigate } from "react-router-dom";
// import toast from "react-hot-toast";

import { OpenVidu } from "openvidu-browser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InitiatingVideoCall from "../VideoCall/InitiatingVideoCall/InitiatingVideoCall";
import RouteNames from "../../Constants/RouteNames";
import {
  createVideoSessionSagaAction,
  openViduSessionIssues,
} from "../../Store/SagaActions/VideoSagaActions";
import OpenViduVideoComponent from "../VideoCall/InitiatingVideoCall/OvVideo";
import Loading from "../../Components/Loading/Loading";
import "../VideoCall/InitiatingVideoCall/video.css";
import {
  actionNoficationListSaga,
  endVideoCallByAgentSagaAction,
  pushNotificationSaga,
} from "../../Store/SagaActions/CommonSagaActions";
import UserVideoComponent from "../VideoCall/InitiatingVideoCall/UserVideoComponent";
import toast, { Toaster } from "react-hot-toast";
import Timer from "react-timer-wrapper";
// import Timecode from "react-timecode";
import Locactionsymbol from "../VideoCall/TokenNumber/Locactionsymbol";
import { v4 as uuidv4 } from "uuid";
import { requestMediaPermissions } from "mic-check";
import CleintNames from "../../Constants/ClientNames";

// {
//   setIsopen,
//   cssProperties,
//   RouteNames,
//   joinVideoSessionreq,
//   id,
//   isClientTideAccess
// }
var OV;
var openviduSessionDetails;
export default function Micvideocheckmodel(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const speechPitchValue = useSelector(
    (state) => state.VcipReducer.speechPitchValue
  );
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  const [session, setSession] = useState(undefined);
  const [subscribers, setSubscribers] = useState([]);
  const [mainStreamManager, setMainStreamManager] = useState(undefined);
  const [publisher, setPublisher] = useState(undefined);
  const [mediadisplay, setMediadisplay] = useState(false);
  const [networkloader, setNetworkloader] = useState(false);
  const [disabledstate, setDisabledstate] = useState(true);
  const [isMainStreamAvailable, setIsMainStreamAvailable] = useState(false);
  const [openViduMediaErrors, setOpenViduMediaErrors] = useState({
    errorCode: "",
    errorName: "",
  });
  const [videoPreviewProgress, setvideoPreviewProgress] = useState({
    status: "",
    code: 0,
  });
  const [enableRefreshBtn, setEnableRefreshBtn] = useState(false);
  const [hasErrors, updateHasErros] = useState("");

  var settionTimeOut;

  const openViduIssues = useSelector(
    (store) => store.VcipReducer?.openViduIssues
  );
  
  const clientName = useSelector((state) => state.HomeReducer.clientName);

  const isakudo = clientName === CleintNames?.AKUDO ? true : false;
  
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { streamingType, streamingURL } = dynamicSettingObj;

  console.log(openViduIssues,"----------------------------------")

  // const videoUrl = sessionStorage.getItem("videoUrl")

  var intervalid;
  // let disabledstate = true;
  useEffect(() => {
    sessionStorage.setItem("error_count", 5);
    console.log("*** mic video check popup opened");
    joinVideoSession();
    return async () => {
      clearintervalid();
      await openviduSessionDetails?.disconnect();
    };
  }, []);

  const joinVideoSession = () => {
    console.log("*** joining session");
    const user = sessionStorage.getItem("user");
    let parsedUser = JSON.parse(user);
    OV = new OpenVidu();
    var mySession = OV.initSession();
    OV.setAdvancedConfiguration({
      publisherSpeakingEventsOptions: {
        interval: 100, // Frequency of the polling of audio streams in ms (default 100)
        threshold: -50, // Threshold volume in dB (default -50)
      },
    });
    // var mySession = session;
    mySession.on("streamCreated", (event) => {
      console.log("*** stream created");

      var subscriber = mySession.subscribe(event.stream, undefined);
      if (event.stream.typeOfVideo !== "SCREEN") {
        sessionStorage.setItem(
          "connectionId",
          event.stream.connection.connectionId
        );
      }
      let subscribersArr = [...subscribers];
      if (subscriber) {
        subscribersArr?.push(subscriber);
        sessionStorage.setItem("subscribers", Object.keys(subscribersArr));
        // setisEnbledEndCallBtn(false);
      }
      console.log("*** Updating subscribers", subscribersArr);

      setSubscribers(subscribersArr);
    });

    setSession(mySession);
    openviduSessionDetails = mySession;

    const userid = uuidv4();
    const name = parsedUser?.acccode + "_cus_" + userid;
    const model = {
      name: name,
      sessionId: name,
      OV: OV,
      session: mySession,
      myUserName: name,
      recordingMode: "MANUAL",
      streamingURL: streamingURL,
    };
    console.log("*** updating progress bar");
    setvideoPreviewProgress({ status: "Getting Session Data", code: 1 });
    console.log("*** hitting api again");
    dispatch(
      createVideoSessionSagaAction({
        model: model,
        // videoUrl:videoUrl,
        // callback:(videoUrl)=>{
        //   getCreateSessionData(videoUrl)
        // }
        callback: getCreateSessionData,
        getPitch: getPitchValue,
        handleError: handleError,
      })
    );
  };

  const handleError = (err) => {
    if (err) {
      console.log("*** no media errors");
      setOpenViduMediaErrors({
        errorCode: err?.name,
        errorName:
          err?.name === "DEVICE_ALREADY_IN_USE"
            ? "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
            : err?.message,
      });
    } else {
      setOpenViduMediaErrors({
        errorCode: "",
        errorName: "",
      });
      let errCount = sessionStorage.getItem("error_count");
      errCount = errCount - 1;
      console.log("*** error handler", errCount);
      if (errCount >= 1) {
        // seterrorCount(errorCount+1);
        sessionStorage.setItem("error_count", errCount);

        settionTimeOut = setTimeout(() => {
          joinVideoSession();
          console.log(
            "*** --- updating error count and joining session after 3 secc",
            errCount
          );
        }, [1000]);
      } else {
        openViduSessionIssues("Internal Connectivity Error");
        if (errCount == 0) {
          toast(<Text tid="please_try_agin" />);
        }
        sessionStorage.setItem("error_count", 0);
        props?.setIsopen(false);
        // seterrorCount(errorCount+1);
      }
    }
  };

  const getCreateSessionData = (data) => {
    console.log("*** session data created");
    console.log("*** data.publisher", data.publisher);

    setvideoPreviewProgress({ status: "Getting Token Data", code: 2 });
    setMainStreamManager(data.mainStreamManager);
    setPublisher(data.publisher);
    setTimeout(() => {
      setEnableRefreshBtn(true);
    }, 30 * 1000);
    // if (data?.mySession && openViduMediaErrors?.errorCode === "") {
    //   console.log("*** no media errors");

    // data?.mySession?.streamManagers[0]?.initialize()
    //   .then(res => {
    //     // if (openViduMediaErrors?.errorCode !== "") {
    //     //   setOpenViduMediaErrors({
    //     //     errorCode: "",
    //     //     errorName: ""
    //     //   });
    //     // }
    //   }).catch(err => {
    //     console.log("*** Media error",err);

    //     if (openViduMediaErrors?.errorCode === "" || openViduMediaErrors?.errorCode !== err?.name) {
    //       setOpenViduMediaErrors({
    //         errorCode: err?.name,
    //         errorName: err?.name === "DEVICE_ALREADY_IN_USE"
    //           ? "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
    //           : err?.message
    //       });
    //     }
    //     // if (err?.name === "DEVICE_ALREADY_IN_USE") {
    //     //   setOpenViduMediaErrors({
    //     //     errorCode: err?.name,
    //     //     errorName: err?.message
    //     //   });
    //     // }
    //     console.log("==================", err);
    //   })
    // }
  };

  const getPitchValue = (data) => {
    audiocheck(data);
  };

  // if(mainStreamManager){
  //   const audiodetect = sessionStorage.getItem("audiodetect")
  //   console.log("audio detect is",audiodetect)
  // }

  const audiocheck = (audiodetect) => {
    console.log("*** Audio check true");
    // intervalid = setInterval(() => {
    //   const audiodetect = sessionStorage.getItem("audiodetect");
    const audio = Math.round(audiodetect);
    const converttonumber = parseInt(audio);
    const audiosource = -1 * converttonumber;
    // console.log(audiosource)
    colorPids(audiosource);
    // }, 100);
  };

  // if (session) {
  //   audiocheck();
  //   setTimeout(() => {
  //     setMediadisplay(true);
  //   }, 2000);
  // }

  const clearintervalid = () => {
    clearInterval(intervalid);
  };
  const movetovideopage = () => {
    let error = "";
    requestMediaPermissions()
      .then(() => {
        // can successfully access camera and microphone streams
        // DO SOMETHING HERE
        updateHasErros("");
        // network();

        if (sessionStorage.getItem("tokenEpireTime") || props?.reschedule) {
          network();
        } else {
          closeModal();
          props?.handleTokenExpire();
        }
      })
      .catch((err) => {
        console.log("err", err);
        const { type, name, message } = err;
        // console.log("============type", type, name, message);
        if (type === "SystemPermissionDenied") {
          // browser does not have permission to access camera or microphone
          error =
            "Browser does not have permission to access camera or microphone";
        } else if (type === "UserPermissionDenied") {
          // user didn't allow app to access camera or microphone
          error = "Please Enable Camera/MicroPhone";
        } else if (type === "CouldNotStartVideoSource") {
          // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
          // (mostly Windows specific problem)
          error = "Could not start video source";
        } else {
          error = "Something is wrong";
          // not all error types are handled by this library
        }
        // setOpenViduMediaErrors({
        //   errorName: error,
        //   errorCode: type
        // })
        updateHasErros(error);
      });

    // console.log("*******************",error)

    // if(!error){
    // network();
    // }
  };

  function colorPids(vol) {
    const allPids = [...document.querySelectorAll(".pid")];
    const numberOfPidsToColor = Math.round(vol / 10);
    const pidsToColor = allPids.slice(0, numberOfPidsToColor);
    for (const pid of allPids) {
      pid.style.backgroundColor = "#e6e7e8";
    }
    for (const pid of pidsToColor) {
      // console.log(pid[i]);
      pid.style.backgroundColor = "#69ce2b";
    }
  }

  let networkspeed;
  const network = () => {
    // var imageUrl = "https://images.pexels.com/photos/20787/pexels-photo.jpg?"
    console.log("*** checking network speed");
    // const imageUrl = "https://syntizen.com/91.png";
    const imgPath = `${window?.location?.origin?.toString()}/images/91.png`;
    const imageUrl = imgPath || "https://syntizen.com/91.png";
    let blob;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", imageUrl, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      blob = xhr.response;
      // console.log("blob", blob.size);
    };
    xhr.send();
    // var downloadSize = 3285366; //bytes
    var downloadSize = 91122; //bytes
    async function ShowProgressMessage(msg) {
      if (console) {
        if (typeof msg == "string") {
          console.log(msg);
          setNetworkloader(false);
          // setError(msg)
        } else {
          // console.log("msg is",msg.length)
          for (var i = 0; i < 1; i++) {
            // console.log("networkspeed", msg[i], "mbps");
            console.log("*** network speed is ", msg[i], "mbps");

            networkspeed = msg[i];
            setNetworkloader(false);
            // console.log("speed is",networkspeed)
            if ( isakudo == false && networkspeed > 1) {
              // navigate(RouteNames.TOKEN_NUMBER);
              await openviduSessionDetails?.disconnect();
              if (
                sessionStorage.getItem("tokenEpireTime") ||
                props?.reschedule
              ) {
                console.log(
                  "*** Video session requested",
                  openviduSessionDetails
                );
                props?.joinVideoSessionreq();
              } else {
                console.log("*** Token expired ");

                closeModal();
                props?.handleTokenExpire();
              }
            }else if(isakudo == true && networkspeed > 0.2){
              await openviduSessionDetails?.disconnect();
              if (
                sessionStorage.getItem("tokenEpireTime") ||
                props?.reschedule
              ) {
                console.log(
                  "*** Video session requested",
                  openviduSessionDetails
                );
                props?.joinVideoSessionreq();
              } else {
                console.log("*** Token expired ");

                closeModal();
                props?.handleTokenExpire();
              }
            }
             else {
              toast(
                <Text tid="poor_internet_connection_detected_Switch_to_other_network" />
              );
              console.log("*** Poor interner connection");
            }
          }
        }
      }
    }
    function InitiateSpeedDetection() {
      ShowProgressMessage("Loading the image, please wait...");
      // console.log("hfgfrfgfh")
      setNetworkloader(true);
      window.setTimeout(MeasureConnectionSpeed, 1);
    }

    if (window.addEventListener) {
      // console.log('window');
      window.addEventListener("load", InitiateSpeedDetection(), false);
    } else if (window.attachEvent) {
      // console.log("window2")
      window.attachEvent("onload", InitiateSpeedDetection());
    }

    function MeasureConnectionSpeed() {
      var startTime, endTime;
      var download = new Image();
      download.onload = function () {
        endTime = new Date().getTime();
        showResults();
      };
      download.onerror = function (err, msg) {
        ShowProgressMessage("Invalid image, or error downloading");
      };
      startTime = new Date().getTime();
      var cacheBuster = "?nnn=" + startTime;
      // console.log(cacheBuster)
      download.src = imageUrl + cacheBuster;
      // console.log(download)

      function showResults() {
        var duration = (endTime - startTime) / 1000;
        var bitsLoaded = downloadSize * 8;
        var speedBps = (bitsLoaded / duration).toFixed(2);
        var speedKbps = (speedBps / 1024).toFixed(2);
        var speedMbps = (speedKbps / 1024).toFixed(2);
        // ShowProgressMessage([
        //     // "Your connection speed is:",
        //     // speedBps + " bps",
        //     // speedKbps + " kbps",
        //     speedMbps
        // ]);
        ShowProgressMessage([
          // "Your connection speed is:",
          // speedBps + " bps",
          // speedKbps + " kbps",
          speedMbps,
        ]);
      }
    }
  };
  // mainStreamManager?.publisher?.canPlayListener((event) => {
  //   console.log("User ===========> start speaking", event);
  // });
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", () => unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  // console.log(disabledstate)
  const closeModal = async () => {
    console.log("*** closing preview modal ");

    if (
      !disabledstate ||
      openViduMediaErrors?.errorCode ||
      openViduIssues ||
      videoPreviewProgress.code > 2
    ) {
      await openviduSessionDetails?.disconnect();
      if ("videoDataInterval" in session) {
        // console.log("closing vid ======+++++++", session,"===", session?.disconnect);
        await session?.disconnect();
        // await session.forceDisconnect();
      }
      props?.setIsopen(false);
      setOpenViduMediaErrors({
        errorCode: "",
        errorName: "",
      });
      clearTimeout(settionTimeOut);
      sessionStorage.setItem("error_count", 0);
    } else {
      props?.setIsopen(false);
      clearTimeout(settionTimeOut);
      sessionStorage.setItem("error_count", 0);
      toast(<Text tid="please_try_agin" />);
    }
  };

  const reload = () => {
    window.location.reload();
    console.log("*** page reloaded");
  };

  // console.log("22222222222",session)
  // let isIceStateHasConnected = false;
  // if (session && "videoDataInterval" in session) {
  //   isIceStateHasConnected = true;
  // }
  // console.log(isIceStateHasConnected)

  // const flipCameraMode = async (flipcamera) => {

  //   const finalSession = openviduSessionDetails || session;
  //   console.log("11111111111111111111111ov",OV, flipcamera)
  //   let videoDevices = await OV?.getDevices().then((devices) => {
  //     // Getting only the video devices
  //     return devices?.filter((device) => device?.kind === "videoinput");
  //   });
  //   console.log("qqqqqqqqqqqqqqqqqqqqqq",videoDevices,flipcamera,publisher)
  //   if (videoDevices && videoDevices.length > 1) {
  //     // Unpublishing the old publisher
  //     finalSession?.unpublish(publisher).then(() => {
  //       console.log("Old publisher unpublished!",flipcamera);
  //       // setTimeout(async()=>{
  //         let newPublisher = OV.initPublisher(undefined, {
  //           videoSource: flipcamera ? videoDevices[1].deviceId : videoDevices[0].deviceId,
  //           publishAudio: true,
  //           publishVideo: true,
  //         });
  //         console.log("nnnnnnnnnnnnnnnnnnnnnn",newPublisher)
  //         // Publishing the new publisher
  //         finalSession?.publish(newPublisher).then(async() => {
  //           console.log("New publisher published!");
  //           await setMainStreamManager(newPublisher);
  //           await setPublisher(newPublisher);
  //         });
  //       // },5000)
  //     });
  //   }
  // };

  return (
    <>
      <div className="darkBG">
        <div
          className="centered"
          style={{ overflowY: "auto", maxHeight: "90vh" }}
        >
          <div className="imgcancel" onClick={closeModal}>
            <img src="/images/Vector.jpg" />
          </div>
          {openViduIssues || hasErrors ? (
            <div className="text-center">
              <img
                src="../images/incomplete.png"
                className="mb-2 w-25"
                alt=""
              />
              <p className="text-center mb-0">{openViduIssues || hasErrors}</p>
            </div>
          ) : (
            <>
              <div className="mainheading">
                <Text tid="audio_video_check" />
              </div>
              <p className="subheading">
                <Text tid="please_check_if_your_camera_and_mic_is_working_and_proceed" />
              </p>
              <div
                className="videopreview"
                style={{
                  marginTop: props?.isClientTideAccess && "24px",
                  color: props?.isClientTideAccess && "#2F3037",
                }}
              >
                <Text tid="video_preview" />
              </div>
              {videoPreviewProgress.code < 5 &&
                openViduMediaErrors?.errorCode === "" && (
                  <>
                    <div className="progress my-3">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-label="Animated striped example"
                        aria-valuenow={`${videoPreviewProgress.code * 20}%`}
                        aria-valuemin={0}
                        aria-valuemax={`${100}`}
                        style={{
                          width: `${videoPreviewProgress.code * 20}%`,
                          backgroundColor: cssProperties?.button?.color,
                        }}
                      />
                    </div>
                    <div className="video-preview-progress-status">
                      {videoPreviewProgress.status}
                    </div>
                  </>
                )}
              {(openViduMediaErrors?.errorCode ||
                (enableRefreshBtn && videoPreviewProgress.code <= 3)) && (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <strong className="text-danger text-center m-0">
                    {openViduMediaErrors?.errorCode ? (
                      openViduMediaErrors?.errorName
                    ) : (
                      <Text tid="please_close_other_Apps_Tabs_sessions_that_are_accessing_the_Camera_and_Microphone_currently_and_Hit_below_refresh_button" />
                    )}
                  </strong>
                  <button
                    className="btn m-2 w-auto"
                    style={{
                      color: cssProperties?.button?.text_color,
                      fontSize: cssProperties?.button?.font_size,
                      background: props?.isClientTideAccess
                        ? "#1929D6"
                        : cssProperties?.button?.color,
                    }}
                    onClick={reload}
                  >
                    <Text tid="refresh" />
                  </button>
                </div>
              )}
              {!openViduMediaErrors?.errorCode ? (
                <div
                  className={`videomain 
              ${
                !isMainStreamAvailable ||
                openViduMediaErrors?.errorName ||
                (enableRefreshBtn && videoPreviewProgress.code <= 3)
                  ? "d-flex flex-column align-items-center justify-content-center"
                  : ""
              }`}
                >
                  {networkloader && (
                    <Loading
                      text={<Text tid="checking_network_please_wait" />}
                      isClientTideAccess={props?.isClientTideAccess}
                    />
                  )}
                  {mainStreamManager ? (
                    <>
                      {openViduMediaErrors?.errorCode === "" && (
                        <OpenViduVideoComponent
                          streamManager={mainStreamManager}
                          setIsMainStreamAvailable={setIsMainStreamAvailable}
                          setDisabledstate={setDisabledstate}
                          setvideoPreviewProgress={setvideoPreviewProgress}
                        />
                      )}
                      {isMainStreamAvailable &&
                        openViduMediaErrors?.errorCode === "" && (
                          <>
                            <div className="recordingsymbol">
                              <div className="elipse1">
                                <div className="elipse2"></div>
                              </div>
                              <p className="videorunningtext">Video Running</p>
                            </div>
                            <img
                              src="/images/icon-voice.png"
                              className="voice-test-icon"
                              width={10}
                              height={20}
                            />
                            {props?.isClientTideAccess ? (
                              <>
                                <img
                                  src="/images/location-dot-solid1.svg"
                                  className="recording-location-icon-tide"
                                  width={10}
                                  height={13}
                                />
                                <span className="locationtideenablemsg">
                                  <Text tid="location_enabled" />
                                </span>
                              </>
                            ) : (
                              <Locactionsymbol
                                color={cssProperties?.button?.color}
                              />
                            )}
                            <div className="pids-wrapper">
                              <p className="pid"></p>
                              <p className="pid"></p>
                              <p className="pid"></p>
                              <p className="pid"></p>
                              <p className="pid"></p>
                              <p className="pid"></p>
                              <p className="pid"></p>
                              <p className="pid"></p>
                              <p className="pid"></p>
                              <p className="pid"></p>
                            </div>
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      {/* {mainStreamManager && mainStreamManager?.videos?.length === 0
                        ? null
                        : <Loading text={"Loading..."} isClientTideAccess={props?.isClientTideAccess} />} */}
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
              {openViduMediaErrors?.errorCode == "" && (
                <p
                  className="subheading warning-text"
                  style={{ marginTop: props?.isClientTideAccess && "35px" }}
                >
                  {props.hasTokenExpired ? (
                    <Text tid="token_has_been_expired_Please_close_and_Try_Again" />
                  ) : (
                    <Text tid="if_the_Proceed_button_does_not_enable_in_30_seconds_Please_close_the_popup_and_join_the_call_again" />
                  )}
                </p>
              )}
              <p
                className="subheading"
                style={{ fontSize: "12px", fontWeight: "600" }}
              >
                <Text tid="this_video_cannot_be_seen_by_anyone_except_you" />
              </p>
              {/* {((mainStreamManager && mainStreamManager?.videos?.length === 0) || (openViduMediaErrors?.errorCode !== "" && !isMainStreamAvailable))
                ? <Button
                  className={props?.isClientTideAccess ? "tidevideobtn" : "videobtn"}
                  type="button"
                  color={cssProperties?.button?.text_color}
                  fontSize={cssProperties?.button?.font_size}
                  backgroundColor={cssProperties?.button?.color}
                  isClientTideAccess={props?.isClientTideAccess}
                  click={closeModal}
                  title={"Close"}
                />
                : <Button
                  className={props?.isClientTideAccess ? "tidevideobtn" : "videobtn"}
                  type="button"
                  opacity={disabledstate == false ? null : "25%"}
                  color={cssProperties?.button?.text_color}
                  fontSize={cssProperties?.button?.font_size}
                  backgroundColor={cssProperties?.button?.color}
                  // color={cssProperties?.button?.text_color}
                  isClientTideAccess={props?.isClientTideAccess}
                  click={mainStreamManager ? movetovideopage : null}
                  title={props.hasTokenExpired ? "Close" : "Proceed"}
                  disabled={disabledstate}
                />
              } */}
              {openViduMediaErrors?.errorCode == "" && (
                <Button
                  className={
                    props?.isClientTideAccess ? "tidevideobtn" : "videobtn"
                  }
                  type="button"
                  opacity={disabledstate == false ? null : "25%"}
                  color={cssProperties?.button?.text_color}
                  fontSize={cssProperties?.button?.font_size}
                  backgroundColor={cssProperties?.button?.color}
                  // color={cssProperties?.button?.text_color}
                  isClientTideAccess={props?.isClientTideAccess}
                  click={mainStreamManager ? movetovideopage : null}
                  title={
                    props.hasTokenExpired ? (
                      <Text tid="close" />
                    ) : (
                      <Text tid="agree" />
                    )
                  }
                  disabled={disabledstate}
                />
              )}
              {/* <Button
                className={
                  props?.isClientTideAccess ? "tidevideobtn" : "videobtn"
                }
                type="button"
                opacity={disabledstate == false ? null : "25%"}
                color={cssProperties?.button?.text_color}
                fontSize={cssProperties?.button?.font_size}
                backgroundColor={cssProperties?.button?.color}
                // color={cssProperties?.button?.text_color}
                click={()=>flipCameraMode(true)}
                title={"Flipback"}
              />
               <Button
                className={
                  props?.isClientTideAccess ? "tidevideobtn" : "videobtn"
                }
                type="button"
                opacity={disabledstate == false ? null : "25%"}
                color={cssProperties?.button?.text_color}
                fontSize={cssProperties?.button?.font_size}
                backgroundColor={cssProperties?.button?.color}
                // color={cssProperties?.button?.text_color}
                click={()=>flipCameraMode(false)}
                title={"Flipfront"}
              /> */}
            </>
          )
          }
        </div>
      </div>
    </>
  );
}
