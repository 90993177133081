import React, { useState } from "react";
import { useSelector } from "react-redux";
// import Button from "../../Components/Elements/Button";
import Button from "../../Elements/Button";
import H5 from "../../Elements/H5";
import toast from "react-hot-toast";
import "./TideUserConsent.css";
import { Text } from "../../../Pages/Language/Language";
import { useNavigate } from "react-router-dom";

const TideUserConsentModal = (props) => {
  const [isChecked, SetisChecked] = useState(false);
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const navigate= useNavigate()

  const handleCheckbox = () => {
    SetisChecked(!isChecked);
  };

  const handleSubmit = () => {
    if (isChecked) {
      props?.agree();
    } else {
      toast(<Text tid="please_check_the_box"/>);
    }
  };

  const prevnavigation = ()=>{
    props?.closeModal()
  }
  return (
    <div
      className="userconsenttext"
      style={{
        backgroundColor: props?.isClientTideAccess ? "#F1F3F4" : "white",
      }}
    >
      <div>
        <div className=" align-items-center">
          {/* <button
            type="button"
            //   className="close"
            className="arrowbutton"
            //   data-dismiss="modal"
            //   aria-label="Close"
            onClick={props.closeModal}
          >
            <img src="/images/Vector(Stroke).svg" alt="close" />
          </button> */}
        </div>
        {props?.isClientTideAccess ? (
          <>
            <div className="userauthorisation"><Text tid="user_consent" /></div>
            <div className="textmains">
              <div className="hometext1">
               <Text tid="as_part_of_verifi_process_you_will_need_to"  /> :
              </div>
            </div>
            <div className="textmains">
              <img src="/images/Vector172.svg" className="tickicon"></img>
              <div className="hometext">
                <Text tid="connect_to_one_of_our_agents_who_will_record_video_of_your_conversation" />
              </div>
            </div>
            <div className="textmains">
              <img src="/images/Vector172.svg" className="tickicon"></img>
              <div className="hometext">
                <Text tid="permit_our_agent_to_capture_live_photo_during_video" />
              </div>
            </div>
            <div className="textmains">
              <img src="/images/Vector172.svg" className="tickicon"></img>
              <div className="hometext">
                <Text tid="provide_your_aadhar_details_which_will_be_used_in_video_call" />
              </div>
            </div>
            <div className="textmains">
              <img src="/images/Vector172.svg" className="tickicon"></img>
              <div className="hometext">
                <Text tid="provide_a_pic_of_pan_card_which_will_be_used_in_video_call_process" />
              </div>
            </div>
            <div className="textmains">
              <img src="/images/Vector172.svg" className="tickicon"></img>
              <div className="hometext">
                <Text tid="share_your_live_locaiton_which_will_be_capture_in_video_call_process" />
              </div>
            </div>
            <div className="textmains">
              <img src="/images/Vector172.svg" className="tickicon"></img>
              <div className="hometext">
                <Text tid="ensure_all_your_details_are_correctly_submitted_in_video_call_process" />
              </div>
            </div>
            <div className="textmains">
              <img src="/images/Vector172.svg" className="tickicon"></img>
              <div className="hometext">
                <Text tid="ensure_your_aadhar_xml_pacaket_is_not_older_than_3_days" />
              </div>
            </div>
            <div className="textmains">
              <img src="/images/Vector172.svg" className="tickicon"></img>
              <div className="hometext">
                <Text tid="user_instruciton8" />
              </div>
            </div>
          </>
        ) : (
          props?.isCashbook && (
            <>
              <div className="modal-header align-items-center">
                <img src="images/Vector(Stroke).svg"  onClick={prevnavigation}/>
                <H5
                  className={"modal-title text-center w-100"}
                  title={<Text tid="user_consent" />}
                  // titleClr2={cssProperties?.titleClr2}
                  color={cssProperties?.sub_heading?.font_color}
                  fontSize={cssProperties?.pagetitle_heading?.font_size}
                /> 
              </div>
              <div className="modal-body">
                <ul className="video-guide">
                  <li style={{ color: cssProperties?.body?.font_color,listStyleType: "disc",display: "list-item" }}>
                    <Text tid="user_instruction1" />
                  </li>
                  <li style={{ color: cssProperties?.body?.font_color,listStyleType: "disc",display: "list-item" }}>
                    <Text tid="user_instruction2" />
                  </li>
                  <li style={{ color: cssProperties?.body?.font_color,listStyleType: "disc",display: "list-item" }}>
                    <Text tid="user_instruction3" />
                  </li>
                  <li style={{ color: cssProperties?.body?.font_color,listStyleType: "disc",display: "list-item" }}>
                    <Text tid="user_instruction4" />
                  </li>
                  <li style={{ color: cssProperties?.body?.font_color,listStyleType: "disc",display: "list-item" }}>
                    <Text tid="user_instruction5" />
                  </li>
                  <li style={{ color: cssProperties?.body?.font_color,listStyleType: "disc",display: "list-item" }}>
                    <Text tid="user_instruction6" />
                  </li>
                  <li
                    style={{
                      color: cssProperties?.body?.font_color,listStyleType: "disc",display: "list-item"
                    }}
                  >
                    <Text tid="user_instruction7" />
                  </li>
                  <li style={{ color: cssProperties?.body?.font_color,  marginBottom: 180 , display: "list-item"}}>
                    <Text tid="user_instruciton8" />
                  </li>
                </ul>
              </div>
            </>
          )
        )}
      </div>
      <div
        className="tidecardmain"
        style={{
          backgroundColor: props?.isClientTideAccess ? "#F1F3F4" : "#FFFFFFF2",
        }}
      >
        <div className="tidecard">
          <input
            className="form-check-input checkbox"
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckbox}
            id="adr"
          />
          <label
            className="form-check-label"
            style={{ flex: 1, color: cssProperties?.body?.font_color }}
            htmlFor="adr"
          >
            <Text tid="i" />, {props?.cust_name} <Text tid="with_vcip_id" /> - {props?.vcipid}  
            <Text tid="agree_with_all_the_above_points_and_confirm_my_consent" />
          </label>
        </div>
        {props?.isClientTideAccess ? (
          <Button
            className=" mt-2 mb-3"
            type="submit"
            // disabled={isChecked === false ? true : false}
            click={handleSubmit}
            // title={<Text tid="agree" />}
            title={<Text tid="next"/>}
            isClientTideAccess={props?.isClientTideAccess}
          />
        ) : (
          <Button
            className="btn cus-consentBtn mb-3"
            type="submit"
            // disabled={isChecked === false ? true : false}
            click={handleSubmit}
            title={<Text tid="agree" />}
            color={cssProperties?.button?.text_color}
            fontSize={cssProperties?.button?.font_size}
            backgroundColor={cssProperties?.button?.color}
          />
        )}
      </div>
    </div>
  );
};

export default TideUserConsentModal;
