import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectVideoCallLanguageCmp from "../../../Components/VideoCallCmp/SelectVideoCallLanguageCmp/SelectVideoCallLanguageCmp";
import RouteNames from "../../../Constants/RouteNames";
import Header from "../../../Layout/Header";
import { actionGetLanguagesSagaAction } from "../../../Store/SagaActions/InitiateVideoCallSagaActions";
import AppFooter from "../../Common/AppFooter";
import { Text } from "../../Language/Language";
import { detect } from "detect-browser";
import Micvideocheckmodel from "../../Modals/Micvideocheckmodel";
import PortalModal from "../../../Portals/PortalModal";
import toast from "react-hot-toast";
import CleintNames from "../../../Constants/ClientNames";
import customeCodes from "../../../Constants/customeCodes";
import { actionGetVcipDetails } from "../../../Store/SagaActions/GetVcipDetailsSagaActions";
import moment from "moment";
import { actionCreateManagementToken } from "../../../Store/SagaActions/VideoSagaActions";
const SelectVideoCallLanguage = () => {
  const browser = detect();
  // const languageList = ['English', 'Telugu', 'Hindi', 'Tamil', 'Kannada', 'Malayalam', 'Kannada'];
  const [languageList, setlanguageList] = useState([]);
  const [customerVcipDetails, setCustomerVcipDetails] = useState({});
  const [selectedLang, setSelectedLang] = useState("");
  const [vcipStatus, setVcipStatus] = useState("");
  const [systemMaintanance, setSystemMaintanance] = useState(false)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let starttime;
  let endtime;
  if (vcipStatus) {
    starttime = vcipStatus?.vkyctime?.split("T")[0];
    endtime = vcipStatus?.vkyctime?.split("T")[1];
  }

  const clientName = useSelector((state) => state.HomeReducer.clientName);
  // const isClientAccess = clientName === CleintNames?.VOLOPAY ? true : false;

  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { streamingType, streamingURL, app_env, skipPanVerifyInCustomer } = dynamicSettingObj;
  const skipPanScreens = skipPanVerifyInCustomer === "true" ? true : false

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  const isBRANCHXAccess = clientName === CleintNames?.BRANCHX ? true : false;
  const isCashbook = clientName === CleintNames?.CASHBOOK ? true : false;
  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;

  window.onpopstate = () => {
    navigate(null);
  };
  useEffect(() => {
    const vcipkeyData = sessionStorage.getItem("vcipkey");
    const model = {
      vcipkey: vcipkeyData,
    };
    // setVcipkey(vcipkeyData);
    dispatch(
      actionGetLanguagesSagaAction({ model: model, callback: getLanguageData })
    );

    const currentDate = moment();
    const isMondayEvening4PM = currentDate.day() === 1 && currentDate.hour() >= 16;
    const isTuesdayMorning10AM = currentDate.day() === 2 && currentDate.hour() < 10;
    setSystemMaintanance(isMondayEvening4PM || isTuesdayMorning10AM);
    const tokenmodel={
      streamingURL: streamingURL,
    }
    if((!sessionStorage.getItem("100msLiveToken") && streamingType === customeCodes.STREAMING_TYPE_100MS_LIVE_VALUE) || ((sessionStorage.getItem("100msLiveToken") == "undefined") && streamingType === customeCodes.STREAMING_TYPE_100MS_LIVE_VALUE)) {
      dispatch(actionCreateManagementToken({model:tokenmodel,callback:(res)=>{managementTokenRes(res)}}))  
    }
  }, []);


  const getLanguageData = (data) => {
    if (isClientTideAccess) {
      const reversedata = data.reverse();
      setlanguageList(reversedata);
    } else {
      setlanguageList(data);
    }
  };
  
  const selectLanguage = (id) => {
    setSelectedLang(id);
  };

  const managementTokenRes = (res) => {
    sessionStorage.setItem("100msLiveToken",res?.token)
  } 
  // console.log("browser", browser);
  const networkready = () => {
    sessionStorage.setItem("langid", selectedLang);
    // const streamingType = process.env.REACT_APP_STREAMING_TYPE;
    let isMotorolaDevice = false;
    if (streamingType === customeCodes.STREAMING_TYPE_LIVEKIT_VALUE) {
      isMotorolaDevice = false;
    } else {
      isMotorolaDevice = navigator.userAgent.includes("motorola");
    }

    if (isMotorolaDevice) {
      const motoVersion = navigator.userAgent.split(" ")[6] || 0;
      if (motoVersion >= 45) {
        navigate(RouteNames.TOKEN_NUMBER, { replace: true });
      } else {
        navigate(RouteNames.BROWSER_CHECK);
      }
    } else if (
      (browser?.name == "crios" ||
        browser?.name == "chrome" ||
        browser?.name == "edge-chromium" ||
        browser?.name == "chromium-webview") &&
      parseInt(browser?.version) >= 70
    ) {
      navigate(RouteNames.TOKEN_NUMBER, { replace: true });
    } else if (
      browser?.name == "ios-webview" ||
      parseInt(browser?.version) >= 600
    ) {
      navigate(RouteNames.TOKEN_NUMBER, { replace: true });
    } else if (
      (browser?.name == "safari" || browser?.name == "ios") &&
      parseInt(browser?.version) >= 14
    ) {
      navigate(RouteNames.TOKEN_NUMBER, { replace: true });
    } else if (browser?.name == "firefox" && parseInt(browser?.version) >= 70) {
      navigate(RouteNames.TOKEN_NUMBER, { replace: true });
    } else {
      navigate(RouteNames.BROWSER_CHECK);
      // navigate(RouteNames.TOKEN_NUMBER, { replace: true });
    }
  };

  const ready = () => {
    sessionStorage.setItem("langid", selectedLang);
    networkready();
  };

  // const bookslot = async () => {
  //   // navigate(RouteNames.RESCHEDULE)
  //   // clearInitiateConference();
  //   // await clearInterval(updateTokenInterval);
  //   // await setIntervalConferenceId(null);
  //   // await setIsRescheduled(true);
  //   navigate(RouteNames.RESCHEDULE, {
  //     replace: true,
  //     // state: { InitiateVCFQ: InitiateVCFQ },
  //   });
  // };

  //  const isLoading = useSelector(state => state.HomeReducer.apiStatus);
  // const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  // Auto NAvigation if Coustmer Directly NAvigates to select Video Call Languge Page

  useEffect(() => {
    if (
      app_env == "tc_prod" ||
      app_env == "lq_prod" ||
      app_env == "unity_prod" ||
      app_env == "lq_dev" ||
      app_env == "lq_uat" ||
      app_env == "tc_uat"
    ) {
      GetVCIPDetailsForPageNavigation();
    }
  }, []);

  const GetVCIPDetailsForPageNavigation = () => {
    let VCIPID = sessionStorage.getItem("vcipref");
    const model = {
      vcipref: VCIPID,
    };
    dispatch(
      actionGetVcipDetails({ model: model, callback: checkTheCompletedProcess })
    );
  };

  // skipPanVerifyInCustomer --> True ? and panstatus 0 then skip pan screens
  // skipPanVerifyInCustomer --> True ? and panstatus 1 then skip pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 0 show pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 1 then skip pan screens


  const checkTheCompletedProcess = (data) => {
    setVcipStatus(data)
    if (data?.kycstatus === "0") {
      if (data?.panstatus === "1") {
        navigate(RouteNames.AADHAR_KYC_KYC_DIGILOCCKER);
      } else {
        navigate(RouteNames.SELECT_KYC_PROCESS);
      }
    } else if (data?.panstatus === "0" && !skipPanScreens) {
      if (isClientTideAccess) {
        navigate(RouteNames.PAN_CAPTURE, { replace: true });
      } else {
        if (iskinabank) {
          navigate(RouteNames.KINA_SELECT_OVD_PROCESS, { replace: true });
        } else {
          navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
        }
      }
    }
  };

  let holidayMessage = [];
  if (vcipStatus?.holiday_reason) {

    holidayMessage = vcipStatus?.holiday_reason.split("$");
  }
  return (
    <>
      {
        (vcipStatus?.isdovkyc == "0" || vcipStatus?.isholiday == "1") ? (
          <>
            <Header
              title={vcipStatus?.isholiday == "1" ?  "Holiday" : "Agents Unavailable"}
              navigate={() => navigate(-1)}
              isClientTideAccess={isClientTideAccess}
              hideHeader={false}
            />
            <article className="app-body">
              <div className="agentunavailableimg">
                <img src="images/agentunavaailable.png" />
              </div>
              {vcipStatus?.isholiday == "1" ? (
                <div className="agentunavailabletxt"><Text tid="holiday" /></div>
              )
                :
                (
                  <div className="agentunavailabletxt"><Text tid="agents_unavailable" /></div>
                )}
              {isClientTideAccess ? (
                <>
                  {vcipStatus?.isholiday == "1" ? (

                    <div className="agentunavailableinfo">
                      {holidayMessage.map(res => <div>{res}</div>)}
                    </div>
                  ) : (
                    <div className="agentunavailableinfo">
                      <Text tid="sorry_we_missed_you" /> <br />
                      <Text tid="please_open_the_app_at_a_time_that_works_best_for_you_between" />{" "}
                      <span
                        style={{ color: "rgb(0, 13, 46)", fontWeight: "bold" }}
                      >
                        {starttime}
                      </span>{" "}
                      to{" "}
                      <span
                        style={{ color: "rgb(0, 13, 46)", fontWeight: "bold" }}
                      >
                        {endtime}
                      </span>{" "}
                      <Text tid="to_complete_the_sign_up" />
                    </div>
                  )}
                </>
              ) : (

                <div className="agentunavailableinfo">
                  {vcipStatus?.isholiday == "1" ? (

                    <div className="agentunavailableinfo">
                      {holidayMessage.map(res => <div>{res}</div>)}
                    </div>
                    ) :<>
                  <Text tid="agents_currently_unavailable_Please_try_again_between" />{" "}
                  <span style={{ color: "rgb(0, 13, 46)", fontWeight: "bold" }}>
                    {starttime}
                  </span>{" "}
                  <Text tid="to" />{" "}
                  <span style={{ color: "rgb(0, 13, 46)", fontWeight: "bold" }}>
                    {endtime}
                  </span>{" "}
                  </>}
                  {/* <Text tid="or_Book_a_new_time_slot" /> */}
                </div>
              )}
              {/* <div
              className="joinnowbtntide"
              style={{
                paddingBottom: "4%",
                backgroundColor: isClientTideAccess ? "#F1F2F3" : "#FFFFFFF2",
              }}
            >
              {isClientTideAccess ? null : (
                <AppFooter
                  // btnName= {isClientTideAccess ? "Book slot for later" : "Book a slot"}
                  btnName={!isClientTideAccess && <Text tid="book_slot" />}
                  // isClientTideAccess = {isClientTideAccess}
                  navigate={bookslot}
                />
              )}
            </div> */}
            </article>
          </>
        )
          : (
            <>
              {!isClientTideAccess && (
                <Header
                  title={
                    isCashbook ? (
                      <Text tid="get_ready_for_video_KYC_call_with_our_agent" />
                    ) : (
                      <Text tid="Keep_things_handy" />
                    )
                  }
                  navigate={""}
                  hideHeader={false}
                />
              )}

              <article className="app-body">
                <SelectVideoCallLanguageCmp
                  languageList={languageList}
                  selectedLang={selectedLang}
                  selectLanguage={selectLanguage}
                  isClientTideAccess={isClientTideAccess}
                  isBRANCHXAccess={isBRANCHXAccess}
                />
                <div
                  className={"selectlangbtn"}
                  style={{
                    backgroundColor: isClientTideAccess ? "#F1F2F3" : "#FFFFFFF2",
                  }}
                >
                  {selectedLang && (
                    <AppFooter
                      btnName={
                        isClientTideAccess ? (
                          <Text tid="next" />
                        ) : isCashbook ? (
                          <Text tid="Join_Start_Video_Call" />
                        ) : (
                          <Text tid="im_ready" />
                        )
                      }
                      navigate={ready}
                      isClientTideAccess={isClientTideAccess}
                    />
                  )}
                  {/* {
          languageList.length && !isClientTideAccess ? 
            <AppFooter
              btnName="Book a slot"
              navigate={bookslot}
              // isClientTideAccess={isClientTideAccess}
            />
          :
          null
        } */}
                </div>
              </article>
            </>
          )
      }

    </>
  );
};

export default SelectVideoCallLanguage;
