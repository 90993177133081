import React, { useEffect, useState } from "react";
import Button from "../../Components/Elements/Button";

import {
  Room,
  RoomEvent,
  Track,
  VideoPresets,
  createLocalTracks,
} from "livekit-client";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../Components/Loading/Loading";
import { createLivekitTokenSagaAction, livekitdeleteroom } from "../../Store/SagaActions/VideoSagaActions";
import { Text } from "../Language/Language";
import "../VideoCall/InitiatingVideoCall/video.css";
import CleintNames from "../../Constants/ClientNames";

var meetingSessionConfiguration;
var _localVideotracksObj;

export default function MicvideocheckmodelLivekit(props) {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  const [disabledBtn, setdisabledBtn] = useState(false);
  const [cameraAccessIssue, setCameraAccessIssue] = useState("");
  const [isMeetingCreated, setisMeetingCreated] = useState(false);
  const [progressStatus, setProgressStatus] = useState("");
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [cameraAccessError, setCameraAccessError] = useState("");

  const [room, setRoom] = useState(undefined);
  const [track, setTrack] = useState("");
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoEnabled, setisVideoEnabled] = useState(false);

  const [isRoomCreated, setIsRoomCreated] = useState(false);
  const [networkloader, setNetworkloader] = useState(false);
  const [isAudioVideoStateCompleted, setIsAudioVideoStateCompleted] =
    useState(false);

  const [hitCounter, setHitCounter] = useState(1);

  const dispatch = useDispatch();

  const clientName = useSelector((state) => state.HomeReducer.clientName);

  const isakudo = clientName === CleintNames?.AKUDO ? true : false;
  const isClientTideAccess =
  clientName === CleintNames?.TIDEDEV ||
  clientName === CleintNames?.TIDEQA ||
  clientName === CleintNames?.TIDE
    ? true
    : false;

  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { streamingType, streamingURL,enableStreamLoadBalance, enableAutoJoinCall } = dynamicSettingObj;

  const autoJoinCall = enableAutoJoinCall ? enableAutoJoinCall : false;
  const isEnableStreamLoadBalalnce = enableStreamLoadBalance == "true"

  useEffect(() => {
    createTokenLiveKit();
    return async () => {
      setdisabledBtn(false)
      if (meetingSessionConfiguration) {
        await meetingSessionConfiguration?.disconnect();
        setisMeetingCreated(false);
      }
      if (room) {
        await room?.disconnect();
        setisMeetingCreated(false);
      }
    };
  }, []);

  useEffect(() => {
    if (hitCounter == 2) {
      network();
      setHitCounter(1);
    }
  }, [hitCounter]);

    // To auto join video call when token is zero and mic & camera enabled
    // useEffect(()=>{
    //   if(isAudioOn && isVideoEnabled && autoJoinCall){
    //     toast("Joining Video Call");
    //     movetovideopage();
    //   }
    // },[isAudioOn, isVideoEnabled, autoJoinCall])

  let networkspeed;
  const network = () => {
    // var imageUrl = "https://images.pexels.com/photos/20787/pexels-photo.jpg?"
    console.warn("*** checking network speed");
    // const imageUrl = "https://syntizen.com/91.png";
    const imgPath = `${window?.location?.origin?.toString()}/images/91.png`;
    const imageUrl = imgPath || "https://syntizen.com/91.png";
    let blob;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", imageUrl, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      blob = xhr.response;
      // console.warn("blob", blob.size);
    };
    xhr.send();
    // var downloadSize = 3285366; //bytes
    var downloadSize = 91122; //bytes
    async function ShowProgressMessage(msg) {
      if (console) {
        if (typeof msg == "string") {
          // console.warn(msg);
          setNetworkloader(false);
          // setError(msg)
        } else {
          // console.warn("msg is",msg.length)
          for (var i = 0; i < 1; i++) {
            // console.warn("networkspeed", msg[i], "mbps");
            console.warn("*** network speed is ", msg[i], "mbps");

            networkspeed = msg[i];
            setNetworkloader(false);
            // console.warn("speed is",networkspeed)
            if (isakudo == false && networkspeed > 0.080) {
              // navigate(RouteNames.TOKEN_NUMBER);
              const roomFinalData = room || meetingSessionConfiguration;
              await roomFinalData?.disconnect();
              if (
                sessionStorage.getItem("tokenEpireTime") ||
                props?.reschedule
              ) {
                console.warn("*** Video session requested", roomFinalData);
                props?.joinVideoSessionreq();
                deleteroom()
              } else {
                console.warn("*** Token expired ");
                closeModal();
                const roomFinalData = room || meetingSessionConfiguration;
                await roomFinalData?.disconnect();
                props?.handleTokenExpire();
              }
            } else if (isakudo == true && networkspeed > 0.2) {
              const roomFinalData = room || meetingSessionConfiguration;
              await roomFinalData?.disconnect();
              if (
                sessionStorage.getItem("tokenEpireTime") ||
                props?.reschedule
              ) {
                console.warn("*** Video session requested", roomFinalData);
                props?.joinVideoSessionreq();
                deleteroom()
              } else {
                console.warn("*** Token expired ");
                closeModal();
                const roomFinalData = room || meetingSessionConfiguration;
                await roomFinalData?.disconnect();
                props?.handleTokenExpire();
              }
            } else if (hitCounter <= 1) {
              setHitCounter(2);
              console.warn("*** Poor interner connection");

              // toast("checking network again");
            } else {
              console.warn("*** Poor interner connection");

              toast(
                <Text tid="poor_internet_connection_detected_Switch_to_other_network" />
              );
            }
          }
        }
      }
    }
    function InitiateSpeedDetection() {
      ShowProgressMessage("Loading the image, please wait...");
      // console.warn("hfgfrfgfh")
      setNetworkloader(true);
      window.setTimeout(MeasureConnectionSpeed, 1);
    }

    if (window.addEventListener) {
      // console.warn('window');
      window.addEventListener("load", InitiateSpeedDetection(), false);
    } else if (window.attachEvent) {
      // console.warn("window2")
      window.attachEvent("onload", InitiateSpeedDetection());
    }

    function MeasureConnectionSpeed() {
      var startTime, endTime;
      var download = new Image();
      download.onload = function () {
        endTime = new Date().getTime();
        showResults();
      };
      download.onerror = function (err, msg) {
        ShowProgressMessage("Invalid image, or error downloading");
      };
      startTime = new Date().getTime();
      var cacheBuster = "?nnn=" + startTime;
      // console.warn(cacheBuster)
      download.src = imageUrl + cacheBuster;
      // console.warn(download)

      function showResults() {
        var duration = (endTime - startTime) / 1000;
        var bitsLoaded = downloadSize * 8;
        var speedBps = (bitsLoaded / duration).toFixed(2);
        var speedKbps = (speedBps / 1024).toFixed(2);
        var speedMbps = (speedKbps / 1024).toFixed(2);
        // ShowProgressMessage([
        //     // "Your connection speed is:",
        //     // speedBps + " bps",
        //     // speedKbps + " kbps",
        //     speedMbps
        // ]);
        ShowProgressMessage([
          // "Your connection speed is:",
          // speedBps + " bps",
          // speedKbps + " kbps",
          speedMbps,
        ]);
      }
    }
  };

  const deleteroom = async () => {
    const sessionvcipid = sessionStorage.getItem("InitiateVCFQStorage");
    const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
    const parsevcipid = JSON.parse(sessionvcipid)
    const previewvcipid = parsevcipid?.videoconfsessionid
    const previewmodel = {
      streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
      body: {
        roomName: `prev_customer_${previewvcipid}`
      }
    }
    dispatch(livekitdeleteroom({ model: previewmodel }))
    const roomFinalData = room || meetingSessionConfiguration;
    await roomFinalData?.disconnect();
  }

  // LIVEKIT - CREATE TOKEN
  const createTokenLiveKit = (item) => {
    const sessionvcipid = sessionStorage.getItem("InitiateVCFQStorage");
    const videoconfsessionurl = sessionStorage.getItem("videoconfsessionurl")
    const parsevcipid = JSON.parse(sessionvcipid)
    const vcipid = parsevcipid?.videoconfsessionid
    const model = {
      streamingURL: isEnableStreamLoadBalalnce ? JSON.parse(videoconfsessionurl) : streamingURL,
      body: {
        room: `prev_customer_${vcipid}`,
        participantName: `customer_${vcipid}`,
        canSubscribe: true,
      }
    };
    dispatch(
      createLivekitTokenSagaAction({
        model,
        callback: (res) => getCreatedRoomData(res, item),
      })
    );
  };

  const getCreatedRoomData = (data, item) => {
    if (data && data?.token && data?.wssURL) {
      setProgressStatus("Session Created Successfully");
      setProgressPercentage((prev) => prev + 25);
      joinVideoSession(data?.token, data?.wssURL);
    } else {
      setCameraAccessError("Joining Session Failed");
      setisVideoEnabled(false)
      endMeeting();
    }
  };

  const joinVideoSession = async (token, wssURL) => {
    const roomOptions = {
      // automatically manage subscribed video quality
      adaptiveStream: true,
      // optimize publishing bandwidth and CPU for published tracks
      dynacast: true,
      // default capture settings
      videoCaptureDefaults: {
        resolution: VideoPresets.h720.resolution,
      },
    };
    const _roomData = new Room(roomOptions);
    let isRoomCreated = false;
    await _roomData
      .connect(wssURL, token)
      .then((res) => {
        console.warn("________ROOM CONNECTED!");
        isRoomCreated = false;
        setIsRoomCreated(false);
      })
      .catch((err) => {
        console.warn("______ROOM NOT CONNECTED", err);
        isRoomCreated = true;
        setIsRoomCreated(true);
      });
    meetingSessionConfiguration = _roomData;
    setRoom(_roomData);
    if (isRoomCreated) {
      setProgressStatus("Unabled to Proceed. Please try again!");
      setIsAudioVideoStateCompleted(true);
      return;
    }
    let audioVideoStatus = false;
    const tracks = await createLocalTracks({
      audio: true,
      // video: true,
      video: {
        facingMode: "user" // Set this to 'user' for the front camera
    },
    })
      .then((res) => {
        console.warn("_____RES", res);
        audioVideoStatus = true;
        return res;
      })
      .catch((err) => {
        console.warn("_____ERR", err.name);
        if (err.name === "NotAllowedError") {
          toast("Please Enable video permissions!");
          setCameraAccessError(
            "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
          );
        } else if (
          err.name === "DOMException" &&
          err.message === "Permission denied"
        ) {
          toast("Please Enable Microphone permissions!");
        } else if (
          err.name === "DOMException" &&
          err.message === "Could not start video source"
        ) {
          toast("Please Enable Microphone permissions!");
          setCameraAccessError(
            "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
          );
        } else {
          setCameraAccessError(
            "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)"
          );
        }
        audioVideoStatus = true;
        return [];
      });
    setIsAudioVideoStateCompleted(audioVideoStatus);
    if (tracks?.length > 0) {
      // set up event listeners
      _roomData
        .on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakerChange)
        .on(RoomEvent.MediaDevicesChanged, handleDevicesChanged);
      // const model = {
      //     roomName: sessionStorage.getItem("id"),
      // }
      console.warn("connected to room", _roomData.name);
      // setRemotevideoelements(prev => [...prev, element]);
      setProgressStatus("Meeting Joined Succesfully");
      setProgressPercentage((prev) => prev + 25);
      // screenshare(_roomData, true);
      for (let track of tracks) {
        const videotrack = await _roomData.localParticipant.publishTrack(track);
        if (
          track.kind === Track.Kind.Video &&
          track.source == Track.Source.Camera &&
          track.source !== Track.Source.ScreenShare
        ) {
          _localVideotracksObj = track;
          let v = document.getElementById("us");
          v.style.transform = "rotateY(180deg)"
          track.attach(v);
          setProgressPercentage((prev) => prev + 25);
          setProgressStatus("video Processing Succesfull");
          setisVideoEnabled(true);
        }
        if (videotrack.kind === Track.Kind.Audio) {
          setTrack(track);
          setProgressPercentage((prev) => prev + 25);
          setProgressStatus("Audio Processing Succesfull");
          setIsAudioOn(true);
        }
      }
    }
  };

  const handleActiveSpeakerChange = (speakers) => {
    let getLocalParticipant = speakers?.find((item) => item?.isLocal === true);
    if (getLocalParticipant) {
      // console.warn("_________________getLocalParticipant",
      //     getLocalParticipant?.audioLevel,
      //     getLocalParticipant?._connectionQuality);
      callback(getLocalParticipant?.audioLevel);
    }
  };

  const endMeeting = async (isClosedByCustomer) => {
    const roomFinalData = room || meetingSessionConfiguration;
    if (isClosedByCustomer) {
      if (sessionStorage.getItem("tokenEpireTime") || props?.reschedule) {
        network();
      } else {
        deleteroom()
        // await roomFinalData?.disconnect();
        setisMeetingCreated(false);
        props?.setIsopen(false);
        props?.handleTokenExpire();
      }
    } else {
      // await roomFinalData?.disconnect();
      deleteroom()
      setisMeetingCreated(false);
      props?.setIsopen(false);
    }
  };

  const callback = (decimal) => {
    const audio = Math.round(decimal * 100);
    // console.warn(audio, "roundof echo value");
    const converttonumber = parseInt(audio);
    const audiosource = -1 * converttonumber;
    // console.warn(audiosource)
    colorPids(audiosource);
  };

  const movetovideopage = () => {
    setdisabledBtn(true);
    endMeeting(true);
  };

  function colorPids(vol) {
    const allPids = [...document.querySelectorAll(".pid")];
    const numberOfPidsToColor = Math.round(vol / 10);
    const pidsToColor = allPids.slice(0, numberOfPidsToColor);
    for (const pid of allPids) {
      pid.style.backgroundColor = "#e6e7e8";
    }
    for (const pid of pidsToColor) {
      pid.style.backgroundColor = "#69ce2b";
    }
  }

  const closeModal = () => {
    if (isAudioVideoStateCompleted) {
      endMeeting(false);

    } else {
      toast("Audio Video status not completed. Please wait!");
    }
  };

  const reload = () => {
    window.location.reload();
  };

  const handleDevicesChanged = (a, c, d) => {
    console.warn("_____________________a, c, d", a, c, d);
  };

  // To auto join video call when token is zero and mic & camera enabled
  useEffect(()=>{
    // console.log("-------> ", autoJoinCall);
    if(isAudioOn && isVideoEnabled && autoJoinCall){
      toast("Joining Video Call");
      movetovideopage();
    }
  },[isAudioOn, isVideoEnabled, autoJoinCall])

  return (
    <>
      <div className="darkBG">
        <div className="centered">
          <div className="imgcancel" onClick={closeModal}>
            <img src="/images/Vector.jpg" />
          </div>

          <div>
            <div className="mainheading">
              <Text tid="audio_video_check" />
            </div>
            {!isVideoEnabled && cameraAccessIssue && (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <button
                  className="btn m-2 w-auto"
                  style={{
                    color: cssProperties?.button?.text_color,
                    fontSize: cssProperties?.button?.font_size,
                    background: props?.isClientTideAccess
                      ? "#1929D6"
                      : cssProperties?.button?.color,
                  }}
                  onClick={reload}
                >
                  <Text tid="refresh" />
                </button>
              </div>
            )}
            <p className="subheading">
              <Text tid="please_check_if_your_camera_and_mic_is_working_and_proceed" />
            </p>
            <div
              className="videopreview"
              style={{
                marginTop: props?.isClientTideAccess && "24px",
                color: props?.isClientTideAccess && "#2F3037",
              }}
            >
              <Text tid="video_preview" />
            </div>
            {/* Progress Bar */}
            <div className="video-preview-progress-status text-danger">
              {cameraAccessError}
            </div>
            {progressPercentage < 100 && (
              <div className="progress my-1">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-label="Animated striped example"
                  // aria-valuenow={`${videoPreviewProgress.code * 20}%`}
                  aria-valuenow={`${progressPercentage}%`}
                  aria-valuemin={0}
                  aria-valuemax={`${100}`}
                  style={{
                    width: `${progressPercentage}%`,
                    backgroundColor: cssProperties?.button?.color,
                  }}
                />
              </div>
            )}
            {progressPercentage < 100 ? (
              <div className="video-preview-progress-status text-success mb-1">
                {progressStatus}
              </div>
            ) : null}
            {networkloader && (
              <Loading
                text={<Text tid="checking_network_please_wait" />}
                isClientTideAccess={props?.isClientTideAccess}
              />
            )}

            <div
              className={`videomain d-flex flex-column align-items-center justify-content-center`}
            >
              <div className="recordingsymbol">
                <div className="elipse1">
                  <div className="elipse2"></div>
                </div>
                <p className="videorunningtext">Video Running</p>
              </div>
              <video id="us" autoPlay muted></video>
              {
                isVideoEnabled == false && (<Loading />)
              }
              <div className="voice-test-icon d-flex align-items-center">
                <img
                  src="/images/icon-voice.png"
                  className="me-1"
                  width={10}
                  height={20}
                />
                <div className="pids-wrapper" style={{ left: 20 }}>
                  <p className="pid mb-0"></p>
                  <p className="pid mb-0"></p>
                  <p className="pid mb-0"></p>
                  <p className="pid mb-0"></p>
                  <p className="pid mb-0"></p>
                  <p className="pid mb-0"></p>
                  <p className="pid mb-0"></p>
                  <p className="pid mb-0"></p>
                  <p className="pid mb-0"></p>
                  <p className="pid mb-0"></p>
                </div>
              </div>
            </div>
            {cameraAccessError == "" && (
              <p
                className="subheading warning-text"
                style={{ marginTop: props?.isClientTideAccess && "35px" }}
              >
                {props?.hasTokenExpired ? (
                  <Text tid="token_has_been_expired_Please_close_and_Try_Again" />
                ) : (
                  <Text tid="if_the_Proceed_button_does_not_enable_in_30_seconds_Please_close_the_popup_and_join_the_call_again" />
                )}
              </p>
            )}
            <p
              className="subheading"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              <Text tid="this_video_cannot_be_seen_by_anyone_except_you" />
            </p>

            {isAudioOn && isVideoEnabled && !props?.hasTokenExpired ? (
              <Button
                className={
                  props?.isClientTideAccess ? "tidevideobtn" : "videobtn"
                }
                type="button"
                disabled={disabledBtn}
                opacity={disabledBtn ? "25%" : "100%"}
                color={cssProperties?.button?.text_color}
                fontSize={cssProperties?.button?.font_size}
                backgroundColor={cssProperties?.button?.color}
                isClientTideAccess={props?.isClientTideAccess}
                click={movetovideopage}
                title={disabledBtn ? "Proceeding" : <Text tid="agree" />}
              />
            ) : isRoomCreated || cameraAccessError || props?.hasTokenExpired ? (
              <Button
                className={
                  props?.isClientTideAccess ? "tidevideobtn" : "videobtn"
                }
                type="button"
                color={cssProperties?.button?.text_color}
                fontSize={cssProperties?.button?.font_size}
                backgroundColor={cssProperties?.button?.color}
                isClientTideAccess={props?.isClientTideAccess}
                title={"Close"}
                click={closeModal}
              />
            ) : (
              <Button
                className={
                  props?.isClientTideAccess ? "tidevideobtn" : "videobtn"
                }
                type="button"
                opacity={"25%"}
                color={cssProperties?.button?.text_color}
                fontSize={cssProperties?.button?.font_size}
                backgroundColor={cssProperties?.button?.color}
                isClientTideAccess={props?.isClientTideAccess}
                title={"Processing..."}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
