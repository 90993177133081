const en = {
    select_language: "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    // instruction:"ನಿಮ್ಮ ವೀಡಿಯೊ ಕರೆಯೊಂದಿಗೆ ಮುಂದುವರಿಯಲು ಕೆಳಗೆ ಪಟ್ಟಿ ಮಾಡಲಾದ <b> ಭಾಷೆ</b> ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ನಾವು ಶಿಫಾರಸು ಮಾಡುತ್ತೇವೆ",
    instruction:"ಕೆಳಗೆ ಪಟ್ಟಿ ಮಾಡಲಾದದನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ನಾವು ಶಿಫಾರಸು ಮಾಡುತ್ತೇವೆ ",
    language:"ಭಾಷೆ ",
    continue:"ನಿಮ್ಮ ವೀಡಿಯೊ ಕರೆಯನ್ನು ಮುಂದುವರಿಸಲು",

    // =================================
    //        Commence your video KYC
    // =================================
    verify_your_identity: "ನಿಮ್ಮ ಗುರುತನ್ನು ಪರಿಶೀಲಿಸಿ",
    handy_documents:"ನಿಮ್ಮ ಪೂರ್ಣ KYC ಪ್ರಕ್ರಿಯೆಯೊಂದಿಗೆ ಮುಂದುವರಿಯುವ ಮೊದಲು ದಯವಿಟ್ಟು ನಿಮ್ಮ ಕೆಳಗಿನ ದಾಖಲೆಗಳನ್ನು ಕೈಯಲ್ಲಿಡಿ.",
    aadhaar_verify: "ಆಧಾರ್ ಪರಿಶೀಲನೆ",
    pan_capture:"PAN ಕ್ಯಾಪ್ಚರ್",
    video_call:"ವೀಡಿಯೊ ಕರೆ (ಪ್ರಶ್ನೋತ್ತರ)",


     // =================================
    //              Modal
    // =================================

    user_consent: "ಬಳಕೆದಾರರ ಸಮ್ಮತಿ",
    // user_instruction:"<b>'ಸಮ್ಮತಿಸಿ'</b> ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ, ನೀವು ಈ ಮೂಲಕ:",
    user_instruction:"ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ",
    user_agree:"'ಒಪ್ಪುತ್ತೇನೆ', ",
    user_here:"ನೀವು ಈ ಮೂಲಕ:",
    // user_instruction1:"ವೈಯಕ್ತಿಕ ವಿವರಗಳನ್ನು ಒದಗಿಸಲು ಸಿಂಟಿಜೆನ್ ಟೆಕ್ನಾಲಜೀಸ್ ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್ ಮಾಡಿದ ವಿನಂತಿಯನ್ನು ಅಂಗೀಕರಿಸಿ.",
    // user_instruction2:"ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳುವ ಮೂಲಕ ಎಲ್ಲಾ ಮಾಹಿತಿಯನ್ನು ಪ್ರವೇಶಿಸಲು, ನಕಲಿಸಲು ಮತ್ತು ಸಂಗ್ರಹಿಸಲು ನನ್ನ ಬೇಷರತ್ತಾದ ಏಕಾಗ್ರತೆಯನ್ನು ಒದಗಿಸಿ.",
    // user_instruction3:"ವಿನಂತಿಸಿದ ಸಂಸ್ಥೆಯ ಪರವಾಗಿ ನಾನು/ನಾವು ಹಾಗೆ ಮಾಡಲು ಅಧಿಕಾರ ಹೊಂದಿದ್ದೇವೆ ಮತ್ತು ಅದರ ಏಕೈಕ ಮತ್ತು ಸಂಪೂರ್ಣ ಜವಾಬ್ದಾರಿಯನ್ನು ಸಹ ಕೈಗೊಳ್ಳುತ್ತೇವೆ.",
    user_instruction1: "ಬ್ಯಾಂಕರ್‌ನೊಂದಿಗಿನ ನಿಮ್ಮ ವೀಡಿಯೊ ಸಂವಹನ ಸೆಷನ್ ರೆಕಾರ್ಡಿಂಗ್ ಮೋಡ್‌ನಲ್ಲಿರುತ್ತದೆ.k",
    user_instruction2: "ಬ್ಯಾಂಕರ್‌ನೊಂದಿಗಿನ ವೀಡಿಯೊ ಸಂವಾದದ ಅವಧಿಯಲ್ಲಿ ಲೈವ್ ograph ಾಯಾಚಿತ್ರವನ್ನು ಸೆರೆಹಿಡಿಯಲಾಗುತ್ತದೆ.",
    user_instruction3: "ವಿ-ಸಿಐಪಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಆಧಾರ್ ಪರಿಶೀಲನೆಗಾಗಿ ನಿಮ್ಮ ಆಧಾರ್ ವಿವರಗಳನ್ನು ಬಳಸಲಾಗುತ್ತದೆ.",
    user_instruction4: "ವಿ-ಸಿಐಪಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಪ್ಯಾನ್ ಪರಿಶೀಲನೆ ಮಾಡಲು ನಿಮ್ಮ ಪ್ಯಾನ್ ಕಾರ್ಡ್‌ನ photograph ಾಯಾಚಿತ್ರವನ್ನು ಸಂಗ್ರಹಿಸಲಾಗುತ್ತದೆ.",
    user_instruction5: "ವಿ-ಸಿಐಪಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ನಿಮ್ಮ ಲೈವ್ ಸ್ಥಳವನ್ನು ಸೆರೆಹಿಡಿಯಲಾಗುತ್ತದೆ.",
    user_instruction6: "ವೀಡಿಯೊ ಸಂವಹನ ಅಧಿವೇಶನದಲ್ಲಿ ಎಲ್ಲಾ ವಿವರಗಳು ಸರಿಯಾಗಿವೆ ಎಂದು ನೀವು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಬೇಕು.",
    user_instruction7: "ಆಧಾರ್ ಎಕ್ಸ್‌ಎಂಎಲ್ ಪ್ಯಾಕೆಟ್ ಅಥವಾ ಆಧಾರ್ ಸುರಕ್ಷಿತ ಕ್ಯೂಆರ್ ಕೋಡ್ 3 ದಿನಗಳಿಗಿಂತ ಹಳೆಯದಾಗಿರಬಾರದು.",
    user_instruciton8: "ಮೇಲೆ ತಿಳಿಸಿದ ಎಲ್ಲಾ ಹಂತಗಳು ಆರ್‌ಬಿಐ ಮಾರ್ಗಸೂಚಿಗಳ ಪ್ರಕಾರವೆ.",
    // content_cdtn1: "ನಾನು, ವಿ-ಸಿಐಪಿ ಐಡಿ",
    // content_cdtn2: " ಮೇಲಿನ ಎಲ್ಲಾ ಅಂಶಗಳನ್ನು ಹೋಲ್ಡರ್ ಒಪ್ಪುತ್ತಾರೆ ಮತ್ತು ಈ ಮೂಲಕ, ನನ್ನ ಒಪ್ಪಿಗೆಯನ್ನು ದೃ irm ೀಕರಿಸಿ.",
    accept_cdtn1: "ನಾನು, ",
    accept_cdtn2: " VCIP ID ಯೊಂದಿಗೆ - ",
    accept_cdtn3: " ಮೇಲೆ ತಿಳಿಸಿದ ಎಲ್ಲಾ ಅಂಶಗಳನ್ನು ಒಪ್ಪುತ್ತೇನೆ ಮತ್ತು ಈ ಮೂಲಕ ನನ್ನ ಒಪ್ಪಿಗೆಯನ್ನು ದೃಢೀಕರಿಸುತ್ತೇನೆ.",
    // disagree:"ಒಪ್ಪುವುದಿಲ್ಲ",
    agree:"ಒಪ್ಪುತ್ತೇನೆ",


     // =================================
    //              Token Number
    // =================================


   
    token_number:"ನಿಮ್ಮ ಟೋಕನ್ ಸಂಖ್ಯೆ ಶೂನ್ಯವಾಗುವವರೆಗೆ ಕಾಯಲು ವಿನಂತಿಸಿ.",
    wait_time:"ನಿಮ್ಮ ಅಂದಾಜು ಕಾಯುವ ಸಮಯ",
    wait_patinently:"ಈ ಕ್ಷಣದಲ್ಲಿ ನಮ್ಮ ಎಲ್ಲಾ ಏಜೆಂಟ್‌ಗಳು ಕಾರ್ಯನಿರತರಾಗಿರುವ ಕಾರಣ, ತಾಳ್ಮೆಯಿಂದ ಕಾಯಲು ಮತ್ತು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಹಿಂತಿರುಗಲು ನಾವು ನಿಮಗೆ ಶಿಫಾರಸು ಮಾಡುತ್ತೇವೆ",

    // =================================
    //              Headers
    // =================================


    initiating_call:"ವೀಡಿಯೊ ಕರೆಯನ್ನು ಪ್ರಾರಂಭಿಸಲಾಗುತ್ತಿದೆ",
    choose_language:"ಒಂದು ಭಾಷೆಯನ್ನು ಆರಿಸಿ",
    commence_video:"ನಿಮ್ಮ ವೀಡಿಯೊ KYC ಅನ್ನು ಪ್ರಾರಂಭಿಸಿ",
    aadhaar_xml:"ಆಧಾರ್ XML",
    kyc_details:"KYC ವಿವರಗಳು",
    aadhaar_offline:"ಆಧಾರ್ ಆಫ್‌ಲೈನ್ KYC",
    download_sucess:"ಡೌನ್‌ಲೋಡ್ ಯಶಸ್ವಿಯಾಗಿ",
    pan_verification:"ಪ್ಯಾನ್ ಪರಿಶೀಲನೆ",
    Keep_things_handy:"ನಮ್ಮ ಏಜೆಂಟ್ ಜೊತೆಗೆ ವೀಡಿಯೊ ಕರೆಗೆ ನೀವು ಸಿದ್ಧರಿದ್ದೀರಾ?",
    title_token:"ಟೋಕನ್ ಸಂಖ್ಯೆ",
    reschedule:"ಮರುನಿಗದಿಪಡಿಸಿ",
    status:"ಸ್ಥಿತಿ",


    // =================================
    //              PAN
    // =================================


    Pan_heading: "ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಫೋಟೋವನ್ನು ಸೆರೆಹಿಡಿಯಿರಿ",
    capture_photo:"ಫೋಟೋ ಸೆರೆಹಿಡಿಯಿರಿ",
    retake:"ಮರುಪಡೆಯಿರಿ",
    submit:"ಸಲ್ಲಿಸು",
    // =================================
    //              PAN Details
    // =================================



    ocr_info:"OCR ಮಾಹಿತಿ",
    name:"ಹೆಸರು",
    father_name:"ತಂದೆಯ ಹೆಸರು",
    dob:"DOB",
    pan_number:"ಪ್ಯಾನ್ ಸಂಖ್ಯೆ",
    please_wait:"ದಯಮಾಡಿ ನಿರೀಕ್ಷಿಸಿ...",
    fetching_aadhar:"ಆಧಾರ್ ವಿವರಗಳನ್ನು ಪಡೆಯಲಾಗುತ್ತಿದೆ...!",
    uid:"ಯುಐಡಿ",
    gender:"ಲಿಂಗ",
    Address:"ವಿಳಾಸ",
    xml_file:"ನಿಮ್ಮ ಬಳಿ ಆಧಾರ್ XML ಫೈಲ್ ಇದೆಯೇ?",
    yes:"ಹೌದು",
    no:"ಸಂ",



    waiting_agent:"ಏಜೆಂಟರಿಗಾಗಿ ಕಾಯಲಾಗುತ್ತಿದೆ",
    end_call:"ಕರೆಯನ್ನು ಕೊನೆಗೊಳಿಸಿ",
    book_slot:"ಸ್ಲಾಟ್ ಬುಕ್ ಮಾಡಿ",
    id_number:"ID Number",




     // =================================
    //              Kyc completed 
    // =================================


    kyc_update:"KYC ಸಲ್ಲಿಸಲಾಗಿದೆ.",
    verification:"ನಿಮ್ಮ ಪರಿಶೀಲನೆ ಪೂರ್ಣಗೊಂಡಿದೆ ಮತ್ತು ನಿಮ್ಮ ಎಲ್ಲಾ ಡೇಟಾವನ್ನು ಸುರಕ್ಷಿತವಾಗಿ ಸಂಗ್ರಹಿಸಲಾಗಿದೆ.",

    // ===================================
    //          Employement Details
    // ===================================

    occupation_details:"ಉದ್ಯೋಗದ ವಿವರಗಳು",
    occupation_details_subtext:"ನಿಮ್ಮ ಆಯ್ಕೆಗಳಲ್ಲಿ ಒಂದನ್ನು ಆಯ್ಕೆಮಾಡಿ"






}

export default en;