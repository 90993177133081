import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AadhaarKYCCmp from "../../../Components/SelectKYCProcessCmp/AadhaarKYCCmp";
import RouteNames from "../../../Constants/RouteNames";
import Header from "../../../Layout/Header";
import PortalModal from "../../../Portals/PortalModal";
import AppFooter from "../../Common/AppFooter";
import { Text } from "../../Language/Language";
import UserConsentModal from "../../Modals/UserConsentModal";

const AadhaarKYC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  let navigate = useNavigate();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const nextPage = () => {
    setIsOpen(false);
    navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD);
  };

  return (
    <>
      <Header
        title={<Text tid="aadhaar_xml" />}
        navigate={() => navigate(-1)}
        hideHeader={false}
      />
      <article className="app-body">
        <AadhaarKYCCmp />
        <AppFooter btnName={<Text tid="continue_button"/>} navigate={openModal} />
      </article>
      <PortalModal isOpen={isOpen}>
        <UserConsentModal agree={nextPage} closeModal={closeModal} />
      </PortalModal>
    </>
  );
};

export default AadhaarKYC;
