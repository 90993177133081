import {
    ACTION_GET_DIGILOCKER_LINK_REQ_EKYC,
    ACTION_GET_DIGILOCKER_STATUS_REQ_EKYC,
    ACTION_GET_EKYC_GENERATE_OTP,
    ACTION_GET_EKYC_GET_CATCHA,
    ACTION_GET_EKYC_GET_DATA,
    ACTION_GET_EKYC_REFRESH_CATCHA,
    AADHAAR_EKYC_PROCESS_SEND_OTP,
    AADHAAR_EKYC_PROCESS_AADHAARDETAILS,
    ACTION_POST_AADHAAR_EKYC_REQUEST,
    ACTION_POST_FETCH_AADHAAR_EKYC_STATUS
} from "./SagaActionTypes";


export const actionAadharDigiLocker = (payload) => {
    return {
        type: ACTION_GET_DIGILOCKER_LINK_REQ_EKYC,
        payload: payload
    }
}


export const actionAadharDigiLockerStatus = (payload) => {
    return {
        type: ACTION_GET_DIGILOCKER_STATUS_REQ_EKYC,
        payload: payload
    }
}
export const createAadhaarEkycReqSagaAction = (payload) => {
    return {
        type: ACTION_POST_AADHAAR_EKYC_REQUEST,
        payload: payload
    }
}
export const fetchAadhaarEkycStatusAction = (payload) => {
    return {
        type: ACTION_POST_FETCH_AADHAAR_EKYC_STATUS,
        payload: payload
    }
}


export const actionEKYCGetCaptcha = (payload) => {
    return {
        type: ACTION_GET_EKYC_GET_CATCHA,
        payload: payload
    }
}

export const actionEKYCRefreshCaptchaAction = (payload) => {
    return {
        type: ACTION_GET_EKYC_REFRESH_CATCHA,
        payload: payload
    }
}

export const actionEKYCGenerateCaptchaAction = (payload) => {
    return {
        type: ACTION_GET_EKYC_GENERATE_OTP,
        payload: payload
    }
}

export const actionCKYCDetailsAction = (payload) => {
    return {
        type: ACTION_GET_EKYC_GET_DATA,
        payload: payload
    }
}

export const sendOTPAction = (payload) => {
    return {
        type: AADHAAR_EKYC_PROCESS_SEND_OTP,
        payload: payload
    }
}

export const AdhaarDetailsAction = (payload) => {
    return {
        type: AADHAAR_EKYC_PROCESS_AADHAARDETAILS,
        payload: payload
    }
}