import React from "react";

const H1 = (props) => {
  return (
    <>
       {
      props?.isClientTideAccess ?(
        <h1
          style={{
        color: props?.color,
        fontSize: props?.fontSize,
        fontWeight: props?.fontWeight,
        textAlign: props?.textAlign,
        marginTop:"24px",
        zIndex:"999999"
      }}>
        {props?.title}
        </h1>
      ):
      (
        <h1
      className="main-heading"
      style={{
        color: props?.color,
        fontSize: props?.fontSize,
        fontWeight: props?.fontWeight,
        textAlign: props?.textAlign,
      }}
    >
      {props?.title}
    </h1>
      )
    }
    </>
   
    
  );
};

export default H1;
