const malyalam = {
    select_language: "ഭാഷ തിരഞ്ഞെടുക്കുക",
    instruction: "ചുവടെ ലിസ്റ്റുചെയ്തിരിക്കുന്ന ഒന്ന് തിരഞ്ഞെടുക്കാൻ ഞങ്ങൾ ശുപാർശ ചെയ്യുന്നു ",
    language: "ഭാഷ ",
    continue: "നിങ്ങളുടെ വീഡിയോ കോളിൽ തുടരാൻ",
    // instruction:"നിങ്ങളുടെ വീഡിയോ കോളിൽ തുടരുന്നതിന് താഴെ ലിസ്‌റ്റ് ചെയ്‌തിരിക്കുന്ന ഒരു <b> ഭാഷ</b> തിരഞ്ഞെടുക്കാൻ ഞങ്ങൾ ശുപാർശ ചെയ്യുന്നു",

    // =================================
    //        Commence your video KYC
    // =================================
    verify_your_identity: "നിങ്ങളുടെ ഐഡന്റിറ്റി സ്ഥിരീകരിക്കുക",
    handy_documents: "നിങ്ങളുടെ പൂർണ്ണമായ KYC പ്രക്രിയയുമായി മുന്നോട്ട് പോകുന്നതിന് മുമ്പ് നിങ്ങളുടെ ഇനിപ്പറയുന്ന പ്രമാണങ്ങൾ കൈവശം വയ്ക്കുക.",
    aadhaar_verify: "ആധാർ വെരിഫിക്കേഷൻ",
    pan_capture: "പാൻ ക്യാപ്‌ചർ",
    video_call: "വീഡിയോ കോൾ (ചോദ്യം)",
    // =================================
    //              Modal
    // =================================

    user_consent: "ഉപയോക്തൃ സമ്മതം",
    user_instruction: "ക്ലിക്ക് ചെയ്യുന്നതിലൂടെ  ",
    user_agree: "'സമ്മതിക്കുന്നു', ",
    user_here: "നിങ്ങൾ ഇതിനാൽ:",
    // user_instruction:"<b>'Agree'</b> എന്നതിൽ ക്ലിക്കുചെയ്യുന്നതിലൂടെ, നിങ്ങൾ ഇതിനാൽ:",
    // user_instruction1: "വ്യക്തിഗത വിശദാംശങ്ങൾ നൽകുന്നതിന് സിന്റൈസൻ ടെക്നോളജീസ് പ്രൈവറ്റ് ലിമിറ്റഡ് നടത്തിയ അഭ്യർത്ഥന അംഗീകരിക്കുക.",
    // user_instruction2: "വിവരങ്ങൾ പങ്കിട്ടുകൊണ്ട് എല്ലാ വിവരങ്ങളും ആക്‌സസ് ചെയ്യാനും പകർത്താനും സംഭരിക്കാനും എന്റെ നിരുപാധികമായ ഏകാഗ്രത നൽകുക.",
    // user_instruction3: "അഭ്യർത്ഥിക്കുന്ന ഓർഗനൈസേഷന്റെ പേരിൽ അങ്ങനെ ചെയ്യാൻ എനിക്ക്/ഞങ്ങൾക്ക് അധികാരമുണ്ട്.",
    user_instruction1: "ബാങ്കറുമായുള്ള നിങ്ങളുടെ വീഡിയോ ഇടപെടൽ സെഷൻ റെക്കോർഡിംഗ് മോഡിലായിരിക്കും.",
    user_instruction2: "ബാങ്കറുമായുള്ള വീഡിയോ ഇന്ററാക്ഷൻ സെഷനിൽ ഒരു തത്സമയ ഫോട്ടോ ക്യാപ്‌ചർ ചെയ്യും.",
    user_instruction3: "വി-സി‌ഐ‌പി പ്രക്രിയയിൽ‌ ആധാർ‌ പരിശോധനയ്‌ക്കായി നിങ്ങളുടെ ആധാർ‌ വിശദാംശങ്ങൾ‌ ഉപയോഗിക്കും.",
    user_instruction4: "വി-സിഐപി പ്രക്രിയയിൽ പാൻ പരിശോധന നടത്താൻ നിങ്ങളുടെ പാൻ കാർഡിന്റെ ഒരു ഫോട്ടോ ശേഖരിക്കും.",
    user_instruction5: "V-CIP പ്രോസസ്സിൽ നിങ്ങളുടെ തത്സമയ സ്ഥാനം ക്യാപ്‌ചർ ചെയ്യും.",
    user_instruction6: "വീഡിയോ ഇന്ററാക്ഷൻ സെഷനിൽ എല്ലാ വിശദാംശങ്ങളും ശരിയാണെന്ന് നിങ്ങൾ ഉറപ്പാക്കണം.",
    user_instruction7: "ആധാർ എക്സ്എം‌എൽ പാക്കറ്റ് അല്ലെങ്കിൽ ആധാർ സുരക്ഷിത ക്യുആർ കോഡ് 3 ദിവസത്തിൽ കൂടുതലാകരുത്.",
    user_instruciton8: "മുകളിൽ സൂചിപ്പിച്ച എല്ലാ നടപടികളും ആർബിഐ മാർഗ്ഗനിർദ്ദേശങ്ങൾ അനുസരിച്ചാണ്.",
    // content_cdtn1: "ഞാൻ, V-CIP ID ",
    // content_cdtn2: " മേൽപ്പറഞ്ഞ എല്ലാ പോയിന്റുകളുമായും ഉടമ യോജിക്കുന്നു, ഇതിനാൽ എന്റെ സമ്മതം സ്ഥിരീകരിക്കുക.",
    accept_cdtn1: "ഞാൻ, ",
    accept_cdtn2: " VCIP ഐഡി ഉപയോഗിച്ച് - ",
    accept_cdtn3: " മുകളിൽ സൂചിപ്പിച്ച എല്ലാ പോയിന്റുകളോടും യോജിക്കുന്നു, ഇതിനാൽ എന്റെ സമ്മതം സ്ഥിരീകരിക്കുന്നു.",
    // disagree: "വിയോജിക്കുന്നു",
    agree: "സമ്മതിക്കുന്നു",


    // =================================
    //              Token Number
    // =================================



    token_number: "നിങ്ങളുടെ ടോക്കൺ നമ്പർ പൂജ്യമാകുന്നത് വരെ കാത്തിരിക്കാൻ നിങ്ങളോട് അഭ്യർത്ഥിക്കുന്നു.",
    wait_time: "നിങ്ങളുടെ കണക്കാക്കിയ കാത്തിരിപ്പ് സമയം",
    wait_patinently: "ഞങ്ങളുടെ എല്ലാ ഏജന്റുമാരും ഇപ്പോൾ തിരക്കിലായതിനാൽ, ക്ഷമയോടെ കാത്തിരിക്കാനും കുറച്ച് സമയത്തിന് ശേഷം തിരികെ വരാനും ഞങ്ങൾ നിങ്ങളോട് ശുപാർശ ചെയ്യുന്നു",
    // =================================
    //              Headers
    // =================================


    initiating_call: "ഒരു വീഡിയോ കോൾ ആരംഭിക്കുന്നു",
    choose_language: "ഒരു ഭാഷ തിരഞ്ഞെടുക്കുക",
    commence_video: "നിങ്ങളുടെ വീഡിയോ KYC ആരംഭിക്കുക",
    aadhaar_xml: "ആധാർ XML",
    kyc_details: "KYC വിശദാംശങ്ങൾ",
    aadhaar_offline: "ആധാർ ഓഫ്‌ലൈൻ കെവൈസി",
    download_sucess: "ഡൗൺലോഡ് വിജയകരമായി",
    pan_verification: "പാൻ സ്ഥിരീകരണം",
    Keep_things_handy: "ഞങ്ങളുടെ ഏജന്റുമായുള്ള വീഡിയോ കോളിന് നിങ്ങൾ തയ്യാറാണോ?",
    title_token: "ടോക്കൺ നമ്പർ",
    reschedule: "വീണ്ടും ഷെഡ്യൂൾ ചെയ്യുക",
    status: "പദവി",


    // =================================
    //              PAN
    // =================================


    Pan_heading: "പാൻ കാർഡ് ഫോട്ടോ എടുക്കുക",
    capture_photo: "ഫോട്ടോ എടുക്കുക",
    retake: "വീണ്ടും എടുക്കുക",
    submit: "സമർപ്പിക്കുക",


    // =================================
    //              PAN Details
    // =================================



    ocr_info: "OCR വിവരങ്ങൾ",
    name: "പേര്",
    father_name: "പിതാക്കന്മാരുടെ പേര്",
    dob: "DOB",
    pan_number: "പാൻ നമ്പർ",
    please_wait: "കാത്തിരിക്കൂ...",
    fetching_aadhar: "ആധാർ വിശദാംശങ്ങൾ ലഭ്യമാക്കുന്നു...!",
    uid: "യുഐഡി",
    gender: "ലിംഗഭേദം",
    Address: "വിലാസം",
    xml_file: "നിങ്ങൾക്ക് ഒരു ആധാർ XML ഫയൽ ഉണ്ടോ?",
    yes: "അതെ",
    no: "ഇല്ല",



    waiting_agent: "ഏജന്റിനായി കാത്തിരിക്കുന്നു",
    end_call: "കോൾ അവസാനിപ്പിക്കുക",
    book_slot: "ഒരു സ്ലോട്ട് ബുക്ക് ചെയ്യുക",
    id_number:"ID Number",



    // =================================
    //              Kyc completed 
    // =================================


    kyc_update: "KYC സമർപ്പിച്ചു.",
    verification: "നിങ്ങളുടെ പരിശോധന പൂർത്തിയായി, നിങ്ങളുടെ എല്ലാ ഡാറ്റയും സുരക്ഷിതമായി സംഭരിച്ചിരിക്കുന്നു.",

    // ===================================
    //          Employement Details
    // ===================================

    occupation_details:"തൊഴിൽ വിശദാംശങ്ങൾ",
    occupation_details_subtext:"നിങ്ങളുടെ ഓപ്ഷനുകളിലൊന്ന് തിരഞ്ഞെടുക്കുക"




}

export default malyalam;