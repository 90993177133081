import React from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Text } from "../../Pages/Language/Language";

import "./largescreen.css";

const QR = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  //   const img = `data:image/png;base64,${props?.logo}`;

  const copyLink = () => {
    // const Id = sessionStorage.getItem("link");
    // const vcipLinkURL = window.location?.href;
    // const linkULR = Id ? Id : vcipLinkURL;
    navigator?.clipboard?.writeText(`${props?.vcipLinkURL}`);
    toast.success("Copied", { position: "bottom-center" });
  };

  return (
    <>
      <div className={"root"}>
        <div className={"root-main"}>
          <div
            className={"videokycheader"}
            style={{
              backgroundColor: cssProperties.theme_bg_color,
              // backgroundImage: `url(${img})`,
              // backgroundPosition: "center",
              // backgroundSize: "cover",
              // backgroundAttachment:"fixed",
              // opacity:"0.4"
            }}
          >
            <div
              style={{
                // backgroundImage: `url(${img})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "1%",
              }}
            >
              <div
                className={"videokyctext"}
                // style={{
                //   color: cssProperties.pagetitle_heading.font_color,
                //   fontSize: cssProperties.pagetitle_heading.font_size,
                //   fontWeight: cssProperties.pagetitle_heading.font_type,
                // }}
              >
                <Text tid="video_kyc" />
              </div>
              <div className={"QR-note"}>
                <Text tid="note" />
              </div>
              <ul
                className={"note"}
                style={{
                  color: cssProperties.sub_heading.font_color,
                  fontSize: cssProperties.sub_heading.font_size,
                  fontWeight: cssProperties.sub_heading.font_type,
                }}
              >
                {/* Note: */}
                <li
                  className={"list"}
                  //   style={{
                  //     color: cssProperties.body.font_color,
                  //     fontSize: cssProperties.body.font_size,
                  //     fontWeight: cssProperties.body.font_type,
                  //   }}
                >
                  <Text tid="do_not_refresh_warning" />
                </li>
                <li
                  className={"list"}
                  //   style={{
                  //     color: cssProperties.body.font_color,
                  //     fontSize: cssProperties.body.font_size,
                  //     fontWeight: cssProperties.body.font_type,
                  //   }}
                >
                  <Text tid="do_not_open_same_link_warning" />
                </li>
                <li
                  className={"list"}
                  //   style={{
                  //     color: cssProperties.body.font_color,
                  //     fontSize: cssProperties.body.font_size,
                  //     fontWeight: cssProperties.body.font_type,
                  //   }}
                >
                  <Text tid="ensure_internet_connectivity" />
                </li>
                <li
                  className={"list"}
                  //   style={{
                  //     color: cssProperties.body.font_color,
                  //     fontSize: cssProperties.body.font_size,
                  //     fontWeight: cssProperties.body.font_type,
                  //   }}
                >
                  <Text tid="enable_cam_mic_location_settings" />
                </li>
                <li
                  className={"list"}
                  //   style={{
                  //     color: cssProperties.body.font_color,
                  //     fontSize: cssProperties.body.font_size,
                  //     fontWeight: cssProperties.body.font_type,
                  //   }}
                >
                  <Text tid="sleep_mode_warning" />
                </li>
              </ul>
              <div className={"videokycimg"}>
                <img
                  src="./../images/QR-layer-img2.svg"
                  width={490}
                  height={179}
                />
              </div>
            </div>
          </div>
          <div
            className={"qrheader"}
            style={{ backgroundImage: "url('./../images/QR-mobile.svg" }}
          >
            <div
              className={"qrtitle"}
              style={{
                color: cssProperties.pagetitle_heading.font_color,
                fontSize: cssProperties.pagetitle_heading.font_size,
                fontWeight: cssProperties.pagetitle_heading.font_type,
              }}
            >
              <Text tid="scan_qr_code" />
            </div>
            <div className={"qr"}>
              {/* <Image src="/khyaal/images/Video KYC.svg" width={202} height={202} /> */}
              <img
                src={`data:image/png;base64,${props?.qrimage}`}
                width={202}
                height={202}
              />
            </div>
            <div
              className={"qrinfo"}
              style={{
                color: cssProperties.sub_heading.font_color,
                fontSize: cssProperties.sub_heading.font_size,
                fontWeight: cssProperties.sub_heading.font_type,
              }}
            >
              <Text tid="scar_qr_and_complete_kyc_process" />
            </div>
            <div className={"or"}><Text tid="or" /></div>
            <button
              onClick={copyLink}
              className={"copybutton"}
              style={{
                backgroundColor: cssProperties.button.color,
              }}
            >
              <span
                style={{
                  color: cssProperties.button.text_color,
                  fontSize: cssProperties.button.font_size,
                  fontWeight: cssProperties.button.font_type,
                }}
              >
                <Text tid="copy_link" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QR;
