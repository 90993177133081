import React from "react";
import Header from "../../Layout/Header";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../Components/Elements/Button";
import RouteNames from "../../Constants/RouteNames";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "../Language/Language"; 



export default function () {
  const navigate = useNavigate();
  // const CustomerDetails = useSelector((state) => state.VcipReducer.CustomerDetails);
  const location = useLocation();
  const customerDetails = location?.state?.data?.kycdetails;
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  const movetonextpage = ()=>{
    navigate(RouteNames.KINA_SELECT_OVD_PROCESS, { replace: true })
  }
  return (
    <div className="idpage">
      <Header
        title={"Customer Details"}
        navigate={() => navigate(RouteNames.CUSTOMER_ID, { replace: true })}
        hideHeader={false}
      />
      <div style={{ padding: "0 24px 0 24px" }}>
        <div className="customerdetailscard">
          <div style={{ padding: "20px 20px 0px 20px"}}>
            <div className="detailslable"><Text tid="name"/></div>
            <div className="detailstext">
              {customerDetails?.name}
              {/* XYZ Customer Name */}
              </div>
          </div>
          <div style={{ padding: "20px 20px 0px 20px" }}>
            <div className="detailslable"><Text tid ="dob" /></div>
            <div className="detailstext">
            {customerDetails?.dob}
              {/* DD-MM-YYYY */}
              </div>
          </div>
          <div style={{padding: "20px 20px 0px 20px" }}>
            <div className="detailslable"><Text tid="Address"/></div>
            <div className="detailstext">
            {customerDetails?.address}
              {/* The purpose of lorem ipsum is to create a natural looking block of
              text sentence, laying out pages. */}
            </div>
          </div>
          <div style={{padding: "20px 20px 0px 20px"}}>
            <div className="detailslable"><Text tid="phone"/></div>
            <div className="detailstext">
            {customerDetails?.mobile}
              {/* +91 999 999 9999 */}
              </div>
          </div>
          <div style={{padding: "20px 20px 0px 20px" }}>
            <div className="detailslable"><Text tid="email_id"/></div>
            <div className="detailstext">
            {customerDetails?.email}
              {/* xyz@gmail.com */}
              </div>
          </div>
        </div>
      </div>
      <div className="customeridbtn">
        <Button
          className={"custidsubbtn"}
          type="button"
          title={<Text tid="done"/>}
          color={cssProperties?.button?.text_color}
          fontSize={cssProperties?.button?.font_size}
          backgroundColor={cssProperties?.button?.color}   
          click={movetonextpage}
        />
      </div>
    </div>
  );
}
