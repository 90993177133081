import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import base64 from "base-64";
import AadhaarUploadCmp from "../../../Components/SelectKYCProcessCmp/AadhaarUploadCmp";
import AadhaarData from "../../../Components/SelectKYCProcessCmp/AadharData";
import AadharDataOkyc from  "../../../Components/SelectKYCProcessCmp/AadharDataOkyc";
import RouteNames from "../../../Constants/RouteNames";
import Header from "../../../Layout/Header";
import PortalModal from "../../../Portals/PortalModal";
import {
  sendOTPAction, AdhaarDetailsAction
} from "../../../Store/SagaActions/AadharEKYCSagaActions";

import {
  actionGetCaptcha,
  actionRefreshCaptchaAction,
  actionGenerateCaptchaAction,
  actionOKYCDetailsAction,
} from "../../../Store/SagaActions/AadharSagaActions";
import AppFooter from "../../Common/AppFooter";
import { Text } from "../../Language/Language";
import AadhaarNumberModal from "../../Modals/AadhaarNumberModal";
import AadhaarNumberVerifyModal from "../../Modals/AadhaarNumberVerifyModal";
import CleintNames from "../../../Constants/ClientNames";

const AadhaarUploadEKYC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [captchaDetails, setCaptchaDetails] = useState({});
  const [isCaptchaTrue, setIsCaptchaTrue] = useState(false);
  const [okycData, setokycData] = useState(false);
  const [aadharapi, setAadharapi] = useState(true);
  const [kycotpTxnid, setkycotpTxnid] = useState("")
  const [aadhar, setAadhar] = useState("")

  const [CustDetail, setCustDetail] = useState({});

  let navigate = useNavigate();
  window.onpopstate = () => {
    navigate(null);
  }
  const dispatch = useDispatch();
  const clientName = useSelector((state) => state.HomeReducer.clientName);

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  const vcipkey = sessionStorage.getItem("vcipkey");

  useEffect(() => {
    generateXMLModal();
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const generateXMLModal = () => {
    setIsOpen(true);
  };

  const sendOTPModal = (aadharNumber) => {
    const finalvalue = base64?.encode(aadharNumber);
    setAadhar(finalvalue);
    const model = {
      vcipkey: vcipkey,
      aadhaar: finalvalue,
      otptype: "1",
      rrn: "1",
    };

    dispatch(sendOTPAction({ model: model, callback: getOKYCCaptchaData }));
  };

  const sendRefreshOTPModal = () => {
    const model = {
      vcipkey: vcipkey,
      token: captchaDetails?.token,
      rrn: "1",
    };
    if (captchaDetails?.token)
      dispatch(
        actionRefreshCaptchaAction({
          model: model,
          callback: getOKYCCaptchaData,
        })
      );
  };

  const getOTPModal = (captchaKey) => {
    // const aadharbase64 = base64?.encode(aadhar);
    const otpbase64 = base64?.encode(captchaKey);
    const model = {
      vcipkey: vcipkey,
      aadhaar: aadhar,
      kycotptxnid: kycotpTxnid,
      kycotp: otpbase64,
      otptype: "3",
      rrn: "1",
    };
    dispatch(actionGenerateCaptchaAction({ model: model, callback: getOTP }));
  };

  const getOKYCdetail = (OTP) => {
    const otpbase64 = base64?.encode(OTP);
    const model = {
      vcipkey: vcipkey,
      aadhaar: aadhar,
      kycotptxnid: kycotpTxnid,
      kycotp: otpbase64,
      otptype: "3",
      rrn: "1",
    };
    dispatch(AdhaarDetailsAction({ model: model, callback: getOKYCdata }));
  };

  const getOKYCCaptchaData = (data) => {
    const { kycotptxnid } = data
    setkycotpTxnid(kycotptxnid)
    if (data.respcode === "406") restartProcess();
    if (data.respcode === "200") {
      setCaptchaDetails(data);
      setIsCaptchaTrue(true);
      setAadharapi(false);
      setIsOpen(false);
      setIsVerify(true);
    }
  };

  const getOTP = (data) => {
    if (data.respcode === "406") restartProcess();
    if (data.respcode === "200") {
      getOKYCCaptchaData(data);
      setIsOpen(false);
      setIsVerify(true);
    }
  };

  const restartProcess = () => {
    setIsVerify(false);
    setIsCaptchaTrue(false);
    setIsOpen(true);
    setAadharapi(true);
    setCaptchaDetails({});
  };

  const getOKYCdata = (data) => {
    if (data.respcode === "406") restartProcess();
    if (data.respcode === "200") {

      const { kycdetails } = data
      setCustDetail(kycdetails)
      // getOKYCCaptchaData(kycdetails);
      setokycData(true);
      setIsOpen(false);
      setIsVerify(false);
    }
  };

  const verifyOTP = () => {
    setIsVerify(false);
  };

  const nextPage = () => {
    navigate(RouteNames.AADHAR_KYC_PROCESS_DOWNLOAD);
  };

  const panPage = () => {
    if (isClientTideAccess) {
      navigate(RouteNames.PAN_CAPTURE, { replace: true });
    } else {
      navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
    }
    // navigate(RouteNames.SELECT_PAN_KYC_PROCESS);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsVerify(false);
    if (okycData === false) {
      navigate(RouteNames.SELECT_KYC_PROCESS);
    }
  };

  return (
    <>
      <Header
        title={<Text tid="aadhaar_ekyc" />}
        navigate={() => navigate(-1)}
        hideHeader={false}
      />
      <article className="app-body">
        {!okycData ? (
          <>
            <AadhaarUploadCmp />
            {/* <AppFooter
              btnName="Proceed"
              isGenerate={true}
              generateXMLModal={generateXMLModal}
              navigate={nextPage}
            /> */}
          </>
        ) : (
          <>
          {/* {ekyc - fname} */}
            {/* <AadhaarData aadharDetails={CustDetail} /> */}
            <AadharDataOkyc aadharDetails={CustDetail} />
            <div className="kycbutton">
              <AppFooter btnName={<Text tid="agree"/>} navigate={panPage} />
            </div>
          </>
        )}
      </article>

      <PortalModal isOpen={isOpen} isBottom={true}>
        <AadhaarNumberModal
          isCaptchaTrue={isCaptchaTrue}
          captchaDetails={captchaDetails}
          sendOTPModal={sendOTPModal}
          closeModal={closeModal}
          sendRefreshOTPModal={sendRefreshOTPModal}
          getOTPModal={getOTPModal}
          aadharapi={aadharapi}
          isEKYCProccess={true}
        />
      </PortalModal>

      <PortalModal isOpen={isVerify} isBottom={true}>
        <AadhaarNumberVerifyModal
          verifyOTP={verifyOTP}
          closeModal={closeModal}
          getOKYCdetail={getOKYCdetail}
          isEkyc={true}
        />
      </PortalModal>
    </>
  );
};

export default AadhaarUploadEKYC;
