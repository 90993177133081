import React, { useState, useEffect, useContext } from "react";
import ChooseLanguageCmp from "../../Components/ChooseLanguageCmp";
import RouteNames from "../../Constants/RouteNames";
import AppFooter from "../Common/AppFooter";
import { useDispatch, useSelector } from "react-redux";
// import {
//   actionCreateSlot,
//   actionGetVcipDetails,
// } from "../../Store/SagaActions/GetVcipDetailsSagaActions";
import toast, { Toaster } from "react-hot-toast";
import { actionLocationDetails } from "../../Store/SagaActions/CommonSagaActions";
import publicIp from "public-ip";
// import StatusCodes from "../../Constants/StatusCodes";
import PortalModal from "../../Portals/PortalModal";
import UserConsentModal from "../Modals/UserConsentModal";
// import { LanguageContext } from "../Language/Language";
// import { languageOptions } from "../../Languages";
import { Text } from "../Language/Language";

import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Layout/Header";
import CleintNames from "../../Constants/ClientNames";
import { actionCreateSlot } from "../../Store/SagaActions/GetVcipDetailsSagaActions";
import Tablepopup from "../Home/TablePopup";
import HomeCmp from "../../Components/HomeCmp";

var parsedUser
const ChooseLanguage = (props) => {
  const lang = sessionStorage.getItem("lang");
  const [ip, setIp] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [locationName, setLocationName] = useState("");
  const [isLargeDevice, setIsLargeDevice] = useState(false);
  const [customerVcipDetails, setCustomerVcipDetails] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setselectedLanguage] = useState(lang ? lang : "en");
  const [ipValid, setIpValid] = useState(false);
  const [slotipData, setslotipData] = useState({});
  const [waitingTimeTxt, setWaitingTimeTxt] = useState("");


  const params = useParams();
  const dispatch = useDispatch();

  var intervalClearId = null;

  let navigate = useNavigate();
  const vcipDetails = useSelector((state) => state.VcipReducer.vcipDetails);
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { skipPanVerifyInCustomer } = dynamicSettingObj;
  const skipPanScreens = skipPanVerifyInCustomer === "true" ? true : false
  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;
  const isusfb = (clientName === CleintNames?.USFB || clientName === CleintNames?.USFBSYNTIZEN) ? true : false;

  useEffect(() => {
    const user = sessionStorage.getItem("user");
    parsedUser = JSON.parse(user);
    setCustomerVcipDetails(parsedUser)
    getLatLong();
    getDeviceSize();

    return () => {
      clearInterval(intervalClearId);
    };


  }, []);

  const getDeviceSize = () => {
    if (window?.screen && window?.screen.width >= 950) {
      setIsLargeDevice(true);
    }
  };

  const getLatLong = () => {
    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition((this.showPosition));
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position.coords.latitude) {
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
            const model = {
              lat: position.coords.latitude,
              long: position.coords.longitude,
            };
            dispatch(
              actionLocationDetails({
                model: model,
                callback: getLocationDetailsData,
              })
            );
          } else {
            toast(<Text tid="please_enable_location" />);
          }
        },
        (error) => {
          // console.log(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        }
      );
    } else {
      toast.warn("Geolocation is not supported by this browser.");
    }
    (async () => {
      setIp(await publicIp.v4());
    })();
  };

  const getLocationDetailsData = (data) => {
    setLocationName(data);
  };


  // skipPanVerifyInCustomer --> True ? and panstatus 0 then skip pan screens
  // skipPanVerifyInCustomer --> True ? and panstatus 1 then skip pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 0 show pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 1 then skip pan screens

  const startProcess1 = () => {
    if (vcipDetails?.kycstatus === "0") {
      navigate(RouteNames.SELECT_KYC_PROCESS);
    } else if (!skipPanScreens && vcipDetails?.panstatus === "0") {
      if (isClientTideAccess) {
        navigate(RouteNames.PAN_CAPTURE, { replace: true });
      } else {
        navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
      }
    } else if (vcipDetails?.videocallstatus === "0") {
      if (parsedUser?.custoccupname === "" && !isusfb) {
        navigate(RouteNames.EMPLOYEMENT_DETAILS);
      } else {
        navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
      }
    }
  };

  // const startProcess1 = () => {
  //   if (vcipDetails?.kycstatus === "0") {
  //     navigate(RouteNames.SELECT_KYC_PROCESS);
  //   } else if (vcipDetails?.panstatus === "0") {
  //     if (isClientTideAccess) {
  //       navigate(RouteNames.PAN_CAPTURE, { replace: true });
  //     } else {
  //       navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
  //     }
  //   } else if (vcipDetails?.videocallstatus === "0") {

  //     if(parsedUser?.custoccupname=="" && !isusfb){
  //       navigate(RouteNames.EMPLOYEMENT_DETAILS);
  //     }else{
  //       navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
  //     }
  //   }
  // };

  const onChangeLanguageSelect = (value) => {
    // console.log(value, "value")
    setselectedLanguage(value);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const nextPage = () => {
    setIsOpen(false);
    navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD);
  };

  const vcipid = vcipDetails?.vcipid;
  const cust_name = vcipDetails?.cust_name;




  // Function Adddition for the Cashbook Customer Starts Here
  const startProcess = () => {
    // if (!ip) {
    //   toast(
    //     "Unable to find your location. Please try again."
    //   );
    //   return;
    // }
    // if (lat && long) {
    // setIsAPIProcessing(true);
    const vcipkey = sessionStorage.getItem("vcipkey");
    const model = {
      vcipkey: vcipkey,
      custdetails: {
        ip,
        location: lat + "," + long,
        geolocation: locationName,
        nw_incoming: "",
        nw_outgoing: "",
        videoresolution: "",
      },
      ref1: "",
    };
    dispatch(
      actionCreateSlot({
        model: model,
        callback: getCreateSlotData,
        // callback: (data) => {
        //   if (data?.respcode === "200") {
        //     sessionStorage.setItem("ip", ip);
        //     sessionStorage.setItem("location", lat + "," + long);
        //     sessionStorage.setItem("geolocation", locationName);
        //     setWaitingTimeTxt("");
        //     navigate(RouteNames.CHOOSE_LANGAUGE);
        //   } else {
        //     setWaitingTimeTxt(data?.respdesc);
        //     startProcess();
        //   }
        // },
      })
    );
    // } else {
    //   toast(
    //     "Unable to find your location. Please check your location and try again."
    //   );
    // }
  };


  const getCreateSlotData = (data) => {
    if (data?.respcode === "200") {
      const ipdata = JSON.parse(data?.ipdata);
      let valid;
      if (Object.values(ipdata).length > 0) {
        valid = validateIPdata(ipdata);
      }

      // if(valid === true){
      //   openModal()
      // }
      setIpValid(valid);
      setslotipData(ipdata);

      sessionStorage.setItem("ip", ip);
      sessionStorage.setItem(
        "location",
        lat ? lat + "," + long : data?.geolatlon
      );
      sessionStorage.setItem(
        "geolocation",
        locationName ? locationName : data?.geoloc
      );
      clearInterval(intervalClearId);
      setWaitingTimeTxt("");
      // navigate(RouteNames.CHOOSE_LANGAUGE);
      if (
        customerVcipDetails?.kycstatus === "0" ||
        customerVcipDetails?.panstatus === "0"
      ) {
        openModal();
      } else {
        startProcess1();
        // navigate(RouteNames.CHOOSE_LANGAUGE);
      }
    } else {
      setWaitingTimeTxt(data?.respdesc);
      if (data?.respcode === "489" && intervalClearId === null) {
        intervalClearId = setInterval(() => {
          startProcess();
        }, 2000);
      }
    }
    // setIsAPIProcessing(false);
  };



  const validateIPdata = (data) => {
    let valid = false;
    Object.keys(data)?.forEach((item, i) => {
      if (
        data[item] === true &&
        (item == "is_tor" ||
          item == "is_proxy" ||
          item == "is_datacenter" ||
          item == "is_icloud_relay")
      ) {
        if (data[item] === true) {
          valid = true;
        }
      }
    });
    // console.log(valid, data)
    return valid;
  };








  // Function Adddition for the Cashbook Customer Ends Here

  return (
    <>
      <Header
        title={<Text tid="choose_language" />}
        navigate={() => navigate(-1)}
        hideHeader={false}
      />
      <article className="app-body">
        {waitingTimeTxt == "" && <ChooseLanguageCmp
          onChangeLanguageSelect={onChangeLanguageSelect}
          selectedLanguage={selectedLanguage}
        />}
        {waitingTimeTxt != "" && <HomeCmp
          waitingTimeTxt={waitingTimeTxt}
        />}
        {waitingTimeTxt == "" && <AppFooter
          btnName={<Text tid="start" />}
          navigate={
            startProcess
          }
        />}
      </article>



      <PortalModal isOpen={isOpen}>
        <UserConsentModal
          agree={startProcess1}
          vcipid={vcipid}
          cust_name={cust_name}
          closeModal={closeModal}
        />
      </PortalModal>







      {/* 
      <PortalModal isOpen={isOpen}>
        <UserConsentModal
          agree={startProcess1}
          vcipid={vcipid}
          cust_name={cust_name}
          closeModal={closeModal}
        />
      </PortalModal> */}
    </>
  );
};

export default ChooseLanguage;
