import {
  ACTION_DYNAMIC_STREAMING_URL,
  ACTION_END_VIDEO_CALL_BY_AGENT_REQ,
  ACTION_GET_LOCATION_DETAILS_REQ,
  ACTION_GET_NOTIFICATIONS_LIST_REQ,
  ACTION_GET_NOTIFICATIONS_PUSH_REQ,
  APISTATUS,
  STORE_PAGE_DATA,
  STREAMING_EVENT_BY_CUSTOMER,
  GET_CUSTOMER_ENV_DATA_SAGA_ACTION
} from "./SagaActionTypes";

export const actionReqResStatusLoaderSagaAction = (payload) => {
  return {
    type: APISTATUS,
    payload: payload,
  };
};

export const actionLocationDetails = (payload) => {
  return {
    type: ACTION_GET_LOCATION_DETAILS_REQ,
    payload: payload,
  };
};

export const actionNoficationListSaga = (payload) => {
  return {
    type: ACTION_GET_NOTIFICATIONS_LIST_REQ,
    payload: payload,
  };
};
export const pushNotificationSaga = (payload) => {
  return { type: ACTION_GET_NOTIFICATIONS_PUSH_REQ, payload: payload };
};

export const endVideoCallByAgentSagaAction = (payload) => {
  return {
    type: ACTION_END_VIDEO_CALL_BY_AGENT_REQ,
    payload: payload,
  };
};

export const storePageData = (payload) => {
  return {
    type: STORE_PAGE_DATA,
    payload: payload,
  };
};

export const streamingEventByCustomerAction = (payload) => ({
  type: STREAMING_EVENT_BY_CUSTOMER,
  payload,  
});

export const storeDynamicURLAction = (payload) => ({
  type: ACTION_DYNAMIC_STREAMING_URL,
  payload,  
});

export const getCustomerEnvDataSagaAction = (payload) => ({
  type: GET_CUSTOMER_ENV_DATA_SAGA_ACTION,
  payload
}) 