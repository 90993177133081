import React, { useEffect, useState } from "react";
import { Text } from '../../../Pages/Language/Language';
import H4 from "../../Elements/H4";
import P from "../../Elements/P";
import { useSelector } from "react-redux";
import "./EmploymentDropdownComp.css";
import CleintNames from "../../../Constants/ClientNames";

export default function EmploymentDropdownComp(props) {
    const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
    const clientName = useSelector((state) => state.HomeReducer.clientName);
    const showInputField = props?.selectSubOccupation?.data?.length === props?.selectedValues?.length;
    const isClientTideAccess =
        clientName === CleintNames?.TIDEDEV ||
            clientName === CleintNames?.TIDEQA ||
            clientName === CleintNames?.TIDE
            ? true
            : false;
    return (
        <section className="occupations-dropdown-options-container">
            {props?.showOccupationNewUI ?
                <>
                    {/* <div className="form-group">
                        <label htmlFor="businessName" className="form-label">Business name</label>
                        <input
                            type="text"
                            name="businessName"
                            className="form-input"
                            value={props?.bussinessName}
                            onChange={props?.handleAdditionalInfoChange}
                        />
                        <p className="form-help-text">If you're a freelancer, enter your full name</p>
                    </div> */}
                    <div className="employment-dropdown-container mt-3">
                        {/* <div className="select-wrapper"> */}
                        <label htmlFor="occupation" className="form-label">Occupation</label>
                        <div className="form-dropdown">
                            <img src='../images/dropdownarrow.png' alt="arrow" className="dropdown-arrow" />
                            <select value={props?.selectedOccupation?.code} onChange={props?.handleSelectChange} className={`form-select_tide application-type ${isClientTideAccess ? "occupationalDropDownTc" : ""}`} id="inputGroupSelect01">
                                <option value="">Select occupation</option>
                                {props?.ocupationList?.map((occupation, index) => (
                                    <option key={index} value={occupation.code}>{occupation.name}</option>
                                ))}
                            </select>
                        </div>
                        {
                            props?.selectSubOccupation?.data?.length > 0 && (
                                props?.selectSubOccupation?.data?.map((item, index) => {
                                    if (index > props?.selectedIndex) return null;
                                    const isCurrencyDropdown = item.name === "T4";
                                    const defaultValue = isCurrencyDropdown
                                        ? props?.selectedValues[index]?.code || "INR"
                                        : props?.selectedValues[index]?.code || "";
                                    return (
                                        <div key={index} className="mt-3">
                                            <label htmlFor={props?.showLabelNames(props?.selectSubOccupation?.data[index]?.name)} className="form-label"> {props?.showLabelNames(props?.selectSubOccupation?.data[index]?.name)}</label>
                                            <div className="form-dropdown">
                                                <img src='../images/dropdownarrow.png' alt="arrow" className="dropdown-arrow" />
                                                <select
                                                    className={`form-select_tide application-type ${isClientTideAccess ? "occupationalDropDownTc" : ""}`} id="inputGroupSelect01"
                                                    // style={{ marginTop: "10px" }}
                                                    value={defaultValue}
                                                    // value={props?.selectedValues[index]?.code || ""}
                                                    onChange={(e) => props?.handleSelectOccupationChange(e, index)}
                                                >
                                                    <option value="">Select value</option>
                                                    {item?.value.map((option) => (
                                                        <option key={option.code} value={option.code}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    );
                                })
                            )
                        }
                    </div>
                    <p className="form-note">
                        Note: Tide may ask you to verify these details by a supporting document.
                    </p>
                </>
                :
                <>
                    <div className="app-body-imgs" style={{ display: "flex", justifyContent: "center" }}>
                        <img src="../images/employement_Details.png" alt="vkyc" />
                    </div>
                    <div className="app-body-data">
                        <H4
                            className="title"
                            title={<Text tid="occupation_details" />}
                            color={cssProperties?.pagetitle_heading?.font_color}
                            fontSize={cssProperties?.pagetitle_heading?.font_size}
                        />
                        <P
                            className="txt"
                            txt={"Please Select the occupation details from the drop down"}
                            color={cssProperties?.body?.font_color}
                            fontSize={cssProperties?.body?.font_size}
                        />
                        <hr />
                    </div>
                    <div className="employment-dropdown-container">
                        {/* <div className="select-wrapper"> */}
                        <select value={props?.selectedOccupation?.code} onChange={props?.handleSelectChange} className={`form-select application-type ${isClientTideAccess ? "occupationalDropDownTc" : ""}`} id="inputGroupSelect01">
                            <option value="">Select value</option>
                            {props?.ocupationList?.map((occupation, index) => (
                                <option key={index} value={occupation.code}>{occupation.name}</option>
                            ))}
                        </select>
                        {
                            props?.selectSubOccupation?.data?.length > 0 && (
                                props?.selectSubOccupation?.data?.map((item, index) => {
                                    if (index > props?.selectedIndex) return null;
                                    return (
                                        <div key={index}>
                                            <select
                                                className={`form-select application-type ${isClientTideAccess ? "occupationalDropDownTc" : ""}`} id="inputGroupSelect01"
                                                style={{ marginTop: "10px" }}
                                                value={props?.selectedValues[index]?.code || ""}
                                                onChange={(e) => props?.handleSelectOccupationChangeLQ(e, index)}
                                            >
                                                <option value="">Select value</option>
                                                {item.value.map((option) => (
                                                    <option key={option.code} value={option.code}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    );
                                })
                            )
                        }
                        {showInputField && (
                            <div className="form-group">
                                <label
                                    className="additional-info-label"
                                    style={{ marginTop: "10px", fontSize: "16px", color: "black", fontWeight: 600 }}
                                >
                                    Please enter Annual salary amount :
                                </label>
                                <input
                                    type="number"
                                    className="form-control add-customer"
                                    placeholder=""
                                    value={props?.additionalInfo}
                                    onChange={props?.handleAdditionalInfoChange}
                                    style={{ height: "45px" }}
                                />
                            </div>
                        )}
                    </div>
                </>
            }
        </section >
    );
}
