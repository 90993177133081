import React from "react";
import { useNavigate } from "react-router-dom";
import SelectKYCProcessCmp from "../../Components/SelectKYCProcessCmp";
import RouteNames from "../../Constants/RouteNames";
import Header from "../../Layout/Header";
import AppFooter from "../Common/AppFooter";
import { Text } from "../Language/Language";
import CleintNames from "../../Constants/ClientNames";
import { useSelector } from "react-redux";
import TideSelectKycProcessCmp from "../../Components/TideComponents/TideSelectKycProcessCmp/TideSelectKycProcessCmp";
import customeCodes from "../../Constants/customeCodes";


// var parsedUser
const SelectKYCProcess = () => {
  let navigate = useNavigate();
  // window.onpopstate = () => {
  //   navigate(null);
  // }
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const vcipDetails = useSelector((state) => state.VcipReducer.vcipDetails);
  const { feature_flags } = vcipDetails
  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;
  const data = sessionStorage.getItem('user');
  const accName = JSON.parse(data)?.acccode;
  const { skipPanVerifyInCustomer } = dynamicSettingObj
  const skipPanScreens = skipPanVerifyInCustomer === "true" ? true : false

  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;

  const isCashbook = clientName === CleintNames?.CASHBOOK ? true : false;

  const isusfb = (clientName === CleintNames?.USFB || clientName === CleintNames?.USFBSYNTIZEN) ? true : false;


  const user = sessionStorage.getItem("user");
  const customerVcipDetails = JSON.parse(user);


  // skipPanVerifyInCustomer --> True ? and panstatus 0 then skip pan screens
  // skipPanVerifyInCustomer --> True ? and panstatus 1 then skip pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 0 show pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 1 then skip pan screens

  const nextStep = () => {
    if (customerVcipDetails?.kycstatus === "0") {
      if (iskinabank) {
        navigate(RouteNames.CUSTOMER_ID);
      } else {
        if (feature_flags?.kyctype === customeCodes?.EKYC || feature_flags?.kyctype === customeCodes?.COSMOS_EKYC) {
          navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD);
        } else {
          navigate(RouteNames.AADHAR_KYC_KYC_DIGILOCCKER);
        }
      }
    } else if (customerVcipDetails?.panstatus === "0" && !skipPanScreens) {
     
      if (isClientTideAccess) {
        navigate(RouteNames.PAN_CAPTURE, { replace: true });
      } else {
        if (iskinabank) {
          navigate(RouteNames.KINA_SELECT_OVD_PROCESS, { replace: true });
        } else {
          navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
        }
      }
    } else if (customerVcipDetails?.videocallstatus === "0") {
      if (customerVcipDetails?.custoccupname === "" && !isusfb) {
        navigate(RouteNames.EMPLOYEMENT_DETAILS);
      } else {
        navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
      }
    }
  };



  return (
    <>
      <Header
        title={
          isClientTideAccess ? (
            <Text tid="identity_verification_process" />
          ) : (
            isCashbook ? <Text tid="start_your_video_KYC" /> : iskinabank ? <Text tid="commence_your_digital_kyc" /> :
              <Text tid="commence_video" />
          )
        }
        isClientTideAccess={isClientTideAccess}
        noarrow="noarrow"
        navigate={() => navigate(-1)}
        hideHeader={false}
      />
      {/* {isClientTideAccess ? (
        <TideHeader
          navigate={() => navigate(-1)}
          text={"Identity verification"}
        />
      ) : (
        <Header
          title={<Text tid="commence_video" />}
          navigate={() => navigate(-1)}
          hideHeader={false}
        />
      )} */}
      {isClientTideAccess ? (
        <article className="app-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "space-between",
              height: "100%",
            }}
          >
            <TideSelectKycProcessCmp />
            <div className="homepagebutton" style={{
              backgroundColor: isClientTideAccess ? "#F1F2F3" : "#FFFFFFF2",
              paddingBottom: isClientTideAccess ? "2%" : ""
            }}>
              <AppFooter
                btnName={<Text tid="start" />}
                isClientTideAccess={isClientTideAccess}
                navigate={nextStep}
              />
            </div>
          </div>
        </article>
      ) : (
        <article className="app-body">
          <SelectKYCProcessCmp customerVcipDetails={customerVcipDetails} iskinabank={iskinabank} />
          <div className="homepagebutton" style={{
            backgroundColor: "#FFFFFFF2",
            paddingBottom: "3%"
          }}>
            <AppFooter
              btnName={<Text tid="start" />}
              navigate={nextStep}
            // navigate={() => navigate(RouteNames.AADHAR_KYC_KYC_DIGILOCCKER)}
            />
          </div>
        </article>
      )}
    </>
  );
};

export default SelectKYCProcess;
