const be = {
    select_language: "ভাষা নির্বাচন কর",
    // instruction:"আপনার ভিডিও কল চালিয়ে যেতে আমরা আপনাকে নীচে তালিকাভুক্ত একটি <b> ভাষা</b> নির্বাচন করার পরামর্শ দিই৷",
    instruction: "আমরা আপনাকে নীচে তালিকাভুক্ত একটি নির্বাচন করার পরামর্শ দিই ",
    language: "ভাষা ",
    continue: "আপনার ভিডিও কল চালিয়ে যেতে ",

    // =================================
    //        Commence your video KYC
    // =================================
    verify_your_identity: "তোমার পরিচিতি নিশ্চিত কর",
    handy_documents: "আপনার সম্পূর্ণ কেওয়াইসি প্রক্রিয়ার সাথে এগিয়ে যাওয়ার আগে অনুগ্রহ করে আপনার নিম্নলিখিত নথিগুলি হাতের কাছে রাখুন৷",
    aadhaar_verify: "আধার যাচাইকরণ",
    pan_capture: "প্যান ক্যাপচার",
    video_call: "ভিডিও কল (প্রশ্ন ও উত্তর)",

    // =================================
    //              Modal
    // =================================

    user_consent: "ব্যবহারকারীর সম্মতি",
    user_instruction: "ক্লিক করে ",
    user_agree: "'একমত', ",
    user_here: "আপনি এতদ্বারা:",
    // user_instruction:"<b>'সম্মত'</b> এ ক্লিক করে, আপনি এতদ্বারা:",
    // user_instruction1: "ব্যক্তিগত বিবরণ প্রদানের জন্য Syntizen টেকনোলজিস প্রাইভেট লিমিটেডের করা অনুরোধ স্বীকার করুন।",
    // user_instruction2: "ইনফর্মেশন শেয়ার করে সমস্ত তথ্য অ্যাক্সেস, কপি এবং সঞ্চয় করার জন্য আমার নিঃশর্ত মনোযোগ প্রদান করুন।",
    // user_instruction3: "এছাড়াও আমি/আমরা অনুরোধকারী সংস্থার পক্ষ থেকে এটি করার জন্য অনুমোদিত এবং tkae একমাত্র এবং এটির জন্য সম্পূর্ণ দায়িত্ব।",
    user_instruction1: "ব্যাঙ্কারের সাথে আপনার ভিডিও মিথস্ক্রিয়া সেশনটি রেকর্ডিং মোডে থাকবে।",
    user_instruction2: "ব্যাঙ্কারের সাথে ভিডিও আলাপচারিতার সময় একটি লাইভ ফটোগ্রাফ ক্যাপচার করা হবে।",
    user_instruction3: "আপনার আধার বিবরণ ভি-সিআইপি প্রক্রিয়াতে আধার যাচাইয়ের জন্য ব্যবহৃত হবে।",
    user_instruction4: "ভি-সিআইপি প্রক্রিয়াতে প্যান যাচাই করতে আপনার প্যান কার্ডের একটি ছবি সংগ্রহ করা হবে।",
    user_instruction5: "আপনার লাইভ অবস্থানটি ভি-সিআইপি প্রক্রিয়াতে ক্যাপচার করা হবে।",
    user_instruction6: "ভিডিও ইন্টারঅ্যাকশন সেশনের সময় আপনার অবশ্যই সমস্ত বিবরণ সঠিক কিনা তা নিশ্চিত করা উচিত।",
    user_instruction7: "আধার এক্সএমএল প্যাকেট বা আধার সুরক্ষিত কিউআর কোডটি 3 দিনের বেশি হওয়া উচিত নয়।",
    // content_cdtn1: "আই, ভি-সিআইপি আইডি",
    // content_cdtn2: " ধারক উপরে বর্ণিত সমস্ত পয়েন্টগুলির সাথে একমত এবং এর মাধ্যমে আমার সম্মতি নিশ্চিত করুন confirm",
    accept_cdtn1: "আমি, ",
    accept_cdtn2: " ভিসিআইপি আইডি সহ - ",
    accept_cdtn3: " উপরে উল্লিখিত সমস্ত পয়েন্টের সাথে একমত এবং এতদ্বারা আমার সম্মতি নিশ্চিত করুন।",
    // disagree: "অসম্মতি",
    agree: "একমত",

    // =================================
    //              Token Number
    // =================================



    token_number: "আপনার টোকেন নম্বর শূন্য না হওয়া পর্যন্ত অপেক্ষা করার জন্য আপনাকে অনুরোধ করছি।",
    wait_time: "আপনার আনুমানিক অপেক্ষার সময়",
    wait_patinently: "যেহেতু আমাদের সমস্ত এজেন্ট এই মুহুর্তে ব্যস্ত, আমরা আপনাকে ধৈর্য ধরে অপেক্ষা করার এবং কিছুক্ষণ পরে ফিরে আসার পরামর্শ দিচ্ছি",



    // =================================
    //              Headers
    // =================================


    initiating_call: "একটি ভিডিও কল শুরু করা হচ্ছে",
    choose_language: "একটি ভাষা চয়ন করুন",
    commence_video: "আপনার ভিডিও কেওয়াইসি শুরু করুন",
    aadhaar_xml: "আধার এক্সএমএল",
    kyc_details: "KYC বিশদ",
    aadhaar_offline: "আধার অফলাইন কেওয়াইসি",
    download_sucess: "সফলভাবে ডাউনলোড করুন",
    pan_verification: "প্যান যাচাইকরণ",
    Keep_things_handy: "আপনি কি আমাদের এজেন্টের সাথে ভিডিও কলের জন্য প্রস্তুত?",
    title_token: "টোকেন নম্বর",
    reschedule: "পুনঃনির্ধারণ",
    status: "স্ট্যাটাস",


    // =================================
    //              PAN
    // =================================


    Pan_heading: "প্যান কার্ডের ছবি তুলুন",
    capture_photo: "ছবি ক্যাপচার করুন",
    retake: "পুনরায় গ্রহণ করুন",
    submit: "জমা দিন",


    // =================================
    //              Details
    // =================================



    ocr_info: "ওসিআর তথ্য",
    name: "নাম",
    father_name: "বাবার নাম",
    dob: "DOB",
    pan_number: "প্যান নম্বর",
    please_wait: "অনুগ্রহপূর্বক অপেক্ষা করুন...",
    fetching_aadhar: "আধারের বিবরণ আনা হচ্ছে...!",
    uid: "ইউআইডি",
    gender: "লিঙ্গ",
    Address: "ঠিকানা",
    xml_file: "আপনার কাছে একটি আধার XML ফাইল আছে?",
    yes: "হ্যাঁ",
    no: "না",


    waiting_agent: "এজেন্টের জন্য অপেক্ষা করছি",
    end_call: "কল শেষ",
    book_slot: "একটি স্লট বুক করুন",



    // =================================
    //              Kyc completed 
    // =================================


    kyc_update: "KYC জমা দিয়েছেন।",
    verification: "আপনার যাচাইকরণ সম্পন্ন হয়েছে এবং আপনার সমস্ত ডেটা নিরাপদে সংরক্ষণ করা হয়েছে।",

       // ===================================
    //          Employement Details
    // ===================================

    occupation_details:"পেশার বিবরণ",
    occupation_details_subtext:"আপনার বিকল্পগুলির মধ্যে একটি নির্বাচন করুন"








}

export default be;