const tamil = {
    select_language: "மொழியை தேர்ந்தெடுங்கள்",
    instruction: "கீழே பட்டியலிடப்பட்டுள்ள ஒன்றைத் தேர்ந்தெடுக்க பரிந்துரைக்கிறோம் ",
    language: "மொழி ",
    continue: "உங்கள் வீடியோ அழைப்பைத் தொடர ",
    // instruction:"உங்கள் வீடியோ அழைப்பைத் தொடர, கீழே பட்டியலிடப்பட்டுள்ள <b> மொழியைத்</b> தேர்ந்தெடுக்க பரிந்துரைக்கிறோம்",

    // =================================
    //        Commence your video KYC
    // =================================
    verify_your_identity: "உங்கள் அடையாளத்தை சரிபார்க்க",
    handy_documents: "உங்களின் முழு KYC செயல்முறையைத் தொடர்வதற்கு முன், பின்வரும் ஆவணங்களை கையில் வைத்திருக்கவும்.",
    aadhaar_verify: "ஆதார் சரிபார்ப்பு",
    pan_capture: "PAN பிடிப்பு",
    video_call: "வீடியோ அழைப்பு (கேள்வி பதில்)",
    // =================================
    //              Modal
    // =================================

    user_consent: "பயனர் ஒப்புதல்",
    user_instruction: "கிளிக் செய்வதன் மூலம்",
    user_agree: "'ஒப்புக்கொள்கிறேன்', ",
    user_here: "நீங்கள் இதன் மூலம்:",
    // user_instruction:"<b>'ஏற்கிறேன்'</b> என்பதைக் கிளிக் செய்வதன் மூலம், நீங்கள்:",
    // user_instruction1: "தனிப்பட்ட விவரங்களை வழங்க சின்டிசன் டெக்னாலஜிஸ் பிரைவேட் லிமிடெட் செய்த கோரிக்கையை ஏற்கவும்.",
    // user_instruction2: "தகவலைப் பகிர்வதன் மூலம் அனைத்துத் தகவலையும் அணுக, நகலெடுக்க மற்றும் சேமிக்க எனது நிபந்தனையற்ற கவனத்தை வழங்கவும்.",
    // user_instruction3: "மேலும் கோருபவர் அமைப்பின் சார்பாக நான்/எங்களுக்கு அதிகாரம் உள்ளது மற்றும் அதற்கான முழுப் பொறுப்பையும் ஏற்கிறேன்.",
    user_instruction1: "வங்கியாளருடனான உங்கள் வீடியோ தொடர்பு அமர்வு பதிவு பயன்முறையில் இருக்கும்.",
    user_instruction2: "வங்கியாளருடனான வீடியோ தொடர்பு அமர்வின் போது ஒரு நேரடி புகைப்படம் எடுக்கப்படும்.",
    user_instruction3: "வி-சிஐபி செயல்பாட்டில் ஆதார் சரிபார்ப்புக்கு உங்கள் ஆதார் விவரங்கள் பயன்படுத்தப்படும்.",
    user_instruction4: "V-CIP செயல்பாட்டில் பான் சரிபார்ப்பைச் செய்ய உங்கள் பான் கார்டின் புகைப்படம் சேகரிக்கப்படும்.",
    user_instruction5: "உங்கள் நேரடி இருப்பிடம் V-CIP செயல்பாட்டில் பிடிக்கப்படும்.",
    user_instruction6: "வீடியோ தொடர்பு அமர்வின் போது அனைத்து விவரங்களும் சரியானவை என்பதை உறுதிப்படுத்த வேண்டும்.",
    user_instruction7: "ஆதார் எக்ஸ்எம்எல் பாக்கெட் அல்லது ஆதார் பாதுகாப்பான கியூஆர் குறியீடு 3 நாட்களுக்கு மேல் இருக்கக்கூடாது.",
    user_instruciton8: "மேலே குறிப்பிடப்பட்டுள்ள அனைத்து நடவடிக்கைகளும் ரிசர்வ் வங்கியின் வழிகாட்டுதலின்படி உள்ளன.",

    // content_cdtn1: "நான், வி-சிஐபி ஐடி ",
    // content_cdtn2: " மேலே குறிப்பிட்டுள்ள எல்லா புள்ளிகளையும் வைத்திருப்பவர் ஒப்புக்கொள்கிறார், இதன்மூலம், எனது சம்மதத்தை உறுதிப்படுத்தவும்.",
    accept_cdtn1: "நான், ",
    accept_cdtn2: " VCIP ஐடியுடன் - ",
    accept_cdtn3: " மேலே குறிப்பிடப்பட்ட அனைத்து புள்ளிகளுடனும் உடன்படுகிறது மற்றும் இதன் மூலம், எனது சம்மதத்தை உறுதிப்படுத்துகிறேன்.",
    // disagree: "கருத்து வேறுபாடு",
    agree: "ஒப்புக்கொள்கிறேன்",


    // =================================
    //              Token Number
    // =================================


    token_number: "உங்கள் டோக்கன் எண் பூஜ்ஜியமாகும் வரை காத்திருக்குமாறு கேட்டுக்கொள்கிறேன்.",
    wait_time: "உங்களின் மதிப்பிடப்பட்ட காத்திருப்பு நேரம்",
    wait_patinently: "இந்த நேரத்தில் எங்கள் முகவர்கள் அனைவரும் பிஸியாக இருப்பதால், பொறுமையாக காத்திருந்து சிறிது நேரம் கழித்து திரும்பி வருமாறு பரிந்துரைக்கிறோம்",

    // =================================
    //              Headers
    // =================================


    initiating_call: "வீடியோ அழைப்பைத் தொடங்குதல்",
    choose_language: "ஒரு மொழியை தேர்வு செய்யவும்",
    commence_video: "உங்கள் வீடியோ KYC ஐத் தொடங்கவும்",
    aadhaar_xml: "ஆதார் எக்ஸ்எம்எல்",
    kyc_details: "KYC விவரங்கள்",
    aadhaar_offline: "ஆதார் ஆஃப்லைன் KYC",
    download_sucess: "பதிவிறக்கம் செய்யப்பட்டது",
    pan_verification: "PAN சரிபார்ப்பு",
    Keep_things_handy: "எங்கள் முகவருடன் வீடியோ அழைப்பிற்கு நீங்கள் தயாரா?",
    title_token: "டோக்கன் எண்",
    reschedule: "மறு அட்டவணை",
    status: "நிலை",

    // =================================
    //              PAN
    // =================================


    Pan_heading: "பான் கார்டு புகைப்படத்தை எடுக்கவும்",
    capture_photo: "புகைப்படம் எடுக்கவும்",
    retake: "மீண்டும் எடுக்கவும்",
    submit: "சமர்ப்பிக்கவும்",

    // =================================
    //              PAN Details
    // =================================



    ocr_info: "OCR தகவல்",
    name: "பெயர்",
    father_name: "தந்தையின் பெயர்",
    dob: "DOB",
    pan_number: "பான் எண்",
    please_wait: "தயவுசெய்து காத்திருங்கள்...",
    fetching_aadhar: "ஆதார் விவரங்களைப் பெறுகிறது...!",
    uid: "UID",
    gender: "பாலினம்",
    Address: "முகவரி",
    xml_file: "உங்களிடம் ஆதார் எக்ஸ்எம்எல் கோப்பு உள்ளதா?",
    yes: "ஆம்",
    no: "இல்லை",

    waiting_agent: "முகவருக்காக காத்திருக்கிறது",
    end_call: "அழைப்பை முடிக்கவும்",
    book_slot: "ஸ்லாட்டை முன்பதிவு செய்யவும்",
    id_number:"ID Number",



    // =================================
    //              Kyc completed 
    // =================================


    kyc_update: "KYC சமர்ப்பிக்கப்பட்டது.",
    verification: "உங்கள் சரிபார்ப்பு முடிந்தது மற்றும் உங்கள் தரவு அனைத்தும் பாதுகாப்பாக சேமிக்கப்படும்.",


     // ===================================
    //          Employement Details
    // ===================================

    occupation_details:"தொழில் விவரங்கள்",
    occupation_details_subtext:"உங்கள் விருப்பங்களில் ஒன்றைத் தேர்ந்தெடுக்கவும்"







}

export default tamil;