import React from "react";
import "./cameraaccess.css";
import Button from "../Elements/Button";
import { Text } from "../../Pages/Language/Language";

const CameraAccess = (props) => {
  const reload = () => {
    window.location.reload();
  };


  return (
    <>
      {props?.isClientTideAccess ? (
        <div className="app-body tidecalope">
          <div>
            <div className="tideloactionperheader">
              <Text tid="need_access_to_location_camera_mic" />
            </div>
            <p className="tideloactionperheaderinfo">
              <Text tid="need_locaiton_cam_mic_permission_for_kyc" />
            </p>
            {props?.isCameraUsed && <p className="tideloactionperheaderinfo text-danger">
              <Text tid="camera_is_used_by_another_app" />
            </p>}
            <div className="tidelocationimg">
              <img src="/images/Selfie.png" />
            </div>
          </div>
          <div className="tidecameraaccessbutton">
            <Button
              type="button"
              isClientTideAccess={props?.isClientTideAccess}
              click={reload}
              title={<Text tid="allow_access" />}
            />
          </div>
        </div>
      ) : (
        <div className="root">
          <div className="accessimg-card">
            <div className="accessimg">
              <img
                src="../images/no_video_camera.svg"
                width={141}
                height={146}
              />
            </div>
            <div className="unblockmsg" style={{ height: props?.isCameraUsed && 'auto' }}>
              {/* {props?.checkAccessTxt} */}
              <Text tid={props?.checkAccessTxt} />
            </div>

            <ul>
              <li className="msglist"><Text tid="enable_permission_in_browser_settings" /></li>
              <li className="msglist" style={{ marginTop: "25px" }}><Text tid="click_unblock_btn_below" /></li>
              <li className="msglist"><Text tid="when_prompted_click_allow" /></li>
            </ul>
            <div className="buttonheader">
              <button className="unblockbutton" onClick={reload}>
                <span><Text tid="unblock" /></span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CameraAccess;
