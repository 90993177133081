import React from "react";

const H5 = (props) => {
  return (
    <h5
      className={props?.className}
      style={{ color: props?.color, fontSize: props?.fontSize }}
    >
      {props?.title}
    </h5>
  );
};

export default H5;
