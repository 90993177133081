import React from "react";
import { useSelector } from "react-redux";
import { Text } from "../../Pages/Language/Language";
import H4 from "../Elements/H4";
import P from "../Elements/P";

const SelectKYCProcessCmp = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const completedImg = "../images/green-checkmark-icon.svg";
  const notCompletedImg = "../images/green-checkmark-icon2.svg";
  return (
    <>
      <section className="app-start">
        <div className="app-body-img">
          <img src="../images/icon-identity.svg" alt="vkyc" />
        </div>
        <div className="app-body-data">
          <H4
            className="title"
            title={<Text tid="verify_your_identity" />}
            // titleClr2={cssProperties?.titleClr2}
            color={cssProperties?.pagetitle_heading?.font_color}
            fontSize={cssProperties?.pagetitle_heading?.font_size}
          />
          <P
            className="txt"
            txt={<Text tid="handy_documents" />}
            // txtClr={cssProperties.txtClr}
            color={cssProperties?.body?.font_color}
            fontSize={cssProperties?.body?.font_size}
          />
          <hr />
        </div>
        <ul className="video-guide">
          <li>
            <div className="d-flex w-75">
              <img src="../images/icon-list.svg" className="me-2" alt="vkyc" />
              <P
                className="identity-names"
                txt={props?.iskinabank ?"Database Verification":<Text tid="aadhaar_verify" />}
                color={cssProperties?.body?.font_color}
                fontSize={cssProperties?.body?.font_size}
              />
            </div>
            <img
              src={
                props?.customerVcipDetails?.kycstatus === "0"
                  ? notCompletedImg
                  : completedImg
              }
              width={20}
              alt="vkyc"
            />
          </li>
          <li>
            <div className="d-flex w-75">
              <img src="../images/icon-list.svg" className="me-2" alt="vkyc" />
              <P
                className="identity-names"
                txt={props?.iskinabank?<Text tid="ovd_verification"  /> : <Text tid="pan_capture" />}
                color={cssProperties?.body?.font_color}
                fontSize={cssProperties?.body?.font_size}
              />
            </div>
            <img
              src={
                props?.customerVcipDetails?.panstatus === "0"
                  ? notCompletedImg
                  : completedImg
              }
              width={20}
              alt="vkyc"
            />
          </li>
          <li>
            <div className="d-flex w-75">
              <img src="../images/icon-list.svg" className="me-2" alt="vkyc" />
              <P
                className="identity-names"
                txt={<Text tid="video_call" />}
                color={cssProperties?.body?.font_color}
                fontSize={cssProperties?.body?.font_size}
              />
            </div>
            <img src={notCompletedImg} width={20} alt="vkyc" />
          </li>
        </ul>
      </section>
    </>
  );
};

export default SelectKYCProcessCmp;
