import toast from 'react-hot-toast';
import { put, takeLatest, call, takeEvery, delay } from 'redux-saga/effects';
import base64 from "base-64";
import RouteNames from '../../Constants/RouteNames';
import Axios from '../../Service/axios'
import { actionReqResStatusLoaderSagaAction, storeDynamicURLAction } from '../SagaActions/CommonSagaActions';
import { actionGetUpdatedTokenSagaAction } from '../SagaActions/InitiateVideoCallSagaActions';
import {
    ACTION_ADD_SINGLE_CUSTOMER_REQ,
    ACTION_DELETE_CUSTOMER_REQ,
    ACTION_GET_CUSTOMER_BY_AGENT,
    ACTION_GET_SINGLE_CUSTOMER_REQ,
    ACTION_GET_VC_INITIATE_CONFERENCE_QUEUE_REQ,
    ACTION_GET_VC_JOIN_VIDEO_SESSION_REQ,
    ACTION_GET_VC_LANGUAGES_REQ,
    ACTION_GET_VC_RESCHEDULE_REQ,
    ACTION_GET_VC_SCHEDULE_CALENDER_REQ,
    ACTION_GET_VC_SCHEDULE_CANCEL_REQ,
    ACTION_GET_VC_SCHEDULE_DETAILS_REQ,
    ACTION_GET_VC_UPDATED_TOKEN_REQ
} from '../SagaActions/SagaActionTypes';

// GET LANGUAGES
const getLanguagesReq = (body) => {
    const URL = 'GetLanguages';
    return Axios.post(URL, body).then(res => { return res?.data })
}

function* getLangaugesReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(getLanguagesReq, action?.payload?.model);
        if (resp && resp?.respcode === "200") {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp?.languages);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// INITIATE CONFERENCE QUEUE
const getInitiateConferenceQueueReq = (body) => {
    const URL = 'InitiateVideoConferenceQueue';
    return Axios.post(URL, body).then(res => { return res?.data })
}

function* getInitiateConferenceQueueReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(getInitiateConferenceQueueReq, action?.payload?.model);
        if (resp) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            console.log("*** Video session requested");

            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// GET UPDATED TOKEN
const getUpdatedTokenReq = (body) => {
    const URL = 'GetUpdatedVCIPIDTokenNumber';
    return Axios.post(URL, body).then(res => { return res?.data })
}

function* getUpdatedTokenReqSaga(action) {
    try {
        const resp = yield call(getUpdatedTokenReq, action?.payload?.model);
        if (action?.payload?.callback) {
            action?.payload?.callback(resp);
        }
        if (resp && resp?.respcode !== "200") {
            toast(resp?.respdesc);
            // if(resp?.respcode === "522"){
            //     navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
            // }
        }

        let pathName = window?.location?.pathname;
        // console.log("parseInt(resp?.tokennumber)", parseInt(resp?.tokennumber), { resp }, (parseInt(resp?.tokennumber) >= 0 &&
        //     resp?.isscheduled === "0" &&
        //     resp?.respcode !== "425"));
        // console.log("*********************", resp);
        // if (parseInt(resp?.tokennumber) === 0 || resp?.isscheduled === "1") {
        // console.log("*********************================", resp);

        //     const data = {
        //         // streamingURL: resp?.videoconfsessionurl + "/openvidu/api/",
        //         // streamingType: resp?.streamurltype
        //         streamingURL: "https://tc-vidu-orig-stream.syntizen.app/vidu_api/vkycrestservices/",
        //         streamingType: "1"
        //     }
        //     const encData = base64?.encode(JSON.stringify(data));
        //     sessionStorage.setItem('dynamicObj', encData);
        //     yield put(storeDynamicURLAction(data));
        // }
        if (pathName === RouteNames.TOKEN_NUMBER &&
            parseInt(resp?.tokennumber) >= 1 &&
            resp?.isscheduled === "0" &&
            resp?.respcode !== "425" && !isNaN(resp?.tokennumber)) {
            // yield delay(10000);
            // yield put(actionGetUpdatedTokenSagaAction(action?.payload));
        }else if(isNaN(resp?.tokennumber) || resp?.respcode === "500"){
            // yield delay(10000);
            // yield put(actionGetUpdatedTokenSagaAction(action?.payload));
        }
    } catch (err) {
        let pathName2 = window?.location?.pathname;
        if (pathName2 === RouteNames.TOKEN_NUMBER) {
            yield delay(5000);
            yield put(actionGetUpdatedTokenSagaAction(action?.payload));
        }
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    }
    //  finally {
    //     yield put(actionReqResStatusLoaderSagaAction(false));
    // }
}

// RESCHEDULE VIDEO CALL
const getRescheduleVcReq = (body) => {
    const URL = 'CreateVideoCallSchedule';
    return Axios.post(URL, body).then(res => { return res?.data })
}

function* getRescheduleVcReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(getRescheduleVcReq, action?.payload?.model);
        if (action?.payload?.callback) {
            action?.payload?.callback(resp);
        }
        if (resp && resp?.respcode === "200") {
            toast.success(resp?.respdesc);
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// GET SCHEDULE CALENDER
const getScheduleCalenderVcReq = (body) => {
    const URL = 'GetVideoCallScheduleCalender';
    return Axios.post(URL, body).then(res => { return res?.data })
}

function* getScheduleCalenderVcReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(getScheduleCalenderVcReq, action?.payload?.model);
        if (resp && resp?.respcode === "200") {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// GET SCHEDULE DETAILS
const getScheduleDetailsVcReq = (body) => {
    const URL = 'GetVideoCallScheduleDetails';
    return Axios.post(URL, body).then(res => { return res?.data })
}

function* getScheduleDetailsVcReqSaga(action) {
    // yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(getScheduleDetailsVcReq, action?.payload?.model);
        if (resp && resp?.respcode === "200") {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        // yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// CANCEL SCHEDULE DETAILS
const CancelScheduleVcReq = (body) => {
    const URL = 'CancelVideoCallSchedule';
    return Axios.post(URL, body).then(res => { return res?.data })
}

function* getScheduleCancelVcReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(CancelScheduleVcReq, action?.payload?.model);
        if (resp && resp?.respcode === "200") {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// JOIN VIDEO SESSION
const joinVideoSessionReq = (body) => {
    const URL = 'JoinVideoConferenceSessionID';
    return Axios.post(URL, body).then(res => { return res?.data })
}

function* joinVideoSessionReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(joinVideoSessionReq, action?.payload?.model);
        if (resp && resp?.respcode === "200") {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        console.log("*** joining video conference failed with", err);

        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// ADD SINGLE CUSTOMER
const  addSingleCustomerReq= (model) => {
    const URL = `${model?.streamingURL}v2/api/addSingleCustomer`;
    return Axios.post(URL, model?.body).then(res => { return res?.data })
}

function* addSingleCustomerReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(addSingleCustomerReq, action?.payload?.model);
        if (resp && resp?.respcode == "200") {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}


// GET SINGLE CUSTOMER
const  getSingleCustomerReq= (model) => {
    const URL = `${model?.streamingURL}v2/api/getSingleCustomer`;
    return Axios.post(URL, model?.body).then(res => { return res?.data })
}

function* getSingleCustomerReqSaga(action) {
    // yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(getSingleCustomerReq, action?.payload?.model);
        if (resp && resp?.respcode == "200") {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            // toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        // yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// DELETE CUSTOMER
const  deleteCustomerReq= (model) => {
    const URL = `${model?.streamingURL}v2/api/deleteCustomer`;
    return Axios.post(URL, model?.body).then(res => { return res?.data })
}

function* deleteCustomerReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(deleteCustomerReq, action?.payload?.model);
        if (resp && resp?.respcode == "200") {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            // toast(resp?.message);
        }
    } catch (err){
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}


export default function* InitiateVCWatcherSaga() {
    yield takeLatest(ACTION_GET_VC_LANGUAGES_REQ, getLangaugesReqSaga);
    yield takeLatest(ACTION_GET_VC_INITIATE_CONFERENCE_QUEUE_REQ, getInitiateConferenceQueueReqSaga);
    yield takeEvery(ACTION_GET_VC_UPDATED_TOKEN_REQ, getUpdatedTokenReqSaga);
    yield takeLatest(ACTION_GET_VC_RESCHEDULE_REQ, getRescheduleVcReqSaga);
    yield takeLatest(ACTION_GET_VC_SCHEDULE_CALENDER_REQ, getScheduleCalenderVcReqSaga);
    yield takeEvery(ACTION_GET_VC_SCHEDULE_DETAILS_REQ, getScheduleDetailsVcReqSaga);
    yield takeLatest(ACTION_GET_VC_SCHEDULE_CANCEL_REQ, getScheduleCancelVcReqSaga);
    yield takeLatest(ACTION_GET_VC_JOIN_VIDEO_SESSION_REQ, joinVideoSessionReqSaga);
    yield takeLatest(ACTION_ADD_SINGLE_CUSTOMER_REQ, addSingleCustomerReqSaga);
    yield takeLatest(ACTION_GET_SINGLE_CUSTOMER_REQ, getSingleCustomerReqSaga);
    yield takeLatest(ACTION_DELETE_CUSTOMER_REQ, deleteCustomerReqSaga);
}