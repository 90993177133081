import toast from "react-hot-toast";
import { put, takeLatest, call, delay } from "redux-saga/effects";
import Axios from "axios";
import base64 from "base-64";
import {
    ACTION_CREATE_VIDEO_TOKEN_LIVEKIT_REQ, ACTION_UPLOAD_PAN_CAPTURED_IMAGE_BY_AGENT_REQ,
    ACTION_LIVEKIT_DELETEROOM_REQ
} from "../SagaActions/SagaActionTypes";

const instance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_LIVEKIT_VIDEO_URL,
});

// CREATE TOKEN
const createLivekitTokenReq = (model) => {
  const URL = model?.streamingURL + "session/createToken";
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return Axios.post(URL, model?.body, headers).then((res) => {
    return res?.data;
  });
};

function* createLivekitTokenReqSaga(action) {
  try {
    const resp = yield call(createLivekitTokenReq, action?.payload?.model);
    if (resp?.respCode == "200") {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      action?.payload?.callback("");
    }
  } catch (err) {
    if (err.response) {
      action?.payload?.callback("");
      toast(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
      action?.payload?.callback("");
    }
  }
}

const uploadPanCapturedImageReq = async (model,redisUploadPan) => {
  let URL;
  if(redisUploadPan){
    URL = model?.streamingURL + "v2/api/uploadPan";
  }else{
    URL = model?.streamingURL + "uploadPan";
  }
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await Axios.post(URL, model?.body);
  return res?.data;
};

function* uploadPanCapturedImageReqSaga(action) {
  try {
    const resp = yield call(uploadPanCapturedImageReq, action?.payload?.model,action?.payload?.redisUploadPan);
    if (resp?.respCode == "200") {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp?.image);
      }
    } else {
      action?.payload?.callback("");
    }
  } catch (err) {
    if (err.response) {
      action?.payload?.callback("");
      toast(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  }
}

// DELETE LIVEKIT ROOM
const livekitdeleteroomsagareq = async (model) => {
  const URL = model?.streamingURL + "session/deleteRoom";
  return await Axios.post(URL, model?.body).then((res) => res?.data);
};

function* livekitDeleteRoomReqSaga(action) {
  try {
    const resp = yield call(livekitdeleteroomsagareq, action?.payload?.model);
    if (resp && (resp?.respCode === 200)) {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      // toast.error(resp?.respDesc)
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast.error(err.message);
    }
  } finally {
  }
}


export default function* LivekitVideoWatcherSaga() {
  yield takeLatest(ACTION_CREATE_VIDEO_TOKEN_LIVEKIT_REQ, createLivekitTokenReqSaga);
  yield takeLatest(ACTION_UPLOAD_PAN_CAPTURED_IMAGE_BY_AGENT_REQ, uploadPanCapturedImageReqSaga);
  yield takeLatest(ACTION_LIVEKIT_DELETEROOM_REQ, livekitDeleteRoomReqSaga);
}
