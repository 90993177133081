import React from 'react';
import RouteNames from '../Constants/RouteNames';
import KYCCompleted from '../Pages/KYCCompleted';

// const ChooseLanguage = React.lazy(() => import("../Pages/ChooseLanguage/ChooseLanguage"));
// const SelectKYCProcess = React.lazy(() => import("../Pages/SelectKYCProcess/SelectKYCProcess"));
// const AadhaarDigiLocker = React.lazy(() => import("../Pages/SelectKYCProcess/AadhaarKYC/AadhaarDigiLocker"));
// const AadhaarKYC = React.lazy(() => import("../Pages/SelectKYCProcess/AadhaarKYC/AadhaarKYC"));
// const AadhaarUpload = React.lazy(() => import("../Pages/SelectKYCProcess/AadhaarKYC/AadhaarUpload"));
// const AadhaarUploadEKYC = React.lazy(() => import("../Pages/SelectKYCProcess/AadhaarKYC/AadhaarUploadEKYC"));
// const AadhaarUploadSuccess = React.lazy(() => import("../Pages/SelectKYCProcess/AadhaarUploadSuccess/AadhaarUploadSuccess"));
// const PanKYC = React.lazy(() => import("../Pages/SelectKYCProcess/PanKYC/PanKYC"));
// const SelectVideoCallLanguage = React.lazy(() => import("../Pages/VideoCall/SelectVideoCallLanguage/SelectVideoCallLanguage"));
// const TokenNumber = React.lazy(() => import("../Pages/VideoCall/TokenNumber/TokenNumber"));
// const Reschedule = React.lazy(() => import("../Pages/VideoCall/Reschedule/Reschedule"));
// const InitiatingVideoCall = React.lazy(() => import("../Pages/VideoCall/InitiatingVideoCall/InitiatingVideoCall"));
// // const KYCCompleted = React.lazy(() => import("../Pages/KYCCompleted"));
// const PanCapture = React.lazy(() => import("../Pages/SelectKYCProcess/PanUpload/PanCapture"));
// const SelectPANProcess = React.lazy(() => import("../Pages/SelectKYCProcess/SelectPANProcess"));
// const Declaration = React.lazy(() => import("../Pages/SelectKYCProcess/PanKYC/Declaration"));
// const BrowserCheck = React.lazy(() => import("../Pages/BrowserCheck/BrowserCheck"))

import ChooseLanguage from '../Pages/ChooseLanguage/ChooseLanguage';
import SelectKYCProcess from '../Pages/SelectKYCProcess/SelectKYCProcess';
import AadhaarDigiLocker from '../Pages/SelectKYCProcess/AadhaarKYC/AadhaarDigiLocker';
import AadhaarKYC from '../Pages/SelectKYCProcess/AadhaarKYC/AadhaarKYC';
import AadhaarUpload from '../Pages/SelectKYCProcess/AadhaarKYC/AadhaarUpload';
import AadhaarUploadEKYC from '../Pages/SelectKYCProcess/AadhaarKYC/AadhaarUploadEKYC';
import AadhaarUploadSuccess from '../Pages/SelectKYCProcess/AadhaarUploadSuccess/AadhaarUploadSuccess';
import PanKYC from '../Pages/SelectKYCProcess/PanKYC/PanKYC';
import SelectVideoCallLanguage from '../Pages/VideoCall/SelectVideoCallLanguage/SelectVideoCallLanguage';
import TokenNumber from '../Pages/VideoCall/TokenNumber/TokenNumber';
import Reschedule from '../Pages/VideoCall/Reschedule/Reschedule';
import PanCapture from '../Pages/SelectKYCProcess/PanUpload/PanCapture';
import SelectPANProcess from '../Pages/SelectKYCProcess/SelectPANProcess';
import Declaration from '../Pages/SelectKYCProcess/PanKYC/Declaration';
import BrowserCheck from '../Pages/BrowserCheck/BrowserCheck';
import Customerid from '../Pages/Kinabank/Customerid';
import CutomerDetails from '../Pages/Kinabank/CutomerDetails';
import SelectEmployementDetails from '../Pages/SelectKYCProcess/EmployementDetails/EmployementDetail';
import InitiatingVideoCall from '../Pages/VideoCall/InitiatingVideoCall/InitiatingVideoCall';
import InitiatingvideocallLivekit from '../Pages/VideoCall/Livekit/Initiatevideocall';
import customeCodes from '../Constants/customeCodes';
import StreamingLayout from '../Pages/VideoCall/StreamingLayout';
import CosmosEKYC from '../Pages/SelectKYCProcess/AadhaarKYC/CosmosEKYC';
import EmploymentLayout from '../Pages/SelectKYCProcess/EmployementDetails/EmploymentLayout';
import BankDetails from '../Pages/BankDetails/BankDetails';

const streamingType = process.env.REACT_APP_STREAMING_TYPE;

const routes = [
    {
        path: RouteNames.CHOOSE_LANGAUGE, element: <ChooseLanguage />
    },
    {
        path: RouteNames.SELECT_KYC_PROCESS, element: <SelectKYCProcess />
    },
    {
        path: RouteNames.AADHAR_KYC_KYC_DIGILOCCKER, element: <AadhaarDigiLocker />
    },
    {
        path: RouteNames.AADHAR_KYC_PROCESS, element: <AadhaarKYC />
        // children: [
        //     {
        //         path: "upload",
        //         element: <AadhaarUpload />,
        //     },
        // ],
    },
    {
        path: RouteNames.AADHAR_KYC_PROCESS_UPLOAD, element: <AadhaarUpload />
    },
    {
        path: RouteNames.AADHAR_EKYC_PROCESS_UPLOAD, element: <AadhaarUploadEKYC />
    },
    {
        path: RouteNames.AADHAR_KYC_PROCESS_DOWNLOAD, element: <AadhaarUploadSuccess />
    },
    {
        path: RouteNames.SELECT_PAN_KYC_PROCESS, element: <SelectPANProcess />
    },
    {
        path: RouteNames.PAN_KYC_PROCESS, element: <PanKYC />
    },
    {
        path: RouteNames.PAN_CAPTURE, element: <PanCapture />
    },
    {
        path: RouteNames.KINA_SELECT_OVD_PROCESS, element: <SelectPANProcess />
    },
    {
        path: RouteNames.KINA_OVD_PROCESS, element: <PanKYC />
    },
    {
        path: RouteNames.KINA_OVD_CAPTURE, element: <PanCapture />
    },
    {
        path: RouteNames.PAN_DECLARATION, element: <Declaration />
    },
    {
        path: RouteNames.SELECT_VIDEOCALL_LANGAUAGE, element: <SelectVideoCallLanguage />
    },
    {
        path: RouteNames.TOKEN_NUMBER, element: <TokenNumber />
    },
    {
        path: RouteNames.RESCHEDULE, element: <Reschedule />
    },
    {
        path: RouteNames.INITIATE_VIDEO_CALL, element: <StreamingLayout />
    },
    // {
    //     path: RouteNames.INITIATE_VIDEO_CALL, element: 
    //         streamingType === customeCodes.STREAMING_TYPE_LIVEKIT 
    //         ? <InitiatingvideocallLivekit/>
    //         : <InitiatingVideoCall/>
    // },
    {
        path: RouteNames.KYC_PROCESS_COMPLETED, element: <KYCCompleted />
    },
    {
        path: RouteNames.BROWSER_CHECK, element: <BrowserCheck />
    },
    {
        path: RouteNames.CUSTOMER_ID, element: <Customerid />
    },
    {
        path: RouteNames.CUSTOMER_DETAILS, element: <CutomerDetails />
    },
    {
        path: RouteNames.EMPLOYEMENT_DETAILS, element: <EmploymentLayout />
    },
    {
        path: RouteNames.COSMOS_EKYC_PROCESS, element: <CosmosEKYC />
    },
    {
        path:RouteNames.BANK_DETAILS,element:<BankDetails/>
    }
]

export default routes;