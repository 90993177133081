import { OpenVidu } from "openvidu-browser";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Timer from "react-timer-wrapper";
// import Timecode from "react-timecode";
import { detect } from "detect-browser";
import Loading from "../../../Components/Loading/Loading";
import BackCameraAccessModal from "../../../Components/BackCameraAccessModal/BackCameraAccessModal";

import InitiatingVideoCallCmp from "../../../Components/VideoCallCmp/InitiatingVideoCallCmp";
import RouteNames from "../../../Constants/RouteNames";
import {
  createVideoSessionSagaAction,
  createVideoTokenSagaAction,
  openViduSessionIssues,
} from "../../../Store/SagaActions/VideoSagaActions";
import OpenViduVideoComponent from "./OvVideo";
import "./video.css";
import {
  actionNoficationListSaga,
  endVideoCallByAgentSagaAction,
  pushNotificationSaga,
} from "../../../Store/SagaActions/CommonSagaActions";
import UserVideoComponent from "./UserVideoComponent";
import { Text } from "../../Language/Language";
import ChatCmp from "../../../Components/ChatCmp/ChatCmp";
import { joinVideoSessionAction } from "../../../Store/SagaActions/InitiateVideoCallSagaActions";
import CleintNames from "../../../Constants/ClientNames";
import customeCodes from "../../../Constants/customeCodes";
import CallTimer from "../../../Components/TimeCounter";
// import {  } from "../../../../public/Audio/tone.mp3";

var apiCount = 0;
var enabledEndCallBtnTimer;
var notifyEndCallMsg;
var openviduSessionDetails;
var OV;
const browser = detect();
const browserDetails =  `Name:${browser?.name},Version:${browser?.version},OS:${browser?.os}`

const InitiatingVideoCall = (props) => {
  const [session, setSession] = useState(undefined);
  const [subscribers, setSubscribers] = useState([]);
  const [mainStreamManager, setMainStreamManager] = useState(undefined);
  const [publisher, setPublisher] = useState(undefined);
  // const [notificationIntervalId, setNotificationIntervalId] = useState('');
  const [notificationList, setNotificationList] = useState({});
  const [displayQtn, setDisplayQtn] = useState({});
  const [isEnbledEndCallBtn, setisEnbledEndCallBtn] = useState(false);
  const [lod, setLod] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [netIssue, setNetIssue] = useState("");
  const [isPushNtfyDone, setIsPushNtfyDone] = useState(false);
  const [bulk, setBulk] = useState([]);
  const [letbot, setletBot] = useState(false);
  const [message, setMessage] = useState("");
  const [notifymsgstate, setNotifymsgstate] = useState(false);
  const [notifymsgcount, setnotifymsgcount] = useState(0);
  const [isCallerWantsToReconnect, setIsCallerWantsToReconnect] =
    useState(false);
  const [allowcameraflip, setAllowcameraflip] = useState(false);
  const [canvasdisplay, setCanvasdisplay] = useState(false);
  const [recapturepan, setRecapturepan] = useState(false);
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  const isPageDataAvailable = useSelector(
    (state) => state.HomeReducer.isPageDataAvailable
  );

  const streamingEventObj = useSelector(
    (store) => store.VcipReducer.streamingEventObj
  );
  // console.log("aaaaaa",streamingEventObj)
  const openViduIssues = useSelector(
    (store) => store.VcipReducer?.openViduIssues
  );

  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { streamingType, streamingURL } = dynamicSettingObj;

  // const videoUrl = sessionStorage.getItem("videoUrl")

  const navigate = useNavigate();
  window.onpopstate = () => {
    navigate(null);
  };
  const dispatch = useDispatch();
  // const params = useParams();
  const params = props?.params;

  var notificationIntervalId;
  const autoEndCallTime = 300;
  // var isPushNtfyDone = false;

  useEffect(() => {
    // console.log("*** initiating video call ");

    sessionStorage.removeItem("InitiateVCFQStorage");
    sessionStorage.removeItem("scheduleDetailsStorage");
    if (isPageDataAvailable) {
      joinVideoSession();
      notifyEndCallEvent();
    } else {
      clearNotificationInterval();
      clearInterval(enabledEndCallBtnTimer);
      clearInterval(notifyEndCallMsg);
      // if(isCallerWantsToReconnect){
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
      // }
    }
    return async () => {
      // endVideoCall();
      await openviduSessionDetails?.disconnect();
      await endCallByCustomer();
      clearNotificationInterval();
      clearInterval(enabledEndCallBtnTimer);
      clearInterval(notifyEndCallMsg);
      dispatch(openViduSessionIssues(""));
      setSubscribers([]);
      // navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
      // leaveCallWithoutEndSession();
    };
  }, []);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", () => unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    // console.log(
    //   "=====================notificationListnotificationList",
    //   notificationList
    // );
    if (notificationList?.notificationid === "2") {
      endVideoCall();
    }
    if (notificationList?.notificationid === "404") {
      // console.log("333333333333", isCallerWantsToReconnect);
      setIsCallerWantsToReconnect(true);
      // endVideoCall();
      customerreconnect();
    }
    if (notificationList?.notificationid === "6") {
      setRecapturepan(false)
      setCanvasdisplay(false)
      var audio = new Audio("/tone.mp3");
      audio.play();
      toast.success(notificationList.notifications, {
        position: "bottom-center",
        duration: 2000,
      });
    }
    if (
      notificationList?.notificationid == "4" &&
      notificationList?.notifications == "Flip Camera for PanCapture"
    ) {
      // console.log("asasasasasasasaasasasasaaaaaaaaaaaaaaaaaa")
      setAllowcameraflip(true);
      setRecapturepan(false)
    }

    if (
      notificationList?.notificationid == "7" &&
      notificationList?.notifications == "Cancle Flip Camera for PanCapture"
    ) {
      setCanvasdisplay(false)
      if (recapturepan == true) {
        flipCameraMode(false);
      }
    }
  }, [notificationList]);

  const customerreconnect = async () => {
    const finalSession = openviduSessionDetails || session;
    finalSession?.unpublish(publisher).then(() => {
      console.log("Old publisher unpublished!");
      var newPublisher = OV.initPublisher(undefined, {
        audioSource: undefined,
        videoSource: undefined,
        publishAudio: true,
        publishVideo: true,
        frameRate: 30,
        insertMode: "APPEND",
      });

      // Publishing the new publisher
      finalSession?.publish(newPublisher).then(async () => {
        setIsCallerWantsToReconnect(false);
        console.log("New publisher published!");
        await setMainStreamManager(newPublisher);
        await setPublisher(newPublisher);
      });
    });
  };

  const flipCameraMode = async (flipcamera) => {
    const finalSession = openviduSessionDetails || session;
    // console.log("11111111111111111111111ov",OV, flipcamera)
    let videoDevices = await OV?.getDevices().then((devices) => {
      // Getting only the video devices
      return devices?.filter((device) => device?.kind === "videoinput");
    });
    // console.log("qqqqqqqqqqqqqqqqqqqqqq",videoDevices)
    if (videoDevices && videoDevices.length > 1) {
      // Unpublishing the old publisher
      finalSession?.unpublish(publisher).then(() => {
        // console.log("Old publisher unpublished!");
        var newPublisher = OV.initPublisher(undefined, {
          videoSource: flipcamera
            ? videoDevices[1].deviceId
            : videoDevices[0].deviceId,
          publishAudio: true,
          publishVideo: true,
          frameRate: 30,
          insertMode: "APPEND",
          mirror: false,
        });

        // Publishing the new publisher
        finalSession?.publish(newPublisher).then(async () => {
          // console.log("New publisher published!");
          await setMainStreamManager(newPublisher);
          await setPublisher(newPublisher);
        });
      });
    }
  };

  useEffect(() => {
    if (isEnbledEndCallBtn) {
      // endVideoCall();
      endCallByCustomer();
      sessionStorage.setItem("isAutoCallEnded", true);
    }
  }, [isEnbledEndCallBtn]);

  useEffect(() => {
    if (subscribers?.length > 0) {
      // console.log("enabledEndCallBtnTimer---> joined", enabledEndCallBtnTimer, notifyEndCallMsg);
      clearInterval(enabledEndCallBtnTimer);
      clearInterval(notifyEndCallMsg);
    }
  }, [subscribers]);

  // STREAMING BASED QUESTION NOTIFICATION SEND
  useEffect(() => {
    if (
      streamingEventObj?.streamingKey ===
      customeCodes.SEND_CUSTOMER_NET_SPEED_TO_AGENT &&
      publisher &&
      mainStreamManager &&
      subscribers?.length > 0
    ) {
      // console.log("11111111111",streamingEventObj)
      sendMessage(null, streamingEventObj);
    }
  }, [streamingEventObj]);

  const notifyEndCallEvent = () => {
    enabledEndCallBtnTimer = setTimeout(() => {
      setisEnbledEndCallBtn(true);
    }, 1000 * autoEndCallTime);
    notifyEndCallMsg = setTimeout(() => {
      var audio = new Audio("/tone.mp3");
      audio.play();
      toast.success(
        <Text tid="As_an_agent_could_not_connect_the_call_will_be_ending_in_5_sec_Please_try_again" />,
        {
          position: "bottom-right",
          duration: 4000,
        }
      );
    }, 1000 * (autoEndCallTime - 5));
  };

  const clearNotificationInterval = () => {
    if (notificationIntervalId) {
      clearInterval(notificationIntervalId);
    }
  };

  // const startVideoSession = () => {
  //   OV = new OpenVidu();
  //   let sessionData = OV.initSession();
  //   setSession(sessionData);
  // };

  const joinVideoSession = () => {
    OV = new OpenVidu();
    var mySession = OV.initSession();
    // var mySession = session;
    mySession.on("streamCreated", (event) => {
      console.log("*** stream created for video conferece ");

      var subscriber = mySession.subscribe(event.stream, undefined);

      if (event.stream.typeOfVideo !== "SCREEN") {
        sessionStorage.setItem(
          "connectionId",
          event.stream.connection.connectionId
        );
        let subscribersArr = [...subscribers];
        if (subscriber) {
          subscribersArr?.push(subscriber);
          sessionStorage.setItem("subscribers", Object.keys(subscribersArr));
          setisEnbledEndCallBtn(false);
        }
        setSubscribers(subscribersArr);
      }
    });
    mySession.on("streamDestroyed", (event) => {
      console.log("*** stream destroyed and reason is", event.reason);

      deleteSubscriber(event.stream.streamManager);
      notifyEndCallEvent();
      if (event?.reason === "disconnect" && event?.type === "streamDestroyed") {
        // endVideoCall();
        sessionStorage.setItem("tokenSessionExpire", "SessionExpire");
        console.log()
        endCallByCustomer();
      }
      if (
        event?.reason === "networkDisconnect" &&
        event?.type === "streamDestroyed"
      ) {
        sessionStorage.setItem(
          "isVideoStreamDisconnected",
          "disconnect/streamDestroyed"
        );
        var audio = new Audio("/tone.mp3");
        audio.play();
        toast(
          <Text tid="agent_disconnected_due_to_network_disconnect_issue" />,
          {
            position: "bottom-center",
            duration: 3000,
          }
        );
        endCallByCustomer();
      }
    });

    // mySession.on("signal", function (signalEvent) {
    //   console.log("==============>signalEvent", signalEvent);
    // });

    // mySession.on("connectionDestroyed", function (connectionDestroyed) {
    //   console.log("==============>connectionDestroyed", connectionDestroyed);
    // });

    mySession.on("sessionDisconnected", (event) => {
      console.log("*** session disconnected ", event.reason);
      sessionStorage.setItem(
        "isVideoStreamDisconnected",
        "disconnect/streamDestroyed"
      );
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
      if (event.reason === "networkDisconnect") {
        sessionStorage.setItem(
          "isVideoStreamDisconnected",
          "disconnect/streamDestroyed"
        );
        setNetIssue("You lost your connection to the session");
        setTimeout(() => {
          // endVideoCall();
          endCallByCustomer();
        }, 2000);
      } else {
      }
    });

    mySession.on("signal", (event) => {
      event.msgtime = Date.now();
      //CUSTOM MESSAGE FROM AGENT
      let getypeDataFromSignal = event?.type;
      let updatedGetypeDataFromSignal = getypeDataFromSignal?.replace(
        /signal:/g,
        ""
      );
      let parseObj = JSON.parse(updatedGetypeDataFromSignal);
      if (parseObj && "notifyMsg" in parseObj && event?.data === "") {
        console.log(
          "=====parseObj?.notifyMsg--------------------",
          parseObj?.notifyMsg
        );
        if (parseObj?.notifyMsg?.streamingValue == "finalendcall") {
          sessionStorage.setItem("finalendcall", "finalendcall")
        }
        if (
          parseObj?.notifyMsg?.streamingKey !==
          customeCodes.SEND_CUSTOMER_NET_SPEED_TO_AGENT
        ) {
          if (parseObj?.notifyMsg?.notificationid === "2") {
            session?.disconnect();
            // if(isCallerWantsToReconnect){
            navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
            // }
            return;
          }
          // Notification for If Agent Wants Customer to reconect
          // if (parseObj?.notifyMsg?.notificationid === "404") {
          //   setNotificationList(parseObj?.notifyMsg);
          //   return
          //   // sessionReconnectFunction()
          // }
          setNotificationList(parseObj?.notifyMsg);
        }
        if (notificationList?.notificationid === "6") {
          setNotificationList(parseObj?.notifyMsg);
          setCanvasdisplay(false)
        }
        return;
      }
      setBulk((prevState) => [...prevState, event]);
      const val = sessionStorage.getItem("connectionId");
      if (event.from.connectionId == val) {
        var audio = new Audio("/tone.mp3");
        audio.play();
        if (!letbot) {
          setnotifymsgcount((prev) => prev + 1);
          setNotifymsgstate(true);
        }
      }
    });

    setSession(mySession);
    openviduSessionDetails = mySession;

    const name = params.id;
    const model = {
      name: name,
      sessionId: name,
      OV: OV,
      session: mySession,
      myUserName: name,
      recordingMode: "MANUAL",
      streamingURL: streamingURL
    };

    // if (isCallerWantsToReconnect) {
    //   dispatch(
    //     createVideoTokenSagaAction({
    //       model: model,
    //       callback: getCreateSessionData,
    //     })
    //   );
    // }

    dispatch(
      createVideoSessionSagaAction({
        model: model,
        // videoUrl:videoUrl,
        // callback:(videoUrl)=>{
        //   getCreateSessionData(videoUrl)
        // }
        callback: getCreateSessionData,
      })
    );
  };

  // DELETE VIDEO CALL SUBSCRIBER
  const deleteSubscriber = (streamManager) => {
    let subscribersData = subscribers;
    let index = subscribersData.indexOf(streamManager, 0);
    if (index > -1) {
      subscribersData.splice(index, 1);
      setSubscribers(subscribersData);
    }
  };

  const getCreateSessionData = (data) => {
    setMainStreamManager(data.mainStreamManager);
    setPublisher(data.publisher);
    var isFrontCamera = true;
    var OV = new OpenVidu();
    console.log("999999999999", OV);
    // setIsCallerWantsToReconnect(false)
    // OV.getDevices().then((devices) => {
    //   // Getting only the video devices
    //   console.log("44444444444444444", devices);
    //   var videoDevices = devices.filter(
    //     (device) => device.kind === "videoinput"
    //   );
    //   console.log("5555555555555555", videoDevices);
    //   OV?.setVideoSource(videoDevices[1].deviceId);

    //   // if (videoDevices && videoDevices.length >= 1) {
    //   //   // Creating a new publisher with specific videoSource
    //   //   // In mobile devices the default and first camera is the front one
    //   //   var newPublisher = OV.initPublisher(undefined, {
    //   //     videoSource: isFrontCamera
    //   //       ? videoDevices[0].deviceId
    //   //       : videoDevices[1] ? videoDevices[1].deviceId : videoDevices[0].deviceId,
    //   //     publishAudio: true,
    //   //     publishVideo: true,
    //   //     mirror: isFrontCamera, // Setting mirror enable if front camera is selected
    //   //   });

    //   //   //  // Changing isFrontCamera value
    //   //   //  isFrontCamera = !isFrontCamera;

    //   //   // Unpublishing the old publisher
    //   //   console.log('777777777777',data?.publisher)
    //   //   if (openviduSessionDetails) {
    //   //     openviduSessionDetails?.unpublish(data?.publisher).then(() => {
    //   //       console.log("Old publisher unpublished!");

    //   //       // Assigning the new publisher to our global variable 'publisher'
    //   //       //  publisher = newPublisher;

    //   //       // Publishing the new publisher
    //   //       openviduSessionDetails?.publish(newPublisher).then(() => {
    //   //         setPublisher(newPublisher);
    //   //         console.log("New publisher published!");
    //   //       });
    //   //     });
    //   //   } else {
    //   //     session?.unpublish(data?.publisher).then(() => {
    //   //       console.log("Old publisher unpublished!");
    //   //       // Assigning the new publisher to our global variable 'publisher'
    //   //       //  publisher = newPublisher;

    //   //       // Publishing the new publisher
    //   //       session?.publish(newPublisher).then(() => {
    //   //         setPublisher(data.publisher);
    //   //         console.log("New publisher published!");
    //   //       });
    //   //     });
    //   //   }
    //   // }
    // })
  };

  const getNoficationData = (data) => {
    setNotificationList(data);
    if (data?.notificationid === "8") {
      setLod(false);
    }
  };

  // console.log("222222222", isCallerWantsToReconnect);

  // END VIDEO CALL
  const endVideoCall = async () => {
    await session?.disconnect();
    sessionStorage.removeItem("session");
    sessionStorage.removeItem("connectionId");
    sessionStorage.removeItem("videoconfsessionid");
    sessionStorage.removeItem("publisher");
    sessionStorage.removeItem("session");
    if (
      notificationList?.notificationid !== "2" &&
      apiCount === 0
      // &&
      // notificationList?.notificationid != "404"
    ) {
      // setIsPushNtfyDone(true);
      const model = {
        notificationid: "3",
        vcipkey: sessionStorage.getItem("vcipkey"),
        notifymsg: "CUSTOMER HAS ENDED THE CALL",
      };
      dispatch(
        pushNotificationSaga({ model, callback: pushNotificationRespData })
      );
      // } else if (notificationList?.notificationid == "404") {
      //   session?.disconnect();
      //   setIsCallerWantsToReconnect(true)
      //   joinVideoSession();
    } else {
      // if(isCallerWantsToReconnect){
      navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
      // }
    }
  };

  // const pushNotificationRespData2 = (data) => {
  //   // dispatch(openViduSessionIssues(""));
  //   // localStorage.setItem("reloaderr","customer ended call")
  //   // apiCount += 1;
  //   // setTimeout(() => {
  //   sessionStorage.setItem("agentendcall", "agentendcall");
  //   joinVideoSession()
  //   // navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
  //   // }, 300);
  // };

  const pushNotificationRespData = (data) => {
    dispatch(openViduSessionIssues(""));
    // localStorage.setItem("reloaderr","customer ended call")
    apiCount += 1;
    // setTimeout(() => {
    sessionStorage.setItem("agentendcall", "agentendcall");
    // if(isCallerWantsToReconnect){
    navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
    // }
    // }, 300);
  };

  const nextPage = () => {
    navigate(RouteNames.KYC_PROCESS_COMPLETED);
  };

  const endCallByCustomer = () => {
    dispatch(endVideoCallByAgentSagaAction({ callback: getEndCallRespData }));
  };

  const getEndCallRespData = (data) => {
    if (data?.respcode === "200") {
      endVideoCall();
    }
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // const toggleview =() => {
  //   setLoader(true)
  // }

  // console.log("op->->subscribers", subscribers, "op->->mainStreamManager", mainStreamManager, "op->->session", session);
  // console.log("op->->subscribers", subscribers)

  const sendMessage = (event, notify) => {
    if (event) {
      event?.preventDefault();
    }
    const userid = sessionStorage.getItem("userid");
    // const vcipid = VCIPDetails.vcipid
    const model = {
      sender: userid,
      // vcipid: vcipid,
      notifyMsg: notify,
    };

    publisher?.stream?.session
      ?.signal({
        data: event ? message : "", // Any string (optional)
        to: [], // Array of Connection objects (optional. Broadcast to everyone if empty)
        type: JSON.stringify(model), // The type of message (optional)
      })
      .then(() => {
        // console.log("Message successfully sent");
      })
      .catch((error) => {
        console.error("error", error);
      });
    if (event && message) {
      setMessage("");
    }
  };

  const changeMessage = (e) => {
    setMessage(e.target.value);
  };

  const handelBotfunction = () => {
    setletBot(!letbot);

    if (letbot) {
      setNotifymsgstate(false);
      setnotifymsgcount(0);
    }
  };

  const tog = () => {
    if (notificationList?.notificationid == "8") return;
    setLod(!lod);
  };

  const joinVideoConferenceSessionID = () => {
    const vcipkeyData = sessionStorage.getItem("vcipkey");
    const location = sessionStorage.getItem("location");
    const geolocation = sessionStorage.getItem("geolocation");
    const ip = sessionStorage.getItem("ip");
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    let outcoming = "";
    if (connection) {
      outcoming =
        connection.rtt +
        " " +
        (connection.effectiveType === "4g"
          ? connection.effectiveType
          : "Low Speed");
    }
    const model = {
      vcipkey: vcipkeyData,
      custdetails: {
        ip: ip,
        location: location,
        geolocation: geolocation,
        nw_incoming: connection ? `${connection.downlink}` : "",
        nw_outgoing: outcoming,
        videoresolution: `${window.screen.width} x ${window.screen.height}`,
        browserdetails: browserDetails,
        // os: browser?.os,
      },
      agentdetails: {
        ip: "10.10.10.10",
        location: "",
        geolocation: "",
        nw_incoming: "",
        nw_outgoing: "",
        videoresolution: "",
      },
    };
    dispatch(
      joinVideoSessionAction({ model: model, callback: joinVideoSessionData })
    );
  };

  const joinVideoSessionData = (data) => {
    // console.log("==============>joinnnnn", data);
    // setIsAPIProcessing(false);
    // if (intervalConferenceId) {
    //   clearInterval(intervalConferenceId);
    // }
    // sessionStorage.setItem(
    //   "videoconfsessionid",
    //   InitiateVCFQ?.videoconfsessionid
    // );
    // // navigate(RouteNames.INITIATE_VIDEO_CALL + InitiateVCFQ?.videoconfsessionid);
    // navigate("/session/" + InitiateVCFQ?.videoconfsessionid);
  };

  // const handelReconnectClick=()=>{
  //   console.log(session,"hemantSession-----------------")
  //     session?.disconnect()
  //     joinVideoSession()
  //     // dispatch(createVideoTokenSagaAction({ model: model, callback: getCreateSessionData }));
  // }

  const clientName = useSelector((state) => state.HomeReducer.clientName);

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  // useEffect(() => {
  //
  // });

  return (
    <>
      <article className="app-body" style={{ height: "100vh" }}>
        {!session ? (
          <InitiatingVideoCallCmp isItReconnection={false} />
        ) : (
          <>
            {(openViduIssues || netIssue) && (
              <div className="warning-popup text-center">
                <img
                  src="../images/incomplete.png"
                  className="mb-2 w-25"
                  alt=""
                />
                <p className="text-center text-white mb-0">
                  {openViduIssues ? openViduIssues : netIssue}
                </p>
              </div>
            )}
            {/* {!true || '' || 0 } */}
            {!mainStreamManager || openViduIssues || subscribers?.length > 0 ? (
              <>
                {
                  // console.log("*** subscribers length : " ,subscribers , '-->' ,' Mainstream manager : ',mainStreamManager , ' --> ', 'openvid issue ',openViduIssues)
                }
              </>
            ) : (
              <>
                {isClientTideAccess ? (
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <Loading
                      text={" "}
                      isClientTideAccess={isClientTideAccess}
                    />
                    {
                      // console.log("*** waiting for agent , session : ",session)
                    }
                    <div
                      style={{
                        zIndex: 999999999,
                        marginTop: "150px",
                        fontWeight: "bold",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.25px",
                        color: "#0C0C0E",
                      }}
                    >
                      <Text tid="connecting_you_with_an_available_agent" />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <div
                      className="badge bg-dark position-relative d-flex align-items-center"
                      style={{ zIndex: 9999 }}
                    >
                      <div
                        className="clock"
                        style={{ borderColor: cssProperties?.button?.color }}
                      ></div>
                      <span
                        style={{
                          wordWrap: "wrap",
                          padding: "5px",
                          fontSize: "12px",
                        }}
                      >
                        Waiting for the Agent
                        <br />
                        <br />
                        Do not close/refresh the page or access any other app
                        {/* {console.log("*** waiting for agent , session" , session)} */}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
            { }
            {subscribers?.map((sub, i) => {
              if (sub.stream.typeOfVideo !== "SCREEN") {
                // console.log(' *** subsciber map  : ',sub?.videos[0]?.video, "subs array ->",subscribers);
                return (
                  <div key={i} className="stream-container othervideo">
                    <UserVideoComponent
                      streamManager={sub}
                      toggleplese={tog}
                      togvid={lod ? "remote" : "local"}
                    />
                  </div>
                );
              }
            })}
            <div className="timer">
              {/* <Timer active duration={null}>
                <Timecode />
              </Timer> */}
              <CallTimer />
            </div>
            {mainStreamManager ? (
              <div id="agent" onClick={tog}>
                <OpenViduVideoComponent
                  streamManager={mainStreamManager}
                  togvid={lod ? "local" : "remote"}
                />
                {notificationList?.notificationid == "8" ? (
                  <>
                    <div className="ovel-canvas">
                      {" "}
                      <img
                        src="../../images/Ovel-canvas.svg"
                        className="ovel-canvas-img"
                      />
                    </div>
                  </>
                ) : null}
                {(notificationList?.notificationid == "4" && canvasdisplay == true &&
                  notificationList?.notifications ==
                  "Flip Camera for PanCapture") || canvasdisplay == true ||
                  (notificationList?.notificationid == "4" &&
                    notificationList?.notifications ==
                    "Flip Camera for PanCapture retake") ? (
                  <>
                    <div className="ovel-canvas">
                      {" "}
                      <img
                        src="../../images/Pancanvas.svg"
                        className="ovel-canvas-img"
                      />
                    </div>
                  </>
                ) : null}
                {/* {notificationList?.notificationid == "7" &&
                  notificationList?.notifications ==
                    "Cancle Flip Camera for PanCapture" &&
                  null} */}
              </div>
            ) : null}

            {notificationList && Object.values(notificationList)?.length > 0 ? (
              notificationList?.notificationid === "1" &&
                notificationList?.notifications ? (
                <div className="display-qtn">
                  <span>{notificationList?.notifications}</span>
                </div>
              ) : null
            ) : null}

            {/* {isEnbledEndCallBtn && (
              <button
                className="btn btn-auto w-auto zIndex center-absolute"
                onClick={endCallByCustomer}
                style={{
                  top: "auto",
                  bottom: "3%",
                  color: cssProperties?.button?.text_color,
                  fontSize: cssProperties?.button?.font_size,
                  background: "#ff0000",
                }}
              >
                <Text tid="end_call" />
              </button>
            )} */}
          </>
        )}
        {isCallerWantsToReconnect && (
          <div className="customerreconnect">
            <InitiatingVideoCallCmp isItReconnection={true} />
          </div>
        )}
      </article>

      {/* <button onClick={handelReconnectClick} style={{'zIndex':9999,'width':'150px','height':'50px','backgroundColor':"red",'marginTop':"300x"}}>
        Reconnect Coustemer
      </button> */}
      {allowcameraflip ? (
        <BackCameraAccessModal
          flipCameraMode={flipCameraMode}
          setAllowcameraflip={setAllowcameraflip}
          setCanvasdisplay={setCanvasdisplay}
          setRecapturepan={setRecapturepan}
          sendMessage={sendMessage}
        />
      ) : null}

      {subscribers?.length === 0 ? null : (
        <div className={letbot ? "bot botactive" : "bot"}>
          {letbot ? null : (
            <button className="chat-bot" id="bot" onClick={handelBotfunction}>
              {notifymsgcount !== 0 && (
                <span
                  className=""
                  style={{
                    width: "22px",
                    height: "22px",
                    background: "red",
                    borderRadius: "60%",
                    color: "white",
                    textAlign: "center",
                    zIndex: "2147483647",
                    position: "absolute",
                    fontWeight: "bold",
                    fontSize: "11px",
                    top: "-4px",
                    display: "flex",
                    alignItems: "center",
                    right: "48px",
                    justifyContent: "center",
                  }}
                >
                  {notifymsgcount}
                </span>
              )}

              <span className="close-chat">
                <i
                  className="far fa-comment-alt"
                  style={{ fontSize: "4vh" }}
                ></i>
              </span>
            </button>
          )}
          <div className={letbot ? "bot-box chatshow" : "bot-box"} id="chat-id">
            <ChatCmp
              sendMessage={sendMessage}
              changeMessage={changeMessage}
              message={message}
              setMessage={setMessage}
              bulk={bulk}
              handelBotfunction={handelBotfunction}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default InitiatingVideoCall;
