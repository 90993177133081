import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import H4 from "../Components/Elements/H4";
// import '../Components/TideComponents/TideSelectKycProcessCmp/TideSelectKyc.css'

const Header = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  return (
    <>
      {props?.title && props?.isClientTideAccess ? (
        <>
          <div className="tideheadermain">
            <button
              type="button"
              className="tideheaderbutton"
              onClick={props?.navigate}
            >
              {props?.noarrow ? (
                ""
              ) : (
                <img
                  src="/images/Vector(Stroke).svg"
                  alt="close"
                  className="tidearrowicon"
                />
              )}
            </button>
            <div></div>
            <div className="tideheadertext">{props?.title}</div>
          </div>
        </>
      ) : (
        <>
          <header
            className={`app-header ${
              props?.hideHeader ? "app-header-dark" : ""
            }`}
          >
            {props?.title &&
              props?.navigate &&
              (props?.noarrow ? (
                ""
              ) : (
                <button className="app-goback" onClick={props?.navigate}>
                  <img src="../images/arrow.svg" alt="arrow" />
                </button>
              ))}
            <H4
              className="app-header-title"
              title={props?.title}
              // titleClr2={cssProperties?.titleClr2}
              color={cssProperties?.pagetitle_heading?.font_color}
              fontSize={cssProperties?.pagetitle_heading?.font_size}
            />
          </header>
        </>
      )}
    </>
  );
};

Header.propTypes = {
  title: PropTypes.any,
  navigate: PropTypes.any,
};

export default Header;
