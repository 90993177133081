import React from "react";
import { useSelector } from "react-redux";
import { Text } from "../../Pages/Language/Language";
import styled from "styled-components";
import Button from "../Elements/Button";
import H4 from "../Elements/H4";
import P from "../Elements/P";

const AadhaarData = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const { aadharDetails } = props
  const { kycdetails } = aadharDetails
  const address = kycdetails?.address.toString()
  return (
    <>
      <section className="app-start">
        <AadhaarDetails>
          <OCRPhoto>
            <img
              src={props?.aadharDetails?.kycdetails ? ("data:image/png;base64," + props?.aadharDetails?.kycdetails?.pht) : ("data:image/png;base64," + props?.aadharDetails?.pht)}
              alt="photo"
            />
          </OCRPhoto>
          <div className="form-floating mt-3">
            <input
              type="text"
              className="form-control border-0 bg-transparent"
              defaultValue={props?.aadharDetails?.name || props?.aadharDetails?.kycdetails?.name}
              id="floatingInput"
              readOnly
            />
            <label htmlFor="floatingInput">
              <Text tid="name" />
            </label>
          </div>
          <div className="form-floating ">
            <input
              type="text"
              className="form-control border-0 bg-transparent"
              defaultValue={props?.aadharDetails?.careof || props?.aadharDetails?.kycdetails?.fname}
              id="floatingInput"
              readOnly
            />
            <label htmlFor="floatingInput">
              <Text tid="father_name" />
            </label>
          </div>
          <div className="form-floating">
            <input
              type="text"
              className="form-control border-0 bg-transparent"
              defaultValue={props?.aadharDetails?.uid || props?.aadharDetails?.kycdetails?.uid}
              id="floatingInput"
              readOnly
            />
            <label htmlFor="floatingInput">
              <Text tid="uid" />
            </label>
          </div>
          <div className="form-floating">
            <input
              type="text"
              className="form-control border-0 bg-transparent"
              defaultValue={props?.aadharDetails?.dob || props?.aadharDetails?.kycdetails?.dob}
              id="floatingInput"
              readOnly
            />
            <label htmlFor="floatingInput">
              <Text tid="dob" />
            </label>
          </div>
          <div className="form-floating">
            <input
              type="text"
              className="form-control border-0 bg-transparent"
              defaultValue={props?.aadharDetails?.gender || props?.aadharDetails?.kycdetails?.gender}
              id="floatingInput"
              readOnly
            />
            <label htmlFor="floatingInput">
              <Text tid="gender" />
            </label>
          </div>
          <div className="form-floating cus-textareaKYC">
            <textarea
              style={{ height: "8rem" }}
              className="form-control pb-4 border-0 bg-transparent"
              id="floatingTextarea"
              defaultValue={address}
              // defaultValue={address ? address : `${props?.aadharDetails?.house} ${props?.aadharDetails?.street} ${props?.aadharDetails?.loc} ${props?.aadharDetails?.subdist} ${props?.aadharDetails?.dist} ${props?.aadharDetails?.state} ${props?.aadharDetails?.country} , ${props?.aadharDetails?.pc}`}
              readOnly
            />
            <label htmlFor="floatingTextarea">
              <Text tid="Address" />
            </label>
          </div>
        </AadhaarDetails>
      </section>
    </>
  );
};

export default AadhaarData;

const AadhaarDetails = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  margin: 1rem auto;
  padding: 1rem;
`;
const OCRPhoto = styled.div`
  img {
    border: 0.875294px solid #d0d0d0;
    border-radius: 7.29412px;
    display: block;
    margin: 0 auto;
    height: 100px;
    width: 100px;
  }
`;
