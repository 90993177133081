import React from "react";
import { Text } from "../../Pages/Language/Language";

const Customeridform = (props) => {
  return (
    <div>
      <div className="debitardimg">
        <img src="images/Adding Credit card or Debit card to the mobile banking app (1) 1.svg" />
      </div>
      <div className="formmain">
        <div className="forminput">
          <label htmlFor="payrollid"> <Text tid="payroll_id" /> </label>
          <input
            className="customeridinput"
            type="text"
            name="payrollid"
            id="payrollid"
            value={props?.payrollid}
            onChange={props?.handleChange}
          />
        </div>
        <div className="forminput">
          <label htmlFor="payrollid"><Text tid="member_id" /> </label>
          <input
            className="customeridinput"
            type="text"
            name="memberid"
            id="memberid"
            value ={props?.memberid}
            onChange={props?.handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Customeridform;
