import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Button from "../../Components/Elements/Button";
import { Text } from "../Language/Language";
import customeCodes from "../../Constants/customeCodes";

const AadhaarNumberVerifyModal = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const vcipDetails = useSelector((state) => state.VcipReducer.vcipDetails);
  const { feature_flags } = vcipDetails
  // const [verificationCode, setVerificationCode] = useState({});
  const [shareCode, setShareCode] = useState({});

  const data = sessionStorage.getItem('user');
  const accName = JSON.parse(data)?.acccode;


  // const inputRef = useRef({});
  const inputRefSC = useRef({});

  const handleChangeSC = (e) => {
    const { name, value } = e.target;
    // setShareCode({ [name]: value });
    let regexp = /^[0-9]{1}$/;
    if (regexp.test(value) && value?.length === 1) {
      setShareCode((prevState) => ({ ...prevState, [name]: value }));
      switch (name) {
        case "sc1":
          inputRefSC.current["sc2"].focus();
          break;
        case "sc2":
          inputRefSC.current["sc3"].focus();
          break;
        case "sc3":
          inputRefSC.current["sc4"].focus();
          break;

        default:
          break;
      }
    } else if (value?.length === 0) {
      setShareCode((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const [disabledbtn, setDisabledbtn] = useState(true);
  let OTP = Object.values(props?.verificationCode).join("");
  let sharecode = Object.values(shareCode).join("");

  useEffect(() => {
    let disablebtn = OTP.length < 6;
    if (!props?.isEkyc) {
      disablebtn = sharecode.length < 4;
    }
    if (feature_flags?.kyctype === customeCodes?.EKYC) {
      disablebtn = OTP.length < 6;
    }
    setDisabledbtn(disablebtn);
    // (OTP.length < 6 || (!props?.isEkyc && sharecode.length < 4)) ? setDisabledbtn(true) : setDisabledbtn(false)
  }, [props?.verificationCode, sharecode, feature_flags?.kyctype]);

  useEffect(() => {
    if (props.resetOtp) {
      props?.setVerificationCode({});
    }

  }, [props.resetOtp])

  const verifyOKYC = () => {
    if (OTP.length === 6) {
      if (!props?.isEkyc && sharecode.length === 4) {
        props.getOKYCdetail(OTP, sharecode);
      } else if (props?.isEkyc) {
        props.getOKYCdetail(OTP)
      } else if (feature_flags?.kyctype === customeCodes?.EKYC) {
        props.getOKYCdetail(OTP);
      }
    } else {
    }
  };

  // Move Focus when backspace is pressed.
  let otpInputs = document.querySelectorAll(".frm-grp-inp");
  for (let i = 0; i < otpInputs.length; i++) {
    otpInputs[i].addEventListener("keydown", function (event) {
      if (event.keyCode === 8 && this.value === "") {
        let prevInput = this.previousElementSibling;
        if (prevInput) {
          prevInput.focus();
        }
      }
    });
  }

  return (
    <>
      <Header>
        <HeadText>
          <h6>verify & get</h6>
          <h4>aadhaar details</h4>
        </HeadText>

        <img
          src="../images/cancel.png"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.closeModal}
          alt="close"
          className="close"
        />
      </Header>
      <AAdhaarImg>
        <img src="../images/popup-aadhaar.png" />
      </AAdhaarImg>
      <ModalBody>
        <div className="modal-body">
          <h6 className="h6" style={{ fontWeight: "bold" }}>
            Enter OTP
          </h6>
          {/* <div className="d-flex justify-content-evenly align-items-center flex-column"> */}

          <div className="secret-codes">
            {[...Array(6)].map((_, index) => (
              <input
                className="form-control cus-logIn"
                style={{ width: "45px", height: '45px', textAlign: "center", margin: "auto" }}
                key={index}
                name={index}
                type="text"
                maxLength="1"
                value={props?.verificationCode[index] || ''}
                onChange={(event) => props?.handleChangeVC(event)}
                // onPaste={handlePaste}
                onKeyDown={(e) => props?.handleChangeVC(e)}
                tabIndex="1"
                ref={(input) => (props.inputRef.current[index] = input)}
              />
            ))}

          </div>
          {/* <ResendOTP onClick={(e) => console.log("clicked")}>
            Resend OTP
          </ResendOTP> */}

          {feature_flags?.kyctype === customeCodes?.EKYC ? "" : (
            <>
              <ShareCodeHead>Enter Share Code</ShareCodeHead>
              <ShareCodeSubHead>
                4-Digit code is required to create share code.
              </ShareCodeSubHead>
              <div className="secret-codes secret-code">
                <input
                  type="number"
                  name="sc1"
                  value={shareCode?.sc1}
                  onChange={handleChangeSC}
                  ref={(el) => (inputRefSC.current["sc1"] = el)}
                  maxLength={1}
                  pattern="[0-9]+"
                  required
                  className="frm-grp-inp"
                />
                <input
                  type="number"
                  name="sc2"
                  value={shareCode?.sc2}
                  onChange={handleChangeSC}
                  ref={(el) => (inputRefSC.current["sc2"] = el)}
                  maxLength={1}
                  min={1}
                  className="frm-grp-inp"
                />
                <input
                  type="number"
                  name="sc3"
                  value={shareCode?.sc3}
                  onChange={handleChangeSC}
                  ref={(el) => (inputRefSC.current["sc3"] = el)}
                  maxLength={1}
                  min={1}
                  className="frm-grp-inp"
                />
                <input
                  type="number"
                  name="sc4"
                  value={shareCode?.sc4}
                  onChange={handleChangeSC}
                  ref={(el) => (inputRefSC.current["sc4"] = el)}
                  maxLength={1}
                  min={1}
                  className="frm-grp-inp"
                />
              </div>
            </>
          )}
          <ProceedBtnDiv>
            <Button
              type="button"
              className="btn w-auto"
              proceed
              click={() => verifyOKYC()}
              disabled={disabledbtn}
              title={<Text tid="agree" />}
              color={cssProperties?.button?.text_color}
              fontSize={cssProperties?.button?.font_size}
              backgroundColor={cssProperties?.button?.color}
            />
          </ProceedBtnDiv>
        </div>
        {/* </div> */}
      </ModalBody>
    </>
  );
};

export default AadhaarNumberVerifyModal;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 22px 22px;
  padding: 0.5rem 0;
  position: relative;
  img {
    position: absolute;
    right: 2rem;
    top: 1.8rem;
  }
`;
const HeadText = styled.div`
  text-align: center;
  h6 {
    text-transform: uppercase;
    font-weight: 400;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 0.8rem;
  }
  h4 {
    text-transform: capitalize;
    font-weight: 900;
    color: #000;
    font-size: 1.1rem;
  }
`;
const AAdhaarImg = styled.div`
  margin: 1rem auto;
`;

const ModalBody = styled.div`
  input {
    padding: 0.6rem 1rem;
    width: 2.8rem;
    border-radius: 0.5rem;
    border: 0.5px solid #b8b8b8;
  }
  input:focus {
    background-color: #fff;
    outline: 0;
    border: none;
    box-shadow: 0px 0px 4px #d17165;
    border-radius: 0.5rem;
  }
`;

const ResendOTP = styled.p`
  color: #cf2128;
  font-size: 0.8rem;
  font-weight: 400;
`;
const ShareCodeHead = styled.h5`
  color: #aa1300;
  font-weight: 600;
  font-size: 1.1rem;
  padding-top: 1rem;
  margin-bottom: 0;
`;
const ShareCodeSubHead = styled.p`
  color: #333333;
`;
const ProceedBtnDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  button {
    /* color: #fff; */
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.3rem;
    /* background: #cf2128; */
  }
`;
