import React from "react";
import { useSelector } from "react-redux";

export default function Loading({ text, isClientTideAccess }) {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const Color = cssProperties?.button?.color
  return (
    <>
      {isClientTideAccess ? (
        <div className="loaderMain">
          <div className="ldsrings">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p style={{ color: "#000000" }}>{text}</p>
        </div>
      ) : (
        <div className="loaderMain">
          <div className="ldsring">
            {[0, 1, 2, 3].map((item, i) => (
              <div
                key={i}
                style={{
                  color: "#000000",
                  border: `8px solid ${Color}`,
                  borderColor:
                    `${Color} transparent transparent transparent`,
                }}
              ></div>
            ))}
          </div>
          <p style={{ color: "#000000" , fontWeight: "600" , marginTop: "1rem"}}>{text}</p>
        </div>
      )}
    </>
  );
}
