import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "../../Components/Elements/Button";
import H4 from "../../Components/Elements/H4";
import { Text } from "../Language/Language";

const AppFooter = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  return (
    <>
      {props.isFooterAvailable ? null : (
        <section className="app-end">
          <div className="text-center">
            {props?.isGenerate ? (
              <>
                {/* <h4 className="title">Don't have an Aadhaar XML file?</h4> */}
                {/* <button className="btn btn-white mb-3" type="button" data-toggle="modal" data-target="#verification" onClick={props.generateXMLModal}>Generate now</button> */}

                <H4
                  className="title"
                  title={"Don't have an Aadhaar XML file?"}
                  // titleClr2={cssProperties?.titleClr2}
                  color={cssProperties?.pagetitle_heading?.font_color}
                  fontSize={cssProperties?.pagetitle_heading?.font_size}
                />
                <Button
                  className="btn btn-white mb-3"
                  type="button"
                  datatoggle="modal"
                  datatarget="#verification"
                  click={props?.generateXMLModal}
                  title={<Text tid="generate_now"/>}
                  color={cssProperties?.button?.text_color}
                  fontSize={cssProperties?.button?.font_size}
                  backgroundColor={cssProperties?.button?.color}
                />
              </>
            ) : null}
            {(props?.isJoinCall && !props?.isClientTideAccess) ? (
              <>
                {/* <button className="btn mb-3" type="button" data-toggle="modal" data-target="#verification" onClick={props.generateXMLModal}>Join Now</button> */}
                {/* <button
                  className="btn mb-3"
                  type="button"
                  onClick={props?.joinVideoSession}
                >
                  Join Now
                </button> */}
                <Button
                  className="btn mb-3"
                  type="button"
                  click={props?.joinVideoSession}
                  title={props?.hasTokenExpired ? <Text tid="retry" /> : <Text tid="join_now"/>}
                  color={cssProperties?.button?.text_color}
                  fontSize={cssProperties?.button?.font_size}
                  backgroundColor={cssProperties?.button?.color}
                />
              </>
            ) : null}
            {/* <button
              className="btn"
              type="button"
              onClick={props?.navigate}
              disabled={props?.isDisabled ? props?.isDisabled : false}
            >
              {props?.btnName ? props?.btnName : "Continue"}
            </button> */}
            {
              props?.isClientTideAccess ? (
                <Button
              className="btn"
              type="button"
              joinnowcolorchange = {props?.isDisabled}
              disabled={props?.isDisabled ? props?.isDisabled : false}
              click={props?.joinVideoSession ? props?.joinVideoSession  : props?.navigate}
              title={props?.btnName ? props?.btnName : <Text tid="continue_button"/>}
              isClientTideAccess = { props?.isClientTideAccess}
            />
              ): props?.showBookSlotOption ? (
                props?.isippb ? null :
                <Button
              className="btn"
              type="button"
              // aadharbutton = {props?.aadharbutton}
              disabled={props?.isDisabled ? props?.isDisabled : false}
              click={props?.navigate}
              title={props?.btnName ? props?.btnName : <Text tid="continue_button"/>}
              color={cssProperties?.button?.text_color}
              fontSize={cssProperties?.button?.font_size}
              backgroundColor={cssProperties?.button?.color}
            />

              ):""
            }
            
          </div>
        </section>
      )}
    </>
  );
};

AppFooter.propTypes = {
  isFooterAvailable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isJoinCall: PropTypes.bool,
  isGenerate: PropTypes.bool,
  navigate: PropTypes.func,
  btnName: PropTypes.string,
  generateXMLModal: PropTypes.func,
  hasTokenExpired: PropTypes.bool,
  showBookSlotOption: PropTypes.bool
};
AppFooter.defaultProps = {
  hasTokenExpired: PropTypes.false,
  showBookSlotOption: true
};

export default AppFooter;
