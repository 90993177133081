import { ACTION_GET_PAN_CAPTURE_REQ, ACTION_GET_PAN_DECLARATION_REQ, ACTION_GET_PAN_SAVEDETAILS_REQ, ACTION_GET_PAN_VERIFY_REQ, ACTION_POST_PAN_DECLARATIONSUBMIT_REQ } from "./SagaActionTypes";


export const actionPanCapture = (payload) => {
    return {
        type: ACTION_GET_PAN_CAPTURE_REQ,
        payload: payload
    }
}

export const actionSavePanDetails = (payload) => {
    return {
        type: ACTION_GET_PAN_SAVEDETAILS_REQ,
        payload: payload
    }
}

export const actionVerifyPanNumber = (payload) => {
    return {
        type: ACTION_GET_PAN_VERIFY_REQ,
        payload: payload
    }
}

export const actionPanDeclaration = (payload) => {
    return {
        type: ACTION_GET_PAN_DECLARATION_REQ,
        payload: payload
    }
}

export const actionPanDeclarationSubmit = (payload) => {
    return {
        type: ACTION_POST_PAN_DECLARATIONSUBMIT_REQ,
        payload: payload
    }
}
