import toast from "react-hot-toast";
import Axios from "../../Service/axios";

export const getAPI = async (url) => {
  return await Axios.get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        toast(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
        );
        // return err?.response?.data?.errors[0]?.message
      } else {
        toast(err.message);
      }
    });
};

export const postAPI = async (url, body) => {
  return await Axios.post(url, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err
      //     if (err.response) {
      //       toast(
      //         err?.response?.data?.errors?.length &&
      //           err?.response?.data?.errors[0]?.message
      //       );
      //       // return err?.response?.data?.errors[0]?.message
      //     } else {
      //       toast(err.message);
      //     }

      //  console.log(err)
    });
};
