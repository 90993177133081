import React from "react";
import { useNavigate } from "react-router-dom";
import AadhaarUploadSuccessCmp from "../../../Components/SelectKYCProcessCmp/AadhaarUploadSuccessCmp";
import RouteNames from "../../../Constants/RouteNames";
import Header from "../../../Layout/Header";
import AppFooter from "../../Common/AppFooter";
import { Text } from "../../Language/Language";

const AadhaarUploadSuccess = () => {
  let navigate = useNavigate();

  const nextPage = () => {
    navigate(RouteNames.INITIATE_VIDEO_CALL);
  };

  return (
    <>
      <Header
        title={<Text tid="download_sucess" />}
        navigate={() => navigate(-1)}
        hideHeader={false}
      />
      <article className="app-body">
        <AadhaarUploadSuccessCmp />
        <AppFooter btnName={<Text tid="agree"/>} navigate={nextPage} />
      </article>
    </>
  );
};

export default AadhaarUploadSuccess;
