import toast from "react-hot-toast";
import { put, takeLatest, call, delay } from "redux-saga/effects";
import base64 from "base-64";
import StatusCodes from "../../Constants/StatusCodes";
import Axios from "../../Service/axios";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/CommonSagaActions";
import {
  actionCreateSlot,
  actionGetVcipDetails,
  actionGetVcipDetailsResponse,
  setClientName,
  setCSSColorCodes,
  setVcipStatusesAction,
} from "../SagaActions/GetVcipDetailsSagaActions";
import {
  ACTION_GET_CUSTOMER_CONSENT,
  ACTION_GET_CUSTOMER_CREATE_SLOT_REQ,
  ACTION_GET_CUSTOMER_DECLARATION,
  ACTION_GET_CUSTOMER_VCIP_DETAILS_REQ,
  ACTION_GET_CUSTOMER_VCIP_STATUSES_REQ,
} from "../SagaActions/SagaActionTypes";
import { postAPI } from "./ApiMethods";
import RouteNames from "../../Constants/RouteNames";

// GET VCIP DETAILS
// const getVcipDetailsReq = (body) => {
//   const URL = "GetVcipLinkDetails";
//   return Axios.post(URL, body).then((res) => {
//     return res?.data;
//   });
// };

function* getVcipDetialsReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  try {
    const resp = yield call(postAPI, "GetVcipLinkDetails", action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      yield put(actionGetVcipDetailsResponse(resp));
      if (
        resp?.vcipstatus === StatusCodes.VCIPSTATUS_PENDING ||
        resp?.vcipstatus === StatusCodes.VCIPSTATUS_REJECTED ||
        resp?.vcipstatus === StatusCodes.VCIPSTATUS_UNDERREVIEW ||
        resp?.vcipstatus === StatusCodes.VCIPSTATUS_ONHOLDBYAGENT ||
        resp?.vcipstatus === StatusCodes.VCIPSTATUS_COMPLETED
      ) {
        // toast.success(resp?.respdesc);
        sessionStorage.setItem("user", JSON.stringify(resp));
        sessionStorage.setItem("authkey", resp?.authkey);
        sessionStorage.setItem("vcipkey", resp?.vcipkey);
        const decodeColors = base64.decode(resp?.colorcode);
        if (action?.payload?.callback) {
          action?.payload?.callback(resp);
        }
        yield put(setCSSColorCodes(JSON.parse(decodeColors)));
        yield put(setClientName(resp?.acccode));
      } else {
        toast.success(resp?.respdesc, { duration: 8000 });
      }
    } else {
      toast(resp?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    }
    let pathName = window?.location?.pathname;
    const isPendingStatus = resp?.vcipstatus === StatusCodes.VCIPSTATUS_PENDING;
    if (action?.payload?.isFinalScreen && pathName === RouteNames.KYC_PROCESS_COMPLETED && isPendingStatus) {
      yield delay(2000);
      yield put(actionGetVcipDetails(action?.payload));
    }
  } catch (err) {
    let pathName2 = window?.location?.pathname;
    if (action?.payload?.isFinalScreen && pathName2 === RouteNames.KYC_PROCESS_COMPLETED) {
      yield delay(5000);
      yield put(actionGetVcipDetails(action?.payload));
    }
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

// CREATE SLOT
// const createSlotReq = (body) => {
//   const URL = "CreateSlot";
//   return Axios.post(URL, body).then((res) => {
//     return res?.data;
//   });
// };

//createslot
function* createSlotReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(postAPI, "CreateSlot", action?.payload?.model);
    if (resp) {
      if (!resp.respcode) {
        throw new Error(resp)
      }
      if (resp?.respcode != "200") {
        toast.error(resp?.respdesc);
      }
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
    let pathName2 = window?.location?.pathname;
    if (pathName2?.includes("/d=")) {
      // yield delay(5000);
      action?.payload?.callback(false)
    }
  }
  finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


//GetCustomerVCIPStatus
function* getCustomerVcipStatuesReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  Axios.defaults.headers.common['uihost'] = window.origin;
  try {
    const resp = yield call(postAPI, "GetCustomerVCIPStatus", action?.payload?.model);
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (resp && resp?.respcode === "200") {
      action?.payload?.callback(resp?.data[0]);
      yield put(setVcipStatusesAction(resp?.data[0]));
    } else {
      toast(resp?.respdesc);
      yield put(actionReqResStatusLoaderSagaAction(false));
      // yield put(setVcipStatusesAction({}));
      action?.payload?.callback({});
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
    yield put(actionReqResStatusLoaderSagaAction(false));
    // yield put(setVcipStatusesAction({}));
  }
  // finally {
  //   yield put(actionReqResStatusLoaderSagaAction(false));
  // }
}

function* getCustomerConsentReqSaga(action) {
  try {
    const resp = yield call(postAPI, "GetCustomerConsent", action?.payload?.model);
    if (resp && resp?.respcode === "200") {
    } else {
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  }
  finally {
    // yield put(actionReqResStatusLoaderSagaAction(false));
  }
}
function* getGetCustomerDeclarationReqSaga(action) {
  try {
    const resp = yield call(postAPI, "GetCustomerDeclaration", action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      action?.payload?.callback(resp);
    } else {
      action?.payload?.callback(resp);
      toast(resp?.respdesc);
    }
  } catch (err) {
    action?.payload?.callback(err);
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  }
  finally {
    // yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

export default function* getVcipDetailsWatcherSaga() {
  yield takeLatest(ACTION_GET_CUSTOMER_VCIP_DETAILS_REQ, getVcipDetialsReqSaga);
  yield takeLatest(ACTION_GET_CUSTOMER_CREATE_SLOT_REQ, createSlotReqSaga);
  yield takeLatest(ACTION_GET_CUSTOMER_VCIP_STATUSES_REQ, getCustomerVcipStatuesReqSaga);
  yield takeLatest(ACTION_GET_CUSTOMER_CONSENT, getCustomerConsentReqSaga);
  yield takeLatest(ACTION_GET_CUSTOMER_DECLARATION, getGetCustomerDeclarationReqSaga);
}