const StatusCodesList = {
    VCIPSTATUS_PENDING: '1',
    VCIPSTATUS_UNDERREVIEW: '2',
    VCIPSTATUS_ONHOLDBYAGENT: '3',
    VCIPSTATUS_COMPLETED: '4',
    VCIPSTATUS_REJECTED: '5',
    PANSTATUS: '1' // SUCCESS DATA
}
// 1 - Pending
// 2 - Under Review
// 4 - Completed
// 5 - Rejected

const StatusCodes = Object.freeze(StatusCodesList);

export default StatusCodes;