import React from 'react';
import { Text } from '../../Pages/Language/Language'

const AadhaarUploadSuccessCmp = () => {
  return (
    <section className="app-start">
      <div className="text-center mb-3">
        <img src="../images/success.svg" alt="success" />
      </div>
      <h4 className="title text-center"><Text tid="file_download_successfully" /></h4>
      <p className="txt text-center">
        <Text tid="kindly_reconfirm_your_share_code_to_access_xml_file" />
      </p>
      <div className="secret-codes">
        <input type="number" name className="scretet-inp" maxLength={1} min={1} />
        <input type="number" name className="scretet-inp" maxLength={1} min={1} />
        <input type="number" name className="scretet-inp" maxLength={1} min={1} />
        <input type="number" name className="scretet-inp" maxLength={1} min={1} />
        {/* <input type="number" name="" class="scretet-inp" maxlength="1" min="1" />
                  <input type="number" name="" class="scretet-inp" maxlength="1" min="1" /> */}
      </div>
    </section>
  )
}

export default AadhaarUploadSuccessCmp