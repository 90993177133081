import React from "react";
import { Text } from "../../Pages/Language/Language";
import H4 from "../Elements/H4";
import { useSelector } from "react-redux";

const Instructions = (props) => {
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { app_env } = dynamicSettingObj;
  return (
    <>
      {!(window.location.pathname.includes("d=")) ?
      <>
      <H4
      className="title mb-2"
      title={<Text tid="instructions"/>}
      // color={cssProperties?.pagetitle_heading?.font_color}
      // fontSize={cssProperties?.pagetitle_heading?.font_size}
    />
      <ul className="instructions-list pl-4 mt-1">
        <li>
        <Text tid={props?.iskinabank ? "do_not_refresh_page_or_you_will_be_logged_out_of_the_digital_kyc_process" : "do_not_refresh_page_or_you_will_be_logged_out_of_the_vkyc_process"} />        
        </li>
        <li><Text tid="do_not_open_same_link_warning" /></li>
        <li><Text tid="ensure_internet_connectivity" /></li>
        <li><Text tid="enable_camera_mic_location_settings_on_device" /> </li>
        <li><Text tid="sleep_mode_warning" /></li>
        {app_env == "lq_uat" && <li><Text tid="please_have_your_Original_Pan_card_during_VKYC_call" /></li>}
        <li className="danger-alert"> <Text tid="do_not_go_back_in_any_kyc_step" /></li>
      </ul></>
       :<>
       <H4
      className="title mb-2"
      title={<Text tid="Instructions" />}
      // color={cssProperties?.pagetitle_heading?.font_color}
      // fontSize={cssProperties?.pagetitle_heading?.font_size}
    />
      <ul className="instructions-list pl-4 mt-1">
        <li>

        <Text tid="Do_not_refresh_the_page_or_access_any_other_app_else_you_will_be_logged_out_of_the_Digital_KYC_process" />

           {/* {props?.iskinabank ? "Do not refresh the page or access any other app, else, you will be logged out of the Digital KYC process." : "Do not refresh the page or access any other app, else, you will be logged out of the Video KYC process."}  */}
        </li>
        <li><Text tid="Please_do_not_open_the_same_link_in_multiple_tabs" /></li>
        <li><Text tid="Please_ensure_your_internet_connectivity_is_stable" /></li>
        <li><Text tid="Enable_camera_microphone_and_location_settings_on_your_device" /></li>
        <li><Text tid="Do_not_let_your_mobile_screen_go_into_sleep_mode" /></li>
        {app_env == "lq_uat" &&<li><Text tid="please_have_your_Original_Pan_card_during_VKYC_call" /></li>}
        <li className="danger-alert"
        style={{color:props?.cssProperties&&props?.cssProperties?.toaster?.font_color}}
        ><Text tid="Do_not_go_back_in_any_step_of_the_KYC_process" />
        <style>
        {`
          .danger-alert {
            font-family: 'ProximaNova-semibold';
            animation: dangerAlert 1.5s linear infinite;
            color: ${props?.cssProperties?.body?.faied_font_color || 'red'};
          }

          @keyframes dangerAlert {
            0% {
              color: ${props?.cssProperties?.body?.faied_font_color || 'red'};
            }
            100% {
              color: white;
            }
          }
        `}
      </style>
        
        </li>
      </ul></>}
    </>
  );
};

export default Instructions;
