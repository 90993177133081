import toast from "react-hot-toast";
import { put, takeLatest, call } from "redux-saga/effects";
import Axios from "../../Service/axios";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/CommonSagaActions";
import {
  ACTION_GET_DIGILOCKER_LINK_REQ_EKYC,
  ACTION_GET_DIGILOCKER_STATUS_REQ_EKYC,
  ACTION_GET_EKYC_GENERATE_OTP,
  ACTION_GET_EKYC_GET_CATCHA,
  ACTION_GET_EKYC_GET_DATA,
  ACTION_GET_EKYC_REFRESH_CATCHA,
  AADHAAR_EKYC_PROCESS_SEND_OTP,
  AADHAAR_EKYC_PROCESS_AADHAARDETAILS,
  ACTION_POST_AADHAAR_EKYC_REQUEST,
  ACTION_POST_FETCH_AADHAAR_EKYC_STATUS
} from "../SagaActions/SagaActionTypes";

const postCall = (URL, body) => {
  return Axios.post(URL, body).then((res) => {
    return res?.data;
  });
};

// GET DIGILOCKER URL
const getDigiLocckerReq = (model) => {
  const URL = "DigiLockerRequest";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getDigiLockerReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(getDigiLocckerReq, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      // toast.success(resp?.respdesc);
      toast.success("Pending...");
      const data = { url: resp?.url, txnid: resp?.txnid };
      if (action?.payload?.callback) {
        action?.payload?.callback(data);
      }
    } else {
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}
const createAadhaarEkycReq = (model) => {
  const URL = "CreateEkycRequest";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* createAadhaarEkycReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(createAadhaarEkycReq, action?.payload?.model);
    if (resp && resp?.respcode === "200") {
      // toast.success(resp?.respdesc);
      // toast.success("Pending...");
      // const data = { url: resp?.url, txnid: resp?.txnid };
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const fetchAadhaarEkycReq = (model) => {
  const URL = "FetchEkycTxnStatus";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* fetchAadhaarEkycReqSaga(action) {
  // yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(fetchAadhaarEkycReq, action?.payload?.model);
    // if (resp && resp?.respcode === "200") {
    if (resp && action?.payload?.callback) {
      // toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
    } else if (resp) {
      action?.payload?.callback(resp);
    }
    // } else {
    //     toast(resp?.respdesc);
    // }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    // yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

// GET DIGILOCKER STATUS
const getDigiLocckerStatusReq = (model) => {
  const URL = "FetchDigiLockerTxnStatus";
  return Axios.post(URL, model).then((res) => {
    return res?.data;
  });
};

function* getDigiLockerStatusReqSaga(action) {
  // yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(getDigiLocckerStatusReq, action?.payload?.model);
    // if (resp && resp?.respcode === "200") {
    if (resp?.statuscode === "1" && action?.payload?.callback) {
      toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
    } else if (resp) {
      action?.payload?.callback(resp);
    }
    // } else {
    //     toast(resp?.respdesc);
    // }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    // yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* getOKYCCaptchaReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postCall,
      "GenerateKycOTP",
      action?.payload?.model
    );
    if (resp && resp?.respcode === "200") {
      // if (resp?.statuscode === "1" && action?.payload?.callback) {
      //   toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
      // } else if (resp) {
      //   action?.payload?.callback(resp);
      // }
    } else {
      action?.payload?.callback(resp);
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


function* getEKYCOTPaReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postCall,
      "GenerateKycOTP",
      action?.payload?.model
    );

    if (resp && resp?.respcode === "200") {
      action?.payload?.callback(resp);
    } else {
      action?.payload?.callback(resp);
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* getaadhaarDetailsReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postCall,
      "DoKycOTP",
      action?.payload?.model
    );
    if (resp && resp?.respcode === "200") {
      action?.payload?.callback(resp);
    } else {
      action?.payload?.callback(resp);
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* getOKYCRefreshCaptchaReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postCall,
      "OKYCv3_RefreshCaptcha",
      action?.payload?.model
    );
    if (resp && resp?.respcode === "200") {
      // if (resp?.statuscode === "1" && action?.payload?.callback) {
      //   toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
      // } else if (resp) {
      //   action?.payload?.callback(resp);
      // }
    } else {
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


function* getOKYCGenerateOTPReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postCall,
      "DoKycOTP",
      action?.payload?.model
    );
    if (resp && resp?.respcode === "200") {
      // if (resp?.statuscode === "1" && action?.payload?.callback) {
      //   toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
      // } else if (resp) {
      //   action?.payload?.callback(resp);
      // }
    } else {
      action?.payload?.callback(resp);
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


function* getOKYCDetailsReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postCall,
      "OKYCv3_GetKYCData",
      action?.payload?.model
    );
    if (resp && resp?.respcode === "200") {
      // if (resp?.statuscode === "1" && action?.payload?.callback) {
      //   toast.success(resp?.respdesc);
      action?.payload?.callback(resp);
      // } else if (resp) {
      //   action?.payload?.callback(resp);
      // }
    } else {
      action?.payload?.callback(resp);
      toast(resp?.respdesc);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

export default function* AdharEKYCWatcherSaga() {
  yield takeLatest(ACTION_GET_DIGILOCKER_LINK_REQ_EKYC, getDigiLockerReqSaga);
  yield takeLatest(
    ACTION_GET_DIGILOCKER_STATUS_REQ_EKYC,
    getDigiLockerStatusReqSaga
  );
  yield takeLatest(ACTION_GET_EKYC_GET_CATCHA, getOKYCCaptchaReqSaga);
  yield takeLatest(ACTION_GET_EKYC_REFRESH_CATCHA, getOKYCRefreshCaptchaReqSaga);
  yield takeLatest(ACTION_GET_EKYC_GENERATE_OTP, getOKYCGenerateOTPReqSaga);
  yield takeLatest(ACTION_GET_EKYC_GET_DATA, getOKYCDetailsReqSaga);
  yield takeLatest(AADHAAR_EKYC_PROCESS_SEND_OTP, getEKYCOTPaReqSaga);
  yield takeLatest(AADHAAR_EKYC_PROCESS_AADHAARDETAILS, getaadhaarDetailsReqSaga);
  yield takeLatest(ACTION_POST_AADHAAR_EKYC_REQUEST, createAadhaarEkycReqSaga);
  yield takeLatest(ACTION_POST_FETCH_AADHAAR_EKYC_STATUS, fetchAadhaarEkycReqSaga);

}
