const te = {
    select_language: "భాషను ఎంచుకోండి",
    instruction: "దిగువ జాబితా చేయబడిన వాటిని ఎంచుకోవాలని మేము మీకు సిఫార్సు చేస్తున్నాము ",
    language: "భాష ",
    continue: "మీ వీడియో కాల్‌తో కొనసాగడానికి",
    // instruction:"మీ వీడియో కాల్‌తో కొనసాగడానికి దిగువ జాబితా చేయబడిన <b> భాష</b>ని ఎంచుకోవాలని మేము మీకు సిఫార్సు చేస్తున్నాము",

    // =================================
    //        Commence your video KYC
    // =================================
    verify_your_identity: "మీ గుర్తింపును ధృవీకరించండి",
    handy_documents: "మీరు మీ పూర్తి KYC ప్రక్రియను కొనసాగించే ముందు దయచేసి మీ క్రింది పత్రాలను సులభంగా ఉంచుకోండి.",
    aadhaar_verify: "ఆధార్ ధృవీకరణ",
    pan_capture: "PAN క్యాప్చర్",
    video_call: "వీడియో కాల్ (Q&A)",
    // =================================
    //              Modal
    // =================================

    user_consent: "వినియోగదారు సమ్మతి",
    user_instruction: "క్లిక్ చేయడం ద్వారా ",
    user_agree: "'అంగీకరిస్తున్నారు', ",
    user_here: "మీరు దీని ద్వారా:",
    // user_instruction:"<b>'అంగీకరించు'</b>పై క్లిక్ చేయడం ద్వారా, మీరు దీని ద్వారా:",
    // user_instruction1: "వ్యక్తిగత వివరాలను అందించడానికి సింటైజెన్ టెక్నాలజీస్ ప్రైవేట్ లిమిటెడ్ చేసిన అభ్యర్థనను అంగీకరించండి.",
    // user_instruction2: "సమాచారాన్ని భాగస్వామ్యం చేయడం ద్వారా మొత్తం సమాచారాన్ని యాక్సెస్ చేయడానికి, కాపీ చేయడానికి మరియు నిల్వ చేయడానికి నా షరతులు లేని ఏకాగ్రతను అందించండి.",
    // user_instruction3: "అభ్యర్థించిన సంస్థ తరపున అలా చేయడానికి నేను/మాకు అధికారం ఉంది మరియు దాని కోసం పూర్తి బాధ్యతను కూడా తీసుకుంటాము.",
    user_instruction1: "బ్యాంకర్‌తో మీ వీడియో ఇంటరాక్షన్ సెషన్ రికార్డింగ్ మోడ్‌లో ఉంటుంది.",
    user_instruction2: "బ్యాంకర్‌తో వీడియో ఇంటరాక్షన్ సెషన్‌లో ప్రత్యక్ష ఫోటో తీయబడుతుంది.",
    user_instruction3: "మీ ఆధార్ వివరాలు V-CIP ప్రక్రియలో ఆధార్ ధృవీకరణ కోసం ఉపయోగించబడతాయి.",
    user_instruction4: "V-CIP ప్రక్రియలో పాన్ ధృవీకరణ చేయడానికి మీ పాన్ కార్డ్ యొక్క ఛాయాచిత్రం సేకరించబడుతుంది.",
    user_instruction5: "మీ ప్రత్యక్ష స్థానం V-CIP ప్రాసెస్‌లో సంగ్రహించబడుతుంది.",
    user_instruction6: "వీడియో ఇంటరాక్షన్ సెషన్‌లో అన్ని వివరాలు సరైనవని మీరు నిర్ధారించుకోవాలి.",
    user_instruction7: "ఆధార్ XML ప్యాకెట్ లేదా ఆధార్ సురక్షిత QR కోడ్ 3 రోజుల కంటే పాతదిగా ఉండకూడదు.",
    user_instruciton8: "పైన పేర్కొన్న అన్ని దశలు RBI మార్గదర్శకాల ప్రకారం ఉన్నాయి.",

    // content_cdtn1: " నేను, V-CIP ID ",
    // content_cdtn2: " పైన పేర్కొన్న అన్ని పాయింట్లతో హోల్డర్ అంగీకరిస్తాడు మరియు దీని ద్వారా, నా సమ్మతిని నిర్ధారించండి.",
    accept_cdtn1: "నేను, ",
    accept_cdtn2: " VCIP IDతో - ",
    accept_cdtn3: " పైన పేర్కొన్న అన్ని అంశాలతో అంగీకరిస్తున్నాను మరియు దీని ద్వారా, నా సమ్మతిని నిర్ధారించండి.",
    // disagree: "అంగీకరించలేదు",
    agree: "అంగీకరిస్తున్నారు",


    // =================================
    //              Token Number
    // =================================


    token_number: "మీ టోకెన్ సంఖ్య సున్నా అయ్యే వరకు వేచి ఉండమని మిమ్మల్ని అభ్యర్థించండి.",
    wait_time: "మీ అంచనా వేయబడిన నిరీక్షణ సమయం",
    wait_patinently: "ఈ సమయంలో మా ఏజెంట్లందరూ బిజీగా ఉన్నందున, ఓపికగా వేచి ఉండి, కొంత సమయం తర్వాత తిరిగి రావాలని మేము మీకు సిఫార్సు చేస్తున్నాము",
    // =================================
    //              Headers
    // =================================


    initiating_call: "వీడియో కాల్‌ని ప్రారంభిస్తోంది",
    choose_language: "ఒక భాషను ఎంచుకోండి",
    commence_video: "మీ వీడియో KYCని ప్రారంభించండి",
    aadhaar_xml: "ఆధార్ XML",
    kyc_details: "KYC వివరాలు",
    aadhaar_offline: "ఆధార్ ఆఫ్‌లైన్ KYC",
    download_sucess: "డౌన్‌లోడ్ విజయవంతంగా",
    pan_verification: "PAN ధృవీకరణ",
    Keep_things_handy: "మీరు మా ఏజెంట్‌తో వీడియో కాల్ చేయడానికి సిద్ధంగా ఉన్నారా?",
    title_token: "టోకెన్ సంఖ్య",
    reschedule: "రీషెడ్యూల్ చేయండి",
    status: "స్థితి",


    // =================================
    //              PAN
    // =================================

    Pan_heading: "పాన్ కార్డ్ ఫోటోను క్యాప్చర్ చేయండి",
    capture_photo: "ఫోటో తీయండి",
    retake: "తిరిగి తీసుకో",
    submit: "సమర్పించండి",


    // =================================
    //              PAN Details
    // =================================



    ocr_info: "OCR సమాచారం",
    name: "పేరు",
    father_name: "తండ్రి పేరు",
    dob: "DOB",
    pan_number: "పాన్ నంబర్",
    please_wait: "దయచేసి వేచి ఉండండి...",
    fetching_aadhar: "ఆధార్ వివరాలను పొందుతోంది...!",
    uid: "UID",
    gender: "లింగం",
    Address: "చిరునామా",
    xml_file: "మీ వద్ద ఆధార్ XML ఫైల్ ఉందా?",
    yes: "అవును",
    no: "కాదు",

    waiting_agent: "ఏజెంట్ కోసం వేచి ఉంది",
    end_call: "కాల్ ముగించు",
    book_slot: "స్లాట్‌ను బుక్ చేయండి",
    id_number:"ID Number",



    // =================================
    //              Kyc completed 
    // =================================


    kyc_update: "KYC సమర్పించబడింది.",
    verification: "మీ ధృవీకరణ పూర్తయింది మరియు మీ డేటా మొత్తం సురక్షితంగా నిల్వ చేయబడుతుంది.",


    // ===================================
    //          Employement Details
    // ===================================

    occupation_details:"వృత్తి వివరాలు",
    occupation_details_subtext:"మీ ఎంపికలో ఒకదాన్ని ఎంచుకోండి"




}

export default te;