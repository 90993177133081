import React from 'react'

export default function BackCameraAccessModal(props) {
    const allowbackcamera = ()=>{
        props?.setAllowcameraflip(false)
        props?.flipCameraMode(true)
        if(props?.Livekit){
            props?.setCameramirror(false)
        }
        props?.setCanvasdisplay(true)
        props?.setRecapturepan(true)
        const Accessapprove= {
            streamingKey: "CUSTOMER_APPROVED_BACK_CAMERA_ACCESS", 
                streamingValue: "Customer Approved Back Camera Access",
                notificationid: "APPROVE",
                notifications: "Customer Approved Back Camera Access",
        }
        props?.Livekit ? props?.sendMessage(Accessapprove) : props?.sendMessage(null,Accessapprove)
    }

    const cancelbackcameraaccess = ()=>{
        props?.setAllowcameraflip(false)
        if(props?.Livekit){
            props?.setCameramirror(true)
        }
        // props?.flipCameraMode(false)
        props?.setRecapturepan(false)
        props?.setCanvasdisplay(false)
        const Accessdecline= {
            streamingKey: "CUSTOMER_DECLINE_BACK_CAMERA_ACCESS", 
                streamingValue: "Customer Declined Back Camera Access",
                notificationid: "DECLINE",
                notifications: "Customer Declined Back Camera Access",
        }
        props?.Livekit ? props?.sendMessage(Accessdecline) : props?.sendMessage(null,Accessdecline) 

    }

  return (
    <div className='modalbackground'>
        <div className='modalbody'>
            <div className='title'>
            Video KYC Would like to Access Your Back Camera
            </div>
            <div className="modalborder"></div>
            <div className="allowoption">
                    <div className="dontallow opacity-80" onClick={cancelbackcameraaccess}>
                        Don't allow
                    </div>
                    <div className='allow' onClick={allowbackcamera}>
                    Allow
                    </div>
                </div>
        </div>

    </div>
  )
}
