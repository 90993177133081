import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import PanDetailsEditCmp from "../../../Components/PanCmp/PanDetailsEditCmp";
import Header from "../../../Layout/Header";
import { Text } from "../../Language/Language";
import CleintNames from "../../../Constants/ClientNames";
import RouteNames from "../../../Constants/RouteNames";
import Tideheader from "../../../Components/TideComponents/Tideheaderforpan/Tideheader";

const PanDetailsEdit = (props) => {
  const [edtname, setEdtname] = useState("");
  const [defname, setDefname] = useState("");
  const [deffname, setDeffname] = useState("");
  const [edtfname, setEdtfname] = useState("");
  const [defdob, setDefdob] = useState("");
  const [edtdob, setEdtdob] = useState("");
  const [edtpannumber, setEdtpannumber] = useState("");
  const [defpannumber, setDefpannumber] = useState("");
  const [edtgender, setEdtgender] = useState("");
  const [edtcountry, setEdtcountry] = useState("");
  const [edtdateofexpiry, setEdtDateofexpiry] = useState("");
  const [dob,setDob]=useState("")
  const [idtype, setIdtype] = useState("");
  const [ref3, setRef3] = useState("");
  const [ref4, setRef4] = useState("");
  const [ref5, setRef5] = useState("");
  const [ref6, setRef6] = useState("");
  const [ref7, setRef7] = useState("");
  const [ref8, setRef8] = useState("");
  const [kinadateofexpiry,setKinadateofexpiry]=useState("")

  const [photo, setPhoto] = useState("");

  const clientName = useSelector((state) => state.HomeReducer.clientName);

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
    clientName === CleintNames?.TIDEQA ||
    clientName === CleintNames?.TIDE
      ? true
      : false;
  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;

  useEffect(async () => {
    // setEdtname(props?.panDetails?.name ? props?.panDetails?.name : "");
    // setEdtfname(props?.panDetails?.fname ? props?.panDetails?.fname : "");
    // setEdtpannumber(
    //   props?.panDetails?.pannumber ? props?.panDetails?.pannumber : ""
    // );
    // setEdtgender(props?.panDetails?.gender ? props?.panDetails?.gender : "");
    // setEdtcountry(props?.panDetails?.ref1 ? props?.panDetails?.ref1 : "");
    // setEdtDateofexpiry(props?.panDetails?.ref2 ? props?.panDetails?.ref2 : "");
    // setRef3(props?.panDetails?.ref3 ? props?.panDetails?.ref3 : "");
    // setRef4(props?.panDetails?.ref4 ? props?.panDetails?.ref4 : "");
    // setRef5(props?.panDetails?.ref5 ? props?.panDetails?.ref5 : "");
    // setRef6(props?.panDetails?.ref6 ? props?.panDetails?.ref6 : "");
    // setRef7(props?.panDetails?.ref7 ? props?.panDetails?.ref7 : "");
    // setRef8(props?.panDetails?.ref8 ? props?.panDetails?.ref8 : "");

    // setPhoto(props?.panDetails?.pht ? props?.panDetails?.pht : "");
    // setIdtype(props?.panDetails?.idtype ? props?.panDetails?.idtype : "");
    let dob = props?.panDetails?.dob;

    // if(dob==""){
    //   setEdtdob(dob)
    // }else if(dob=="NA"){
    //   setEdtdob("NA")
    // }

    if(dob.includes("/")){
      var newdate = await dob?.split("/")?.reverse()?.join("-");
    }else if(dob.includes(".")){
      var newdate = await dob?.split(".")?.reverse()?.join("-");
    }else if(dob.includes("-")){
      var newdate = await dob?.split("-")?.reverse()?.join("-");
    }
      // if(newdate!=undefined)
      setDob(newdate);
      setDefname(props?.panDetails?.name)
      setDeffname(props?.panDetails?.fname)
      setDefdob(newdate)
      setDefpannumber(props?.panDetails?.pannumber)

      // const ksdoe = props?.panDetails?.ref2?.split(": ")[1]
      // if(ksdoe?.includes("/")){
      //   var doe = await ksdoe?.split("/")?.reverse()?.join("-");
      // }else if(ksdoe?.includes(".")){
      //   var doe = await ksdoe?.split(".")?.reverse()?.join("-");
      // }else if(ksdoe?.includes("-")){
      //   var doe = await ksdoe?.split("-")?.reverse()?.join("-");
      // }
      // setKinadateofexpiry(doe)

  }, [props?.panDetails]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
        switch (name) {
      case "edtname":
        setEdtname(value);
        setDefname(value)
        break;
      case "edtfname":
        setEdtfname(value);
        setDeffname(value)
        break;
      case "edtdob":
        setEdtdob(value);
        setDefdob(value)
        break;
      case "edtpannumber":
        setEdtpannumber(value);
        setDefpannumber(value)
        break;
      case "edtgender":
        setEdtgender(value);
        break;
      case "edtcountry":
        setEdtcountry(value);
        break;
      case "edtdateofexpiry":
        setEdtDateofexpiry(value);

        break;
      case "ref3":
        setRef3(value);
        break;
      case "ref4":
        setRef4(value);
        break;
      case "ref5":
        setRef5(value);
        break;
      case "ref6":
        setRef6(value);
        break;
      case "ref7":
        setRef7(value);
        break;
      case "ref8":
        setRef8(value);
        break;
      default:
        break;
    }
  };

  const submitEditPanEdtails = () => {
    if (!iskinabank) {
        props.savePanDetails(
          defname,
          defdob,
          defpannumber,
          deffname,
          edtname,
          edtfname,
          edtdob,
          edtpannumber
        );
    }else{
      props?.saveKinaIDDetails(
        edtname,
        edtfname,
        edtdob,
        edtpannumber,
        edtgender,
        edtdateofexpiry,
        edtcountry,
        ref3,
        ref4,
        ref5,
        ref6,
        ref7,
        ref8
      );
    }
    // else {
    //   toast("please enter all input fields")
    // }
  };

  const navigate = () => {
    if (props?.panDetails && !isClientTideAccess) {
      if(props?.KINABANK){
      props.navigate(RouteNames.KINA_SELECT_OVD_PROCESS);

      }else{
      props.navigate(RouteNames.SELECT_PAN_KYC_PROCESS);

      }
    } else if (isClientTideAccess) {
      props.navigate(RouteNames.PAN_CAPTURE);
    } else {
      return null;
    }
  };

  return (
    <>
      {isClientTideAccess ? (
        <Tideheader text={<Text tid="pan_details"/>} navigate={navigate} />
      ) : (
        <Header
          title={iskinabank?<Text tid="ovd_verifcation"/>:<Text tid="pan_verification" />}
          navigate={navigate}
          hideHeader={false}
        />
      )}

      <article className="app-body">
        <PanDetailsEditCmp
          isClientTideAccess={isClientTideAccess}
          iskinabank={iskinabank}
          kinadateofexpiry={kinadateofexpiry}
          ref3={ref3}
          ref4={ref4}
          ref5={ref5}
          ref6={ref6}
          ref7={ref7}
          ref8={ref8}
          photo={photo}
          idtype={idtype}
          panDetails={props?.panDetails}
          dob={dob}
          dateDisplay={props?.dateDisplay}
          handleChange={handleChange}
          submitEditPanEdtails={submitEditPanEdtails}
          defname={defname}
          defdob={defdob}
          defpannumber={defpannumber}
          deffname={deffname}
        />
      </article>
    </>
  );
};

export default PanDetailsEdit;
