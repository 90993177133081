const gu = {
    select_language: "ભાષા પસંદ કરો",
    // instruction:"અમે ભલામણ કરીએ છીએ કે તમે તમારો વીડિયો કૉલ ચાલુ રાખવા માટે નીચે સૂચિબદ્ધ <b> ભાષા</b> પસંદ કરો",
    instruction: "અમે ભલામણ કરીએ છીએ કે તમે નીચે સૂચિબદ્ધ એક પસંદ કરો ",
    language: "ભાષા ",
    continue: "તમારા વિડિયો કૉલ ચાલુ રાખવા માટે ",

    // =================================
    //        Commence your video KYC
    // =================================
    verify_your_identity: "તમારી ઓળખ ચકાસો",
    handy_documents: "તમે તમારી સંપૂર્ણ KYC પ્રક્રિયા સાથે આગળ વધો તે પહેલાં કૃપા કરીને તમારા નીચેના દસ્તાવેજો હાથમાં રાખો.",
    aadhaar_verify: "આધાર ચકાસણી",
    pan_capture: "PAN કેપ્ચર",
    video_call: "વીડિયો કૉલ (પ્રશ્ન અને જવાબ)",


    // =================================
    //              Modal
    // =================================

    user_consent: "વપરાશકર્તા સંમતિ",
    user_instruction: "પર ક્લિક કરીને  ",
    user_agree: "'સંમત', ",
    user_here: "તમે આથી:",
    // user_instruction:"<b>'સંમત'</b> પર ક્લિક કરીને, તમે આ દ્વારા:",
    // user_instruction1:"વ્યક્તિગત વિગતો પ્રદાન કરવા માટે સિન્ટાઇઝન ટેક્નોલોજી પ્રાઇવેટ લિમિટેડ દ્વારા કરવામાં આવેલી વિનંતીને સ્વીકારો.",
    // user_instruction2:"ઇન્ફોર્મેશન શેર કરીને બધી માહિતીને ઍક્સેસ કરવા, કૉપિ કરવા અને સ્ટોર કરવા માટે મારી બિનશરતી સાંદ્રતા પ્રદાન કરો.",
    // user_instruction3:"હું/અમે વિનંતી કરનાર સંસ્થા વતી આમ કરવા માટે અધિકૃત છીએ અને તેના માટે એકમાત્ર અને સંપૂર્ણ જવાબદારી પણ સ્વીકારીએ છીએ.",
    user_instruction1: "બેંકર સાથેનું તમારું વિડિઓ ક્રિયાપ્રતિક્રિયા સત્ર રેકોર્ડિંગ મોડમાં હશે.",
    user_instruction2: "બેન્કર સાથે વિડિઓ ઇન્ટરેક્શન સત્ર દરમિયાન એક લાઇવ ફોટોગ્રાફ મેળવવામાં આવશે.",
    user_instruction3: "તમારી આધાર વિગતો વી-સીઆઇપી પ્રક્રિયામાં આધાર ચકાસણી માટે ઉપયોગમાં લેવામાં આવશે.",
    user_instruction4: "વી-સીઆઇપી પ્રક્રિયામાં પાન ચકાસણી કરવા માટે તમારા પાનકાર્ડનો ફોટોગ્રાફ એકત્રિત કરવામાં આવશે.",
    user_instruction5: "તમારું લાઇવ સ્થાન વી-સીઆઇપી પ્રક્રિયામાં કેદ કરવામાં આવશે.",
    user_instruction6: "તમારે ખાતરી કરવી જોઈએ કે વિડિઓ ઇન્ટરેક્શન સત્ર દરમિયાન બધી વિગતો યોગ્ય છે.",
    user_instruction7: "આધાર XML પેકેટ અથવા આધાર સુરક્ષિત ક્યૂઆર કોડ 3 દિવસથી વધુનો ન હોવો જોઈએ.",
    user_instruciton8: "ઉપરોક્ત તમામ પગલાં આરબીઆઈની માર્ગદર્શિકા મુજબ છે.",
    // content_cdtn1: "હું, વી-સીઆઇપી આઈડી",
    // content_cdtn2: " ધારક ઉપરોક્ત તમામ મુદ્દાઓ સાથે સંમત થાય છે અને આ દ્વારા, મારી સંમતિની પુષ્ટિ કરો.",
    accept_cdtn1: "હું, ",
    accept_cdtn2: " VCIP ID સાથે - ",
    accept_cdtn3: " ઉપરોક્ત તમામ મુદ્દાઓ સાથે સંમત છું અને આથી, મારી સંમતિની પુષ્ટિ કરો.",
    // disagree: "અસંમત",
    agree: "સંમત",
    // =================================
    //              Token Number
    // =================================



    token_number: "તમારો ટોકન નંબર શૂન્ય થાય ત્યાં સુધી રાહ જોવાની વિનંતી.",
    wait_time: "તમારો અંદાજિત પ્રતીક્ષા સમય",
    wait_patinently: "અમારા બધા એજન્ટો આ ક્ષણે વ્યસ્ત હોવાથી, અમે તમને ધીરજપૂર્વક રાહ જોવા અને થોડા સમય પછી પાછા આવવાની ભલામણ કરીએ છીએ",


    // =================================
    //              Headers
    // =================================


    initiating_call: "વીડિયો કૉલ શરૂ કરી રહ્યાં છીએ",
    choose_language: "એક ભાષા પસંદ કરો",
    commence_video: "તમારો વીડિયો KYC શરૂ કરો",
    aadhaar_xml: "આધાર XML",
    kyc_details: "KYC વિગતો",
    aadhaar_offline: "આધાર ઑફલાઇન કેવાયસી",
    download_sucess: "ડાઉનલોડ સફળતાપૂર્વક",
    pan_verification: "PAN ચકાસણી",
    Keep_things_handy: "શું તમે અમારા એજન્ટ સાથે વીડિયો કૉલ માટે તૈયાર છો?",
    title_token: "ટોકન નંબર",
    reschedule: "ફરીથી શેડ્યૂલ કરો",
    status: "સ્થિતિ",


    // =================================
    //              PAN
    // =================================


    Pan_heading: "પાન કાર્ડનો ફોટો કેપ્ચર કરો",
    capture_photo: "ફોટો કેપ્ચર કરો",
    retake: "ફરી લો",
    submit: "સબમિટ કરો",
    // =================================
    //              PAN Details
    // =================================



    ocr_info: "OCR માહિતી",
    name: "નામ",
    father_name: "પિતાનું નામ",
    dob: "DOB",
    pan_number: "પાન નંબર",
    please_wait: "મહેરબાની કરી રાહ જુવો...",
    fetching_aadhar: "આધાર વિગતો મેળવી રહ્યાં છીએ...!",
    uid: "UID",
    gender: "લિંગ",
    Address: "સરનામું",
    xml_file: "શું તમારી પાસે આધાર XML ફાઇલ છે?",
    yes: "હા",
    no: "ના",


    waiting_agent: "એજન્ટની રાહ જુએ છે",
    end_call: "કૉલ સમાપ્ત કરો",
    book_slot: "સ્લોટ બુક કરો",
    id_number:"ID Number",


    // =================================
    //              Kyc completed 
    // =================================


    kyc_update: "KYC સબમિટ કર્યું.",
    verification: "તમારી ચકાસણી પૂર્ણ થઈ ગઈ છે અને તમારો તમામ ડેટા સુરક્ષિત રીતે સંગ્રહિત છે.",

       // ===================================
    //          Employement Details
    // ===================================

    occupation_details:"વ્યવસાયની વિગતો",
    occupation_details_subtext:" તમારા વિકલ્પમાંથી એક પસંદ કરો"




}

export default gu;