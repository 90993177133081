const pu = {
    select_language: "ਭਾਸ਼ਾ ਚੁਣੋ",
    instruction: "ਅਸੀਂ ਤੁਹਾਨੂੰ ਹੇਠਾਂ ਸੂਚੀਬੱਧ ਇੱਕ ਚੁਣਨ ਦੀ ਸਿਫ਼ਾਰਿਸ਼ ਕਰਦੇ ਹਾਂ ",
    language: "ਭਾਸ਼ਾ ",
    continue: "ਤੁਹਾਡੀ ਵੀਡੀਓ ਕਾਲ ਜਾਰੀ ਰੱਖਣ ਲਈ",
    // instruction:"ਅਸੀਂ ਸਿਫ਼ਾਰਿਸ਼ ਕਰਦੇ ਹਾਂ ਕਿ ਤੁਸੀਂ ਆਪਣੀ ਵੀਡੀਓ ਕਾਲ ਜਾਰੀ ਰੱਖਣ ਲਈ ਹੇਠਾਂ ਦਿੱਤੀ <b> ਭਾਸ਼ਾ</b> ਦੀ ਚੋਣ ਕਰੋ",

    // =================================
    //        Commence your video KYC
    // =================================
    verify_your_identity: "ਆਪਣੀ ਪਛਾਣ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋs",
    handy_documents: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਪੂਰੀ ਕੇਵਾਈਸੀ ਪ੍ਰਕਿਰਿਆ ਨਾਲ ਅੱਗੇ ਵਧਣ ਤੋਂ ਪਹਿਲਾਂ ਆਪਣੇ ਹੇਠਾਂ ਦਿੱਤੇ ਦਸਤਾਵੇਜ਼ਾਂ ਨੂੰ ਹੱਥ ਵਿੱਚ ਰੱਖੋ।",
    aadhaar_verify: "ਆਧਾਰ ਵੈਰੀਫਿਕੇਸ਼ਨ",
    pan_capture: "ਪੈਨ ਕੈਪਚਰ",
    video_call: "ਵੀਡੀਓ ਕਾਲ (ਸਵਾਲ ਅਤੇ ਜਵਾਬ)",

    // =================================
    //              Modal
    // =================================

    user_consent: "ਉਪਭੋਗਤਾ ਦੀ ਸਹਿਮਤੀ",
    user_instruction: "'ਤੇ ਕਲਿੱਕ ਕਰਕੇ ",
    user_agree: "'ਸਹਿਮਤ ਹੋ', ",
    user_here: "ਤੁਹਾਨੂੰ ਇਸ ਦੁਆਰਾ:",
    // user_instruction:"<b>'ਸਹਿਮਤ'</b> 'ਤੇ ਕਲਿੱਕ ਕਰਕੇ, ਤੁਸੀਂ ਇਸ ਦੁਆਰਾ:",
    // user_instruction1: "ਨਿੱਜੀ ਵੇਰਵੇ ਪ੍ਰਦਾਨ ਕਰਨ ਲਈ Syntizen ਤਕਨਾਲੋਜੀ ਪ੍ਰਾਈਵੇਟ ਲਿਮਟਿਡ ਦੁਆਰਾ ਕੀਤੀ ਗਈ ਬੇਨਤੀ ਨੂੰ ਸਵੀਕਾਰ ਕਰੋ।",
    // user_instruction2: "ਇਨੋਫਰਮੇਸ਼ਨ ਨੂੰ ਸਾਂਝਾ ਕਰਕੇ ਸਾਰੀ ਜਾਣਕਾਰੀ ਤੱਕ ਪਹੁੰਚ, ਕਾਪੀ ਅਤੇ ਸਟੋਰ ਕਰਨ ਲਈ ਮੇਰੀ ਬਿਨਾਂ ਸ਼ਰਤ ਇਕਾਗਰਤਾ ਪ੍ਰਦਾਨ ਕਰੋ।",
    // user_instruction3: "ਇਸ ਦੇ ਨਾਲ ਹੀ ਮੈਂ/ਸਾਨੂੰ ਬੇਨਤੀਕਰਤਾ ਸੰਸਥਾ ਦੀ ਤਰਫੋਂ ਅਜਿਹਾ ਕਰਨ ਲਈ ਅਧਿਕਾਰਤ ਹਾਂ ਅਤੇ ਇਸਦੇ ਲਈ ਇਕੱਲੇ ਅਤੇ ਪੂਰੀ ਜ਼ਿੰਮੇਵਾਰੀ tkae ਹੈ।",
    user_instruction1: "ਸ਼ਾਹੂਕਾਰ ਨਾਲ ਤੁਹਾਡਾ ਵੀਡੀਓ ਪ੍ਰਤਿਕ੍ਰਿਆ ਸੈਸ਼ਨ ਰਿਕਾਰਡਿੰਗ ਮੋਡ ਵਿੱਚ ਹੋਵੇਗਾ.",
    user_instruction2: "ਇੱਕ ਸਿੱਧਾ ਫੋਟੋਗ੍ਰਾਫ਼ ਬੈਂਕਰ ਨਾਲ ਵੀਡੀਓ ਸੰਵਾਦ ਸੈਸ਼ਨ ਦੌਰਾਨ ਕੈਪਚਰ ਕੀਤਾ ਜਾਵੇਗਾ.",
    user_instruction3: "ਤੁਹਾਡੇ ਆਧਾਰ ਵੇਰਵੇ ਦੀ ਵਰਤੋਂ ਵੀ-ਸੀਆਈਪੀ ਪ੍ਰਕਿਰਿਆ ਵਿਚ ਆਧਾਰ ਤਸਦੀਕ ਕਰਨ ਲਈ ਕੀਤੀ ਜਾਏਗੀ.",
    user_instruction4: "ਤੁਹਾਡੇ ਪੈਨ ਕਾਰਡ ਦੀ ਇੱਕ ਤਸਵੀਰ ਵੀ-ਸੀਆਈਪੀ ਪ੍ਰਕਿਰਿਆ ਵਿੱਚ ਪੈਨ ਤਸਦੀਕ ਕਰਨ ਲਈ ਇਕੱਠੀ ਕੀਤੀ ਜਾਏਗੀ.",
    user_instruction5: "ਤੁਹਾਡੀ ਲਾਈਵ ਸਥਿਤੀ ਨੂੰ ਵੀ-ਸੀਆਈਪੀ ਪ੍ਰਕਿਰਿਆ ਵਿੱਚ ਕੈਪਚਰ ਕਰ ਲਿਆ ਜਾਵੇਗਾ.",
    user_instruction6: "ਤੁਹਾਨੂੰ ਇਹ ਯਕੀਨੀ ਬਣਾਉਣਾ ਚਾਹੀਦਾ ਹੈ ਕਿ ਵੀਡੀਓ ਇੰਟਰੈਕਸ਼ਨਿੰਗ ਸੈਸ਼ਨ ਦੌਰਾਨ ਸਾਰੇ ਵੇਰਵੇ ਸਹੀ ਹਨ.",
    user_instruction7: "ਆਧਾਰ ਐਕਸਐਮਐਲ ਪੈਕੇਟ ਜਾਂ ਅਧਾਰ ਸੁਰੱਖਿਅਤ ਕਿ Qਆਰ ਕੋਡ 3 ਦਿਨਾਂ ਤੋਂ ਵੱਡਾ ਨਹੀਂ ਹੋਣਾ ਚਾਹੀਦਾ.",
    user_instruciton8: "ਉਪਰੋਕਤ ਸਾਰੇ ਕਦਮ RBI ਦੇ ਦਿਸ਼ਾ-ਨਿਰਦੇਸ਼ਾਂ ਅਨੁਸਾਰ ਹਨ।",
    // content_cdtn1: "ਮੈਂ, ਵੀ-ਸੀਆਈਪੀ ਆਈਡੀ",
    // content_cdtn2: "ਧਾਰਕ ਉੱਪਰ ਦੱਸੇ ਸਾਰੇ ਬਿੰਦੂਆਂ ਨਾਲ ਸਹਿਮਤ ਹੈ ਅਤੇ ਇਸ ਤਰ੍ਹਾਂ, ਮੇਰੀ ਸਹਿਮਤੀ ਦੀ ਪੁਸ਼ਟੀ ਕਰਦਾ ਹੈ.",
    accept_cdtn1: "ਮੈਂ, ",
    accept_cdtn2: " VCIP ID ਨਾਲ - ",
    accept_cdtn3: " ਉਪਰੋਕਤ ਸਾਰੇ ਬਿੰਦੂਆਂ ਨਾਲ ਸਹਿਮਤ ਹਾਂ ਅਤੇ ਇਸ ਦੁਆਰਾ, ਮੇਰੀ ਸਹਿਮਤੀ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ।",
    // disagree: "ਅਸਹਿਮਤ",
    agree: "ਸਹਿਮਤ ਹੋ",


    // =================================
    //              Token Number
    // =================================


    token_number: "ਤੁਹਾਨੂੰ ਬੇਨਤੀ ਹੈ ਕਿ ਤੁਹਾਡਾ ਟੋਕਨ ਨੰਬਰ ਜ਼ੀਰੋ ਹੋਣ ਤੱਕ ਉਡੀਕ ਕਰੋ।",
    wait_time: "ਤੁਹਾਡਾ ਅਨੁਮਾਨਿਤ ਉਡੀਕ ਸਮਾਂ",
    wait_patinently: "ਕਿਉਂਕਿ ਸਾਡੇ ਸਾਰੇ ਏਜੰਟ ਇਸ ਸਮੇਂ ਰੁੱਝੇ ਹੋਏ ਹਨ, ਅਸੀਂ ਤੁਹਾਨੂੰ ਧੀਰਜ ਨਾਲ ਉਡੀਕ ਕਰਨ ਅਤੇ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਵਾਪਸ ਆਉਣ ਦੀ ਸਿਫਾਰਸ਼ ਕਰਦੇ ਹਾਂ",
    // =================================
    //              Headers
    // =================================


    initiating_call: "ਇੱਕ ਵੀਡੀਓ ਕਾਲ ਸ਼ੁਰੂ ਕੀਤੀ ਜਾ ਰਹੀ ਹੈ",
    choose_language: "ਕੋਈ ਭਾਸ਼ਾ ਚੁਣੋ",
    commence_video: "ਆਪਣਾ ਵੀਡੀਓ ਕੇਵਾਈਸੀ ਸ਼ੁਰੂ ਕਰੋ",
    aadhaar_xml: "ਆਧਾਰ XML",
    kyc_details: "KYC ਵੇਰਵੇ",
    aadhaar_offline: "ਆਧਾਰ ਔਫਲਾਈਨ ਕੇ.ਵਾਈ.ਸੀ",
    download_sucess: "ਸਫਲਤਾਪੂਰਵਕ ਡਾਊਨਲੋਡ ਕਰੋ",
    pan_verification: "ਪੈਨ ਪੁਸ਼ਟੀਕਰਨ",
    Keep_things_handy: "ਕੀ ਤੁਸੀਂ ਸਾਡੇ ਏਜੰਟ ਨਾਲ ਵੀਡੀਓ ਕਾਲ ਲਈ ਤਿਆਰ ਹੋ?",
    title_token: "ਟੋਕਨ ਨੰਬਰ",
    reschedule: "ਮੁੜ ਤਹਿ ਕਰੋ",
    status: "ਸਥਿਤੀ",


    // =================================
    //              PAN
    // =================================


    Pan_heading: "ਪੈਨ ਕਾਰਡ ਦੀ ਫੋਟੋ ਕੈਪਚਰ ਕਰੋ",
    capture_photo: "ਫੋਟੋ ਕੈਪਚਰ ਕਰੋ",
    retake: "ਦੁਬਾਰਾ ਲਓ",
    submit: "ਜਮ੍ਹਾਂ ਕਰੋ",


    // =================================
    //              PAN Details
    // =================================



    ocr_info: "OCR ਜਾਣਕਾਰੀ",
    name: "பெயர்",
    father_name: "தந்தையின் பெயர்",
    dob: "DOB",
    pan_number: "பான் எண்",
    please_wait: "ਕ੍ਰਿਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ...",
    fetching_aadhar: "ਆਧਾਰ ਵੇਰਵਿਆਂ ਨੂੰ ਪ੍ਰਾਪਤ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...!",
    uid: "UID",
    gender: "ਲਿੰਗ",
    Address: "ਪਤਾ",
    xml_file: "ਕੀ ਤੁਹਾਡੇ ਕੋਲ ਆਧਾਰ XML ਫਾਈਲ ਹੈ?",
    yes: "ਹਾਂ",
    no: "ਨੰ",




    waiting_agent: "ਏਜੰਟ ਦੀ ਉਡੀਕ ਕੀਤੀ ਜਾ ਰਹੀ ਹੈ",
    end_call: "ਕਾਲ ਸਮਾਪਤ ਕਰੋ",
    book_slot: "ਇੱਕ ਸਲਾਟ ਬੁੱਕ ਕਰੋ",
    id_number:"ID Number",



    // =================================
    //              Kyc completed 
    // =================================


    kyc_update: "ਕੇ.ਵਾਈ.ਸੀ.",
    verification: "ਤੁਹਾਡੀ ਤਸਦੀਕ ਪੂਰੀ ਹੋ ਗਈ ਹੈ ਅਤੇ ਤੁਹਾਡਾ ਸਾਰਾ ਡਾਟਾ ਸੁਰੱਖਿਅਤ ਰੂਪ ਨਾਲ ਸਟੋਰ ਕੀਤਾ ਗਿਆ ਹੈ।",

    // ===================================
    //          Employement Details
    // ===================================

    occupation_details:"ਕਿੱਤੇ ਦੇ ਵੇਰਵੇ",
    occupation_details_subtext:"ਆਪਣੇ ਵਿਕਲਪਾਂ ਵਿੱਚੋਂ ਇੱਕ ਚੁਣੋ"



}

export default pu;