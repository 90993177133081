import React, { useEffect, useState } from "react";
// import Button from "../../../Components/Elements/Button";
import Button from "../../../Components/Elements/Button";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../../Components/Loading/Loading";
import { create100msLiveRoomCodeSagaAction, create100msLiveRoomSagaAction, disableRoom100msLiveSagaAction } from "../../../Store/SagaActions/VideoSagaActions";
import { selectPeers, useHMSStore, selectLocalPeer, selectRemotePeers, useHMSActions, selectBroadcastMessages, selectHMSMessages, selectTrackByID, selectPeerAudioByID, selectSpeakers, useHMSNotifications, HMSNotificationTypes, useScreenShare, useDevices, DeviceType } from "@100mslive/react-sdk";
import { Text } from "../../Language/Language";
// import "../VideoCall/InitiatingVideoCall/video.css";
import CleintNames from "../../../Constants/ClientNames";

export default function MicvideocheckModal100msLive(props) {
    const LocalPeer = useHMSStore(selectLocalPeer);
    const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
    const [progressStatus, setProgressStatus] = useState("");
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [cameraAccessError, setCameraAccessError] = useState("");
    const [isAudioOn, setIsAudioOn] = useState(false);
    const [isVideoEnabled, setisVideoEnabled] = useState(false);
    const [audiolevel, setAudioLevel] = useState(0)
    const [networkloader, setNetworkloader] = useState(false);
    const [isAudioVideoStateCompleted, setIsAudioVideoStateCompleted] =
        useState(false);

    const [hitCounter, setHitCounter] = useState(1);
    const hmsActions = useHMSActions();

    const dispatch = useDispatch();

    const clientName = useSelector((state) => state.HomeReducer.clientName);

    const isakudo = clientName === CleintNames?.AKUDO ? true : false;

    const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
    const { streamingType, streamingURL, enableStreamLoadBalance,HundredMsTemplateId} = dynamicSettingObj;
    const isEnableStreamLoadBalalnce = JSON.parse(enableStreamLoadBalance)

    useEffect(() => {
        createPreviewRoom()
        return async () => {
            hmsActions.leave()
        };
    }, []);


    //create room
    const createPreviewRoom = () => {
        const sessionvcipid = sessionStorage.getItem("InitiateVCFQStorage");
        const parsevcipid = JSON.parse(sessionvcipid)
        const vcipid = parsevcipid?.videoconfsessionid
        const model = {
            "name": `prev_customer_${vcipid}`,
            "description": "preview room for customer",
            "template_id": HundredMsTemplateId
        }
        dispatch(create100msLiveRoomSagaAction({
            model,
            callback: (res) => get100msliveCreatedRoomData(res),
        }))
    }

    const get100msliveCreatedRoomData = (data) => {
        setProgressStatus("Room Created Successfully");
        setProgressPercentage((prev) => prev + 25);
        if (data?.enabled == true) {
            createRooomCode(data)
        }
    }

    //create roomcode
    const createRooomCode = (data) => {
        sessionStorage.setItem("roomidfor100mslive",data?.id)
        const model = {
            roomid: data?.id,
            role: "customer"
        }
        dispatch(create100msLiveRoomCodeSagaAction({
            model,
            callback: (res) => get100msLiveRoomCodedata(res)
        }))
    }

    const get100msLiveRoomCodedata = (data) => {
        setProgressStatus("Room Code Created Succeefully");
        setProgressPercentage((prev) => prev + 25);
        if (data?.enabled == true) {
            previewJoin(data?.code)
        }
    }

    //Join Preview
    const previewJoin = async (roomCode) => {
        setProgressStatus("Meeting Joined Succesfully");
        setProgressPercentage((prev) => prev + 25);
        const authToken = await hmsActions.getAuthTokenByRoomCode({ roomCode:roomCode });
        const sessionvcipid = sessionStorage.getItem("InitiateVCFQStorage");
        const parsevcipid = JSON.parse(sessionvcipid)
        const vcipid = parsevcipid?.videoconfsessionid
        const config = {
            userName: `customer_${vcipid}`,
            authToken: authToken,
            settings: {
                // initial states
                isAudioMuted: false,
                isVideoMuted: false
            },
            rememberDeviceSelection: true, // remember manual device change
            captureNetworkQualityInPreview: true // whether to measure network score in preview
        };
        await hmsActions.preview(config);

    }

    useEffect(() => {
        const videoElement = document.getElementById("us")
        if (LocalPeer?.videoTrack != undefined && videoElement ) {
            setProgressStatus("video Processing Succesfull");
            setProgressPercentage((prev) => prev + 25);
            setIsAudioVideoStateCompleted(true)
            setisVideoEnabled(true)
            setIsAudioOn(true)
            hmsActions.attachVideo(LocalPeer?.videoTrack, videoElement);
        }
    }, [LocalPeer])

    useEffect(() => {
        if (hitCounter == 2) {
            network();
            setHitCounter(1);
        }
    }, [hitCounter]);

    let networkspeed;
    const network = () => {
        // var imageUrl = "https://images.pexels.com/photos/20787/pexels-photo.jpg?"
        console.warn("*** checking network speed");
        // const imageUrl = "https://syntizen.com/91.png";
        const imgPath = `${window?.location?.origin?.toString()}/images/91.png`;
        const imageUrl = imgPath || "https://syntizen.com/91.png";
        let blob;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", imageUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
            blob = xhr.response;
            // console.warn("blob", blob.size);
        };
        xhr.send();
        // var downloadSize = 3285366; //bytes
        var downloadSize = 91122; //bytes
        async function ShowProgressMessage(msg) {
            if (console) {
                if (typeof msg == "string") {
                    // console.warn(msg);
                    setNetworkloader(false);
                    // setError(msg)
                } else {
                    // console.warn("msg is",msg.length)
                    for (var i = 0; i < 1; i++) {
                        // console.warn("networkspeed", msg[i], "mbps");
                        console.warn("*** network speed is ", msg[i], "mbps");

                        networkspeed = msg[i];
                        setNetworkloader(false);
                        // console.warn("speed is",networkspeed)
                        if (isakudo == false && networkspeed > 0.080) {
                            // navigate(RouteNames.TOKEN_NUMBER);
                            hmsActions.leave()
                            if (
                                sessionStorage.getItem("tokenEpireTime") ||
                                props?.reschedule
                            ) {
                                props?.joinVideoSessionreq();
                                hmsActions.leave()
                            } else {
                                console.warn("*** Token expired ");
                                closeModal();
                                hmsActions.leave()
                                props?.handleTokenExpire();
                            }
                        } else if (isakudo == true && networkspeed > 0.2) {
                            hmsActions.leave()
                            if (
                                sessionStorage.getItem("tokenEpireTime") ||
                                props?.reschedule
                            ) {
                                props?.joinVideoSessionreq();
                                hmsActions.leave()
                            } else {
                                console.warn("*** Token expired ");
                                closeModal();
                                hmsActions.leave()
                                props?.handleTokenExpire();
                            }
                        } else if (hitCounter <= 1) {
                            setHitCounter(2);
                            console.warn("*** Poor interner connection");

                            // toast("checking network again");
                        } else {
                            console.warn("*** Poor interner connection");

                            toast(
                                <Text tid="poor_internet_connection_detected_Switch_to_other_network" />
                            );
                        }
                    }
                }
            }
        }
        function InitiateSpeedDetection() {
            ShowProgressMessage("Loading the image, please wait...");
            // console.warn("hfgfrfgfh")
            setNetworkloader(true);
            window.setTimeout(MeasureConnectionSpeed, 1);
        }

        if (window.addEventListener) {
            // console.warn('window');
            window.addEventListener("load", InitiateSpeedDetection(), false);
        } else if (window.attachEvent) {
            // console.warn("window2")
            window.attachEvent("onload", InitiateSpeedDetection());
        }

        function MeasureConnectionSpeed() {
            var startTime, endTime;
            var download = new Image();
            download.onload = function () {
                endTime = new Date().getTime();
                showResults();
            };
            download.onerror = function (err, msg) {
                ShowProgressMessage("Invalid image, or error downloading");
            };
            startTime = new Date().getTime();
            var cacheBuster = "?nnn=" + startTime;
            // console.warn(cacheBuster)
            download.src = imageUrl + cacheBuster;
            // console.warn(download)

            function showResults() {
                var duration = (endTime - startTime) / 1000;
                var bitsLoaded = downloadSize * 8;
                var speedBps = (bitsLoaded / duration).toFixed(2);
                var speedKbps = (speedBps / 1024).toFixed(2);
                var speedMbps = (speedKbps / 1024).toFixed(2);
                // ShowProgressMessage([
                //     // "Your connection speed is:",
                //     // speedBps + " bps",
                //     // speedKbps + " kbps",
                //     speedMbps
                // ]);
                ShowProgressMessage([
                    // "Your connection speed is:",
                    // speedBps + " bps",
                    // speedKbps + " kbps",
                    speedMbps,
                ]);
            }
        }
    };

    const endMeeting = async (isClosedByCustomer) => {
        if (isClosedByCustomer) {
            if (sessionStorage.getItem("tokenEpireTime") || props?.reschedule) {
                const model = {
                    "enabled": false
                }
                const disableroomid ={
                    roomid : sessionStorage.getItem("roomidfor100mslive")
                }
                dispatch(disableRoom100msLiveSagaAction({model:model,disableroomid:disableroomid,callback:(res)=>disabledPreviewRoom(res)}))
                network();
            } else {
                hmsActions.leave()
                props?.setIsopen(false);
                props?.handleTokenExpire();
            }
        } else {
            hmsActions.leave()
            props?.setIsopen(false);
        }
    };

    const disabledPreviewRoom = (res)=>{
        // console.log("disablepreviewroom",res)
    }

    const callback = (peerAudioLevel) => {
        colorPids(peerAudioLevel);
    };
    const peerAudioLevel = useHMSStore(selectPeerAudioByID(LocalPeer?.id));
    callback(peerAudioLevel)

    const movetovideopage = () => {
        endMeeting(true);
    };

    function colorPids(vol) {
        const allPids = [...document.querySelectorAll(".pid")];
        const numberOfPidsToColor = Math.round(vol / 10);
        const pidsToColor = allPids.slice(0, numberOfPidsToColor);
        for (const pid of allPids) {
            pid.style.backgroundColor = "#e6e7e8";
        }
        for (const pid of pidsToColor) {
            pid.style.backgroundColor = "#69ce2b";
        }
    }

    const errornotification = useHMSNotifications(HMSNotificationTypes.ERROR);
    useEffect(() => {
        if(errornotification?.data?.code == "3003"){
            setCameraAccessError("Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)")
        }
    }, [errornotification])

    const closeModal = () => {
        // if (isAudioVideoStateCompleted) {
        //     endMeeting(false);

        // } else {
        //     toast("Audio Video status not completed. Please wait!");
        // }
        endMeeting(false);

    };

    const reload = () => {
        window.location.reload();
    };

    const handleDevicesChanged = (a, c, d) => {
        console.warn("_____________________a, c, d", a, c, d);
    };

    return (
        <>
            <div className="darkBG">
                <div className="centered">
                    <div className="imgcancel" onClick={closeModal}>
                        <img src="/images/Vector.jpg" />
                    </div>

                    <div>
                        <div className="mainheading">
                            <Text tid="audio_video_check" />
                        </div>
                        {!isVideoEnabled && cameraAccessError && (
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <button
                                    className="btn m-2 w-auto"
                                    style={{
                                        color: cssProperties?.button?.text_color,
                                        fontSize: cssProperties?.button?.font_size,
                                        background: props?.isClientTideAccess
                                            ? "#1929D6"
                                            : cssProperties?.button?.color,
                                    }}
                                    onClick={reload}
                                >
                                    <Text tid="refresh" />
                                </button>
                            </div>
                        )}
                        <p className="subheading">
                            <Text tid="please_check_if_your_camera_and_mic_is_working_and_proceed" />
                        </p>
                        <div
                            className="videopreview"
                            style={{
                                marginTop: props?.isClientTideAccess && "24px",
                                color: props?.isClientTideAccess && "#2F3037",
                            }}
                        >
                            <Text tid="video_preview" />
                        </div>
                        {/* Progress Bar */}
                        <div className="video-preview-progress-status text-danger">
                            {cameraAccessError}
                        </div>
                        {progressPercentage < 100 && (
                            <div className="progress my-1">
                                <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    aria-label="Animated striped example"
                                    // aria-valuenow={`${videoPreviewProgress.code * 20}%`}
                                    aria-valuenow={`${progressPercentage}%`}
                                    aria-valuemin={0}
                                    aria-valuemax={`${100}`}
                                    style={{
                                        width: `${progressPercentage}%`,
                                        backgroundColor: cssProperties?.button?.color,
                                    }}
                                />
                            </div>
                        )}
                        {progressPercentage < 100 ? (
                            <div className="video-preview-progress-status text-success mb-1">
                                {progressStatus}
                            </div>
                        ) : null}

                        <div
                            className={`videomain d-flex flex-column align-items-center justify-content-center`}
                        >
                            <div className="recordingsymbol">
                                <div className="elipse1">
                                    <div className="elipse2"></div>
                                </div>
                                <p className="videorunningtext">Video Running</p>
                            </div>
                            <video id="us" style={{transform:"rotateY(180deg)"}} autoPlay muted playsInline></video>
                            {
                                isVideoEnabled == false && (<Loading />)
                            }
                            <div className="voice-test-icon d-flex align-items-center">
                                <img
                                    src="/images/icon-voice.png"
                                    className="me-1"
                                    width={10}
                                    height={20}
                                />
                                <div className="pids-wrapper" style={{ left: 20 }}>
                                    <p className="pid mb-0"></p>
                                    <p className="pid mb-0"></p>
                                    <p className="pid mb-0"></p>
                                    <p className="pid mb-0"></p>
                                    <p className="pid mb-0"></p>
                                    <p className="pid mb-0"></p>
                                    <p className="pid mb-0"></p>
                                    <p className="pid mb-0"></p>
                                    <p className="pid mb-0"></p>
                                    <p className="pid mb-0"></p>
                                </div>
                            </div>
                        </div>
                        {cameraAccessError == "" && (
                            <p
                                className="subheading warning-text"
                                style={{ marginTop: props?.isClientTideAccess && "35px" }}
                            >
                                {props?.hasTokenExpired ? (
                                    <Text tid="token_has_been_expired_Please_close_and_Try_Again" />
                                ) : (
                                    <Text tid="if_the_Proceed_button_does_not_enable_in_30_seconds_Please_close_the_popup_and_join_the_call_again" />
                                )}
                            </p>
                        )}
                        <p
                            className="subheading"
                            style={{ fontSize: "12px", fontWeight: "600" }}
                        >
                            <Text tid="this_video_cannot_be_seen_by_anyone_except_you" />
                        </p>

                        {isAudioOn && isVideoEnabled && !props?.hasTokenExpired ? (
                            <Button
                                className={
                                    props?.isClientTideAccess ? "tidevideobtn" : "videobtn"
                                }
                                type="button"
                                // opacity={disabledstate == false ? null : "25%"}
                                color={cssProperties?.button?.text_color}
                                fontSize={cssProperties?.button?.font_size}
                                backgroundColor={cssProperties?.button?.color}
                                isClientTideAccess={props?.isClientTideAccess}
                                click={movetovideopage}
                                title={<Text tid="agree" />}
                            />
                        ) :
                            cameraAccessError ||
                            props?.hasTokenExpired ? (
                                <Button
                                    className={
                                        props?.isClientTideAccess ? "tidevideobtn" : "videobtn"
                                    }
                                    type="button"
                                    color={cssProperties?.button?.text_color}
                                    fontSize={cssProperties?.button?.font_size}
                                    backgroundColor={cssProperties?.button?.color}
                                    isClientTideAccess={props?.isClientTideAccess}
                                    title={"Close"}
                                    click={closeModal}
                                />
                            ) : (
                                <Button
                                    className={
                                        props?.isClientTideAccess ? "tidevideobtn" : "videobtn"
                                    }
                                    type="button"
                                    opacity={"25%"}
                                    color={cssProperties?.button?.text_color}
                                    fontSize={cssProperties?.button?.font_size}
                                    backgroundColor={cssProperties?.button?.color}
                                    isClientTideAccess={props?.isClientTideAccess}
                                    title={"Processing..."}
                                />
                            )}
                    </div>
                </div>
            </div>
        </>
    );
}
