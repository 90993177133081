import React from "react";

const P = (props) => {
  return (
    <p
      className={props?.className}
      style={{ color: props?.color, fontSize: props?.fontSize,fontWeight:props?.fontWeight }}
    >
      {props?.txt}
    </p>
  );
};

export default P;
