import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Text } from "../../Pages/Language/Language";
import H4 from "../Elements/H4";
import P from "../Elements/P";

const DeclarationCmp = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  const newDate = new Date();
  const date = moment(newDate).format("DD/MM/YYYY");

  return (
    <>
      <section className="app-start">
        <div className="app-body-img mb-0">
          <img src="../images/declaration.png" alt="vkyc" />
        </div>
        <div className="app-body-data">
          {/* <H4
            className="title"
            title={"Declaration"}
            color={cssProperties?.pagetitle_heading?.font_color}
            fontSize={cssProperties?.pagetitle_heading?.font_size}
          /> */}
          {/* <P
            className="txt text-start"
            txt={props?.declationData}
            color={cssProperties?.body?.font_color}
            fontSize={cssProperties?.body?.font_size}
          /> */}

          <div className="form-check d-flex justify-content-center">
            <input
              className="form-check-input me-2"
              type="checkbox"
              checked={props?.isChecked}
              onChange={props?.handleCheckbox}
              id="adr"
              style={
                props?.isChecked
                  ? {
                      backgroundColor: cssProperties?.radio_button_color,
                      borderColor: cssProperties?.radio_button_color,
                    }
                  : null
              }
            />
            <label
              className="form-check-label text-start"
              style={{ flex: 1, color: cssProperties?.body?.font_color }}
              htmlFor="adr"
            >
              {props?.declationData}
            </label>
          </div>

          <P
            className="txt text-start"
            txt={"Date: " + date}
            color={cssProperties?.body?.font_color}
            fontSize={cssProperties?.body?.font_size}
          />

          <hr />
          {/* <P
            className="txt text-center"
            txt={"Link:"}
            color={cssProperties?.body?.font_color}
            fontSize={cssProperties?.body?.font_size}
          />
          <div className="text-center">
            <a href={""} className="" target="_blank">
              http//:www.examplesite.com/
            </a>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default DeclarationCmp;
