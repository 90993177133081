import React from 'react'
import './Tideheader.css'
export default function Tideheader({navigate,text,noarrow}) {
  return (
    <div className="headermain">
        <div className="arrowicon" onClick={navigate}>
        {
          noarrow ? "":<img src="/images/Vector(Stroke).svg" />
        }     
        </div>
        
        <div className='text'>{text}</div>
    </div>
  )
}
