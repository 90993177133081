import * as SagaActionTypes from '../SagaActions/SagaActionTypes'

const user = sessionStorage.getItem('user');
const initial = {
    vcipDetails: JSON.parse(user) || {},
    videoSessionData: {},
    speechPitchValue: null,
    openViduIssues: "",
    customerVcipStatues: {},
    streamingEventObj: {},
    CustomerDetails:"",
}

const VcipReducer = (state = initial, action) => {
    switch (action.type) {
        case SagaActionTypes.ACTION_GET_CUSTOMER_VCIP_DETAILS_RES:
            return { ...state, vcipDetails: action.payload }

        case SagaActionTypes.ACTION_GET_CUSTOMER_VCIP_STATUSES_RESP_STORE:
            return { ...state, customerVcipStatues: action.payload }
            
        case SagaActionTypes.ACTION_CREATE_VIDEO_TOKEN_RES:
            return { ...state, videoSessionData: action.payload }
                
        case SagaActionTypes.ACTION_GET_SPEECH_PITCH:
            return { ...state, speechPitchValue: action.payload }

        case SagaActionTypes.ACTION_OPENVIDU_ISSUE:
            return { ...state, openViduIssues: action.payload }
        
        case SagaActionTypes.ACTION_GET_CUSTOMER_KINA_DETAILS:
                return { ...state, CustomerDetails: action.payload }
            
        case SagaActionTypes.STREAMING_EVENT_BY_CUSTOMER:
            return { ...state, streamingEventObj: action.payload };

        default:
            return state;
    }
}

export default VcipReducer;