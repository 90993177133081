import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { languageOptions } from "../../Languages";
import { LanguageContext } from "../../Pages/Language/Language";
const InitialeLanguge = sessionStorage.getItem("lang");

const SelectLangugeFAB = (props) => {
  const languageContext = useContext(LanguageContext);
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  const [selectedValue, setSelectedValue] = useState(
    InitialeLanguge || (languageContext?.language?.id) == "hi"
      ? "हिन्दी"
      : "English"
  );

  useEffect(()=>{
    setSelectedValue(InitialeLanguge == "hi" ? "हिन्दी"
    : "English")
  },[])

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOptionSelect = (option) => {
    setSelectedValue(option.text);
    sessionStorage.setItem("lang", option.id);
    const selectedLanguage = languageOptions.find(
      (item) => item?.id === option.id
    );
    languageContext.setLanguage(selectedLanguage);
    // props.onChangeLanguageSelect(option.id);
    setShowDropdown(false);
  };

  return (
    <div
      style={{
        width: "9rem",
        position: "absolute",
        top: "30px",
        right: "10px",
        zIndex: "99",
      }}
    >
      <div className="custom-select" onClick={toggleDropdown}>
        <div className="selected-option">
          {" "}
          <img src="../images/lanDrop1.svg" width={20} height={20} />
          <span style={{ fontWeight: "600" }}>{selectedValue} </span>
          <img src="../images/lanDrop2.svg" width={12} height={12} />
        </div>
        {showDropdown && (
          <ul className="options-list">
            {languageOptions?.map((option) => (
              <li key={option} onClick={() => handleOptionSelect(option)}
              style={option.id == 'en' ? {fontSize : '17px' } : { fontWeight: '500' }}>
                {option.text}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SelectLangugeFAB;
