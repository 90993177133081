import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../Components/Elements/Button";
import { Text } from "../Language/Language";
import { useSelector } from "react-redux";
import H4 from "../../Components/Elements/H4";
import P from "../../Components/Elements/P";
import customeCodes from "../../Constants/customeCodes";

var verhoeff = require("node-verhoeff");

const AadhaarNumberModalOkyc = (props) => {
  const [aadharNumber, setAadharNumber] = useState("");
  const [aadharErr, setAadharErr] = useState("");
  const [captchaKey, setCaptchaKey] = useState("");
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const [openFailedModal, setOpenFailedModal] = useState(false)
  const data = sessionStorage.getItem('user');
  const accName = JSON.parse(data)?.acccode;


  useEffect(() => {
    if (props?.isAadharKycFailed) {
      setOpenFailedModal(true)
    }
  }, [props?.isAadharKycFailed])


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "aadharNumber" && value.length <= 12) {
      //   var regexp = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
      var regexp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
      let err = "";
      if (regexp.test(value)) {
        err = "";
      } else if (value.length == "") {
        err = "";
      } else {
        err = "Invalid Aadhaar Number";
      }
      setAadharErr(err);

      const result = value.replace(/\D/g, "");
      setAadharNumber(result);
    }
    if (name === "captchaKey") {
      setCaptchaKey(value);
    }
  };

  const submitAadhar = () => {
    if (props?.captchaDetails?.captcha && captchaKey && !props.aadharapi) {
      props.getOTPModal(captchaKey);
    } else {
      if (props.aadharapi) {
        const isValid = verhoeff.validateAadhaar(aadharNumber);
        if (isValid) {
          props.sendOTPModal(aadharNumber);
        } else {
          setAadharErr("Invalid Aadhaar Number.");
        }
      }
    }
  };

  const submitEKYCAadhar = () => {
    const isValid = verhoeff.validateAadhaar(aadharNumber);
    if (isValid) {
      props.sendOTPModal(aadharNumber);
    } else if (aadharNumber.length == 0) {
      setAadharErr("");
    } else {
      setAadharErr("Invalid Aadhaar Number.");
    }
  };

  return (
    <>
      <Header>
        <HeadText>
          <h6>verify & get</h6>
          <h4>aadhaar details</h4>
        </HeadText>
        <img
          src="../images/cancel.png"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.closeModal}
          alt="close"
          className="close"
        />
      </Header>

      {props?.feature_flags?.kyctype == customeCodes?.EKYC ?
        <ModalBody>
          <div className="modal-body">
            <div style={{ marginTop: "80px" }}>
              <div className="text-center mb-3">
                <img
                  src="../images/waiting.svg"
                  className="spin-loader"
                  alt="waiting"
                />
              </div>
              <H4
                className="title text-center"
                title={<Text tid="please_wait" />}
                color={cssProperties?.pagetitle_heading?.font_color}
                fontSize={cssProperties?.pagetitle_heading?.font_size}
              />
              <P
                className="txt text-center"
                txt={<Text tid="proceed_with_aua_kua_for_aadhar_verification" />}
                color={cssProperties?.body?.font_color}
                fontSize={cssProperties?.body?.font_size}
              />
              {openFailedModal &&
                <p className="text-danger fw-bold text-center">Transaction failed , Please try again</p>
              }
              <Button
                type="submit"
                title={<Text tid="agree" />}
                className="btn"
                // disabled={aadharErr || aadharNumber.length < 12}
                click={submitAadhar}
                color={cssProperties?.button?.text_color}
                fontSize={cssProperties?.button?.font_size}
                backgroundColor={cssProperties?.button?.color}
              />
            </div>
          </div>
        </ModalBody>
        :
        <>
          <AAdhaarImg>
            <img src="../images/popup-aadhaar.png" />
          </AAdhaarImg>
          <ModalBody>
            <div className="modal-body">
              <h6 className="h6" style={{ fontWeight: "bold" }}>
                Enter Aadhaar Number
              </h6>

              <div className="frm-grp mb-4">
                {props?.isCaptchaTrue ? (
                  <input
                    type="number"
                    style={{ opacity: 0.5 }}
                    value={aadharNumber}
                    placeholder="Aadhaar Number"
                    className="frm-grp-inp"
                  />
                ) : (
                  <input
                    type="number"
                    name="aadharNumber"
                    value={aadharNumber}
                    onChange={handleChange}
                    className="frm-grp-inp"
                    placeholder="Aadhaar Number"
                    required
                  />
                )}
                {aadharErr && (
                  <p className="error position-absolute text-danger m-0 small">
                    {aadharErr}
                  </p>
                )}
              </div>
              {props.isCaptchaTrue ? (
                <>
                  {props?.isCaptchaTrue && (
                    <h6 className="h6" style={{ fontWeight: "bold" }}>
                      Enter Security Code
                    </h6>
                  )}
                  <CapthaDiv>
                    <div>
                      <div className="frm-grp">
                        {props?.isCaptchaTrue ? (
                          <input
                            type="text"
                            name="captchaKey"
                            onChange={handleChange}
                            className="frm-grp-inp"
                            placeholder="Enter Security Code*"
                          />
                        ) : null}
                      </div>
                    </div>
                    <CapthaRef>
                      <Capthas
                        style={{
                          opacity: props?.isCaptchaTrue ? 1 : 0.5,
                        }}
                      >
                        <img
                          src={
                            props?.isCaptchaTrue
                              ? "data:image/png;base64," +
                              props?.captchaDetails?.captcha
                              : "../images/captcha.jpeg"
                          }
                          className=""
                          alt="captcha"
                        />
                      </Capthas>
                      <div onClick={props.sendRefreshOTPModal}>
                        <i className="fa-sharp fa-solid fa-arrows-rotate"></i>
                      </div>
                    </CapthaRef>
                  </CapthaDiv>
                </>
              ) : null}
              <OkycButtonDiv>
                {props?.isEKYCProccess ? (
                  <Button
                    type="submit"
                    className="btn w-auto"
                    disabled={aadharErr || aadharNumber.length < 12}
                    click={submitEKYCAadhar}
                    title={<Text tid="send_otp" />}
                    color={cssProperties?.button?.text_color}
                    fontSize={cssProperties?.button?.font_size}
                    backgroundColor={cssProperties?.button?.color}
                  />
                ) : (
                  <>
                    {props.aadharapi ? (
                      <Button
                        type="submit"
                        title={<Text tid="submit" />}
                        className="btn w-auto"
                        disabled={aadharErr || aadharNumber.length < 12}
                        click={submitAadhar}
                        color={cssProperties?.button?.text_color}
                        fontSize={cssProperties?.button?.font_size}
                        backgroundColor={cssProperties?.button?.color}
                      />
                    ) : (
                      <Button
                        type="submit"
                        className="w-auto btn"
                        title={<Text tid="send_otp" />}
                        disabled={captchaKey.length < 5}
                        click={submitAadhar}
                        color={cssProperties?.button?.text_color}
                        fontSize={cssProperties?.button?.font_size}
                        backgroundColor={cssProperties?.button?.color}
                      />
                    )}
                  </>
                )}
              </OkycButtonDiv>
            </div>
          </ModalBody>
          </>
          }
    </>
  );
};

export default AadhaarNumberModalOkyc;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 22px 22px;
  padding: 0.5rem 0;
  position: relative;
  img {
    position: absolute;
    right: 2rem;
    top: 1.8rem;
  }
`;
const HeadText = styled.div`
  text-align: center;
  h6 {
    text-transform: uppercase;
    font-weight: 400;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 0.8rem;
  }
  h4 {
    text-transform: capitalize;
    font-weight: 900;
    color: #000;
    font-size: 1.1rem;
  }
`;
const AAdhaarImg = styled.div`
  margin: 1rem auto;
`;
const ModalBody = styled.div`
  input:focus {
    background-color: #fff;
    outline: 0;
    border: none;
    box-shadow: 0px 0px 4px #d17165;
    border-radius: 0.5rem;
  }
`;
const OkycButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const OkycButton = styled.button`
  /* color: #fff; */
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.3rem;
  /* background: #cf2128; */
`;

const CapthaDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  .frm-grp {
    margin-bottom: 0;
  }
`;
const CapthaRef = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  img {
    height: 2.3rem;
    padding: 0.2rem;
  }
`;
const Capthas = styled.div`
  padding: 0.2rem;
  background: #dcdcdc;
  border-radius: 0.3rem;
`;
