import React, { Component } from 'react';
import PropTypes from "prop-types";

class OpenViduVideoComponent extends Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidUpdate(props) {
        if (props && !!this.videoRef) {
            this.props.streamManager.addVideoElement(this.videoRef.current);
        }
    }

    componentDidMount() {
        if (this.props && !!this.videoRef) {
            this.props.streamManager.addVideoElement(this.videoRef.current);
            if (this.props.streamManager?.videos?.length > 0 && this.props.setIsMainStreamAvailable) {
                this.props.setIsMainStreamAvailable(true);
                this.props.setvideoPreviewProgress({ status: "Fetching Video Data", code: 3 });
                this.props.setDisabledstate(true);
            }
            if (this.props.streamManager?.videos?.length === 0 && this.props.setIsMainStreamAvailable) {
                this.props.setIsMainStreamAvailable(false);
            }

            // this.videoRef.current.addEventListener('loadedmetadata', () => {
            //     if (this.videoRef.current.readyState >= 2 &&
            //         this.props.streamManager?.videos?.length > 0 &&
            //         this.props.setIsMainStreamAvailable) {
            //         this.videoRef.current.play();
            //         this.props.setvideoPreviewProgress({ status: "Connected", code: 5 });
            //         // this.props.setDisabledstate(this.videoRef.current.readyState >= 4 ? false : true);
            //     } else {
            //         // this.props.setDisabledstate(true);
            //         this.props.setvideoPreviewProgress({ status: "Connecting Video...", code: 4 });
            //     }

            // });

            this.videoRef?.current?.addEventListener('loadeddata', () => {
                console.log("video load data state  ", this?.videoRef?.current?.readyState);
                if (this.videoRef?.current?.readyState >= 2 &&
                    this.props.streamManager?.videos?.length > 0 &&
                    this.props.setIsMainStreamAvailable) {
                    this.videoRef.current?.play();
                    this.props.setvideoPreviewProgress({ status: "Connected", code: 5 });
                    this.props.setDisabledstate(this.videoRef?.current?.readyState >= 4 ? false : true);
                } else {
                    this.props.setDisabledstate(true);
                    this.props.setvideoPreviewProgress({ status: "Connecting Video...", code: 4 });
                }

            });
        }
    }

    render() {
        return <video className={this.props.togvid}  autoPlay={true} ref={this.videoRef} playsInline webkit-playsinline={true} />;
    }

}

OpenViduVideoComponent.propTypes = {
    setDisabledstate: PropTypes.func,
    setvideoPreviewProgress: PropTypes.func
};

OpenViduVideoComponent.defaultProps = {
    setDisabledstate: () => {},
    setvideoPreviewProgress: () => {}
};

export default OpenViduVideoComponent;
