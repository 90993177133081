import React, { useState } from 'react'
import "./cosmosEkyc.css"
import { Text } from '../../Language/Language'
import Button from '../../../Components/Elements/Button'


var verhoeff = require("node-verhoeff");
const CosmosEKYC = ({ getOtpforCosmos, aadharErr, setAadharErr, aadharNumber, setAadharNumber, showEnterOtp, enterOtpforCosmos, setIsChecked, isChecked, otpCosmos, setOtpCosmos }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "aadharNumber" && value.length <= 12) {
            //   var regexp = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
            var regexp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
            let err = "";
            if (regexp.test(value)) {
                err = "";
            } else if (value.length == "") {
                err = "";
            } else {
                err = "Invalid Aadhaar Number";
            }
            setAadharErr(err);

            const result = value.replace(/\D/g, "");
            setAadharNumber(result);
        }
        if (name === "otp" && value?.length <= 6) {
            setOtpCosmos(value)
        }
    };

    const handleKeyDown = (e) => {
        const { name } = e.target;
        const charCode = e.keyCode || e.which;
        if (name === "otp" && (charCode < 48 || charCode > 57) && charCode !== 8 && charCode !== 46) {
            e.preventDefault();
        }
    };

    const handleCheckBox = (e) => {
        setIsChecked(e.target.checked)
    }

    const submitEKYCAadhar = () => {
        const isValid = verhoeff.validateAadhaar(aadharNumber);
        if (isValid) {
            getOtpforCosmos(aadharNumber);
        } else if (aadharNumber.length == 0) {
            setAadharErr("");
        } else {
            setAadharErr("Invalid Aadhaar Number.");
        }
    };
    return (
        <>
            <div style={{
                margin: "1rem 0"
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0px 0px 10px 0px"
                }}>
                    <img src='/images/cosmosLogo.svg' alt='logo' />
                </div>
                <div>
                    <p style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#2D3090",
                        letterSpacing: "0.1px",
                        margin: "0",
                        textAlign: "center"
                    }}>
                        Video KYC
                    </p>
                    <hr className='cos_hr' />
                </div>
                <div style={{
                    margin: "1.2rem 0.8rem 0.5rem 0.8rem",
                    boxShadow: "0px 0px 10px 0px #0000001A"
                }}>
                    <div style={{
                        height: "35px",
                        background: "#38ACE2"
                    }}>
                    </div>
                    <div className='cos_inputs'>
                        <div className="input-container">
                            <input
                                type="text"
                                name='aadharNumber'
                                value={aadharNumber}
                                onChange={handleChange}
                                required
                                disabled={showEnterOtp}
                                style={{ color: showEnterOtp ? 'gray' : 'inherit' }}

                            />
                            {showEnterOtp ? "" : <label for="input" className="cos_label">Aadhaar Number*</label>}
                            <div className="underline"></div>
                            <label className="cos_input-label">Please enter your 12 digit Aadhar number</label>
                        </div>
                        {
                            showEnterOtp ?
                                <div className="input-container">
                                    <input type="text"
                                        id="input"
                                        name='otp'
                                        value={otpCosmos}
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        maxLength="6"
                                        required
                                    // disabled={showEnterOtp}
                                    />
                                    <label for="input" className="cos_label">Enter OTP</label>
                                    <div className="underline"></div>
                                    <label className="cos_input-label">This field is required</label>
                                </div>
                                :
                                <Button
                                    type="submit"
                                    className={aadharNumber.length < 12 ? "opacity-25 cos_getOtp_btn" : "cos_getOtp_btn"}
                                    title={<Text tid="get_otp" />}
                                    disabled={aadharErr || aadharNumber.length < 12}
                                    click={submitEKYCAadhar}
                                // color={cssProperties?.button?.text_color}
                                // fontSize={cssProperties?.button?.font_size}
                                // backgroundColor={cssProperties?.button?.color}
                                />
                        }
                        <div className="input-container">
                            <label className='cos_select-label'>Consent Language</label>
                            <select className='cos_select'>
                                <option className="cos_option" value="en">English</option>
                            </select>
                        </div>
                        <div className='cos_check_main'>
                            <input type='checkbox' className='cos_checkBox' onChange={handleCheckBox} />
                            <div className='d-flex justify-content-center align-content-center flex-column'>
                                <p className='cos_checkbox_content mb-1'>
                                    <Text tid="cosmos_aadhaar_check_content_one" />
                                </p>
                                <p className='cos_checkbox_content'>
                                    <Text tid="cosmos_aadhaar_check_content_two" />
                                </p>
                            </div>
                        </div>
                        <Button
                            type="submit"
                            className={otpCosmos?.length < 6 || !isChecked ? "opacity-25 cos_submit_btn" : "cos_submit_btn"}
                            title={<Text tid="submit" />}
                            disabled={!isChecked || otpCosmos?.length < 6}
                            click={enterOtpforCosmos}
                        // color={cssProperties?.button?.text_color}
                        // fontSize={cssProperties?.button?.font_size}
                        // backgroundColor={cssProperties?.button?.color}
                        />
                    </div>
                </div>
                <p className='cos_disclaimer mb-2 d-flex flex-column fw-normal'>
                    <span className='fw-bolder'>Disclaimer:</span>
                    The applicationcan be best viewed in browser viz. IE Edge, Google Chrome, Mozilla Firefox 38.0+
                </p>
            </div>
        </>
    )
}

export default CosmosEKYC