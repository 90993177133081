import toast from "react-hot-toast";
import { put, takeLatest, call, delay } from "redux-saga/effects";
import Axios from "axios";
import base64 from "base-64";
import { ACTION_100MS_START_RECORDING, ACTION_100MS_STOP_RECORDING, ACTION_CREATE_100MSLIVE_ROOM, ACTION_CREATE_MANAGEMENT_TOKEN, ACTION_CREATE_ROOM_CODE, ACTION_DISABLE_ROOM } from "../SagaActions/SagaActionTypes";
import axios from "axios";

// CREATE ROOM
const create100msLiveRoomReq = (model) => {
  const URL = "https://api.100ms.live/" + "v2/rooms";
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${sessionStorage.getItem("100msLiveToken")}`
    },
  };
  return Axios.post(URL, model, headers).then((res) => {
    return res?.data;
  });
};

function* HundredMsLIveCreateRoomReqSaga(action) {
  try {
    const resp = yield call(create100msLiveRoomReq, action?.payload?.model);
    if (resp?.enabled == true) {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      action?.payload?.callback("");
    }
  } catch (err) {
    if (err.response) {
      action?.payload?.callback("");
      toast(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
      action?.payload?.callback("");
    }
  }
}

// CREATE ROOM CODE
const create100msLiveRoomCodeReq = (model) => {
    const URL = `https://api.100ms.live/v2/room-codes/room/${model?.roomid}/role/${model?.role}`;  
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${sessionStorage.getItem("100msLiveToken")}`
      },
    };
    return Axios.post(URL,model,headers).then((res) => {
      return res?.data;
    });
  };
  
  function* HundredMsLIveCreateRoomCodeReqSaga(action) {
    try {
      const resp = yield call(create100msLiveRoomCodeReq, action?.payload?.model);
      if (resp?.enabled == true) {
        if (action?.payload?.callback) {
          action?.payload?.callback(resp);
        }
      } else {
        action?.payload?.callback("");
      }
    } catch (err) {
      if (err.response) {
        action?.payload?.callback("");
        toast(
          err?.response?.data?.errors?.length &&
            err?.response?.data?.errors[0]?.message
        );
      } else {
        toast(err.message);
        action?.payload?.callback("");
      }
    }
}

  // DISABLE ROOM
const disable100msLiveRoomReq = (model,disableroomid) => {
  const URL = `https://api.100ms.live/v2/rooms/${disableroomid?.roomid}`
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${sessionStorage.getItem("100msLiveToken")}`
    },
  };
  return Axios.post(URL, model, headers).then((res) => {
    return res?.data;
  });
};

function* HundredMsLIveDisableRoomReqSaga(action) {
  try {
    const resp = yield call(disable100msLiveRoomReq, action?.payload?.model,action?.payload?.disableroomid);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
  } catch (err) {
    if (err.response) {
      action?.payload?.callback("");
      toast(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
      action?.payload?.callback("");
    }
  }
}

// create ManagementToken
const createManagementTokenReq = (model) => {
  const URL = model?.streamingURL +"v2/100ms/managementToken"
  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return Axios.post(URL, model, headers).then((res) => {
    return res?.data;
  });
};

function* HundredMsCreateManagementTokenReqSaga(action) {
  try {
    const resp = yield call(createManagementTokenReq, action?.payload?.model);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
  } catch (err) {
    if (err.response) {
      action?.payload?.callback("");
      toast(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
      action?.payload?.callback("");
    }
  }
}

// START RECORDING
const startRecordingReq = (model) => {
  const URL = model?.streamingURL +"startrecording";
  const headers = {
      headers: {
          "Content-Type": "application/json",
      },
  };
  return Axios.post(URL, model, headers).then((res) => {
      return res?.data;
  });
};

function* HundredMsLiveStartRecordingReqSaga(action) {
  try {
      const resp = yield call(startRecordingReq, action?.payload?.model);
      if (action?.payload?.callback) {
          action?.payload?.callback(resp);
      }
  } catch (err) {
      if (err.response) {
          action?.payload?.callback("");
          toast(
              err?.response?.data?.errors?.length &&
              err?.response?.data?.errors[0]?.message
          );
      } else {
          toast(err.message);
          action?.payload?.callback("");
      }
  }
}

// STOP RECORDING
const stopRecordingReq = (model) => {
  const URL = model?.streamingURL +"stoprecording";
  const headers = {
      headers: {
          "Content-Type": "application/json",
      },
  };
  return Axios.post(URL, model, headers).then((res) => {
      return res?.data;
  });
};

function* HundredMsLiveStopRecordingReqSaga(action) {
  try {
      const resp = yield call(stopRecordingReq, action?.payload?.model);
      if (action?.payload?.callback) {
          action?.payload?.callback(resp);
      }
  } catch (err) {
      if (err.response) {
          action?.payload?.callback("");
          toast(
              err?.response?.data?.errors?.length &&
              err?.response?.data?.errors[0]?.message
          );
      } else {
          toast(err.message);
          action?.payload?.callback("");
      }
  }
}

export default function* HundredMsLiveWatcherSaga() {
  yield takeLatest(ACTION_CREATE_100MSLIVE_ROOM, HundredMsLIveCreateRoomReqSaga);
  yield takeLatest(ACTION_CREATE_ROOM_CODE, HundredMsLIveCreateRoomCodeReqSaga);
  yield takeLatest(ACTION_DISABLE_ROOM, HundredMsLIveDisableRoomReqSaga);
  yield takeLatest(ACTION_CREATE_MANAGEMENT_TOKEN, HundredMsCreateManagementTokenReqSaga);
  yield takeLatest(ACTION_100MS_START_RECORDING, HundredMsLiveStartRecordingReqSaga);
  yield takeLatest(ACTION_100MS_STOP_RECORDING, HundredMsLiveStopRecordingReqSaga);
}