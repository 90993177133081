import toast from 'react-hot-toast';
import { put, takeLatest, call } from 'redux-saga/effects';
import StatusCodes from '../../Constants/StatusCodes';
import Axios from '../../Service/axios';
import { actionReqResStatusLoaderSagaAction } from '../SagaActions/CommonSagaActions';
import { ACTION_GET_PAN_CAPTURE_REQ, ACTION_GET_PAN_DECLARATION_REQ, ACTION_GET_PAN_SAVEDETAILS_REQ, ACTION_GET_PAN_VERIFY_REQ, ACTION_POST_PAN_DECLARATIONSUBMIT_REQ } from '../SagaActions/SagaActionTypes';

// PAN CAPTURE
const getPanCaptureReq = (model) => {
    const URL = "PanOCR";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* gePanCaptureReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(getPanCaptureReq, action?.payload?.model);
        if (resp && resp?.respcode === "200") {
            if (resp?.panstatus === StatusCodes.PANSTATUS && action?.payload?.callback) {
                toast.success(resp?.respdesc);
                action?.payload?.callback(resp);
            } else {
                toast(resp?.respdesc);
                action?.payload?.callback(resp);
            }
        } else {
            toast(resp?.respdesc);
            action?.payload?.callback(resp);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// SAVE PAN DETAILS
const savePanDetailsReq = (model) => {
    const URL = "SavePanData";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* geSavePanDetailsReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(savePanDetailsReq, action?.payload?.model);
        if (resp && resp?.respcode === "200") {
            toast.success(resp?.respdesc);
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// SAVE PAN DETAILS
const verifyPanReq = (model) => {
    const URL = "VerifyPANNumber";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* geVerifyPanReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(verifyPanReq, action?.payload?.model);
        if (resp && resp?.respcode === "200") {
            toast.success(resp?.respdesc);
            if (action?.payload?.callback) {
                action?.payload?.callback(resp);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// SAVE PAN DECLARATION
const getPanDeclarationReq = () => {
    const URL = "GetVCIPDeclaration";
    const vcipkeyData = sessionStorage.getItem("vcipkey");
    const body = {
      vcipkey: vcipkeyData,
      declarationtype: "1"
    };
    return Axios.post(URL, body).then(res => { return res?.data })
}

function* getPanDeclarationReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(getPanDeclarationReq);
        if (resp && resp?.respcode === "200") {
            toast.success(resp?.respdesc);
            if (action?.payload?.callback) {
                action?.payload?.callback(resp?.vcipdeclaration);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

// SAVE PAN DECLARATION SUBMIT
const panDeclarationSubmitReq = (model) => {
    const URL = "SubmitVCIPDeclaration";
    return Axios.post(URL, model).then(res => { return res?.data })
}

function* panDeclarationSubmitReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(panDeclarationSubmitReq, action?.payload?.model);
        if (resp && resp?.respcode === "200") {
            toast.success(resp?.respdesc);
            if (action?.payload?.callback) {
                action?.payload?.callback(resp?.vcipdeclaration);
            }
        } else {
            toast(resp?.respdesc);
        }
    } catch (err) {
        if (err.response) {
            toast(err?.response?.data?.errors?.length && err?.response?.data?.errors[0]?.message);
        } else {
            toast(err.message);
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

export default function* PanWatcherSaga() {
    yield takeLatest(ACTION_GET_PAN_CAPTURE_REQ, gePanCaptureReqSaga);
    yield takeLatest(ACTION_GET_PAN_SAVEDETAILS_REQ, geSavePanDetailsReqSaga);
    yield takeLatest(ACTION_GET_PAN_VERIFY_REQ, geVerifyPanReqSaga);
    yield takeLatest(ACTION_GET_PAN_DECLARATION_REQ, getPanDeclarationReqSaga);
    yield takeLatest(ACTION_POST_PAN_DECLARATIONSUBMIT_REQ, panDeclarationSubmitReqSaga);
}