const RouteList = {
    CHOOSE_LANGAUGE: '/choose-language',
    SELECT_KYC_PROCESS: '/select-kyc-process',
    AADHAR_KYC_KYC_DIGILOCCKER: '/aadhaar-digilocker',
    AADHAR_KYC_PROCESS: '/aadhaar-kyc-process',
    AADHAR_KYC_PROCESS_UPLOAD: '/aadhaar-kyc-process/upload',
    AADHAR_EKYC_PROCESS_UPLOAD: '/aadhaar-ekyc-process/ekyc',
    AADHAR_KYC_PROCESS_DOWNLOAD: '/aadhaar-kyc-process/downloaded',
    SELECT_PAN_KYC_PROCESS: '/select-pan-kyc-process',
    PAN_KYC_PROCESS: '/pan-kyc-process',
    PAN_DECLARATION: '/pan-declaration',
    PAN_CAPTURE: '/pan-capture',
    SELECT_VIDEOCALL_LANGAUAGE: '/select-video-call-language',
    TOKEN_NUMBER: '/token-number',
    RESCHEDULE: '/reschedule',
    INITIATE_VIDEO_CALL: '/session/:id',
    KYC_PROCESS_COMPLETED: '/kyc-process-completed',
    BROWSER_CHECK : '/BrowserCheck',
    CUSTOMER_ID: '/customer-id',
    CUSTOMER_DETAILS:'/customer-details',
    KINA_SELECT_OVD_PROCESS:"/select-ovd-process",
    KINA_OVD_PROCESS:"/ovd-process",
    KINA_OVD_CAPTURE:"/ovd-capture",
    EMPLOYEMENT_DETAILS:"/employement-details",
    COSMOS_EKYC_PROCESS: "/aadhaar-ekyc-process/cosmosekyc",
    BANK_DETAILS:"/bank-details"
}

const RouteNames = Object.freeze(RouteList);

export default RouteNames;