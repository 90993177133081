const en = {
  select_language: "Select Language",

  // instruction: "We recommend you select a below listed ",
  // language: "Language ",
  // continue: "to continue with your video call",
  instruction: "We recommend you to select a ",
  language: "Language ",
  continue: "listed below to continue with your video call",

  // =================================
  //        Commence your video KYC
  // =================================
  verify_your_identity: "Verify your identity",
  twelve_digit_aadhaar: "12 digit Aadhaar",
  sixteen_digin_aadhar: "16 digit VID",
  // handy_documents: "Please keep the following documents handy before you proceed with your full KYC process.",
  handy_documents:
    "Please keep the following documents ready before you proceed with your KYC process.",
  aadhaar_verify: "Aadhaar Verification",
  pan_capture: "PAN Verification",
  video_call: "Video Call (Q&A)",
  aadhaar: "Aadhaar",
  aadhaar_number: "Enter Aadhaar Number",
  enter_your_12_digit_Aadhaar_number_to_begin:
    "Enter your 12 digit Aadhaar number to begin",
  refresh: "Refresh",
  type_the_character_you_see_in_the_picture:
    "Type the character you see in the picture",
  send_otp: "Send OTP",
  get_captcha: "Get Captcha",
  enter_your: "Enter your",
  number_to_begin: "number to begin",
  get_otp: "Get OTP",
  submit_otp: "Submit OTP",
  verification_code: "Verification Code",
  Please_enter_the_verification_code_we_sent_to_your_phone_number:
    "Please enter the verification code we sent to your phone number",
  enter_share_code: "Enter Share Code",
  create_a_4_digit_code_to_secure_your_offline_eKYC:
    "Create a 4 digit code to secure your offline eKYC",
  checking_network_please_wait: "Checking Network Please Wait...",
  location_enabled: "Location enabled",
  token_has_been_expired_Please_close_and_Try_Again:
    "Token has been expired, Please close and Try Again",
  if_the_Proceed_button_does_not_enable_in_30_seconds_Please_close_the_popup_and_join_the_call_again:
    "If the Proceed button does not enable in 30 seconds. Please close the pop-up and join the call again.",
  // =================================
  //              Modal
  // =================================

  user_consent: "User Consent",
  request: "IP Change Request",
  ip_threat_detected: "IP Threat Detected",
  close: "Close",
  user_instruction: "By clicking on ",
  user_agree: "'Agree', ",
  user_here: "you hereby:",
  // user_instruction:"By clicking on <b>'Agree'</b>, you hereby:",
  // user_instruction1: "Acknowledge the request made by Syntizen technologies private limited to provide personal details.",
  // user_instruction2: "Provide my unconditional concent to access, copy and store all information by sharing the inofrmation.",
  // user_instruction3: " Also undertake I/We are authorised to do so on behalf of the requestee organisation and tkae sole and complete responsibilitity for the same.",
  user_instruction1:
    "Your Video interaction session with the VKYC Agent will be in the recording mode.",
  user_instruction2:
    "A live Photograph will be captured during the Video interaction session with the VKYC Agent.",
  user_instruction3:
    "Your Aadhaar details will be used for Aadhaar verification in the V-CIP process.",
  user_instruction4:
    "A photograph of Your PAN Card will be collected to perform PAN verification in the V-CIP process.",
  user_instruction5:
    "Your Live Location will be Captured in the V-CIP process.",
  user_instruction6:
    "You should ensure all the details are correct during the Video interaction Session.",
  user_instruction7:
    "The Aadhaar XML packet or Aadhaar secure QR code should not be older than 3 days.",
  user_instruciton8: "All the above-mentioned steps are as per RBI guidelines.",
  // check_box_agree: "I, V-CIP ID, holder agrees with all the above-mentioned points and hereby, confirm my consent.",
  // content_cdtn1: "I, V-CIP ID ",
  // content_cdtn2: " holder agrees with all the above-mentioned points and hereby, confirm my consent.",
  accept_cdtn1: "I, ",
  accept_cdtn2: " with the VCIP ID - ",
  accept_cdtn3:
    " agrees with all the above-mentioned points and hereby, confirm my consent.",
  agree: "Proceed",
  audio_video_check: "Audio / Video Check",
  please_check_if_your_camera_and_mic_is_working_and_proceed:
    "Please check if your camera and mic is working and proceed.",
  video_preview: "Video Preview",
  please_close_other_Apps_Tabs_sessions_that_are_accessing_the_Camera_and_Microphone_currently_and_Hit_below_refresh_button:
    "Please close other Apps/Tabs/Sessions that are accessing the Camera and Microphone currently and Hit below refresh button.",
  toggle_your_video_call_screen_with_agent_screen_for_photo_capture:
    "Toggle your video call screen with agent screen, for photo capture",
  page_not_found: "Page Not Found",
  We_are_verifying_your_identity: "We’re verifying your identity",
  identity_verification_process: "Identity Verification Process",
  start_your_video_KYC: "Start your video KYC",
  commence_your_digital_kyc: "Commence your Digital KYC",
  cancel: "Cancel",
  your_business_details: "Your Business Details",
  // disagree: "Disagree",
  // agree: "Agree",

  // =================================
  //              Token Number
  // =================================

  token_number: "Request you to wait till your token number is zero.",
  wait_time: "Your estimated wait time",
  wait_patinently:
    " As all our agents are busy at this moment, we recommend you to wait patiently and come back after some time.",
  your_token_expires_in: "Your Token Expires in",

  // =================================
  //              Headers
  // =================================

  initiating_call: "Initiating a video call",
  choose_language: "Choose a language",
  commence_video: "Commence your video KYC",
  aadhaar_xml: "Aadhaar XML",
  kyc_details: "KYC Details",
  aadhaar_offline: "Aadhaar Offline KYC",
  aadhaar_ekyc: "Aadhaar EKYC",
  download_sucess: "Download Successfully",
  pan_verification: "PAN Verification",
  Keep_things_handy: "Are you ready for the video call with our agent?",
  title_token: "Token Number",
  reschedule: "Reschedule",
  status: "Status",
  // =================================
  //              PAN
  // =================================

  Pan_heading: "Capture PAN card photo",
  capture_photo: "Proceed",
  retake: "Retake",
  submit: "Submit",
  upload_id: "Upload ID",
  upload_pan: "Upload PAN",
  please_select_below_option_to_submit_your_id_card:
    "Please select below option to submit your ID card",
  please_select_below_option_to_submit_your_pan_card:
    "Please select below option to submit your PAN card",
  capture_id: "Capture ID",
  captuer_pan: "Capture PAN",
  upload_from_gallery: "Upload from Gallery",
  date_of_birth: "Date of Birth",
  please_enter_your_name: "Please enter your name",
  please_enter_father_name: "Please enter father's name",
  please_enter_dob: "Please enter DOB",
  please_check_pan: "Please check PAN",
  please_enter_gender: "Please enter gender",
  please_select_a: "Please select a ",
  please_connect_to_another_network: "Please connect to another network.",
  as_your_ip_address_is_not_healthy: "As your IP address is not healthy.",
  date_time: "date & time ",
  to_reschedule_a_video_call_session_with_our_agent:
    "to reschedule a video call session with our agent ",
  please_switch_off_the_iCloud_relay: "Please switch off the iCloud Relay",
  if_using_a_iPhone_for_doing_the_VKYC: "if using a iPhone for doing the VKYC.",
  inspect: "Inspect",
  number: "number",
  to: " to ",

  // capture_photo: "Capture Photo",
  // retake: "Retake",
  // submit: "Submit",

  // ================================
  //          Digilocker
  // ================================

  connection_you_to_digilocker: "Connecting you to DigiLocker",
  proceed_with_digilocker_for_aadhar_verification:
    "Proceed with Digilocker for Aadhaar verification",
  proceed_with_digilocker: "Proceed with DigiLocker",
  if_you_are_unable_to_complete_aadhar_verification_with_digilocker:
    "If you are unable to complete Aadhaar verification through Digilocker",
  click_here: "Click Here",
  aadhar_kyc_details: "Aadhaar KYC Details",
  uuid: "UUID",
  proceed_with_aua_kua_for_aadhar_verification:
    "Proceed with AUA/KUA for Aadhaar verification",

  // ================================
  // Tide labels
  // ================================

  for_a_smooth_identification_process: "For a smooth identification process",
  please_have_your_Original_Pan_card_during_VKYC_call: "Please have your Original Pan card during VKYC call.",
  do_not_refresh_page_or_access_other_app:
    "Do not refresh the page or access another app.",
  ensure_your_internet_connectivity_is_stable:
    "Ensure your internet connectivity is stable.",
  do_not_let_your_mobile_enter_sleep_mode:
    "Do not let your mobile enter sleep mode.",
  please_ensure_you_have_following_in_hand:
    "Please ensure you have the following in hand",
  aadhar_id: "Aadhaar ID",
  physical_pan_card_not_e_pan_card: "Physical PAN card (not an ePAN card)",
  as_part_of_verifi_process_you_will_need_to:
    " As part of the verification process, you’ll need to",
  connect_to_one_of_our_agents_who_will_record_video_of_your_conversation:
    "Connect to one of our agents, who’ll record a video of your conversation.",
  permit_our_agent_to_capture_live_photo_during_video:
    "Permit our agent to capture a live photograph of you during the interactive video session.",
  provide_your_aadhar_details_which_will_be_used_in_video_call:
    "Provide your Aadhaar details, which will be used as part of the video identification process.",
  provide_a_pic_of_pan_card_which_will_be_used_in_video_call_process:
    "Provide a photo of your PAN card, which will be used as part of the video identification process.",
  share_your_live_locaiton_which_will_be_capture_in_video_call_process:
    "Share your live location, which will be captured as part of the video identification process.",
  ensure_all_your_details_are_correctly_submitted_in_video_call_process:
    "Ensure all your details are correctly submitted in the interactive video session.",
  ensure_your_aadhar_xml_pacaket_is_not_older_than_3_days:
    "Ensure your Aadhaar XML packet is no older than 3 days.",
  with_vcip_id: "with VCIP ID",
  agree_with_all_the_above_points_and_confirm_my_consent:
    " agree with all the above-mentioned points and hereby confirm my consent.",
  use: "Use",
  // =================================
  //              PAN-CAPTURE
  // =================================
  //  pan_capture: "PAN Verification",

  // =================================
  //              Details
  // =================================

  ocr_info: "OCR information",
  name: "Name",
  phone: "Phone",
  father_name: "Father's Name",
  id_number: "ID Number",
  dob: "DOB",
  pan_number: "PAN Number",
  please_wait: "Please wait...",
  fetching_aadhar: "Click below to start your Aadhaar Validation",
  uid: "UID",
  gender: "Gender",
  Address: "Address",
  xml_file: "Do you have an Aadhaar XML file?",
  yes: "Yes",
  no: "No",
  checking_browser_details: "Checking Browser Details",
  you_are_using: "You are using",
  email_id: "Email ID",
  // =================================
  //              Details
  // =================================

  waiting_agent: "Waiting for the Agent",
  end_call: "End Call",
  book_slot: "Book a slot",
  schedule_details: "Schedule Details",
  get_ready_for_video_KYC_call_with_our_agent:
    "Get ready for video KYC call with our agent",
  agents_unavailable: "Agents Unavailable",
  System_Maintenance:"System Maintenance",
  agents_currently_unavailable_Please_try_again_between:
    "Agents currently unavailable. Please try again between",
  or_Book_a_slot_for_your_Video_KYC: "or Book a slot for your Video KYC.",
  holiday: "Holiday",
  sorry_we_missed_you: "Sorry we missed you!",
  please_open_the_app_at_a_time_that_works_best_for_you_between:
    "Please open the app at a time that works best for you between",
  to_complete_the_sign_up: "to complete the sign up.",
  or_Book_a_new_time_slot: "or Book a new time slot.",

  //===================================
  //Greetings
  //=====================================

  hi: "Hi",

  // =================================
  //              Kyc completed
  // =================================

  kyc_update: "KYC submitted.",
  verification:
    "We will notify you once your KYC is approved at our end. All your data is stored securely.",

  // =================================
  //           Permission check
  // =================================

  need_access_to_location_camera_mic:
    "We need access to your location camera and microphone",
  unblock_camera_mic_in_settings:
    "Unblock your Camera or Microphone in Settings",
  enable_permission_in_browser_settings:
    "Enable Permissions in Browser settings & Phone Settings.",
  click_unblock_btn_below: 'Click the "unblock" button below.',
  when_prompted_click_allow: 'When prompted, click "Allow"',
  need_locaiton_cam_mic_permission_for_kyc:
    "In order to perform the KYC checks, we need to access your current location, camera and microphone.",
  camera_is_used_by_another_app:
    "Camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)",
  while_we_are_searching_for_the_agent_to_initiate_the_video_call:
    "While we are searching for the agent to initiate the video call",

  // ================================
  //      Buttons
  // ================================

  allow_access: "Allow access",
  unblock: "Unblock",

  // ================================
  //      Lables
  // ================================

  VID: "VID",
  video_kyc_chat: "VIDEO KYC CHAT",
  video_kyc: "Video KYC",
  note: "Note:",
  do_not_refresh_warning:
    "Do not refresh the page or access any other app,else,you will be logged out of the Video KYC process.",
  enable_cam_mic_location_settings:
    "Enable camera,microphone and location settings on your device.",
  scan_qr_code: "Scan QR Code",
  scar_qr_and_complete_kyc_process:
    "Please Scan this QR on your mobile and Complete the video KYC Process",
  or: "or",
  copy_link: "Copy Link",
  payroll_id: "Payroll ID",
  member_id: "Member ID",
  file_download_successfully: "File Download Successfully",
  kindly_reconfirm_your_share_code_to_access_xml_file:
    "Kindly re-confirm your share code to grant access to your XML file.",
  ovd_verification: "OVD Verification",
  i: "I",
  select_an_available_time_slot: "Select an available time Slot",
  select_time: "Select Time",
  select_available_time_slots: "Select Available Time Slots",
  video_settings: "Video Settings",
  language_settings: "Language settings",
  select_the_language_youd_like_to_chat_to_our_agent_in_on_your_upcoming_video_call:
    "Select the language you’d like to chat to our agent in your upcoming video call.",
  agents_currently_unavailable_please_try_again_in_sometime:
    "Agents currently unavailable, please try again in sometime.",
  if_you_speak: "If you Speak ",
  bengali: "Bengali ",
  please_choose: "please Choose ",
  hindi: "Hindi",
  you_can_wait_and_call_between: " You can wait a few minutes to join the call or open the app at a time that works best for you between ",
  to_try_again: " to try again.",

  you_can_wait_a_few_minutes_to_join_the_call_or_open_the_app_at_a_time:
    "You can wait a few minutes to join the call or open the app at a time that works best for you between 10 AM to 7 PM to try again.",
  // ===============================
  //          instructions
  // ===============================
  do_not_open_same_link_warning:
    "Please do not open the same link in multiple tabs.",
  ensure_internet_connectivity:
    "Please ensure your internet connectivity is stable.",
  enable_camera_mic_location_settings_on_device:
    "Enable camera, microphone and location settings on your device.",
  sleep_mode_warning: "Do not let your mobile screen go into sleep mode.",
  do_not_go_back_in_any_kyc_step:
    "Do not go back in any step of the KYC process.",
  do_not_refresh_page_or_you_will_be_logged_out_of_the_digital_kyc_process:
    "Do not refresh the page or access any other app, else, you will be logged out of the Digital KYC process.",
  do_not_refresh_page_or_you_will_be_logged_out_of_the_vkyc_process:
    "Do not refresh the page or access any other app, else, you will be logged out of the Video KYC process.",
  on: "on",
  copy_link_here: "Copy link here",
  android: "Android",
  google_chrome_V70: "Google Chrome V70+",
  iPhone: "iPhone",
  Safari_V14: "Safari V14+",
  safari_V12: "Safari V12+",
  this_browser_and_device_combination_does_not_support_video_calling:
    "this browser and device combination, does not support video calling.",
  please_copy_the_link_and_open_this_link_will_experire_in_10_minutes:
    "please copy the link and open it in any of the below browser combinations. this link will experire in 10 minutes.",
  for_android: "For Android",
  for_iPhone: "For iPhone",
  invalid_url: "Invalid URL",
  sorry_the_page_not_to_found: "Sorry the page not found",
  connecting_you_with_an_available_agent:
    "Connecting you with an available agent",
  // ===============================
  //          KYC status
  // ===============================

  kyc_processing: "KYC Processing",
  kyc_interrupted: "KYC interrupted",
  kyc_completed_and_under_review:
    "KYC process has been completed and is under review.",
  successfully_completed_kyc_process_check_after_24hrs:
    "you have successfully completed the KYC process. Please check after 24 hours.",
  kyc_under_review: "KYC under review",
  kyc_rejected: "KYC Rejected",
  kyc_completed: "KYC Completed",
  screen_has_been_reloaded: "The screen has been reloaded.",
  reinitiate_your_kyc_to_continue: "Reinitiate your KYC to continue.",
  oops_looks_like_page_is_reloaded: "Oops! Looks like the page was reloaded.",
  we_recommend_you_reinitiate_kyc: "We recommend you reinitiate your KYC.",
  oops_looks_like_video_call_was_dropped:
    "Oops! Looks like the video call was dropped.",
  unable_to_connect_with_agent_right_now:
    "We’re unable to connect you with an agent right now.",
  we_recommend_you_to_reinitiate_kyc_process:
    "We recommend you reinitiate your KYC process.",
  looks_like_agent_could_not_take_the_call:
    "Looks like agent could not take the call.",
  your_call_was_disconnected: "Your call was disconnected.",
  reinitiate_your_kyc: "Reinitiate your KYC to continue.",
  we_will_notify_once_your_kyc_is_approved_and_your_data_is_stored:
    "We’ll notify you once your KYC is approved at our end. All your data is stored securely.",
  digital_kyc_is_verified_by_agent_waiting_for_auditor:
    "Digital KYC Verified by Agent, and is Waiting for Auditor Review.",
  your_verification_is_success_and_data_stored:
    "Your verification is completed and all your data is stored securely.",
  your_kyc_got_rejected: "Your KYC got rejected.",

  // ===================================
  //          Employement Details
  // ===================================

  occupation_details: "Occupation Details",
  occupation_details_subtext: "Select one of your option",
  details: "Details",
  ovd_verifcation: "OVD Verifcation",
  pan_details: "PAN Details",
  confirm_id: "Confirm ID",
  please_crop_the_image_of_PAN_card: "Please crop the image of PAN card.",
  pan_card_image: "PAN Card image",
  rotate_image: "Rotate Image",
  please_ensure_you_upload_an_image_of_your_physical_PAN_card_ePAN_images_are_not_accepted: "Please ensure you upload an image of your physical PAN card. ePAN images are not accepted.",
  please_ensure_you_capture_an_image_of_your_physical_PAN_card_ePAN_images_are_not_accepted: "Please ensure you capture an image of your physical PAN card. ePAN images are not accepted.",
  looks_good: "Looks good",
  try_again: "Try again",
  re_upload: "Re-Upload",
  position_the_front_of_the_PAN_card_in_the_frame: "Position the front of the PAN card in the frame.",
  capture_id_card: "Capture ID Card",
  capture_pan_card: "Capture PAN Card",
  position_the_front_of_the_ID_card_in_the_frame: "Position the front of the ID card in the frame",
  next: "Next",
  cancel_schedule: "Cancel Schedule",
  join_now: "Join Now",
  generate_now: "Generate now",
  retry: "Retry",
  continue_button: "Continue",
  done: "Done",
  retry_button: "Re-try",
  Retry_KYC_button: "Re-try KYC",
  book_slot_for_later: "Book slot for later",
  confirm_PAN_proof: "Confirm PAN Proof",
  select_language_img_alt: "select language",
  im_ready: "I'm Ready",
  instructions: "Instructions:",
  start: "Start",
  get_started: "Get Started",
  digital_KYC: "Digital KYC",
  aadhaar_number_for_placeholder: "Aadhaar Number*",
  token_has_been_expired: "Token has been expired",
  unable_to_find_your_location_please_try_again: "Unable to find your location. Please try again.",
  please_try_agin: "Please Try Again",
  OCR_extraction_failed_please_try_again: "OCR extraction failed please try again.",
  As_an_agent_could_not_connect_the_call_will_be_ending_in_5_sec_Please_try_again: "As an agent could not connect, the call will be ending in 5 sec. Please try again",
  please_check_the_box: "Please check the box",
  please_enable_location: "Please enable location",
  poor_internet_connection_detected_Switch_to_other_network: "Poor internet connection detected, Switch to other network.",
  loading_the_image_please_wait: "Loading the image, please wait...",
  aadhaar_details: "Aadhaar Details",
  geolocation_is_not_supported_by_this_browser: "Geolocation is not supported by this browser.",
  please_select_check_box_and_proceed: "Please select check box and proceed",
  upload_image_proceed: "upload image & proceed",
  agent_disconnected_due_to_network_disconnect_issue: "Agent Disconnected due to network Disconnect issue!",
  please_close_other_AppsTabsSessions_that_are_accessing_the_Camera_and_Microphone_currently_and_Hit_below_refresh_button: "Please close other Apps/Tabs/Sessions that are accessing the Camera and Microphone currently and Hit below refresh button.",
  Join_Start_Video_Call: "Join/Start Video Call",
  unblock_your_location: "Unblock your Location",
  unblock_your_location_camera_or_microphone: "Unblock your Location, Camera or Microphone",
  confirm: "Confirm",
  All_our_agents_are_busy_right_now_We_request_you_to_be_with_us_for_few_mins: "All our agents are busy right now. We request you to be with us for few mins",
  this_video_cannot_be_seen_by_anyone_except_you: "This video cannot be seen by anyone except you",
  submit_ePAN: "Submit E-PAN",
  please_select_check_box_and_proceed_cosmos: "Please mark the checkbox and then click 'submit' to proceed",


  // NEW INSTRUCTIONS
  Do_not_refresh_the_page_or_access_any_other_app_else_you_will_be_logged_out_of_the_Digital_KYC_process: "Do not refresh the page or access any other app, else, you will be logged out of the Digital KYC process.",
  Do_not_go_back_in_any_step_of_the_KYC_process: "Do not go back in any step of the KYC process.",
  Do_not_let_your_mobile_screen_go_into_sleep_mode: "Do not let your mobile screen go into sleep mode.",
  Enable_camera_microphone_and_location_settings_on_your_device: "Enable camera, microphone and location settings on your device.",
  Please_ensure_your_internet_connectivity_is_stable: "Please ensure your internet connectivity is stable.",
  Please_do_not_open_the_same_link_in_multiple_tabs: "Please do not open the same link in multiple tabs.",
  Instructions: "Instructions",
  Get_Started: "Get Started",


  // cosmos EKYC

  "cosmos_aadhaar_check_content_one": "I, the holder of Aadhaar number / VID enter as above hereby give my consent to The Cosmos Co-op Bank Ltd. to obtain my Aadhaar number, Name and fingerprint / Iris for online or offline authentication with UIDAI . The Cosmos Co-op Bank Ltd. has informed me that my identity information would only be used for EKYC and that my biometrics will not be stored / shared and will be submitted to Central Identities Data Repository (CIDR) only for purpose of authentication.",
  "cosmos_aadhaar_check_content_two": "I/We hereby declare that, I/We do not have any other similar account with this Bank or any other Bank which is opened using OTP base KYC.I/We further declare that, I/We will not open such online account with this Bank or any other Bank using OTP base KYC."
}

export default en;
