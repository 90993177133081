import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Text } from "../../../Pages/Language/Language";
import H1 from "../../Elements/H1";
import H4 from "../../Elements/H4";
import P from "../../Elements/P";
import TokenTimer from '../TokenTimer/TokenTimer'
import "../clock.css";
// import moment from 'moment';
// import Countdown from 'react-countdown';

const TokenNumberCmp = (props) => {
  const [value, setvalue] = useState(0);
  const [tokentimer,setTokentimer]=useState(0)

  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const isLoading = useSelector((state) => state.HomeReducer.apiStatus);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { app_env } = dynamicSettingObj;
  // var countDownDate = moment().format('MMMM Do YYYY, h:mm:ss a');
  // const OPTIONS = { prefix: 'seconds elapsed!', delay: props.InitiateVCFQ?.tokenwaittimer };

  useEffect(() => {
    var token = JSON.parse(sessionStorage.getItem("InitiateVCFQStorage"));
    let tokenwaittimer =  token?.tokenwaittimer
    let val = 0
    const cleaerInterval = setInterval(() => {
      if (token != "undefined" && token != "-1") {
        val = val + 1;
        setvalue(val)
        setTokentimer(tokenwaittimer)
        if(tokenwaittimer == val){
          tokenwaittimer = token?.tokenwaittimer
          val = 0
        }
        if(token?.tokennumber == 0){
          clearInterval(cleaerInterval)
        }
        // if (nextPercent >= val) {
        // } else if (token == "0") {
        //   clearInterval(cleaerInterval)
        //   setvalue(token?.tokenwaittimer);
        // } else {
        //   setvalue(nextPercent, "next");
        // }
      }
    }, 1000);
    return () => clearInterval(cleaerInterval);
  },[]);
  return (
    <>
      {props?.isClientTideAccess ? (
        <>
          {props?.InitiateVCFQ?.tokennumber !== "0" && (
            <>
              <div className="tidetokennumbertitle"><Text tid="title_token"/></div>
              <div className="tidetokennumber">
                {props?.isEnableGetCutomerKey ? 0 : props?.InitiateVCFQ?.tokennumber}
              </div>
              <div className="bookaslotinfo">
                {/* <Text tid="you_can_wait_and_call_between" />
                {  props?.starttime  || ""  }
                <Text tid="to" />
                {  props?.endtime  }
                <Text tid="to_try_again" /> */}

                <Text tid="you_can_wait_a_few_minutes_to_join_the_call_or_open_the_app_at_a_time"/>
              </div>
              <div className="tideteaimage">
                <img src="/images/Tea.png" />
              </div>
              <div className="tidewaitingtxt">
                <Text tid="for_a_smooth_identification_process"/>:
              </div>
              <div className="tidewaitinginfotxt">
                <img src="/images/Vector172.svg" className="tickicon"></img>
                <div className="tidewaitinginfo">
                  <Text tid="do_not_refresh_page_or_access_other_app"/>
                </div>
              </div>
              <div className="tidewaitinginfotxt">
                <img src="/images/Vector172.svg" className="tickicon"></img>
                <div className="tidewaitinginfo">
                  <Text tid="ensure_your_internet_connectivity_is_stable"/>
                </div>
              </div>
              <div className="tidewaitinginfotxt">
                <img src="/images/Vector172.svg" className="tickicon"></img>
                <div className="tidewaitinginfo">
                  <Text tid="enable_camera_mic_location_settings_on_device"/>
                </div>
              </div>
              <div className="tidewaitinginfotxt">
                <img src="/images/Vector172.svg" className="tickicon"></img>
                <div className="tidewaitinginfos">
                  <Text tid="do_not_let_your_mobile_enter_sleep_mode"/>
                </div>
              </div>
              {app_env == "lq_uat" &&<div className="tidewaitinginfotxt">
                <img src="/images/Vector172.svg" className="tickicon"></img>
                <div className="tidewaitinginfos">
                  <Text tid="please_have_your_Original_Pan_card_during_VKYC_call"/>
                </div>
              </div>}
            </>
          )}

          {props?.InitiateVCFQ?.tokennumber == "0" && (
            <>
            {props.hasTokenExpired 
            ? 
              <Text tid="Token has been expired"/>
            : 
              <>
                <div className="tidetokennumbertitle"><Text tid="title_token"/></div>
                <div className="tidetokennumber">
                  {props?.isEnableGetCutomerKey ? 0 :props?.InitiateVCFQ?.tokennumber}
                </div>
                { props?.expireTime ? <div className="token-timer-container center">
                  <div><Text tid="your_token_expires_in"/></div>
                  <TokenTimer expireTime={props?.expireTime}/>
                </div> : null}
              </>
            }
              
              {/* <div className="bookaslotinfo">
                You can wait a few minutes, or book a new slot to find a
                suitable time that works best for you.
              </div> */}
              <div className="tideteaimage">
                <img src="/images/Tea.png" />
              </div>
              <div className="tidewaitingtxt">
                <Text tid="for_a_smooth_identification_process"/>:
              </div>
              <div className="tidewaitinginfotxt">
                <img src="/images/Vector172.svg" className="tickicon"></img>
                <div className="tidewaitinginfo">
                  <Text tid="do_not_refresh_page_or_access_other_app"/>
                </div>
              </div>
              <div className="tidewaitinginfotxt">
                <img src="/images/Vector172.svg" className="tickicon"></img>
                <div className="tidewaitinginfo">
                  <Text tid="ensure_your_internet_connectivity_is_stable"/>
                </div>
              </div>
              <div className="tidewaitinginfotxt">
                <img src="/images/Vector172.svg" className="tickicon"></img>
                <div className="tidewaitinginfo">
                  <Text tid="enable_camera_mic_location_settings_on_device"/>
                </div>
              </div>
              <div className="tidewaitinginfotxt">
                <img src="/images/Vector172.svg" className="tickicon"></img>
                <div className="tidewaitinginfos">
                  <Text tid="do_not_let_your_mobile_enter_sleep_mode"/>
                </div>
              </div>
              {app_env == "lq_uat" &&<div className="tidewaitinginfotxt">
                <img src="/images/Vector172.svg" className="tickicon"></img>
                <div className="tidewaitinginfos">
                  <Text tid="please_have_your_Original_Pan_card_during_VKYC_call"/>
                </div>
              </div>}
            </>
          )}
        </>
      ) : (
        <section className="app-start">
          {/* <h1 className="txt-color text-center f-bold">
          {props.InitiateVCFQ?.tokennumber}
        </h1> */}
          <div className="center">
            {props?.InitiateVCFQ?.tokennumber !== "0" && (
              <div
                className="clock"
                style={{ borderColor: cssProperties?.button?.color }}
              ></div>
            )}
            <H1
              className="text-center f-bold"
              title={props?.isEnableGetCutomerKey ? 0 : props?.InitiateVCFQ?.tokennumber}
              color={cssProperties?.button?.color}
            />
          </div>
          { props?.expireTime ? <div className="token-timer-container center">
            <div><Text tid="your_token_expires_in"/></div>

            <TokenTimer expireTime={props.expireTime}/>
          </div>: null}
          
          {props?.InitiateVCFQ?.tokennumber !== "0" && <P
            className="txt text-center"
            txt={<Text tid="token_number" />}
            color={cssProperties?.body?.font_color}
            fontSize={cssProperties?.body?.font_size}
          />}
          {/* <p className="txt text-center">
          <Text tid="token_number" />
        </p> */}
          <div className="my-3">
            <hr />
          </div>
          {parseInt(props.InitiateVCFQ?.tokenwaittimer) !== 0 ? (
            <>
              <H4
                className="title text-center"
                title={<Text tid="wait_time" />}
                color={cssProperties?.pagetitle_heading?.font_color}
                fontSize={cssProperties?.pagetitle_heading?.font_size}
              />
              {/* <H1
              className="text-center f-bold"
              title={parseInt(props.InitiateVCFQ?.tokenwaittimer) / 60 + " min"}
              color={cssProperties?.button?.color}
            /> */}
              <div className="progress my-3">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-label="Animated striped example"
                  aria-valuenow={`${value}%`}
                  aria-valuemin={0}
                  aria-valuemax={`${tokentimer}`}
                  style={{
                    width: `${(value/tokentimer)*100}%`,
                    backgroundColor: cssProperties?.button?.color,
                  }}
                />
              </div>
              <P
                className="txt text-center"
                txt={
                  <Text tid="All_our_agents_are_busy_right_now_We_request_you_to_be_with_us_for_few_mins"/>
                }
                color={cssProperties?.body?.font_color}
                fontSize={cssProperties?.body?.font_size}
              />
            </>
          ) : null}
        </section>
      )}
    </>
  );
};

export default TokenNumberCmp;
