import React from 'react'

const AadhaarUploadCmp = () => {
  return (
    <section className="app-start">
      {/* <div className="app-adhaar-upload">
        <input type="file" name="aadharxml" className="upload-file" />
        <div className="app-adhaar-upload-content">
          <h6 className="title title2">Upload from device</h6>
          <p className="txt">Zip file should not be more that
            3 days old</p>
        </div>
        <img src="../images/icon-upload.svg" alt="upload" />
      </div> */}
    </section>
  )
}

export default AadhaarUploadCmp