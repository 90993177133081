import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

const PortalModal = (props) => {
    const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        setIsOpen(props?.isOpen);
    }, [props?.isOpen]);

    const closeModal = () => {
        setIsOpen(false);

        // You might dispatch an action to update Redux state here if needed
    };

    const element = document.getElementById("portal-root");

    const customTag = isOpen ?
        <>
            <div className={`modal fade ${isOpen && ' show'}`}
                id="portalModal"
                tabIndex={-1}
                data-keyboard="false"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                style={{ display: 'block' }}
                aria-hidden="true">
                <div className={`${props?.isBottom ? 'custom-dialog-end' : ''} modal-dialog modal-dialog-centered`} role="document"
                    style={{
                        padding: props?.isClientTideAccess ? "0" : null,
                        margin: props?.isClientTideAccess ? "0" : null,
                        minHeight: props?.isClientTideAccess ? "100%" : null,
                        background: props?.isClientTideAccess ? "#FFE4C4" : null,
                        ...props?.modelBgStyle
                    }}
                >
                    <div className={`modal-content modal-form ${props?.modalStyle ? props?.modalStyle : ""}`} style={{
                        backgroundColor: cssProperties?.theme_bg_color,
                        minHeight: props?.isClientTideAccess ? "100%" : "100vh"
                    }}>
                        {props?.children}
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
        : (null)

    return createPortal(customTag, element);
};

export default PortalModal;
