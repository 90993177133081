import React from "react";
import { useSelector } from "react-redux";
import { Text } from "../../../Pages/Language/Language";
import Button from "../../Elements/Button";
import H4 from "../../Elements/H4";
import P from "../../Elements/P";
import "../../TideComponents/tideglobal.css";

const SelectVideoCallLanguageCmp = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  return (
    <>
      {props?.isClientTideAccess ? (
        <section className="app-start">
          <div className="tidevideocalllangheading"><Text tid="language_settings"/></div>
          <div>
            {props?.languageList?.length > 0 ? (
              <>
                <p className="tidevideocalllanginfo">
                  <Text tid="select_the_language_youd_like_to_chat_to_our_agent_in_on_your_upcoming_video_call"/>
                </p>
                {props?.languageList?.map((item, idx) => (
                  <div
                    key={idx}
                    className="tidelangopt"
                    onClick={() => props?.selectLanguage(item?.langid)}
                  >
                    {item?.langid === props?.selectedLang && (
                      <img
                        src="/images/Vector172.svg"
                        className="tidelangarrow"
                      />
                    )}
                    {item?.lang}
                  </div>
                ))}
              </>
            ) : (
              <strong className="text-center text-danger">
                <Text tid="agents_currently_unavailable_please_try_again_in_sometime"/>
              </strong>
            )}
          </div>
        </section>
      ) : (
        <section className="app-start">
          <div className="text-center mb-3">
            <img src="../images/icon-identity.svg" alt={<Text tid="select_language_img_alt"/>} />
          </div>
          <div className="my-3">
            <hr />
          </div>
          <div className="text-center mb-3">
            <img src="../images/icon-lang.svg" alt={<Text tid="select_language_img_alt"/>} />
          </div>
          {props?.languageList?.length > 0 ? (
            <>
              <H4
                className="title text-center"
                title={<Text tid="select_language" />}
                color={cssProperties?.pagetitle_heading?.font_color}
                fontSize={cssProperties?.pagetitle_heading?.font_size}
              />
              <P
                className="txt text-center"
                txt={
                  <>
                    <Text tid="instruction" />
                    <b>
                      <Text tid="language" />
                    </b>
                    <Text tid="continue" />
                  </>
                }
                color={cssProperties?.body?.font_color}
                fontSize={cssProperties?.body?.font_size}
              />
            </>
          ) : null}
          <div className="text-center">
            {props?.languageList?.length > 0 ? (
              props?.languageList?.map((item) => (
                <Button
                  className={`btn w-auto m-1 btn-white btn-white-no-opacity`}
                  type="button"
                  click={() => props.selectLanguage(item?.langid)}
                  title={item?.lang}
                  key={item?.langid}
                  color={
                    item?.langid === props?.selectedLang
                      ? cssProperties?.button?.text_color
                      : cssProperties?.body?.font_color
                  }
                  fontSize={cssProperties?.button?.font_size}
                  backgroundColor={
                    item?.langid === props?.selectedLang
                      ? cssProperties?.button?.color
                      : null
                  }
                />
              ))
            ) : (
              <strong className="text-center text-danger">
                <Text tid="agents_currently_unavailable_please_try_again_in_sometime"/>
              </strong>
            )}
            {props?.isBRANCHXAccess && (
              <p className="agentunavailableinfo">
                <Text tid="if_you_speak"/>
                <span style={{ color: "rgb(0, 13, 46)", fontWeight: "bold" }}>
                  <Text tid="bengali"/>
                </span>
                <Text tid="please_choose"/>
                <span style={{ color: "rgb(0, 13, 46)", fontWeight: "bold" }}>
                  <Text tid="hindi"/>
                </span>
              </p>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default SelectVideoCallLanguageCmp;
