import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  MediaPermissionsError,
  // MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";
import "./Layout.css";
// import Loader from "../Pages/Common/Loader";
import Loading from "../Components/Loading/Loading";
import Div from "../Components/Elements/Div";
// import H1 from "../Components/Elements/H1";
import RouteNames from "../Constants/RouteNames";
import CameraAccess from "../Components/CameraAccess/CameraAccess";
import toast from "react-hot-toast";
import CleintNames from "../Constants/ClientNames";
import NetworkLayer from "./NetworkLayer";
import { Text } from "../Pages/Language/Language";
import version from "../../package.json";
import customeCodes from "../Constants/customeCodes";

const Layout = (props) => {
  // const [title, setTitle] = useState("");
  // const [hideHeader, setHideHeader] = useState(false);
  const [browserErr1, setBrowserErr1] = useState("");
  const [browserErr2, setBrowserErr2] = useState("");
  const [isLargeDevice, setIsLargeDevice] = useState(false);
  const [networkloader, setNetworkloader] = useState(false);

  const isLoading = useSelector((state) => state.HomeReducer.apiStatus);
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const vcipDetails = useSelector((state) => state.VcipReducer.vcipDetails);
  const { feature_flags } = vcipDetails
  const navigate = useNavigate()
  let location = useLocation();

  const isClientAccess = clientName === CleintNames?.VOLOPAY ? true : false;
  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  const isCashbook = clientName === CleintNames?.CASHBOOK ? true : false

  const user = sessionStorage.getItem("user");
  const parsedUser = JSON.parse(user);
  // const isClientAccess =  (parsedUser?.acccode === "TIDEDEV" || parsedUser?.acccode === "TIDEQA") ? true : false;

  // const navigate = useNavigate()
  // window.onpopstate = () => {
  //   navigate(null);
  // }
  // let navigate = useNavigate();

  let qParams = location?.search ? location?.search?.split("=") : [];
  // const params = useParams();

  const pathname = location?.pathname;
  const showWaterMark = () =>{
    return clientName === CleintNames?.IPPB || isClientTideAccess ? false : true
  }
  // const vcipref = sessionStorage.getItem("vcipref");

  useEffect(() => {
    // if (!vcipref && !params.id) {
    //     navigate('/', { replace: true })
    // }
    if (window?.screen && window?.screen.width >= 950) {
      setIsLargeDevice(true);
    }
    // navigator.geolocation.getCurrentPosition(
    //   (position) => {},
    //   (error) => {
    //     setBrowserErr2(error);
    //   },
    //   {
    //     enableHighAccuracy: true,
    //     timeout: 5000,
    //   }
    // );

    // this.getLocalStream();
    requestMediaPermissions()
      .then(() => {
        // can successfully access camera and microphone streams
        // DO SOMETHING HERE
      })
      .catch((err) => {
        const { type, name, message } = err;
        // console.log("============type", type, name, message);
        if (type === "SystemPermissionDenied") {
          // browser does not have permission to access camera or microphone
          setBrowserErr1(
            "Browser does not have permission to access camera or microphone"
          );
        } else if (type === "UserPermissionDenied") {
          // user didn't allow app to access camera or microphone
          setBrowserErr1("Please Enable Camera/MicroPhone");
        } else if (type === "CouldNotStartVideoSource") {
          // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
          // (mostly Windows specific problem)
          setBrowserErr1(type);
        } else {
          // not all error types are handled by this library
        }
      });

    navigator?.mediaDevices?.getUserMedia(
      {
        video: true,
        audio: true,
      })
      // successCallback
      .then((localMediaStream) => {
        // localMediaStream.stop()
        let tracks = localMediaStream.getTracks()
        localMediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      })
      // errorCallback
      .catch((err) => {
        setBrowserErr1("Please Enable Camera/MicroPhone");
        // if (err === PERMISSION_DENIED) {
        //     // Explain why you need permission and how to update the permission setting
        // }
      })
  }, []);


  let bothCamMicLocation = false;

  if (browserErr1 && browserErr2) {
    bothCamMicLocation = true;
  }

  let checkAccessTxt = bothCamMicLocation
    ? <Text tid="unblock_your_location_camera_or_microphone"/>
    : browserErr1
      ? browserErr1 === "CouldNotStartVideoSource"
        ? <Text tid="camera_is_used_by_another_app"/>
        : <Text tid="unblock_camera_mic_in_settings"/>
      : browserErr2
        ? <Text tid="unblock_your_location"/>
        : null;

  const protean = process.env.REACT_APP_PROTEAN;

  const checkURLPath = () => {
    if (qParams?.length !== 2 || qParams[0] !== "?d" || qParams[1] === "") {
      return false;
    }
    return true;
  };

  return (
    <section className="main">
      <NetworkLayer networkstart={props?.networkstart}/>
      <Div
        className={
          "app" + (isLargeDevice && !isClientAccess ? " large-device-app" : "")
        }
        bgClr={isClientTideAccess ? "#F1F3F4" : cssProperties?.theme_bg_color}
      >
        {<div className="watermark">
          {(parsedUser?.acclogo && !isClientTideAccess) && (
            <img
              src={"data:image/png;base64," + parsedUser?.acclogo}
              className=""
              alt=""
            />
          )}
          <h1 className="watermark-title text-center">
          {showWaterMark() && parsedUser?.clientname}
          </h1>
        </div>}
        {location?.pathname === "/" && !checkURLPath() && (
          <div className="center center-absolute flex-column">
            <img src={"/images/nodata.png"} className="w-100" alt="" />
            NO DATA FOUND
          </div>
        )}

        {browserErr1 || browserErr2 ? (
          <div>
            <CameraAccess
              checkAccessTxt={checkAccessTxt}
              isClientTideAccess={isClientTideAccess}
              isCameraUsed={browserErr1 === "CouldNotStartVideoSource"}
            />
          </div>
        ) : (
          <>
            {(isLoading !== 0 && clientName && pathname !== RouteNames.KYC_PROCESS_COMPLETED) && (
              <Loading
                text={<Text tid="loading"/>}
                isClientTideAccess={isClientTideAccess}
              />
            )}
            <Outlet />
          </>
        )}
        <div className="poweredby-txt">
          <p className="mb-0">
            <strong className="text-muted">
              {parsedUser?.vcipid}
            </strong>
            <span style={{ opacity: "0" }}>v-{version?.version}</span>
          </p>
          {feature_flags?.kyctype === customeCodes?.COSMOS_EKYC ? null :
            <p className="mb-0">
              <span className="text-muted">
                Powered By <span>{protean ? "PROTEAN" : "SYNTIZEN"}</span>
              </span>
            </p>
          }
        </div>
      </Div>
    </section>
  );
};

export default Layout;
