export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator?.clipboard?.writeText(text);
  } else {
    return document?.execCommand("copy", true, text);
  }
}


export const checkSessionValues = (values) => {
  const services = [
    'USFB',
    'MOBILEPE',
    'PAYPOINT',
    'UNIMONI',
    'SBIC',
    'PAULMERCHANT',
    'ADANICAPITAL',
    'DIGIKHATA'
  ];

  return !services.includes(values);
}

