import React, { useContext, useState } from "react";
import { LanguageContext } from "../../Pages/Language/Language";
import { languageOptions } from "../../Languages";
import { useSelector } from "react-redux";

const ChooseLanguageCmp = (props) => {
  const [active, setActive] = useState(false);

  const languageContext = useContext(LanguageContext);
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  const handleChange = (event) => {
    const { value } = event;
    sessionStorage.setItem("lang", event);
    const selectedLanguage = languageOptions.find((item) => item.id === event);
    languageContext.setLanguage(selectedLanguage);
    props.onChangeLanguageSelect(event);
    setActive(true);
  };

  return (
    <>
      <section className="app-start">
        <ul className="languages-list">
          {languageOptions.map(
            (item) => (
              <li
                className="languages-list-item active"
                key={item.id}
                style={
                  props?.selectedLanguage == item.id
                    ? { color: cssProperties?.radio_button_color }
                    : null
                }
                onClick={() => handleChange(item.id)}
                value={item.id}
              >
                {item.text}
                {props?.selectedLanguage == item.id && (
                  <img
                    src="images/icon-check.svg"
                    className="languages-list-check"
                    alt="check"
                  />
                )}
              </li>
            )
            // props?.selectedLanguage == item.id ? (
            //   <li
            //     className="languages-list-item active"
            //     key={item.id}
            //     style={{color: cssProperties?.radio_button_color}}
            //     onClick={() => handleChange(item.id)}
            //     value={item.id}
            //   >
            //     {item.text}
            //     <img
            //       src="images/icon-check.svg"
            //       className="languages-list-check"
            //       alt="check"
            //     />
            //   </li>
            // ) : (
            //   <li
            //     className="languages-list-item "
            //     key={item.id}
            //     onClick={() => handleChange(item.id)}
            //     value={item.id}
            //   >
            //     {item.text}
            //   </li>
            // )
          )}
        </ul>
      </section>
    </>
  );
};

export default ChooseLanguageCmp;
