import React from 'react'
import { Text } from '../../../Pages/Language/Language';

import './TideHomepage.css'

export default function TideHomePageInstructions(props) {
  return (
    <div className='tidemain'>
      {/* <div className="instructions-list pl-4 mt-1">
      <li>
        Do not refresh the page or access any other app, else, you will be
        logged out of the Video KYC process.
      </li>
      <li>Please do not open the same link in multiple tabs.</li>
      <li>Please ensure your internet connectivity is stable.</li>
      <li>Enable camera, microphone and location settings on your device.</li>
      <li>Do not let your mobile screen go into sleep mode.</li>
      <li>Do not go back in any step of the KYC process.</li>
    </div> */}
      <div className='textmain'>
        {/* <img src='' className='tickiconnull'></img> */}
        <div className='hometext1'> <Text tid="for_a_smooth_identification_process" /> : </div>
      </div>
      <div className='textmain'>
        <img src='/images/Vector172.svg' className='tickicon'></img>
        <div className='hometext'><Text tid="do_not_refresh_page_or_access_other_app" /> </div>
      </div>
      <div className='textmain'>
        <img src='/images/Vector172.svg' className='tickicon'></img>
        <div className='hometext'><Text tid="ensure_your_internet_connectivity_is_stable" /></div>
      </div>
      <div className='textmain'>
        <img src='/images/Vector172.svg' className='tickicon'></img>
        <div className='hometext'><Text tid="enable_camera_mic_location_settings_on_device" /></div>
      </div>
      {/* <div className='textmain'>
      <img src='/images/Vector172.svg' className='tickicon'></img>
      <div className='hometext'>Enable camera, microphone and locations settings on your device</div>
    </div> */}
      <div className='textmain'>
        <img src='/images/Vector172.svg' className='tickicon'></img>
        <div className='hometext'><Text tid="do_not_let_your_mobile_enter_sleep_mode" /></div>
      </div>
      {props?.app_env == "lq_uat" && <div className='textmain'>
        <img src='/images/Vector172.svg' className='tickicon'></img>
        <div className='hometext'><Text tid="please_have_your_Original_Pan_card_during_VKYC_call" /></div>
      </div>}
    </div>
  )
}
