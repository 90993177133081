import toast from "react-hot-toast";
import { put, takeLatest, call, delay } from "redux-saga/effects";
import Axios from "axios";
import base64 from "base-64";
import AES256 from "aes-everywhere";
import {
  ACTION_CREATE_VIDEO_SESSION_REQ,
  ACTION_CREATE_VIDEO_TOKEN_REQ,
} from "../SagaActions/SagaActionTypes";
import {
  createVideoTokenSagaAction,
  createVideoTokenSagaActionResponse,
  getSpeechPitch,
  openViduSessionIssues,
} from "../SagaActions/VideoSagaActions";

let KEY = "f379e0b661ae4650b19169e4d93665dc";

const aesEncrypt = (data) => {
  var passphrase = KEY;
  let val1 = passphrase.substr(0, 4);
  let val2 = passphrase.substr(passphrase.length, 4);
  let updatedValue = val1 + passphrase + val2;
  const finalvalue = base64.encode(updatedValue).substr(0, 32);
  const encrypted = AES256.encrypt(JSON.stringify(data), finalvalue);
  return encrypted;
};

const aesDecrypt = (data) => {
  var passphrase = KEY;
  let val1 = passphrase.substr(0, 4);
  let val2 = passphrase.substr(passphrase.length, 4);
  let updatedValue = val1 + passphrase + val2;
  const finalvalue = base64?.encode(updatedValue).substr(0, 32);
  const decrypted = AES256?.decrypt(data, finalvalue);
  return decrypted;
};

const instance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_VIDEO_URL,
});
// console.log("env is", process.env.REACT_APP_BASE_VIDEO_URL);
// CREATE SESSION
const createVideoSessionReq = (model) => {
  // const URL =videoUrl+"vkycrestservices/"+"sessions";
  const URL = model?.streamingURL + "sessions";
  const data = {
    // "recordingMode": "ALWAYS",
    // recordingMode: "ALWAYS",
    // recordingMode: "MANUAL",
    recordingMode: model?.recordingMode,
    customSessionId: model?.sessionId,
    "defaultRecordingProperties": {
      "recordingLayout": "CUSTOM",
      "name": model?.sessionId,
    }

  };
  const headers = {
    headers: {
      "Content-Type": "application/json",
      // "Authorization": "Basic T1BFTlZJRFVBUFA6TVlfU0VDUkVU"
    },
  };
  const body = {
    data: aesEncrypt(data),
  };
  return Axios.post(URL, body, headers).then((res) => {
    return res?.data;
  });
};

function* createVideoSessionReqSaga(action) {

  yield put(createVideoTokenSagaAction(action?.payload));
  try {
    const resp = yield call(createVideoSessionReq, action?.payload?.model);
    console.log("*** create session resp", resp);
    var response = aesDecrypt(resp.data);
    // console.log("response->", response,"hemantresponce");
    // if(response){
    const parsedRes = JSON.parse(response)
    console.log(parsedRes, "====================================================++++++++++++++++++++++++++")
    if (parsedRes?.code === "ECONNREFUSED") {
      alert("Currently, we are facing technical issue. Request you to contact our technical administration team.");
      yield put(openViduSessionIssues(
        "Currently, we are facing technical issue. Request you to contact our technical administration team."
      ));
    } else {
      yield put(createVideoTokenSagaAction(action?.payload));
    }
    // }
  } catch (err) {
    console.log("==========+++++", err)
    var error = err?.data ? Object.assign({}, aesDecrypt(err?.data)) : "";
    if (error?.response && error?.response.status === 409) {
      yield put(createVideoTokenSagaAction(action?.payload));
    } else if (error) {
      console.warn(
        "No connection to Server. This may be a certificate error at " +
        this.OPENVIDU_SERVER_URL
      );
      yield put(openViduSessionIssues(
        "No connection to Server. This may be a certificate error at server"
      ));
      if (
        window.confirm(
          'No connection to Server. This may be a certificate error at "' +
          this.OPENVIDU_SERVER_URL +
          '"\n\nClick OK to navigate and accept it. ' +
          'If no certificate warning is shown, then check that your Server is up and running at "' +
          this.OPENVIDU_SERVER_URL +
          '"'
        )
      ) {
        window.location.assign(
          this.OPENVIDU_SERVER_URL + "/accept-certificate"
        );
      }
    } else {
      // toast("Internal connectivity error")      
      console.log("*** internal connectivity error with -> ", err);
      // yield put(openViduSessionIssues("Internal Connectivity Error"));
      yield delay(1000);
      if (action?.payload?.handleError) {
        console.log("*** calling error handler");
        action?.payload?.handleError();
      }
    }
  } finally {
    // yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

// CREATE TOKEN
const createVideoTokenReq = (model) => {
  // const URL = videoUrl+"vkycrestservices/"+"tokens";
  const URL = model?.streamingURL + "tokens";
  const data = {
    session: model?.sessionId,
  };
  let body = {
    data: aesEncrypt(data),
  };
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Basic T1BFTlZJRFVBUFA6TVlfU0VDUkVU"
    },
  };
  return Axios.post(URL, body, headers).then((res) => {
    return res?.data;
  });
};

function* createVideoTokenReqSaga(action) {
  // console.log("action->->",action.payload);
  try {
    let respData = yield call(createVideoTokenReq, action?.payload?.model);
    let resp = JSON.parse(aesDecrypt(respData.data));
    let mySession = action?.payload?.model?.session;

    sessionStorage.setItem("session", resp.session);
    let openViduErrorMsg = yield mySession
      .connect(resp?.token, "customer")
      .then(() => {
        // let _videoSource = await action?.payload?.model?.OV?.getDevices().then((devices) => {
        //   // Getting only the video devices
        //   console.log("44444444444444444", devices);
        //   var videoDevices = devices?.filter(
        //     (device) => device.kind === "videoinput"
        //   );
        //   console.log("5555555555555555", videoDevices);
        //   return videoDevices[1]?.deviceId
        //   // action?.payload?.model?.OV?.setVideoSource(videoDevices[1].deviceId);
        // })
        let publisher = action?.payload?.model?.OV.initPublisher(undefined, {
          audioSource: undefined,
          videoSource: undefined,
          publishAudio: true,
          publishVideo: true,
          // resolution: '640x480',
          frameRate: 30,
          insertMode: "APPEND",
          mirror: true,
        }, (error => {
          if (action?.payload?.handleError && error) {
            action?.payload?.handleError(error);
          }
          return;
        }));
        let audiodetect;
        if (action?.payload?.getPitch) {
          publisher.on('streamAudioVolumeChange', (event) => {
            audiodetect = event.value.newValue;
            // sessionStorage.setItem("audiodetect",audiodetect)
            action?.payload?.getPitch(audiodetect);
          });
        }
        sessionStorage.setItem("publisher", Object.keys(publisher));
        mySession.publish(publisher);
        if (action?.payload?.callback) {
          action?.payload?.callback({
            mainStreamManager: publisher,
            publisher: publisher,
            mySession: mySession
          });
        }
        return "";
        // yield put(createVideoTokenSagaActionResponse(resData))
      })
      .catch((error) => {
        let text = error?.message;
        console.log(text, "==--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-===--=-=-=-=-=-text")
        let result = text.replace(/ in OpenVidu/g, ".");
        console.log(
          "There was an error connecting to the session:",
          error.code,
          result
        );
        if (text == "Cannot read properties of undefined (reading 'match')") {
          return ""
        }
        return "There was an error connecting to the session: " + result;
      });
    // yield put(getSpeechPitch(audiodetect))


    // console.log(openViduErrorMsg,"-------------------------openViduErrorMsg")
    // if(openViduErrorMsg !="There was an error connecting to the session: Cannot read properties of undefined (reading 'match')"){
    yield put(openViduSessionIssues(openViduErrorMsg));
    // }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
      yield put(openViduSessionIssues(err?.response?.data?.errors[0]?.message));
    } else {
      toast(err.message);
      yield put(openViduSessionIssues(err.message));
    }
  } finally {
    // yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

export default function* VideoWatcherSaga() {
  yield takeLatest(ACTION_CREATE_VIDEO_SESSION_REQ, createVideoSessionReqSaga);
  yield takeLatest(ACTION_CREATE_VIDEO_TOKEN_REQ, createVideoTokenReqSaga);
}
