import { object } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AadhaarDigiLockerCmp from "../../../Components/SelectKYCProcessCmp/AadhaarDigiLockerCmp";
import CleintNames from "../../../Constants/ClientNames";
// import AadhaarKYCCmp from '../../../Components/SelectKYCProcessCmp/AadhaarKYCCmp';
import RouteNames from "../../../Constants/RouteNames";
import Header from "../../../Layout/Header";
import {
  actionAadharDigiLocker,
  actionAadharDigiLockerStatus,
} from "../../../Store/SagaActions/AadharSagaActions";
import AppFooter from "../../Common/AppFooter";
import { Text } from "../../Language/Language";
// import AppFooter from '../../Common/AppFooter';

const AadhaarDigiLocker = () => {
  // let intervalId;
  let windowOpen;
  let windowopenStatus;
  const [digiLockerObj, setdigiLockerObj] = useState({});
  const [aadharDetails, setAadharDetails] = useState({});
  const [isAadharSuccess, setIsAadharSuccess] = useState(false);
  const [closeWindowState, setcloseWindowState] = useState("");
  const [intervalId, setIntervalId] = useState("");
  // console.log(aadharDetails , "Dta")

  let navigate = useNavigate();
  // window.onpopstate = () => {
  //   navigate(null);
  // }
  const dispatch = useDispatch();

  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { app_env, skipPanVerifyInCustomer } = dynamicSettingObj;
  const skipPanScreens = skipPanVerifyInCustomer === "true" ? true : false

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  const isusfb = (clientName === CleintNames?.USFB || clientName === CleintNames?.USFBSYNTIZEN) ? true : false;


  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
      (typeof safari !== "undefined" && window["safari"].pushNotification)
    );
  var userAgent = window.navigator.userAgent;

  const isSafari2 = userAgent.indexOf("Safari") != -1;
  const isSafari3 = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
  var intervalIddg;
  var intervalIddg2;

  useEffect(() => {
    const vcipkey = sessionStorage.getItem("vcipkey");
    const model = {
      vcipkey: vcipkey,
      doctype: "AADHAAR",
      rrn: "1",
    };
    dispatch(
      actionAadharDigiLocker({ model: model, callback: getDigiLockerData })
    );
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (intervalIddg) {
        clearInterval(intervalIddg);
      }
      if (intervalIddg2) {
        clearInterval(intervalIddg2);
      }
      // if (windowopenStatus) windowOpen.close();
      if (closeWindowState) closeWindowState.close();
    };
  }, []);

  const getDigiLockerData = (data) => {
    // windowOpen = window.open(data?.url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes");
    // console.log("!isSafari || isSafari2 || !isSafari3", isSafari, isSafari2, detectMobile());
    if (isSafari || !isSafari2 || detectMobile()) {
      setdigiLockerObj(data);
    } else {
      // var closeWindow = window.open(
      //   data?.url,
      //   "_blank",
      //   "toolbar=yes,scrollbars=yes,resizable=yes"
      // );
      var closeWindow = window.open(data?.url, "", "");
      // setcloseWindowState(closeWindow)
      // windowopenStatus = true;
      setdigiLockerObj(data);
      const vcipkey = sessionStorage.getItem("vcipkey");
      const model = {
        vcipkey: vcipkey,
        txnid: data.txnid,
        // txnid: "235",
        rrn: "1",
      };
      // intervalIddg = setInterval(() => {
      // setIntervalId(intervalIddg);
      dispatch(
        actionAadharDigiLockerStatus({
          model: model,
          callback: (data) =>
            getDigiLockerStatusData(data, intervalIddg, closeWindow),
        })
      );
      // }, 5000);
    }
  };


  const digilockerFetchDetails = () => {
    var closeWindow = window.open(digiLockerObj?.url, "", "");

    const vcipkey = sessionStorage.getItem("vcipkey");
    const model = {
      vcipkey: vcipkey,
      txnid: digiLockerObj.txnid,
      // txnid: "235",
      rrn: "1",
    };
    // intervalIddg2 = setInterval(() => {
    // setIntervalId(intervalIddg);
    dispatch(
      actionAadharDigiLockerStatus({
        model: model,
        callback: (data) =>
          getDigiLockerStatusData(data, intervalIddg2, closeWindow),
      })
    );
    // }, 5000);
  };

  const getDigiLockerStatusData = (data, intervalIddg, closeWindow) => {
    if (
      (data?.respcode === "200" && data?.status === "Success") ||
      (data?.respcode === "200" && data?.statuscode === "1")
    ) {
      setIsAadharSuccess(true);
      setAadharDetails(data.kycdetails);
      clearInterval(intervalIddg);
      // try {
      //   if (closeWindow) closeWindow.close();
      //   navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
      // } catch (error) {
      //   navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
      // }

      // if (windowopenStatus) windowOpen.close()
      // if (closeWindowState) closeWindowState.close()
      if (closeWindow) closeWindow.close();
    } else if (
      (data?.respcode === "457" &&
        data?.status === "Failed" &&
        data?.statuscode === "2") || data?.respcode === "500"
    ) {
      // windowOpen.close();
      closeWindow?.close();
      clearInterval(intervalIddg);
      // if(isClientTideAccess || window.location?.origin?.includes("unimoniindia")){
      navigate(RouteNames.SELECT_KYC_PROCESS);
      // }else if (app_env == "tc_uat") {
      //   // navigate(RouteNames.AADHAR_EKYC_PROCESS_UPLOAD)  // For EKYC
      //   navigate(RouteNames.SELECT_KYC_PROCESS);
      // }
      // else {
      //   navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD,{ replace: true })   //For Offline Kyc
      // }

      // try {
      //   closeWindow.close();
      //   clearInterval(intervalIddg);
      //   navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD, { replace: true });
      // } catch (error) {
      //   closeWindow.close();
      //   clearInterval(intervalIddg);
      //   navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD, { replace: true });
      // }
    } else if (data?.respcode === "451" && data?.respdesc == "Transaction Fecth Limit Exceeded" && data?.statuscode === "3") {
      closeWindow?.close();
      clearInterval(intervalIddg);
      if (isClientTideAccess || window.location?.origin?.includes("unimoniindia")) {
        navigate(RouteNames.SELECT_KYC_PROCESS);
      }
      navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD, { replace: true });
    }
    // else if (closeWindow) {
    //   if (closeWindow?.closed)
    //     navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD, { replace: true });
    //   if (closeWindow?.closed) clearInterval(intervalIddg);
    // }
    // setInterval(() => {
    //     navigate(RouteNames.PAN_KYC_PROCESS)
    // }, 1000);
  };

  const detectMobile = () => {
    const toMatch = [/iPhone/i, /iPad/i, /iPod/i];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  // skipPanVerifyInCustomer --> True ? and panstatus 0 then skip pan screens
  // skipPanVerifyInCustomer --> True ? and panstatus 1 then skip pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 0 show pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 1 then skip pan screens

  const nextPage = () => {
    const user = sessionStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    if (parsedUser?.panstatus === "1") {
      if(parsedUser.custoccupname=="" && !isusfb){
        navigate(RouteNames.EMPLOYEMENT_DETAILS , { replace: true });
      }else{
        navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE , { replace: true });
      }
    } else if(!skipPanScreens && parsedUser?.panstatus === "0") {
      if (isClientTideAccess) {
        navigate(RouteNames.PAN_CAPTURE, { replace: true });
      } else {
        navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
      }
    }
  };

  const digiLockerAction = (e) => {
    e.preventDefault();
    digilockerFetchDetails();
    // var closeWindow = window.open(digiLockerObj?.url, "", "")
    // if (!closeWindow || closeWindow.closed || typeof closeWindow.closed == 'undefined') {
    //     // toast("Please allow Popup")
    //     alert("Please allow Popup")
    // }
    // setcloseWindowState(closeWindow)
  };


  return (
    <>
      {(!isAadharSuccess && isClientTideAccess) ? <Header
        title={
          !isAadharSuccess && isClientTideAccess
            ? <Text tid="We_are_verifying_your_identity" />
            : (!isClientTideAccess && <Text tid="kyc_details" />)
        }
        // navigate={!isAadharSuccess ? () => navigate(-1) : ""}
        hideHeader={false}
        isClientTideAccess={!isAadharSuccess ? isClientTideAccess : ""}
      />
        : <Header
          title={<Text tid="aadhaar_details" />}
          navigate={() => navigate(-1)}
          noarrow={"noarrow"}
          hideHeader={false}
        />}
      <article className={!isClientTideAccess ? "app-body" : "tideadhardetails"}>
        <AadhaarDigiLockerCmp
          aadharDetails={aadharDetails}
          isAadharSuccess={isAadharSuccess}
          digiLockerAction={digiLockerAction}
          isClientTideAccess={isClientTideAccess}
        />
        {/* <AadhaarKYCCmp /> */}
        <div className={"aaharbutton"} style={{
          backgroundColor: isClientTideAccess ? "rgb(241, 243, 244)" : "#FFFFFFF2",
          paddingBottom: isClientTideAccess ? "7%" : "",
          transform: isClientTideAccess ? "translate(-50%,0%)" : "translate(-50%,-20%)"
        }}>
          {isAadharSuccess && (
            <AppFooter
              btnName={isClientTideAccess ? <Text tid="next" /> : <Text tid="continue_button" />}
              navigate={nextPage}
              // aadharbutton = {"aadharbutton"}
              isClientTideAccess={isClientTideAccess}
            />
          )}
        </div>
      </article>
    </>
  );
};

export default AadhaarDigiLocker;
