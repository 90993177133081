import toast from "react-hot-toast";
import { put, takeLatest, call } from "redux-saga/effects";
import Axios from "../../Service/axios";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/CommonSagaActions";
import {
  ACTION_END_VIDEO_CALL_BY_AGENT_REQ,
  ACTION_GET_LOCATION_DETAILS_REQ,
  ACTION_GET_NOTIFICATIONS_LIST_REQ,
  ACTION_GET_NOTIFICATIONS_PUSH_REQ,
  GET_CUSTOMER_ENV_DATA_SAGA_ACTION
} from "../SagaActions/SagaActionTypes";

const getLocationDetailsReq = (model) => {
  const URL =
    "https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=" +
    model.lat +
    "&longitude=" +
    model.long;
  return Axios.get(URL).then((res) => {
    return res?.data;
  });
};

function* getLocationReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  try {
    const resp = yield call(getLocationDetailsReq, action?.payload?.model);
    const data =
      (resp.city ? resp.city : resp.locality) +
      ", " +
      resp.principalSubdivision +
      ", " +
      resp.countryName;

    if (action?.payload?.callback) {
      action?.payload?.callback(data);
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const getNotificationListReq = () => {
  const URL = "GetNotifications";
  const vcipkeyData = sessionStorage.getItem("vcipkey");
  const body = {
    vcipkey: vcipkeyData,
  };
  return Axios.post(URL, body).then((res) => {
    return res?.data;
  });
};

function* getNotificationListReqSaga(action) {
  // yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(getNotificationListReq);
    if (resp?.respcode === "200") {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    }
    //  else {
    //   toast(err.message);
    // }
  }
  //  finally {
  //     yield put(actionReqResStatusLoaderSagaAction(false));
  // }
}

const endVideoCallReq = () => {
  const URL = "EndVideoConferenceSessionID";
  const vcipkeyData = sessionStorage.getItem("vcipkey");
  const body = {
    vcipkey: vcipkeyData,
  };
  return Axios.post(URL, body).then((res) => {
    return res?.data;
  });
};

function* endVideoCallReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(endVideoCallReq);
    // console.log("=========resp", resp);
    action?.payload?.callback(resp);
    if (resp?.respcode === "200") {
      if (action?.payload?.callback) {
        toast.success(resp?.respdesc);
      } else {
        toast(resp?.respdesc);
      }
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

const pushtNotificationReq = (model) => {
  const URL = "PushNotifications";
  return Axios.post(URL, model).then((res) => res?.data);
};

function* pushtNotificationReqSaga(action) {
  // yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(pushtNotificationReq, action?.payload?.model);
    if (resp?.respcode === "200") {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    }
  } catch (err) {
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
  }
  //  finally {
  //     yield put(actionReqResStatusLoaderSagaAction(false));
  // }
}

const getEnvDetails = async (model) => {
  const URL = "GetEnvDetails";
  return await Axios.post(URL, model).then((res) => res?.data).catch((err) => err);
};

function* getCustomerEnvDataSaga(action){
  try{
    const resp = yield call(getEnvDetails, action?.payload?.model);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
  }catch (err){
    if (err.response) {
      toast(
        err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
      );
    } else {
      toast(err.message);
    }
    action?.payload?.callback(err);
  }
}

export default function* CommonWatcherSaga() {
  yield takeLatest(ACTION_GET_LOCATION_DETAILS_REQ, getLocationReqSaga);
  yield takeLatest(
    ACTION_GET_NOTIFICATIONS_LIST_REQ,
    getNotificationListReqSaga
  );
  yield takeLatest(ACTION_END_VIDEO_CALL_BY_AGENT_REQ, endVideoCallReqSaga);
  yield takeLatest(ACTION_GET_NOTIFICATIONS_PUSH_REQ, pushtNotificationReqSaga);
  yield takeLatest(GET_CUSTOMER_ENV_DATA_SAGA_ACTION, getCustomerEnvDataSaga);
}
