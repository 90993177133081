import React from "react";

const H4 = (props) => {
  return (
    <h4
      className={props?.className}
      style={{ color: props?.color, fontSize: props?.fontSize }}
    >
      {props?.title}
    </h4>
  );
};

export default H4;
