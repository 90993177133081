import en from './en';
import hi from './hi';
import te from './te';
import tamil from './tamil';
import kannada from './kannada';
import malyalam from './malyalam';
import marathi from './marathi';
import pu from './pu';
import be from './bengali';
import gu from './gujrati';


export const dictionaryList = {
    en,
    hi,
    te,
    tamil,
    kannada,
    malyalam,
    pu,
    marathi,
    gu,
    be,

};


export const languageOptions = [
    { id: 'en', text: 'English' },
    { id: 'hi', text: 'हिन्दी' },
    // { id: 'te', text: 'తెలుగు' },
    // { id: 'kannada', text: 'ಕನ್ನಡ' },
    // { id: 'tamil', text: 'தமிழ்' },
    // { id: 'malyalam', text: 'മലയാളം' },
    // { id: 'pu', text: 'ਪੰਜਾਬੀ' },
    // { id: 'marathi', text: 'मराठी' },
    // { id: 'gu', text: 'પાસ' },
    // { id: 'be', text: 'বাংলা'},

];