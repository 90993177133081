import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import base64 from "base-64";
import AadhaarUploadCmp from "../../../Components/SelectKYCProcessCmp/AadhaarUploadCmp";
import AadhaarData from "../../../Components/SelectKYCProcessCmp/AadharData";

import RouteNames from "../../../Constants/RouteNames";
// import Header from "../../../Layout/Header";
import PortalModal from "../../../Portals/PortalModal";
import {
  actionGetCaptcha,
  actionRefreshCaptchaAction,
  actionGenerateCaptchaAction,
  actionOKYCDetailsAction,
} from "../../../Store/SagaActions/AadharSagaActions";
import AppFooter from "../../Common/AppFooter";
import { Text } from "../../Language/Language";
import AadhaarNumberModal from "../../Modals/AadhaarNumberModal";
import AadhaarNumberVerifyModal from "../../Modals/AadhaarNumberVerifyModal";
import CleintNames from "../../../Constants/ClientNames";
import styled from "styled-components";
import { AdhaarDetailsAction, createAadhaarEkycReqSagaAction, fetchAadhaarEkycStatusAction, sendOTPAction } from "../../../Store/SagaActions/AadharEKYCSagaActions";
import toast from "react-hot-toast";
import Loading from "../../../Components/Loading/Loading";
import customeCodes from "../../../Constants/customeCodes";
import CosmosEKYC from "./CosmosEKYC";

const AadhaarUpload = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [captchaDetails, setCaptchaDetails] = useState({});
  const [isCaptchaTrue, setIsCaptchaTrue] = useState(false);
  const [okycData, setokycData] = useState(false);
  const [aadharapi, setAadharapi] = useState(true);
  const [aadhaarBase64, setAadharBase64] = useState('');
  const [resetOtp, setResetOtp] = useState(false)
  const [verificationCode, setVerificationCode] = useState({});
  const [ekycData, setEkycData] = useState({});
  const [aadharDetails, setAadharDetails] = useState({});
  const [isCreateEkyApiDone, setIsCreateEkyApiDone] = useState(false);
  const [isAadharSuccess, setIsAadharSuccess] = useState(false);
  const [isFingerprintStarted, setIsFingerprintStarted] = useState(false);
  const [kycstatus, setKycstatus] = useState('');
  const [isAadharKycFailed, setIsAadharKycFailed] = useState(false);
  const [loader, setLoader] = useState(false)
  const [cosmosOpen, setCosmosOpen] = useState(false)
  const [aadharNumber, setAadharNumber] = useState("");
  const [aadharErr, setAadharErr] = useState("");
  const [showEnterOtp, setShowEnterOtp] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [otpCosmos, setOtpCosmos] = useState("");

  // console.table({
  //   "ekycData": ekycData,
  //   "isCreateEkyApiDone": isCreateEkyApiDone, "isAadharSuccess": isAadharSuccess, "isFingerprintStarted": isFingerprintStarted,
  //   "kycstatus": kycstatus, "isAadharKycFailed": isAadharKycFailed, "aadharDetails": aadharDetails , "loader" : loader
  // }, "table")

  const inputRef = useRef({});


  let navigate = useNavigate();
  const dispatch = useDispatch();
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const vcipDetails = useSelector((state) => state.VcipReducer.vcipDetails);
  const { feature_flags } = vcipDetails
  const { skipPanVerifyInCustomer } = dynamicSettingObj;
  const skipPanScreens = skipPanVerifyInCustomer === "true" ? true : false

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  const data = sessionStorage.getItem('user');
  const accName = JSON.parse(data)?.acccode;


  const isusfb = (clientName === CleintNames?.USFB || clientName === CleintNames?.USFBSYNTIZEN) ? true : false;

  const vcipkey = sessionStorage.getItem("vcipkey");

  useEffect(() => {
    if (feature_flags?.kyctype === customeCodes?.COSMOS_EKYC) {
      return null
    } else {
      sendOTPModal()
    }
  }, [feature_flags?.kyctype])


  useEffect(() => {
    if (feature_flags?.kyctype === customeCodes?.COSMOS_EKYC) {
      setCosmosOpen(true)
    } else {
      generateXMLModal();
    }
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const generateXMLModal = () => {
    setIsOpen(true);
  };

  // const sendOTPModal = (aadharNumber) => {
  //   const finalvalue = base64?.encode(aadharNumber);
  //   const model = {
  //     vcipkey: vcipkey,
  //     uid: finalvalue,
  //     rrn: "1",
  //   };
  //   dispatch(actionGetCaptcha({ model: model, callback: getOKYCCaptchaData }));
  // };

  const getOtpforCosmos = (aadharNumber) => {
    const finalvalue = base64?.encode(aadharNumber);
    setAadharBase64(finalvalue)
    const model = {
      vcipkey: vcipkey,
      aadhaar: finalvalue,
      otptype: "1",
      rrn: "1",
    };

    dispatch(sendOTPAction({ model: model, callback: getOKYCCaptchaData }));
  };


  const enterOtpforCosmos = () => {
    const otpbase64 = base64?.encode(otpCosmos);
    const model = {
      vcipkey: vcipkey,
      aadhaar: aadhaarBase64,
      kycotptxnid: captchaDetails?.kycotptxnid,
      kycotp: otpbase64,
      // otptype: "3",
      rrn: "1",
    };
    setResetOtp(false);
    if (!isChecked) {
      toast("Please mark the checkbox and then click 'submit' to proceed")
    } else {
      dispatch(AdhaarDetailsAction({ model: model, callback: getOKYCdata }));
    }
  };


  const sendOTPModal = () => {
    const model = {
      uid: "",
      svctype: "05",
      dvcid: "02",
      vcipkey: vcipkey
    }
    dispatch(createAadhaarEkycReqSagaAction({ model: model, callback: createAadhaarRequestRes }))

  }

  const createAadhaarRequestRes = (data) => {
    if (data?.respcode === "200") {
      var closeWindow;
      setTimeout(() => {
        openGateWay(data);
      }, 1000);
      setEkycData(data);
      fetchAadhaarEkyc(data?.txnid, closeWindow);
    } else {
      toast.error(data?.respdesc);
    }
  }

  const fetchAadhaarEkyc = (txnid, closeWindow) => {
    const model = {
      vcipkey: vcipkey,
      txnid: txnid,
      rrn: "05"
    }
    dispatch(fetchAadhaarEkycStatusAction({ model: model, callback: (resp) => fetchAadhaarEkycStatusRes(resp, txnid, closeWindow) }))
  }

  const fetchAadhaarEkycStatusRes = (data, txnid, closeWindow) => {
    // console.table(data, txnid, closeWindow, "table")
    if (
      (data?.respcode === "200" && data?.status === "Success") ||
      (data?.respcode === "200" && data?.status === "Process completed") ||
      (data?.respcode === "200" && data?.statuscode === "200")
    ) {
      setIsAadharSuccess(true);
      setAadharDetails(data);
      setIsOpen(false);
      setIsVerify(false);
      setLoader(false)
      if (closeWindow) closeWindow.close();
    } else if (data?.respcode === "201" && data?.status === "pending" && (data?.statuscode === "201")) {
      setLoader(true)
      setTimeout(() => {
        fetchAadhaarEkyc(txnid, closeWindow);
      }, 2000);
    } else if (
      (data?.respcode === "457" &&
        data?.status === "Failed") || data?.respcode === "500"
    ) {
      setKycstatus(data?.status);
      setIsAadharKycFailed(true);
      setLoader(false)
      closeWindow?.close();
      // navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD, { replace: true })   //For Offline Kyc
    } else if (data?.respcode === "451" && data?.respdesc == "Transaction Fecth Limit Exceeded") {
      toast.error(data?.respdesc);
      setKycstatus(data?.status);
      setIsAadharKycFailed(true);
      setIsOpen(false);
      setLoader(false)
      closeWindow?.close();
      // navigate(RouteNames.CUSTOMER_REVIEW, { replace: true });
    } else if (data?.respcode === "S-451") {
      toast.error(data?.respdesc);
      setKycstatus(data?.status);
      setIsAadharKycFailed(true);
      setLoader(false)
      closeWindow?.close();
      // navigate(RouteNames.CUSTOMER_REVIEW, { replace: true });
    } else {
      toast.error(data?.respdesc);
      setKycstatus(data?.status || data?.respdesc);
      setIsAadharKycFailed(true);
      setLoader(false)
      closeWindow?.close();
    }
  };

  const openGateWay = (data) => {
    const form = document.createElement('form');
    form.id = 'URL';
    form.name = 'URL';
    form.method = 'POST';
    form.enctype = 'multipart/form-data';
    form.action = data?.ekycgatewayurl;

    // Create an input element for AUTHXML
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'AUTHXML';
    input.value = data?.ekycreq; // Replace with your actual value
    form.appendChild(input);

    // Set form target to '_blank' to open in a new tab
    form.target = '_blank';

    // Append the form to the document and submit it
    document.body.appendChild(form);
    form.submit();
  }


  const handleChangeVC = (e) => {
    const { name, value } = e.target;

    if (e.keyCode === 8 && name !== "0") {
      const previousInputName = String(parseInt(name));
      setVerificationCode((prevState) => ({ ...prevState, [previousInputName]: '', [previousInputName + 1]: "" }));
      inputRef.current[previousInputName - 1].focus();
      return;
    }
    let regexp = /^[0-9]{1}$/;
    if (regexp.test(value) && value?.length === 1) {
      setVerificationCode((prevState) => ({ ...prevState, [name]: value }));
      switch (name) {
        case "0":
          inputRef.current["1"].focus();
          break;
        case "1":
          inputRef.current["2"].focus();
          break;
        case "2":
          inputRef.current["3"].focus();
          break;
        case "3":
          inputRef.current["4"].focus();
          break;
        case "4":
          inputRef.current["5"].focus();
          break;

        default:
          break;
      }
    } else if (value?.length === 0) {
      setVerificationCode((prevState) => ({ ...prevState, [name]: value }));
    }
  };



  const sendRefreshOTPModal = () => {
    const model = {
      vcipkey: vcipkey,
      token: captchaDetails?.token,
      rrn: "1",
    };
    if (captchaDetails?.token)
      dispatch(
        actionRefreshCaptchaAction({
          model: model,
          callback: getOKYCCaptchaData,
        })
      );
  };

  const getOTPModal = (captchaKey) => {
    const model = {
      vcipkey: vcipkey,
      token: captchaDetails?.token,
      captcha: captchaKey,
      rrn: "1",
    };
    dispatch(actionGenerateCaptchaAction({ model: model, callback: getOTP }));
  };


  const getOKYCdetail = (OTP) => {
    const otpbase64 = base64?.encode(OTP);
    const model = {
      vcipkey: vcipkey,
      aadhaar: aadhaarBase64,
      kycotptxnid: captchaDetails?.kycotptxnid,
      kycotp: otpbase64,
      // otptype: "3",
      rrn: "1",
    };
    setResetOtp(false);
    dispatch(AdhaarDetailsAction({ model: model, callback: getOKYCdata }));
  };

  // const getOKYCCaptchaData = (data) => {
  //   if (data.respcode === "406") restartProcess();
  //   if (data.respcode === "200") {
  //     setCaptchaDetails(data);
  //     setIsCaptchaTrue(true);
  //     setAadharapi(false);
  //   }
  // };
  const getOKYCCaptchaData = (data) => {
    if (data.respcode === "200") {
      setShowEnterOtp(true)
      setCaptchaDetails(data)
      toast.success(data?.respdesc)
    } else {
      setShowEnterOtp(false)
      setLoader(false)
    }
  };

  const getOTP = (data) => {
    if (data.respcode === "406") restartProcess();
    if (data.respcode === "200") {
      getOKYCCaptchaData(data);
      setIsOpen(false);
      setIsVerify(true);
    }
  };

  const restartProcess = () => {
    setIsVerify(false);
    setIsCaptchaTrue(false);
    setIsOpen(true);
    setAadharapi(true);
    setCaptchaDetails({});
  };

  const getOKYCdata = (data) => {
    if (data.respcode === "406") restartProcess();
    if (data.respcode === "200") {
      setIsAadharSuccess(true)
      getOKYCCaptchaData(data);
      setAadharDetails(data);
      setokycData(true);
      setCosmosOpen(false);
      setAadharBase64("")
      setIsChecked(false)
      setAadharNumber("")
      setOtpCosmos("")
    }
    setResetOtp(true)
  };

  const verifyOTP = () => {
    setIsVerify(false);
  };

  const nextPage = () => {
    navigate(RouteNames.AADHAR_KYC_PROCESS_DOWNLOAD);
  };

  // skipPanVerifyInCustomer --> True ? and panstatus 0 then show pan screens
  // skipPanVerifyInCustomer --> True ? and panstatus 1 then skip pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 0 then skip pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 1 then skip pan screens
  

  const panPage = () => {
    const user = sessionStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    if (parsedUser?.panstatus === "1") {
      if (parsedUser.custoccupname == "" && !isusfb) {
        navigate(RouteNames.EMPLOYEMENT_DETAILS, { replace: true });
      } else {
        navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE, { replace: true });
      }
      // navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE, { replace: true });
    } else if (!skipPanScreens && parsedUser?.panstatus === "0"){
      if (isClientTideAccess) {
        navigate(RouteNames.PAN_CAPTURE, { replace: true });
      } else {
        navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
      }
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsVerify(false);
    setCosmosOpen(false)
    if (isAadharSuccess === false) {
      navigate(RouteNames.SELECT_KYC_PROCESS);
    }
  };

  return (
    <>
      {loader ? <Loading text={"Fetching Aadhaar Details"} /> : null}
      <Header>
        <HeadText>
          <h4>aadhaar details</h4>
          {/* <h6>{feature_flags?.kyctype === customeCodes?.EKYC ? "" : "Offline KYC"}</h6> */}
          <h6>{customeCodes?.eKycHeading}</h6>
        </HeadText>
      </Header>
      <article className="app-body">
        {isAadharSuccess &&
          <>
            <AadhaarData aadharDetails={aadharDetails} />
            <AppFooter btnName={<Text tid="agree" />} navigate={panPage} />
          </>
        }
      </article>

      <PortalModal isOpen={isOpen} isBottom={true}>
        <AadhaarNumberModal
          isCaptchaTrue={isCaptchaTrue}
          captchaDetails={captchaDetails}
          sendOTPModal={sendOTPModal}
          closeModal={closeModal}
          sendRefreshOTPModal={sendRefreshOTPModal}
          getOTPModal={getOTPModal}
          aadharapi={aadharapi}
          isAadharKycFailed={isAadharKycFailed}
          feature_flags={feature_flags}
        />
      </PortalModal>

      <PortalModal isOpen={isVerify} isBottom={true}>
        <AadhaarNumberVerifyModal
          verifyOTP={verifyOTP}
          closeModal={closeModal}
          getOKYCdetail={getOKYCdetail}
          resetOtp={resetOtp}
          handleChangeVC={handleChangeVC}
          verificationCode={verificationCode}
          setVerificationCode={setVerificationCode}
          inputRef={inputRef}
        />
      </PortalModal>

      <PortalModal isOpen={cosmosOpen} isBottom={true} modalStyle="border-0" modelBgStyle={{ background: "white" }}>
        <CosmosEKYC
          getOtpforCosmos={getOtpforCosmos}
          setAadharNumber={setAadharNumber}
          aadharNumber={aadharNumber}
          setAadharErr={setAadharErr}
          aadharErr={aadharErr}
          showEnterOtp={showEnterOtp}
          enterOtpforCosmos={enterOtpforCosmos}
          setIsChecked={setIsChecked}
          isChecked={isChecked}
          setOtpCosmos={setOtpCosmos}
          otpCosmos={otpCosmos}

        />
      </PortalModal >

    </>
  );
};

export default AadhaarUpload;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 22px 22px;
  padding: 0.5rem 0;
  position: relative;
`;
const HeadText = styled.div`
  text-align: center;
  h6 {
    text-transform: capitalize;
    font-weight: 400;
    margin: 0;
    font-size: 0.9rem;
  }
  h4 {
    text-transform: capitalize;
    font-weight: 900;
    color: #000;
    font-size: 1.1rem;
    padding-top: 1rem;
  }
`;
