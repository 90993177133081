import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import customeCodes from '../../Constants/customeCodes';
import InitiatingVideoCall from './InitiatingVideoCall/InitiatingVideoCall';
import InitiatingvideocallLivekit from './Livekit/Initiatevideocall';
import Initiatecall100MsLive from './100msLive/initiatecall100mslive';

const StreamingLayout = () => {
    const params = useParams();
    const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
    const { streamingType, streamingURL } = dynamicSettingObj;

    // const element = streamingType === customeCodes.STREAMING_TYPE_LIVEKIT_VALUE
    //     ? <InitiatingvideocallLivekit params={params} />
    //     : streamingType === customeCodes.STREAMING_TYPE_OPENVIDU_VALUE
    //         ? <InitiatingVideoCall params={params} />
    //         : null;

    const element = streamingType === customeCodes.STREAMING_TYPE_LIVEKIT_VALUE
        ? < InitiatingvideocallLivekit params={params} />
        : streamingType === customeCodes.STREAMING_TYPE_OPENVIDU_VALUE
            ? <InitiatingVideoCall params={params} />
            : streamingType === customeCodes.STREAMING_TYPE_100MS_LIVE_VALUE ? <Initiatecall100MsLive params={params} /> : null

    return (
        <>{element}</>
    )
}

export default StreamingLayout;