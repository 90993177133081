import React, { useState } from "react";
import Header from "../../Layout/Header";
import { useNavigate } from "react-router";
import AppFooter from "../Common/AppFooter";
import { Text } from "../Language/Language";
import RouteNames from "../../Constants/RouteNames";
import { verifyAccountSagaAction } from "../../Store/SagaActions/BankDetailsSagaActions";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

function BankDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bankDetails, setBankDetails] = useState({
    accountNumber: '',
    ifscCode: '',
  });
  const [errors, setErrors] = useState({
    accountNumberError: false,
    ifscCodeError: false,
  });

  // TO HANDLE ACCOUNT NUMBER AND IFSC INPUTS
  const handleChange = (event) => {

    const { name, value } = event.target;

    // REGEX FOR ACCOUNT NUMBER AND IFSC CODE
    let accountNumberPattern = /^\d*$/;  
    let ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    // ACCOUNT NUMBER HANDLING
    if (name === "accountNumber") {
      if (accountNumberPattern.test(value)) {
        if (value.length <= 18) {
          setBankDetails((prevValues) => ({
            ...prevValues,
            [name]: value,
          }));
          if (value.length >= 9 || value.length === 0) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              accountNumberError: false,
            }));
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              accountNumberError: true,
            }));
          }
        }
      }
    }

    // IFSC Code Handling
    if (name === "ifscCode") {
      const uppercasedValue = value.toUpperCase();
      if (uppercasedValue.length <= 11) {
        setBankDetails((prevValues) => ({
          ...prevValues,
          [name]: uppercasedValue,
        }));
        if (ifscPattern.test(uppercasedValue)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ifscCodeError: false,
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ifscCodeError: true,
          }));
        }
      }
    }
  };

  // BANK DETAILS SUBMIT HANDLE
  const handelBankDeailsSubmit = ()=>{
    const vcipkeyData = sessionStorage.getItem("vcipkey");
    const model = {
      vcipkey: vcipkeyData,
      accountnumber: bankDetails.accountNumber,
      ifsccode: bankDetails.ifscCode
    };
    dispatch(verifyAccountSagaAction({ model: model, callback: getBankAccountVerifyResp }));
  }

  const getBankAccountVerifyResp = (data) => {
    if (data?.respcode === "200") {
      navigate(RouteNames.EMPLOYEMENT_DETAILS)

    }
  }
  
  return (
    <>
      <Header
        title={"Bank Details"}
        navigate={() => navigate(-1)}
        hideHeader={false}
      />
      <article className="app-body">
        <div className="form-group">
          <label
            className="additional-info-label"
            style={{
              marginTop: "10px",
              fontSize: "16px",
              color: "black",
            }}
          >
            Account Number :
          </label>
          <input
            type="number"
            title="Please enter a valid bank account number, between 9 and 18 digits."
            className="form-control add-customer"
            style={{border:`${errors.accountNumberError ? "2px solid red": ""}`, height: "45px", boxShadow:"none"}}
            placeholder=""
            name="accountNumber"
            onChange={handleChange}
            value = {bankDetails.accountNumber}
            // style={{ height: "45px" }}
          />
           {errors.accountNumberError && (
              <span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                Bank Account Number must be at least 9 digits long
              </span>
            )}
          <label
            className="additional-info-label"
            style={{
              marginTop: "15px",
              fontSize: "16px",
              color: "black",
            }}
          >
            IFSC Code :
          </label>
          <input
            type="text"
            className="form-control add-customer"
            name="ifscCode"
            placeholder=""
            onChange={handleChange}
            value = {bankDetails.ifscCode}
            style={{border:`${errors.ifscCodeError ? "2px solid red": ""}`, height: "45px", boxShadow:"none"}}
          />
          {errors.ifscCodeError && (
            <span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              Invalid IFSC Code
            </span>
          )}
        </div>
        <div
          className="homepagebutton"
          style={{
            backgroundColor: "#FFFFFFF2",
            paddingBottom: "3%",
          }}
        >
          <AppFooter
            btnName={"Proceed"}
            navigate={handelBankDeailsSubmit}
            isDisabled={errors.accountNumberError ||errors.ifscCodeError || bankDetails?.accountNumber.length == 0 || bankDetails?.ifscCode.length == 0}
          />
        </div>
      </article>
    </>
  );
}

export default BankDetails;
