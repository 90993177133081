import React from "react";

const Div = (props) => {
  return (
    <div className={props?.className} style={{ backgroundColor: props?.bgClr }}>
      {props?.children}
    </div>
  );
};

export default Div;
