import React from "react";
import { Text } from "../../Pages/Language/Language";
import Lottie from "react-lottie";
import animation from "../../Pages/KYCCompleted/Abc.json";
import { useSelector } from "react-redux";
import H4 from "../Elements/H4";
import P from "../Elements/P";
import StatusCodes from "../../Constants/StatusCodes";
import Button from "../Elements/Button";

const KYCCompletedCmp = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    // here is where we will declare lottie animation
    // "animation" is what we imported before animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
    {
      props?.isClientTideAccess ? (
        <section className="kycinfomain">
          <div className="kycstatusheader">{props?.kycStatusTitle}</div>
          <p className="kycstausinfo">{props?.kycStatusTxt}</p>
          <div className="kycstausimg">
            {
              props?.retryimage ? (
                <img src={props?.kycStatusImg} height={"210px"}/>
              ):(
                <img src={props?.kycStatusImg} width={"80%"}/>
              )
            }
            
          </div>
        <div>
          {props?.ischoose == true ? (
            <Lottie
              options={defaultOptions}
              height="100%"
              width={300}
              style={{ position: "absolute", top: "0px" }}
            />
          ) : (
            false
          )}
        </div>
      </section>
      ):(
        <section className="app-start">
        <div>
          <div className="text-center mb-3">
            <img src={props?.kycStatusImg} alt="" />
            {/* {isPending && <img src="../images/KYC_process_icon.svg" alt="" />}
            {isCompleted && <img src="../images/completed.svg" alt="" />}
            {isUnderReview && (
              <img src="../images/auditor_pending.svg" alt="" />
            )}
            {isRejected && <img src="../images/google-docs.png" alt="" />} */}
            {/* {isUnderReview && <img src="../images/incomplete.png" alt="" />} */}
          </div>
          {/* <h4 className="title text-center">
            <Text tid="kyc_update" />
          </h4> */}
          <H4
            className="title text-center"
            title={props?.kycStatusTitle}
            color={cssProperties?.pagetitle_heading?.font_color}
            fontSize={cssProperties?.pagetitle_heading?.font_size}
          />
          {/* <p className="txt text-center">
            <Text tid="verification" />
          </p> */}
          {!props?.isRejected && (
            <P
              className={`txt text-center ${props?.isPending ? "text-danger" : ""}`}
              // txt={props?.customerVcipDetails?.respdesc}
              txt={props?.kycStatusTxt}
              color={cssProperties?.body?.font_color}
              // fontSize={cssProperties?.body?.font_size}
            />
          )}
          {/* <P
            className="txt text-center"
            txt={<Text tid="verification" />}
            color={cssProperties?.body?.font_color}
            fontSize={cssProperties?.body?.font_size}
          /> */}
          {props?.ischoose == true ? (
            <Lottie
              options={defaultOptions}
              height="100%"
              width={300}
              style={{ position: "absolute", top: "0px" }}
            />
          ) : (
            false
          )}
        </div>
        {/* {isPending ? (
          <Button
            className="btn mb-3"
            type="button"
            click={props?.retryKYC}
            title={"Re-try"}
            color={cssProperties?.button?.text_color}
            fontSize={cssProperties?.button?.font_size}
            backgroundColor={cssProperties?.button?.color}
          />
        ) : null} */}
      </section>
      )
    }
    </>
  );
};

export default KYCCompletedCmp;
