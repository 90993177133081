import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Text } from "../../Pages/Language/Language";
import H4 from "../Elements/H4";
import P from "../Elements/P";
import CleintNames from "../../Constants/ClientNames";

const SelectPANProcessCmp = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { app_env } = dynamicSettingObj;

  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
    clientName === CleintNames?.TIDEQA ||
    clientName === CleintNames?.TIDE
      ? true
      : false;
  const [passwordtoggle, setPasswordtoggle] = useState(false);
  return (
    <>
      <section className="app-start">
        <div className="app-body-img">
          <img src="../images/icon-identity.svg" alt="vkyc" />
        </div>
        <div className="app-body-data">
          <H4
            className="title"
            title={
              props?.iskinabank ? (
                <Text tid="upload_id" />
              ) : (
                <Text tid="upload_pan" />
              )
            }
            color={cssProperties?.pagetitle_heading?.font_color}
            fontSize={cssProperties?.pagetitle_heading?.font_size}
          />
          <P
            className="txt"
            txt={
              props?.iskinabank ? (
                <Text tid="please_select_below_option_to_submit_your_id_card" />
              ) : (
                <Text tid="please_select_below_option_to_submit_your_pan_card" />
              )
            }
            color={cssProperties?.body?.font_color}
            fontSize={cssProperties?.body?.font_size}
          />
          <hr />
        </div>
        <div>
          <div className="form-check mb-2">
            <input
              className="form-check-input"
              type="radio"
              name="panProcess"
              id="selectPanProcess1"
              onChange={(e) => props?.selectPanProcess(e, "CAPTUREPAN")}
              defaultChecked={props?.selctedPANStep === "CAPTUREPAN"}
            />
            <label className="form-check-label" htmlFor="selectPanProcess1">
              {props?.iskinabank ? (
                <Text tid="capture_id" />
              ) : (
                <Text tid="captuer_pan" />
              )}
            </label>
          </div>

          {props?.skipUploadPan == false && (
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="panProcess"
                id="selectPanProcess2"
                onChange={(e) => props?.selectPanProcess(e, "UPLOADPAN")}
                defaultChecked={props?.selctedPANStep === "UPLOADPAN"}
              />
              <label className="form-check-label" htmlFor="selectPanProcess2">
                <Text tid="upload_from_gallery" />
              </label>
            </div>
          )}

          {props?.selctedPANStep === "UPLOADPAN" && (
            <div>
              <div
                className="mb-3 position-relative"
                style={{ border: "1px solid #ced4da", borderRadius: "0.25rem" }}
              >
                <input
                  type="file"
                  className="form-control"
                  name="uploadpan"
                  onChange={(e) => props?.handleFileInputChange(e.target)}
                  accept="image/*"
                  // accept=".jpg, .jpeg, .png"
                  style={{
                    opacity: 0,
                    backgroundColor: "transparent",
                    zIndex: 99,
                  }}
                />
                <span
                  className="filename-display"
                  style={{
                    color: cssProperties?.button?.color,
                  }}
                >
                  {props?.fileimg}
                </span>

                <span
                  className="fileicon-display"
                  style={{
                    backgroundColor: cssProperties?.button?.color,
                  }}
                >
                  <img src="../images/icon-upload-white.svg" alt="vkyc" />
                </span>
              </div>
              {/* <span>Allowed types:png jpg jpeg.</span> */}
            </div>
          )}

          {/* {app_env == "tc_prod" ||
          app_env == "lq_prod" ||
          app_env == "unity_prod" || app_env == "lq_uat" ? null : (
            <>
              {!isClientTideAccess && (
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="panProcess"
                    id="selectPanProcess3"
                    onChange={(e) => props?.selectPanProcess(e, "EPAN")}
                    defaultChecked={props?.selctedPANStep === "EPAN"}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="selectPanProcess3"
                  >
                    <Text tid="submit_ePAN" />
                  </label>
                </div>
              )}
            </>
          )} */}
          {props?.selctedPANStep === "EPAN" && (
            <>
              <div
                className="mb-3 position-relative"
                style={{ border: "1px solid #ced4da", borderRadius: "0.25rem" }}
              >
                <input
                  type="file"
                  className="form-control"
                  name="epan"
                  onChange={(e) => props?.handleFileInputChange(e.target)}
                  accept="application/pdf"
                  style={{
                    opacity: 0,
                    backgroundColor: "transparent",
                    zIndex: 99,
                  }}
                />
                <span
                  className="filename-display"
                  style={{
                    color: cssProperties?.button?.color,
                  }}
                >
                  {props?.fileimg}
                </span>

                <span
                  className="fileicon-display"
                  style={{
                    backgroundColor: cssProperties?.button?.color,
                  }}
                >
                  <img src="../images/icon-upload-white.svg" alt="vkyc" />
                </span>
              </div>
              <div
                className="mb-3 position-relative"
                style={{ border: "1px solid #ced4da", borderRadius: "0.25rem" }}
              >
                <input
                  type={`${passwordtoggle ? "text" : "password"}`}
                  className="form-control"
                  name="secretekey"
                  placeholder="Enter password"
                  onChange={(e) =>
                    props?.handelPasswordInputChange(e.target.value)
                  }
                  style={{ opacity: "0.9", position: "relative" }}
                />
                <img
                  className="fileicon-display"
                  onClick={() => {
                    setPasswordtoggle(!passwordtoggle);
                  }}
                  src={!passwordtoggle ? "images/hide.png" : "images/show.png"}
                  alt="show"
                  style={{ position: "absolute", width: "32px", zIndex: "99" }}
                />
              </div>
            </>
          )}

          {/* form60 */}
          {/* <div className="form-check mb-2">
            <input
              className="form-check-input"
              type="radio"
              name="panProcess"
              id="selectPanProcess4"
              onChange={(e) => props?.selectPanProcess(e, "NOPAN")}
              defaultChecked={props?.selctedPANStep === "NOPAN"}
            />
            <label className="form-check-label" htmlFor="selectPanProcess4">
              No PAN Card
            </label>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default SelectPANProcessCmp;
