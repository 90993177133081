import React from 'react'
import { Text } from '../../Pages/Language/Language'

const AadhaarKYCCmp = () => {
  return (
    <>
      <section className="app-start">
        <div className="app-body-img">
          <img src="../images/identity.svg" alt="vkyc" />
        </div>
        <div className="app-body-data">
          <h4 className="title text-left">
            <Text tid="xml_file" />
            {/* Do you have an Aadhaar XML file? */}
          </h4>
        </div>
        <div>
          <div className="custom-control custom-radio mb-2">
            <input type="radio" id="yes" name="xml" className="custom-control-input" />
            <label className="custom-control-label" htmlFor="yes">
              <Text tid="yes" />

              {/* Yes */}
            </label>
          </div>
          <div className="custom-control custom-radio mb-2">
            <input type="radio" id="no" name="xml" className="custom-control-input" />
            <label className="custom-control-label" htmlFor="no">
              <Text tid="no" />

              {/* No */}
            </label>
          </div>
        </div>
      </section>
    </>
  )
}

export default AadhaarKYCCmp