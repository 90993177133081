import { all } from 'redux-saga/effects';
import AdharWatcherSaga from './AdharWatcherSaga';
import CommonWatcherSaga from './CommonWatcherSaga';
import getVcipDetailsWatcherSaga from './GetVcipDetailsWatcherSaga';
import InitiateVCWatcherSaga from './InitiateVCWatcherSaga';
import PanWatcherSaga from './PanWatcherSaga';
import VideoWatcherSaga from './VideoWatcherSaga';
import AdharEKYCWatcherSaga from './AdharEKYCWatcherSaga';
import LivekitVideoWatcherSaga from './LivekitVideoWatcherSaga';
import HundredMsLiveWatcherSaga from './100msLiveWatcherSaga';
import BankDetailsSaga from './BankDetailsWatcherSaga';

export default function* rootSage() {
    yield all([
        CommonWatcherSaga(),
        getVcipDetailsWatcherSaga(),
        AdharWatcherSaga(),
        PanWatcherSaga(),
        InitiateVCWatcherSaga(),
        VideoWatcherSaga(),
        AdharEKYCWatcherSaga(),
        LivekitVideoWatcherSaga(),
        HundredMsLiveWatcherSaga(),
        BankDetailsSaga()
    ])
}