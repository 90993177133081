import {
    ACTION_ADD_SINGLE_CUSTOMER_REQ,
    ACTION_DELETE_CUSTOMER_REQ,
    ACTION_GET_SINGLE_CUSTOMER_REQ,
    ACTION_GET_VC_INITIATE_CONFERENCE_QUEUE_REQ,
    ACTION_GET_VC_JOIN_VIDEO_SESSION_REQ,
    ACTION_GET_VC_LANGUAGES_REQ,
    ACTION_GET_VC_RESCHEDULE_REQ,
    ACTION_GET_VC_SCHEDULE_CALENDER_REQ,
    ACTION_GET_VC_SCHEDULE_CANCEL_REQ,
    ACTION_GET_VC_SCHEDULE_DETAILS_REQ,
    ACTION_GET_VC_UPDATED_TOKEN_REQ
} from "./SagaActionTypes";

export const actionGetLanguagesSagaAction = (payload) => {
    return {
        type: ACTION_GET_VC_LANGUAGES_REQ,
        payload: payload
    }
}

export const actionGetInitiateConferenceQueueSagaAction = (payload) => {
    return {
        type: ACTION_GET_VC_INITIATE_CONFERENCE_QUEUE_REQ,
        payload: payload
    }
}

export const actionAddSingleCustomer = (payload)=>{
    return{
        type:ACTION_ADD_SINGLE_CUSTOMER_REQ,
        payload
    }
}

export const actionGetSingleCustomer = (payload)=>{
    return{
        type:ACTION_GET_SINGLE_CUSTOMER_REQ,
        payload
    }
}

export const deleteCustomerAction = (payload)=>{
    return{
        type:ACTION_DELETE_CUSTOMER_REQ,
        payload
    }
}

export const actionGetUpdatedTokenSagaAction = (payload) => {
    return {
        type: ACTION_GET_VC_UPDATED_TOKEN_REQ,
        payload: payload
    }
}

export const actionRescheduleSagaAction = (payload) => {
    return {
        type: ACTION_GET_VC_RESCHEDULE_REQ,
        payload: payload
    }
}

export const actionGetScheduleCalenderAction = (payload) => {
    return {
        type: ACTION_GET_VC_SCHEDULE_CALENDER_REQ,
        payload: payload
    }
}

export const actionGetScheduleDetailsAction = (payload) => {
    return {
        type: ACTION_GET_VC_SCHEDULE_DETAILS_REQ,
        payload: payload
    }
}

export const actionCancelScheduleAction = (payload) => {
    return {
        type: ACTION_GET_VC_SCHEDULE_CANCEL_REQ,
        payload: payload
    }
}

export const joinVideoSessionAction = (payload) => {
    return {
        type: ACTION_GET_VC_JOIN_VIDEO_SESSION_REQ,
        payload: payload
    }
}
