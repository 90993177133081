import React,{useEffect} from 'react';
import { useSelector } from 'react-redux';
import H5 from "../../Components/Elements/H5";
import AppFooter from '../Common/AppFooter';
import { Text } from "../Language/Language";
import { useState } from 'react';
import { detect } from "detect-browser";
// import Button from "../../Components/Elements/Button";



const Tablepopup = ({ ipdata, closeModal,isUnimony }) => {
    const [arrow, setArrow] = useState(false);
    const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
    const [ios,setIos] = useState(false)

    const browser = detect();
    useEffect(()=>{
        // console.log("22222222222222",ipdata)
        Object.keys(ipdata)?.forEach((item, i) => {
            if(item == "is_icloud_relay" && (
                ipdata[item] === true
            ) ){
                setIos(true)
            }
           
          });
    },[])

    return (
        <>
            {/*  */}
            <div className='ipdata'>
                <div
                    className="closebutton"
                    data-dismiss="modal"
                    onClick={closeModal}
                >
                    <img src="images/icon-close.svg" alt="close" />
                </div>


                {/* <div className="modal-header align-items-center ">
                    <H5
                        className="modal-title text-center w-100 fontBold"
                        title={<Text tid="request" />}
                        color={cssProperties?.sub_heading?.font_color}
                        fontSize={cssProperties?.pagetitle_heading?.font_size}
                    />


                </div> */}
                <div className='ipChange' style={{
                    textAlign: 'center', fontWeight: 'bold'
                    , fontSize: '22px', marginTop: '5%', paddingBottom: '12px'
                }}><Text tid="request"/></div>

                <div className="changeContainer">
                    {/* <div className='reqTxt'>Request</div> */}
                    <div className='changeNtwkImg'>
                        <img src="../images/change-network.svg" />
                    </div>
                    <div className='changeNtwkTxt'><Text tid="ip_threat_detected"/></div>
                </div>

                <div className="connectNtwk" >
                    <Text tid="please_connect_to_another_network" />
                    <br /> <Text tid="as_your_ip_address_is_not_healthy"/>

                </div>
                {
                    ios && (
                        <div className="ioserr">
                        <Text tid="please_switch_off_the_iCloud_relay"/>
                         <br />  <Text tid="if_using_a_iPhone_for_doing_the_VKYC" />
                     </div>
                    )
                }
                
                <div class="text-center">
                    <button class="inspectBtn  w-50 mb-3  "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#inspectBtn"
                        aria-expanded="false"
                        aria-controls="inspectBtn2"
                        style={{
                            backgroundColor: cssProperties?.button?.color,
                            fontSize: cssProperties?.button?.font_size,
                            color: cssProperties?.button?.text_color,
                        }}
                        onClick={() => setArrow(() => !arrow)}
                    >
                        <Text tid="inspect"/>
                        <img src={!arrow ? "../images/downarrow.svg" : "../images/uparrow.svg"}
                            className='trans-img'
                            alt="downarrow" />

                    </button>

                </div>
                <div class="row">
                    <div class="col">
                        <div class="collapse multi-collapse" id="inspectBtn">
                            <div class="card card-body m-2 border-0 ">
                                <div className='tableContainer'>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr style={{
                                                backgroundColor: "#D9D9D9"
                                            }}>
                                                <th className='keys'>Key</th>
                                                <th className='values'>Value</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(ipdata)?.map((item, i) => (
                                                ((ipdata[item] === true) && (item=="is_tor" || item=="is_proxy"||item=="is_datacenter" && isUnimony == false ||item == "is_icloud_relay" ))&& (
                                                    <tr key={i}>
                                                        <td className={ipdata[item] ? 'text-danger' : 'text-success'}>{item}</td>
                                                        <td className={ipdata[item] ? 'text-danger' : 'text-success'}> {ipdata[item] ? 'True' : 'False'}</td>
                                                    </tr>)
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default Tablepopup