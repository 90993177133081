import React from "react";

const NetworkSvgIcon = ({color}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512.004 512.004"
      style={{ enableBackground: "new 0 0 512.004 512.004" }}
      xmlSpace="preserve"
      fill={color ? color : 'black'}
      width={12} 
      height={12}
      className="network-svg"
    >
      <g>
        <g>
          <path d="M155.314,4.692c-6.256-6.256-16.368-6.256-22.624,0l-96,96l22.624,22.624l68.688-68.688v425.376h32V54.628l68.688,68.688 l22.624-22.624L155.314,4.692z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M452.69,388.692l-68.688,68.688V32.004h-32V457.38l-68.688-68.688l-22.624,22.624l96,96 c3.12,3.12,7.216,4.688,11.312,4.688s8.192-1.568,11.312-4.688l96-96L452.69,388.692z" />
        </g>
      </g>
    </svg>
  );
};

export default NetworkSvgIcon;
