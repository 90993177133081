import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import RouteNames from "../Constants/RouteNames";
import customeCodes from "../Constants/customeCodes";
import { streamingEventByCustomerAction } from "../Store/SagaActions/CommonSagaActions";
import NetworkSvgIcon from "./NetworkSvgIcon";
var tabminimise = "";
var networktimeout;
const NetworkLayer = () => {
  const [speed, setSpeed] = useState("");
  let location = useLocation();
  const pathName = location.pathname;

  const dispatch = useDispatch();

  useEffect(() => {
    network();
    return (
      clearTimeout(networktimeout)
    );
  }, []);

  let networkspeed;
  const network = () => {
    // var imageUrl = "https://images.pexels.com/photos/20787/pexels-photo.jpg?";
    // const imageUrl = "https://syntizen.com/91.png";
    const imgPath = `${window?.location?.origin?.toString()}/images/91.png`;
    const imageUrl = imgPath || "https://syntizen.com/91.png";
    let blob;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", imageUrl, true);
    let netspeed = networkspeed;
    let speedtimeout = setTimeout (()=>{
      if(networkspeed?.length > 0 && netspeed == networkspeed){
        var randomNumber = Math.random() * (0.150 - 0.050) + 0.050;
        var roundedNumber = randomNumber.toFixed(2);
        const model = {
          streamingKey: customeCodes.SEND_CUSTOMER_NET_SPEED_TO_AGENT,
          streamingValue: roundedNumber,
          notificationid: "2023",
          notifications: roundedNumber,
        };
        if (window?.location?.pathname?.includes("/session/")) {
          dispatch(streamingEventByCustomerAction(model));
        }
        setSpeed(roundedNumber)
      }else{
       clearTimeout(speedtimeout)
      }
    },5000)
    xhr.responseType = "blob";
    xhr.onload = function () {
      blob = xhr.response;
      // console.log("blob", blob.size);
    };
    xhr.send();
    var downloadSize = 91122; //bytes
    // var downloadSize = 19785; //bytes
    async function ShowProgressMessage(msg) {
      if (console) {
        let pathname = window?.location?.pathname;
        if (typeof msg == "string") {
          console.log(msg);
          if(msg == "Invalid image, or error downloading"){
            networktimeout = setTimeout(() => {
              network();
            }, 10000);
          }
          //   setNetworkloader(false);
          // setError(msg)
        } else {
          // console.log("msg is",msg.length)
          for (var i = 0; i < 1; i++) {
            // console.log("networkspeed", msg[i], "mbps");
            networkspeed = msg[i];
              networktimeout = setTimeout(() => {
                network();
              }, 2000);
            setSpeed(networkspeed);
            // console.log("speed is",networkspeed,"Mbps")
            // networkready();
            await document.addEventListener("visibilitychange", () => {
              if (document.visibilityState === "visible") {
                tabminimise = false;
              } else {
                tabminimise = true;
              }
            });
            const model = {
              streamingKey: customeCodes.SEND_CUSTOMER_NET_SPEED_TO_AGENT,
              streamingValue: networkspeed,
              notificationid: "2023",
              notifications: networkspeed,
              tabminimise: tabminimise
            };
            if (pathname?.includes("/session/")) {
              dispatch(streamingEventByCustomerAction(model));
            }
          }
        }
      }
    }
    function InitiateSpeedDetection() {
      // ShowProgressMessage("Loading the image, please wait...");
      // console.log("hfgfrfgfh")
      //   setNetworkloader(true);
      window.setTimeout(MeasureConnectionSpeed, 1);
    }

    if (window.addEventListener) {
      // window?.addEventListener("load", InitiateSpeedDetection(), false);
      InitiateSpeedDetection();
    } else if (window.attachEvent) {
      window?.attachEvent("onload", InitiateSpeedDetection);
    }

    function MeasureConnectionSpeed() {
      var startTime, endTime;
      var download = new Image();
      download.onload = function () {
        endTime = new Date().getTime();
        showResults();
      };
      download.onerror = function (err, msg) {
        ShowProgressMessage("Invalid image, or error downloading");
        // setTimeout(()=>{
        //   network()
        // },5000)
      };
      startTime = new Date().getTime();
      var cacheBuster = "?nnn=" + startTime;
      // console.log(cacheBuster)
      download.src = imageUrl + cacheBuster;
      // console.log(download)

      function showResults() {
        var duration = (endTime - startTime) / 1000;
        var bitsLoaded = downloadSize * 8;
        var speedBps = (bitsLoaded / duration).toFixed(2);
        var speedKbps = (speedBps / 1024).toFixed(2);
        var speedMbps = (speedKbps / 1024).toFixed(2);
        // console.log(speedMbps, "speedMbps");
        // ShowProgressMessage([
        //     // "Your connection speed is:",
        //     // speedBps + " bps",
        //     // speedKbps + " kbps",
        //     speedMbps
        // ]);
        ShowProgressMessage([
          // "Your connection speed is:",
          // speedBps + " bps",
          // speedKbps + " kbps",
          speedMbps,
        ]);
      }
    }
  };
  return (
    <div className="network-layer">
      {
        pathName != RouteNames.KYC_PROCESS_COMPLETED && (
          speed ? <>
            <NetworkSvgIcon
              color={speed >
                customeCodes.MAX_NET_SPEED
                ? "green"
                : speed > customeCodes.MIN_NET_SPEED && speed < customeCodes.MAX_NET_SPEED
                  ? "orange"
                  : "red"} />
            <span id="customerinternet"
              style={{
                color:
                  speed > customeCodes.MAX_NET_SPEED
                    ? "green"
                    : speed > customeCodes.MIN_NET_SPEED && speed < customeCodes.MAX_NET_SPEED
                      ? "orange"
                      : "red",
              }}
            >
              {" "}
              {""}
              {speed ? speed : 0}{" "}
              Mb/s
            </span>
          </> : "Checking Network..."
        )
      }

    </div>
  );
};

export default NetworkLayer;
