import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
// import Gleap from 'gleap';

import App from './App';
import store from './Store/store';
// import Tracker from '@openreplay/tracker';
// import trackerAxios from '@openreplay/tracker-axios';
// import * as Sentry from "@sentry/react";

// import posthog from 'posthog-js'
// import { PostHogProvider } from 'posthog-js/react'
import { HMSRoomProvider } from "@100mslive/react-sdk";


// import reportWebVitals from './reportWebVitals';

// if(process.env.REACT_APP_ENV == "tc_prod" || process.env.REACT_APP_ENV == "lq_prod" || process.env.REACT_APP_ENV == "unity_prod"){
//   // Initializing gleep here
//   Gleap.initialize(process.env.REACT_APP_GLEAP_ID);
//   setTimeout(() => {
//     Gleap.showFeedbackButton(false);
//   }, 2000);

//   // Gleep sends a silent crash report.
//   Gleap.sendSilentCrashReport(
//     {
//       screenshot: true,
//       replays: true,
//       consoleLog: true,
//       networkLogs: true
//     }
//   );
// }


// Initializing sentry
// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,

//   integrations: [new Sentry.BrowserTracing()],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// const tracker = new Tracker({
//   projectKey: process.env.REACT_APP_PROJECT_KEY,
//   ingestPoint: process.env.REACT_APP_INGEST_POINT || "https://openreplay-cloud.syntizen.app/ingest",
//   __DISABLE_SECURE_MODE : true,
//   network: {
//     capturePayload: true // Capture HTTP payload
//   }
// });
// const options ={
//   // instance: AxiosInstance,
//   failuresOnly: false,
//   capturePayload: true
//   // captureWhen: (AxiosRequestConfig) => boolean,
//   // sessionTokenHeader: string,
//   // ignoreHeaders: Array<string> | boolean,
//   // sanitiser: (RequestResponseData) => RequestResponseData | null
// };

// tracker.use(trackerAxios(options));
// tracker.start();

// posthog.init('', { api_host: 'https://app.posthog.com' })
// posthog.init('phc_42TY4Gd1GNmi9HGjSABvjzK8JQCNCx1uhn7co8eJXHF', { api_host: 'https://app.posthog.com' })


ReactDOM.render(
  <Provider store={store}>
    {/* <PostHogProvider client={posthog} > */}
      <HMSRoomProvider>
        <Router>
          <App />
        </Router>
      </HMSRoomProvider>
    {/* </PostHogProvider> */}
  </Provider>,
  document.getElementById('root')
);