import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Text } from "../../Pages/Language/Language";
import Button from "../Elements/Button";
import H4 from "../Elements/H4";
import P from "../Elements/P";
import DigilockerLoader from "../TideComponents/DigilokerLoader/DigilockerLoader";
import "../TideComponents/tideglobal.css";
import RouteNames from "../../Constants/RouteNames";


const AadhaarDigiLockerCmp = (props) => {
  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { app_env } = dynamicSettingObj;
  const [isloading, setLoading] = useState(true);
  const navigate = useNavigate()

  const navigateekyc = (e) => {
    e.preventDefault();

    if (props?.isClientTideAccess) {
      return;
    } else if (app_env == "tc_uat") {
      navigate(RouteNames.AADHAR_EKYC_PROCESS_UPLOAD)  // For EKYC
    }
    else {
      navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD)   //For Offline Kyc
    }
  }

  return (
    <>
      <section className="app-start">
        {!props?.isAadharSuccess ? (
          <>
            {props?.isClientTideAccess ? (
              <div>
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: "10%" }}
                >
                  {(isloading && props?.isClientTideAccess) && <DigilockerLoader />}
                </div>
                <div
                  style={{
                    fontWight: "400",
                    fontSize: "20px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: "#343434",
                    textAlign: "center",
                    marginTop: "10%",
                  }}
                >
                  <Text tid="please_wait" />
                </div>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    textAlign: "center",
                    letterSpacing: "0.25px",
                    color: "#343434",
                    marginBottom: "10%",
                  }}
                >
                  <Text tid="connection_you_to_digilocker" />
                </div>

                <Button
                  // className="btn"
                  type="button"
                  click={props?.digiLockerAction}
                  title={<Text tid="proceed_with_digilocker" />}
                  isClientTideAccess={props?.isClientTideAccess}
                // color={cssProperties?.button?.text_color}
                // fontSize={cssProperties?.button?.font_size}
                // backgroundColor={cssProperties?.button?.color}
                />
                {
                  app_env == "tc_uat" && !props?.isClientTideAccess && (
                    <p
                      style={{
                        fontSize: "14px",
                        letterSpacing: "0.15px",
                        color: "rgb(0, 13, 46)",
                        textAlign: "center",
                        marginTop: "20px"
                      }}
                    >
                      {/* If DigiLocker Fails Proceed with Okyc: */}
                      <Text tid="if_you_are_unable_to_complete_aadhar_verification_with_digilocker" />
                      <a href="" onClick={navigateekyc}><Text tid="click_here" /></a>
                    </p>
                  )
                }

                {/* <div
                  style={{
                    fontWight: "400",
                    fontSize: "24px",
                    lineHeight: "32px",
                    letterSpacing: "0.15px",
                    color: "#343434",
                    // textAlign: "center",
                    marginTop: "8%",
                    marginBottom: "4%"
                  }}
                >
                  If DigiLocker Fails Proceed with Ekyc:
                </div>
                <Button
                  // className="btn"
                  type="button"
                  click={navigateekyc}
                  title={"Ekyc"}
                  isClientTideAccess={props?.isClientTideAccess}
                  // color={cssProperties?.button?.text_color}
                  // fontSize={cssProperties?.button?.font_size}
                  // backgroundColor={cssProperties?.button?.color}
                /> */}
              </div>
            ) : (
              <div style={{ marginTop: "100px" }}>
                <div className="text-center mb-3">
                  <img
                    src="../images/waiting.svg"
                    className="spin-loader"
                    alt="waiting"
                  />
                </div>
                <H4
                  className="title text-center"
                  title={<Text tid="please_wait" />}
                  //   titleClr2={cssProperties?.titleClr2}
                  color={cssProperties?.pagetitle_heading?.font_color}
                  fontSize={cssProperties?.pagetitle_heading?.font_size}
                />
                {/* <h4 className="title text-center">
              <Text tid="please_wait" />
            </h4> */}
                <P
                  className="txt text-center"
                  // txt={<Text tid="fetching_aadhar" />}
                  txt={<Text tid="proceed_with_digilocker_for_aadhar_verification" />}
                  //   txtClr={cssProperties.txtClr}
                  color={cssProperties?.body?.font_color}
                  fontSize={cssProperties?.body?.font_size}
                />
                {/* <p className="txt text-center">
              <Text tid="fetching_aadhar" />
            </p> */}
                {/* <button className="btn" onClick={props?.digiLockerAction}>
              Digi-Locker
            </button> */}
                <Button
                  className="btn"
                  type="button"
                  click={props?.digiLockerAction}
                  title={<Text tid="proceed_with_digilocker" />}
                  color={cssProperties?.button?.text_color}
                  fontSize={cssProperties?.button?.font_size}
                  backgroundColor={cssProperties?.button?.color}
                />
                {/* <p
                  style={{
                    fontSize: "14px",
                    letterSpacing: "0.15px",
                    color: "rgb(0, 13, 46)",
                    textAlign: "center",
                    marginTop: "20px"
                  }}
                >
                  <Text tid="if_you_are_unable_to_complete_aadhar_verification_with_digilocker" /> <a href="" onClick={navigateekyc}><Text tid="click_here" /></a>
                </p> */}
                {/* <Button
                  className="btn"
                  type="button"
                  click={navigateekyc}
                  title={"Proceed with okyc"}
                  color={cssProperties?.button?.text_color}
                  fontSize={cssProperties?.button?.font_size}
                  backgroundColor={cssProperties?.button?.color}
                  // color={cssProperties?.button?.text_color}
                  // fontSize={cssProperties?.button?.font_size}
                  // backgroundColor={cssProperties?.button?.color}
                /> */}

              </div>
            )}
          </>
        ) : (
          <>
            {props?.isAadharSuccess && !props?.isClientTideAccess ? (
              <>
                <div style={{ overflow: "scroll" }}>
                  <div className="aadhar-details">
                    {/* <h4 className="title mb-3 mt-3">Aadhar Details</h4> */}
                    <div className="ocr-pht">
                      <img
                        src={"data:image/png;base64," + props?.aadharDetails?.pht}
                        alt="photo"
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={props?.aadharDetails?.name}
                        id="floatingInput"
                        readOnly
                      />
                      <label htmlFor="floatingInput">
                        <Text tid="name" />
                        {/* Name */}
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={props?.aadharDetails?.fname}
                        id="floatingInput"
                        readOnly
                      />
                      <label htmlFor="floatingInput">
                        <Text tid="father_name" />

                        {/* Father Name */}
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={props?.aadharDetails?.uid}
                        id="floatingInput"
                        readOnly
                      />
                      <label htmlFor="floatingInput">
                        <Text tid="uid" />

                        {/* UID */}
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={props?.aadharDetails?.dob}
                        id="floatingInput"
                        readOnly
                      />
                      <label htmlFor="floatingInput">
                        <Text tid="dob" />

                        {/* DOB */}
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={props?.aadharDetails?.gender}
                        id="floatingInput"
                        readOnly
                      />
                      <label htmlFor="floatingInput">
                        <Text tid="gender" />

                        {/* Gender */}
                      </label>
                    </div>
                    <div className="form-floating cus-textareaKYC mb-2">
                      <textarea
                        className="form-control"
                        id="floatingTextarea"
                        defaultValue={props?.aadharDetails?.address}
                        style={{
                          paddingTop: "0px",
                          height: "120px"
                        }}
                        readOnly
                      />
                      <label htmlFor="floatingTextarea">
                        <Text tid="Address" />

                        {/* Address */}
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div style={{ overflow: "scroll" }}>
                  <div className="tideaadhardatatitle"><Text tid="aadhar_kyc_details" /></div>
                  {props?.aadharDetails?.pht ? (
                    <div className="tideaadhaarphoto">
                      <img
                        src={"data:image/png;base64," + props?.aadharDetails?.pht}
                        alt="photo"
                        width={80}
                        height={80}
                        className="tideaadhharimg"
                      />
                    </div>
                  ) : (
                    <div className="tideaadhaarphoto">
                      <img
                        src="/images/OIP.jfif"
                        alt="photo"
                        width={80}
                        height={80}
                        className="tideaadhharimg"
                      />
                    </div>
                  )}
                  <div>
                    <label className="tablelabel"><Text tid="name" /></label>
                    <input
                      type="text"
                      className="tableinput"
                      defaultValue={props?.aadharDetails?.name}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="tablelabel"><Text tid="father_name" /></label>
                    <input
                      type="text"
                      className="tableinput"
                      defaultValue={props?.aadharDetails?.fname}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="tablelabel"><Text tid="uuid" /></label>
                    <input
                      type="text"
                      className="tableinput"
                      defaultValue={props?.aadharDetails?.uid}
                      readOnly
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <div>
                      <label className="tablelabel"><Text tid="date_of_birth" /></label>
                      <input
                        type="text"
                        className="tableinput"
                        defaultValue={props?.aadharDetails?.dob}
                        readOnly
                      />
                      <div className="calendericon">
                        {props?.aadharDetails?.dob ? (
                          <img
                            src="/images/Union.png"
                            style={{ position: "absolute", top: "-5px" }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="tablelabel"><Text tid="Address" /></label>
                    <textarea
                      type="text"
                      className="tableaddressinput"
                      defaultValue={props?.aadharDetails?.address}
                      readOnly
                      style={{
                        paddingTop: "0px",
                        height: "120px"
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default AadhaarDigiLockerCmp;
